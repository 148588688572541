// IMPORT LIBS
import React from "react";
import { connect } from "react-redux";
// IMPORT LIBS
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Divider } from "primereact/divider";
import { PointSpreadLoading } from "react-loadingg";
// IMPORT STORE
import * as actionTypeReport from "../../../../store/report/actionReport";
//IMPORT STYLES
import "primeflex/primeflex.css";
//IMPORT API
import * as API from "../../../../api/energy-model/energyServices";
//IMPORT COMPONENTS
import MapDemandAzure from "./map-bubble/mapBubbleDemandAzure";
import MapPieChartAzure from "./map-renew-gas/mapPieChartDemandAzure";
import MapPieChartSupplyDivision from "./map-supply-demand/mapPieChartSuppDemand";
import Loading from "../../../../components/Loading";
// IMPORT SETTINGS
import { MAP_LIST } from "./mapSettings";
import SideFilter from "../../../../components/Filters";

import { withMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import { extractYearArray, range } from "../../../../utils/comm";

// MAIN

const dtIntervals = 1000;

const convertToMapDataSource = (resData, currYear) => {
    if (resData) {
        let fltData = resData
            .filter((r) => r.Year === currYear && r.Latitude && r.Longitude)
            .map((d) => {
                if (d) {
                    let demand = parseFloat(d.PowerDemandMWavg);
                    let balance = parseFloat(d.BalanceMWavg);
                    let renew = parseFloat(d.RenewableMWavg);
                    let gas = balance < 0 ? Math.abs(balance) : 0.0;
                    let solar = parseFloat(d.SolarMWavg);
                    let wind = parseFloat(d.WindMWavg);
                    let supply = renew + gas;
                    return {
                        Year: parseInt(d.Year),
                        SubstationID: parseInt(d.SubstationID),
                        Substation: d.Substation,
                        Latitude: parseFloat(d.Latitude),
                        Longitude: parseFloat(d.Longitude),
                        demand: demand,
                        renew: renew,
                        balance: balance,
                        wind: wind,
                        solar: solar,
                        gas: gas,
                        supply: supply,
                    };
                } else {
                    return {};
                }
            });

        let fltDataSorted = fltData.sort((a, b) => (a.SubstationID > b.SubstationID ? 1 : b.SubstationID > a.SubstationID ? -1 : 0));
        return fltDataSorted;
    }
    return null;
};

class MapDemandMain extends React.Component {
    state = {
        mapDataSource: null,
        selectedYear: 2021,
        selectedMap: MAP_LIST[0],
        minYear: null,
        maxYear: null,
        animate: false,
        timeInterval: null,
        subStationData: null,
        maxPower: null,
        isLoading: false,
        hasToken: true,
        years: [],
    };

    setScenario = (scenario) => {
        if (scenario) {
            if (this.state.subStationData) {
                let resData = this.state.subStationData.filter((r) => r.PathwayScenarioID === scenario.PathwayScenarioID);
                let mapDataSource = convertToMapDataSource(resData, this.state.selectedYear);
                this.setState({
                    mapDataSource: mapDataSource,
                });
            }
        }
    };

    setYear = (year) => {
        if (year) {
            let mapDataSource = null;
            if (this.state.subStationData) {
                let resData = this.state.subStationData.filter((r) => r.PathwayScenarioID === this.props.selectedScenario.PathwayScenarioID);
                mapDataSource = convertToMapDataSource(resData, year);
            }
            this.setState({
                selectedYear: year,
                mapDataSource: mapDataSource,
            });
        }
    };

    componentDidMount() {
        this.setState({
            isLoading: true,
            // maxYear: this.props.years ? this.props.years[this.props.years.length - 1] : null,
            // minYear: this.props.years ? this.props.years[0] : null,
        });
        let scenario = this.props.selectedScenario; // scenarios[0];

        setAccessToken(this.props.msalContext.instance, this.props.msalContext.accounts).then(
            (resolve) => {
                API.getAllSubstationBalance().then((res) => {
                    if (!res.hasError) {
                        const projID = this.props.selectedUserAccess.ProjectID;
                        // let yrs = extractYearArray(res.data, "Year").filter((y) => y > 2020);
                        const syear = 2021;
                        const eyear = projID === 1 ? 2050 : 2030;
                        const yrs = range(syear, eyear + 1);
                        this.setState({
                            years: yrs,
                            maxYear: yrs ? yrs[yrs.length - 1] : null,
                            minYear: yrs ? yrs[0] : null,
                        });

                        const fltData = projID === 1 ? res.data.filter((r) => r.SubstationID < 23) : res.data.filter((r) => r.SubstationID >= 23);

                        let max = fltData.reduce(function (prev, current) {
                            return parseFloat(prev.PowerDemandMWavg) > parseFloat(current.PowerDemandMWavg) ? prev : current;
                        }).PowerDemandMWavg;

                        this.setState({
                            subStationData: fltData,
                            maxPower: max,
                        });
                        this.setScenario(scenario);
                    } else {
                        throw new Error(res.message);
                    }
                    this.setState({
                        hasToken: true,
                        isLoading: false,
                    });
                });
            },
            (err) => {
                this.setState({
                    hasToken: false,
                });
            }
        );
    }

    handleChangeScenario = (selectedScenario) => {
        this.setScenario(selectedScenario);
    };

    handleChangeYear = (e) => {
        let selectedYear = parseInt(e.target.value);
        this.setYear(selectedYear);
    };

    handleChangeMapSelection = (e) => {
        let selectedMap = MAP_LIST.filter((m) => m.name === e.target.value)[0];
        console.log(selectedMap);
        this.setState({
            selectedMap: selectedMap,
        });
    };

    handleToggleChange = (e) => {
        let toggle = !this.state.animate;
        let timeInterval = null;
        if (toggle) {
            timeInterval = setInterval(this.animateOverYears, dtIntervals);
        } else {
            clearInterval(this.state.timeInterval);
        }

        this.setState({
            animate: toggle,
            timeInterval: timeInterval,
        });
    };

    animateOverYears = () => {
        let year = this.state.selectedYear >= this.state.maxYear ? this.state.minYear : this.state.selectedYear + 1;
        this.setYear(year);
    };

    dummyFunc = () => {};

    render() {
        if (!this.state.hasToken) {
            return <RefreshSignIn />;
        }

        let customFilt = (
            <React.Fragment>
                <Divider style={{ marginTop: "20px" }} align="left" type="solid">
                    <b>Animate Over Years</b>
                </Divider>
                <ToggleButton onLabel="Stop" offLabel="Animate" onIcon="pi pi-check" offIcon="pi pi-times" checked={this.state.animate} onChange={this.handleToggleChange} />
                <Divider style={{ marginTop: "20px" }} align="left" type="solid">
                    <b>Years</b>
                </Divider>
                <Dropdown value={this.state.selectedYear} options={this.state.years} onChange={this.handleChangeYear} placeholder="Select a year" />
                <div>
                    <Divider style={{ marginTop: "20px" }} align="left" type="solid">
                        <b>Map View</b>
                    </Divider>
                    <Dropdown optionLabel="disp" optionValue="name" value={this.state.selectedMap.name} options={MAP_LIST} onChange={this.handleChangeMapSelection} placeholder="Select a Map" />
                </div>
            </React.Fragment>
        );

        const sideFilters = (
            <SideFilter
                hasScenarioFilter={true}
                hasBaselineFilter={false}
                hasTargetVerFilter={false}
                hasSiteFilter={false}
                hasTMMSOPFilter={false}
                hasSiteSearchBar={false}
                handleScenarioChange={this.handleChangeScenario}
                handleTargetVerChange={this.dummyFunc}
                handleBaselineVerChange={this.dummyFunc}
                onChangeCheckedSites={this.dummyFunc}
                handleTMMSOPVerChange={this.dummyFunc}
                isOverlay={false}
                maxHeightSiteFilter="400px"
                customFilt={customFilt}
            />
        );

        let mapView = <Loading comp={<PointSpreadLoading />} />;
        if (!this.state.isLoading) {
            switch (this.state.selectedMap.name) {
                case MAP_LIST[0].name:
                    mapView = <MapDemandAzure dataSource={this.state.mapDataSource} maxValue={this.state.maxPower} field="demand" />;
                    break;

                case MAP_LIST[1].name:
                    mapView = <MapPieChartAzure dataSource={this.state.mapDataSource} maxValue={this.state.maxPower} field="demand" />;
                    break;

                case MAP_LIST[2].name:
                    mapView = <MapPieChartSupplyDivision dataSource={this.state.mapDataSource} maxValue={this.state.maxPower} field="supply" />;
                    break;

                default:
                    break;
            }
        }

        return (
            <React.Fragment>
                {/* <Card className="p-col-3" style={{  marginRight: "25px" }}>
                        <div className="p-grid p-justify-between p-align-center" style={{ padding: "10px" }}>
                            {dropdownScenario}
                            {dropdownMap}
                            {dropdownYears}
                            <ToggleButton onLabel="Stop" offLabel="Animate" onIcon="pi pi-check" offIcon="pi pi-times" checked={this.state.animate} onChange={this.handleToggleChange} />
                        </div>
                    </Card> */}
                <Card>
                    <div className="p-grid p-align-center" style={{ padding: "10px" }}>
                        <h4 style={{ marginRight: "15px" }}>
                            <b>{this.state.selectedMap.title}</b>
                        </h4>
                        {sideFilters}
                    </div>
                    {mapView}
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedScenario: state.report.selectedScenario,
        scenarioNames: state.report.scenarioNames,
        scenarios: state.report.lstOfScenarios,
        selectedUserAccess: state.user.selectedUserAccess,
        // years: state.report.years,
    };
};

const mapPropsToState = (dispatch) => {
    return {
        setStoreScenario: (scenario) => {
            dispatch({ type: actionTypeReport.SELECT_SCENARIO, payload: { response: scenario } });
        },
    };
};

export default connect(mapStateToProps, mapPropsToState)(withMsal(MapDemandMain));
