import React from "react";
import { connect } from "react-redux";
import { error } from "../../store/error/actionError";

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError(_) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            this.props.sendError();
            return <></>;
        }

        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendError: () => dispatch(error("There was a problem in rendering this content.")),
    };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
