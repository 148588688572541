import React, {useRef} from "react";
import { Dialog } from "primereact/dialog";
import NetItemDiagContent from "./newItemDiagContent";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const NewItemDiag = (props) => {
    const toast = useRef(null);
    const hideDialog = () => {
        props.setShowDialog(false);
    };
    const showWarnError = (level,header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };
    let prevUnqVal = null;
    if (props.diagMode === "edit") {
        let flt = props.addRowVals.filter((r) => r.newRow && r.newRow.isUnique);
        prevUnqVal = flt.length > 0 ? flt[0].value : null;
    }

    const pushData = () => {
        let message = "";
        let newItem = {};
        props.addRowVals.forEach((r) => {
            if (r.newRow && (r.value === null || r.value === "")) {
                message += `Value cannot be empty: ${r.name}\n`;
            }
            if (r.newRow && r.newRow.isUnique && r.unqVals.includes(r.value) && r.value !== prevUnqVal) {
                message += `Value for the field ${r.name} should be unique.\n`;
                message += `There is already a ${r.name} with value of "${r.value}"\n`;
            }
            newItem[r.name] = r.value;
        });

        newItem["editStatus"] = "new";

        if (message !== "") {
            showWarnError("warn",message,"")
            return;
        }

        if (props.diagMode === "edit") {
            let lstNewItems = [...props.lstNewItems];
            lstNewItems[props.idx] = newItem;
            props.setLstNewItems(lstNewItems);

            // let dataSource = [...props.dataSource]
            // let sourceIdx = dataSource.length - lstNewItems.length + props.idx;
            // dataSource[sourceIdx] = newItem
            // props.setDataSource(dataSource)
        } else {
            props.setLstNewItems([...props.lstNewItems, newItem]);
            // props.setDataSource([...props.dataSource, newItem])
        }
        props.setShowDialog(false);
    };

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
            <Button label={props.diagMode === "edit" ? "Update" : "Add to List"} icon="pi pi-check" className="p-button-outlined" onClick={pushData} />
        </React.Fragment>
    );

    let diagContent = <NetItemDiagContent addRowVals={props.addRowVals} setAddRowVals={props.setAddRowVals} mode="edit" />;

    return (
        <Dialog visible={props.showDialog} style={{ width: "40vw" }} header="New Item" modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
            {diagContent}
            <Toast ref={toast} />
        </Dialog>
    );
};

export default NewItemDiag;
