import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

const BtnDeleteRenewableRow = ({ renewableTable, onUpdate, rowIdx }) => {
    const handleDeletePath = () => {
        const tempData = global.structuredClone(renewableTable);
        tempData.splice(rowIdx, 1);
        const deletedData = [...tempData];
        onUpdate(deletedData);
    };
    return (
        <td>
            <Button icon={<DeleteOutlined />} danger type="text" onClick={handleDeletePath} />
        </td>
    );
};

export default BtnDeleteRenewableRow;
