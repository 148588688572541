import React, { useState } from "react";

// import { Dropdown, Menu, Space } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from "primereact/dropdown";

const DropDownOptions = ({ techs, selectedPath, trans, pathIdx, onUpdate }) => {
    const [options, setOptions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedTech = techs.filter((r) => r.TechnologyID === trans.TechnologyID)[0];
    const selectedRepTech = techs.filter((r) => r.TechnologyID === selectedPath.ReplacedTechID)[0];
    if (options.length > 0) {
        options.map((r) => {
            if (r.TechnologyID == selectedRepTech.TechnologyID) {
                if (r.SpecificEnergyRate != selectedRepTech.SpecificEnergyRate) {
                    setIsLoaded(false);
                }
            }
        });
    }
    if (selectedTech.Replacement.length > 0 && !isLoaded) {
        setOptions(
            selectedTech.Replacement.map((ID) => {
                return techs.filter((r) => r.TechnologyID === ID)[0];
            })
        );
        setIsLoaded(true);
    }

    // const menu_items = options.map((r,idx) => {
    //     const color = (selectedPath.status && selectedPath.status === 'new')? 'green':null
    //     return {
    //         key: `${idx}-${pathIdx} - ${trans.TransID} - Replacement`,
    //         label: (
    //           <span key={`${idx}-${pathIdx} - ${trans.TransID} - Replacement - span`} onClick={() => handleChange(r,idx)} style={{color:color}}>
    //             {r}
    //           </span>
    //         ),
    //       }
    // })

    // const menu = <Menu items={menu_items} />

    const handleChange = (e) => {
        const r = e.target.value;
        let tempData = global.structuredClone(trans);
        tempData.ReplacePaths[pathIdx].ReplaceTechName = r.Name;
        tempData.ReplacePaths[pathIdx].ReplacedTechID = r.TechnologyID;
        onUpdate(tempData);
        // props.setTransition(tempData)
    };

    return (
        <td>
            <Dropdown options={options} value={selectedRepTech} optionLabel="Name" style={{ width: "200px" }} onChange={handleChange} />
            {/* <Dropdown.Button icon={<DownOutlined />} overlay={menu} >
                <a onClick={(e) => e.preventDefault()}>
                <Space style={{width:"150px"}}>
                    {selectedPath.ReplaceTechName}
                </Space>
                </a>
            </Dropdown.Button> */}
        </td>
    );
};

export default DropDownOptions;
