import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ChkListBox from "../CheckListBox/CheckListBox";
import OverlayCheckListBox from "../CheckListBox/OverlayCheckListBox";
import * as actionTypeReport from "../../../store/report/actionReport";

const FuncFilter = (props) => {
    /**
     * @isOverlay               An overlay view is selected or direct inclusion.
     * @onChangeCheckedSites    function implementation when checked items are ticked or unticked. The function includes the updated items after the event.
     * @filter                  include filter field at the top.
     * @maxHeight               maximum height of the check listbox
     * @btnWidth                if Overlay is selected.
     */

    const lstOfFunctions = useSelector((s) => s.report.lstOfFunctions);
    const dispatch = useDispatch();

    const onChangeCheckedFuncs = (updatedItems) => {
        dispatch({ type: actionTypeReport.SET_CHK_STATE_FUNCTIONS, payload: { response: updatedItems } });
        props.onChangeCheckedFuncs(updatedItems);
    };

    let funcs = lstOfFunctions.map((r) => {
        return { ...r };
    });

    let funcFilter = null;
    if (funcs !== null && funcs.length > 0) {
        funcFilter = props.isOverlay ? (
            <OverlayCheckListBox btnLabel="Functions" btnWidth={props.btnWidth} filter allItem items={funcs} maxHeight={props.maxHeight} IdField="FunctionID" labelField="Function" setItems={onChangeCheckedFuncs} />
        ) : (
            <ChkListBox btnLabel="Functions" filter={props.filter} allItem items={funcs} maxHeight={props.maxHeight} IdField="FunctionID" labelField="Function" setItems={onChangeCheckedFuncs} />
        );
    }

    return funcFilter;
};

export default FuncFilter;
