import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../../api/energy-model/siteServices";
import Loading from "../../../../components/Loading";
import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import SOPChart from "./sopChart";
import { extractYearArray } from "../../../../utils/comm";

const convertToDataSource = (scenarioDetail, valueKeyName, yValueName, labels, lblMap, years) => {
    let dataSource = [];
    if (scenarioDetail) {
        labels.forEach((l) => {
            let dataCat = scenarioDetail.filter((r) => r[valueKeyName] === l);

            var dataCur = [];
            years.forEach((yr) => {
                var d = dataCat.filter((r) => r.Year === yr);

                if (d.length > 0) {
                    dataCur.push(parseFloat(d[0][yValueName]));
                } else {
                    dataCur.push(null);
                }
            });

            dataSource.push({
                name: lblMap[l],
                // type: 'area',
                data: dataCur,
            });
        });
    }
    return dataSource;
};

// const ConvertedData = convertToDataSource(TMMData, "Mine", "SOP-MTPA", MineLabels)

const SOP = (props) => {
    const siteMap = useSelector((state) => state.report.mineMap);

    const [years, setYears] = useState([]);
    const [SiteLabels, setSiteLabels] = useState(null);
    const [ConvertedData, setConvertedData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(null);
    const [hasToken, setHasToken] = useState(true);

    const { instance, accounts } = useMsal();
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);

    const updateData = () => {
        API.getTmmSopCalcByProjectID(selectedUserAccess.ProjectID, siteMap).then((res) => {
            if (!res.hasError) {
                let sites = [...new Set(res.data.map((item) => item.Site))];
                setSiteLabels(sites);
                let yrs = extractYearArray(res.data, "Year");
                setYears(yrs);
                setConvertedData(convertToDataSource(res.data, "Site", "SOP_MTPA", sites, siteMap, yrs));
            } else {
                throw new Error(res.message);
            }
            setIsDataLoading(false);
        });
    };

    useEffect(() => {
        setAccessToken(instance, accounts).then(
            (res) => {
                updateData();
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    let view = <Loading />;
    if (!isDataLoading) {
        view = <SOPChart dataSource={ConvertedData} legends={SiteLabels} xaxis={years} ProjectID={selectedUserAccess.ProjectID} />;
    }

    return <React.Fragment>{view}</React.Fragment>;
};

export default SOP;
