import React, { useState } from "react";
import TransitionTable from "./pathways/TransitionTable";
import MonthlyProfile from "./monthly";
import ToolbarPathways from "./ToolbarPathways";
import TechnologyInputs from "./techs";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const SetInputs = ({ transition, setTransition, techs, setTechs, sharedData, results, setResults, setProfiles, funcsOnMonthly, setFuncsOnMonthly, monthlyProfiles, renewableInputs, setRenewableInputs, renewableOutputs, setRenewableOutputs, setImportDropdown }) => {
    const [startOverallYr, setStartOverallYr] = useState(2021);
    const [endOverallYr, setEndOverallYr] = useState(sharedData.projID === 1 ? 2050 : 2030);

    const onTabChange = (key) => {
        console.log(key);
    };

    return (
        <>
            <ToolbarPathways
                sharedData={sharedData}
                results={results}
                setProfiles={setProfiles}
                setResults={setResults}
                setTechs={setTechs}
                transition={transition}
                setTransition={setTransition}
                startOverallYr={startOverallYr}
                setStartOverallYr={setStartOverallYr}
                endOverallYr={endOverallYr}
                setEndOverallYr={setEndOverallYr}
                funcsOnMonthly={funcsOnMonthly}
                monthlyProfiles={monthlyProfiles}
                setFuncsOnMonthly={setFuncsOnMonthly}
                renewableInputs={renewableInputs}
                setRenewableInputs={setRenewableInputs}
                renewableOutputs={renewableOutputs}
                setRenewableOutputs={setRenewableOutputs}
                setImportDropdown={setImportDropdown}
            />
            <Tabs defaultActiveKey="1" onChange={onTabChange} tabPosition="left" type="card">
                <TabPane tab="Transition Pathways" key="1-inps">
                    <TransitionTable transitions={transition} setTransitions={setTransition} techs={techs} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />
                </TabPane>
                {/* <TabPane tab="Monthly" key="2-inps">
                    <MonthlyProfile funcsOnMonthly={funcsOnMonthly} setFuncsOnMonthly={setFuncsOnMonthly} monthlyProfiles={monthlyProfiles} setMonthlyProfiles={setMonthlyProfiles} />
                </TabPane> */}
                <TabPane tab="Technology setup" key="2-inps">
                    <TechnologyInputs techs={techs} setTechs={setTechs} />
                </TabPane>
                {/* <TabPane tab="Production Forecast" key="3-inps">
                    <TechnologyInputs techs={techs} setTechs={setTechs} />
                </TabPane> */}
            </Tabs>
        </>
    );
};

export default SetInputs;
