import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <span className="font-medium ml-2">
                <p>&copy;Copyright 2022: Worley-Australia</p>
            </span>
        </div>
    );
};
