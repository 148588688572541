import React from "react";
import { useSelector } from "react-redux";
import InputESpec from "./InputESpec";

const rowCellStyle = { verticalAlign: "baseline", padding: "10px" };

function RowTechItem({ tech, allTechs, setTechs, idx }) {
    const functionMap = useSelector((s) => s.report.functionMap);
    const funcName = functionMap[tech.Function];
    const backColor = idx % 2 === 0 ? "#F8F9F9" : null;

    return (
        <tr style={{ backgroundColor: backColor }}>
            <td style={rowCellStyle}>{tech.Name}</td>
            <td style={rowCellStyle}>{funcName}</td>
            {tech.Replacement.length > 0
                ? tech.Replacement.map((r, idx) => {
                      return <tr>{`${idx + 1}. ${allTechs.filter((t) => t.TechnologyID === r)[0].Name}`}</tr>;
                  })
                : "No Replacement Options"}

            <InputESpec setTechs={setTechs} selectedTech={tech} allTechs={allTechs} />
        </tr>
    );
}

export default RowTechItem;
