import React from "react";
import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddRowRenewable = ({ renewableTable, selectedSub, onUpdate }) => {
    const handleAddItem = () => {
        let tempData = global.structuredClone(renewableTable);
        const fltTable = renewableTable.filter((r) => r.show);
        if (fltTable.length > 0) {
            tempData.push(global.structuredClone(fltTable[fltTable.length - 1]));
        } else {
            tempData = [{ SubstationID: selectedSub.SubstationID, Substation: selectedSub.Substation, InstalledYear: 2021, SolarCap: 2, WindCap: 1, StorageCap: 6 }];
        }
        onUpdate(tempData);
    };

    return (
        <Tooltip placement="top" title="Add New Row">
            <Button icon={<PlusOutlined />} type="primary" onClick={handleAddItem} />
        </Tooltip>
    );
};

export default AddRowRenewable;
