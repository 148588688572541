import React from "react";
import Help from "../../../../../../components/Buttons/Help";

function HelpResultTable() {
    return (
        <Help width="700px">
            <h3>Columns Definition</h3>
            <b>Renewable Util. (%):</b> Percentage of site load is met by wind and solar <br />
            <br />
            <b>Storage Util. (%):</b> Percent of site load met by discharge from energy storage. Gives indication of how well utilised the storage is. <br />
            <br />
            <b>Grid Utilisation (%):</b> Percentage of site load met by grid import (grid dependence) <br />
            <br/>
            <b>Grid Export (%):</b> Excess supply or export to the grid expressed as percentage of the site load. <br />
            <br />
            <b>Wind, Solar, Storage Install costs ($M):</b> Installation cost of respective renewable components <br />
            <br />
            <b>Wind, Solar, Storage O&M costs($M):</b> operation and maintenance cost of respective renewable components over asset life <br />
            <br/>
            <b>Grid Cost (M$):</b> Yearly remaining grid costs <br />
            <br />
            <b>Total cost (M$):</b> Combination of above costs. <br />
            <br />
            <b>Hours of grid import:</b> Number of hours per year of grid import. Gives indication of the impact to operation if site were to operate independent of the grid. <br />
            <br/>
        </Help>
    );
}

export default HelpResultTable;
