import React from "react";
import RenewableInputsImg from "../../assets/home/RenewableInputs.png";
import AIGuideLineImg from "../../assets/home/AIGuideLine.png";
const Step3Intro = () => {
    const renewIntro = (
        <div>
            <h3>
                <b>Step 3 – Renewables Deployment:</b>
            </h3>
            <h4>
                <b>Introduction</b>
            </h4>
            <p>
                This page helps with planning renewable schedule to supply the load or part thereof. Users select one of the load results from the Step 2 transition analysis. Using that load, the user can schedule installation of wind, solar, and battery storage. The performed simulation is
                conducted at a detailed level, i.e., at an hourly-timestep, over the whole selected period of time. For example, if the time span is selected for 30 years, the simulation runs for every hour of every day of all the 30 years.
                <br />
                <br />
            </p>
            <h4>
                <b>Inputs</b>
            </h4>
            <ul>
                <li>First select the load result that the installed renewables.</li>
                <li>Choose to add some variation/randomness to the load profile. 0.1 is the default value, adding up to + or – 10% random variation to each hour.</li>
                <li>Choose to change costs associated with renewables, grid, diesel and biodiesel. Note that changing some of these costs from their default may impact the effectiveness of the AI guideline.</li>
                <li>Define the installation schedule for solar, wind, and battery storage separately for each location. Note that both the Lake Macleod Salt and Gypsum sites are presumed connected to the same network.</li>
                <li>Select Run simulation to compute the results.</li>
            </ul>
            <center>
                <img src={RenewableInputsImg} style={{ width: "100%" }} alt="Featured" />
            </center>
            <p>
                <br />
                <br />
                In this example for Lake Macleod, 1.5MW of solar is installed by 2026, another 1 MW of solar and 3MW of wind installed by 2028. No storage is installed.
                <br />
            </p>
            <p>
                An AI Guideline available to aid the user to size renewables appropriately. User to select the Wind % and the Grid dependence % by using the sliders:
                <br />
            </p>
            <ul>
                <li>Wind % in is the wind and solar mix. i.e. 0% = solar only, 100% = wind only.</li>
                <li>Grid dependence is what portion of the load will be handled by the grid, i.e. when there is not enough wind, sun or stored energy in the battery to cover the load. 0% is grid independent. </li>
            </ul>
            <p>
                The AI guideline provides the combination of wind, solar and, storage to meet the user selected Wind % and Grid % that will give the cheapest solution over the lifetime of the installation. It does this for every year of the calculated load. The user can then choose to size the
                renewable installation to meet the guideline at certain stages of the transition. The total cost of the AI guideline solution is also estimated. This allows users to see how changing the Wind % and Grid % impacts the total cost. This total cost includes the installation, O&M over 20
                years and the remaining grid costs over 20 years.
                <br />
                <br />
            </p>
            <center>
                <img src={AIGuideLineImg} style={{ width: "100%" }} alt="Featured" />
            </center>
            <p>
                <br />
                <br />
                In this example the Solar and Wind are installed to ultimately meet the AI guide 2030 by installing in stages by 2026 and 2028. The AI Guide is providing the cost optimal solution for a 55%/45% split between wind and solar and to still be dependent on the network energy provider
                (grid) for approximately 14% of the annual load. Also in this example, AI guideline recommends a small amount of storage. However, small storage amounts, especially those below the Average load line provide little benefit to the total renewable system. Hence this aspect of the AI
                guideline is ignored in this example and no storage is scheduled.
                <br />
                <br />
            </p>
        </div>
    );
    return <>{renewIntro}</>;
};

export default Step3Intro;
