import React from 'react'
import {Button} from 'primereact/button'

const RefreshPage = (props) => {

    return (
        <div className="p-grid p-align-center p-justify-center" style={{height:"100vh"}}>
            <span style={{marginRight: "25px"}}><h5>{props.message}</h5></span>
            <Button onClick={() => window.location.reload()} icon="pi pi-refresh" className="p-button-rounded p-button-success "/>
        </div>
    )
}

export default RefreshPage;