import { FASTAPI_URL } from "../../settings/settings";
import * as ApiUtils from "../utils/ApiUtils";

const prefix = "/site";

export const getListOfSitesByProjectID = async (projectID) => {
    let tag = "ListOfSitesByProjectID";
    return ApiUtils.GET(FASTAPI_URL + prefix + `/sites/project?project_id=${projectID}`, "GET", tag);
};

export const getListOfSiteDataPrimaryByProjectID = async (projectID) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/site-data/tmm-sop/primary/project?project_id=${projectID}`, "GET", "SitePrimaryDataByProjectID");
};

export const getListOfSiteDataPrimaryByRecordId = async (recordId) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/site-data/tmm-sop/primary?record_id=${recordId}`, "GET", "ListOfSiteDataPrimaryByRecordId");
};

export const getTmmSopHistoryByRecordID = async (RecordID) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/site-data/tmm-sop/history?record_id=${RecordID}`, "GET", "TmmSopHistoryByRecordID");
};

export const getTmmSopCalcByProjectID = async (projectID) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/site-data/tmm-sop/calc/project?project_id=${projectID}`, "GET", "getTmmSopCalcByProjectID");
};

export const uploadNewTmmSopData = async (items, primaryData, projectId) => {
    let reqBody = items.map((r) => {
        return {
            Site: parseInt(r.Site),
            Year: parseInt(r.Year),
            TMM_MTPA: parseFloat(r.TMM_MTPA),
            SOP_MTPA: parseFloat(r.SOP_MTPA),
        };
    });

    const url = FASTAPI_URL + prefix + `/site-data/tmm-sop/upload?data_version=${primaryData.DataVersion}&data_description=${primaryData.DataDescription}&project_id=${projectId}`;

    return ApiUtils.POST(url, reqBody);
};
