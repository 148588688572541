import React, { useState } from "react";
import EnergyDemand from "./yearly-demand";
import { Tabs } from "antd";
import ToolbarResults from "./ToolbarResults";
import MonthlyDemandResult from "./monthly-demand";
import YearlyByFuncResults from "./yearly-by-funcs";
import cloneDeep from 'lodash/cloneDeep';


const { TabPane } = Tabs;

const Results = ({ results }) => {
    // const [fltResults, setFltResults] = useState(global.structuredClone(results));
    
    const [fltResults, setFltResults] = useState(cloneDeep(results));

    // const energyDemandPlot = React.useMemo(() => , [results, fltResults]);

    const onTabChange = () => {};

    return (
        <>
            <ToolbarResults results={results} setFltResults={setFltResults} />
            <Tabs defaultActiveKey="1" onChange={onTabChange} tabPosition="left" type="card">
                {/* <TabPane tab="Compare Inputs" key="0-res">
                    In this page we compare the inputs of different runs.
                </TabPane> */}
                <TabPane tab="Yearly Demand" key="1-res">
                    <EnergyDemand results={results} fltResults={fltResults} />
                </TabPane>
                <TabPane tab="Split By Functions" key="2-res">
                    <YearlyByFuncResults results={results} fltResults={fltResults} />
                </TabPane>
                <TabPane tab="Monthly Demand" key="3-res">
                    <MonthlyDemandResult results={results} fltResults={fltResults} />
                </TabPane>
                {/* <TabPane tab="Daily Demand" key="4-res">
                    here we setup the daily schedule of techs workloads and cycles
                </TabPane>
                <TabPane tab="Supply" key="5-res">
                    here we setup how the source of energies are going to be addressed.
                </TabPane> */}
            </Tabs>
        </>
    );
};

Results.propTypes = {};

export default Results;
