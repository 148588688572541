import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import * as actionTypeUser from "../../../store/user/actionUser";

const ProjectDropdown = (props) => {
    const lstOfAccess = useSelector((state) => state.user.userDB);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let select = lstOfAccess.filter((s) => s.ProjectName === e.target.value)[0];
        dispatch({ type: actionTypeUser.SELEC_USER_ACCESS, payload: { response: select } });

        props.onChange(select); // Extra implementation of onChange event in parent component.
    };

    return <Dropdown style={{ marginLeft: "10px" }} optionLabel="ProjectName" optionValue="ProjectName" value={selectedUserAccess ? selectedUserAccess.ProjectName : null} options={lstOfAccess} onChange={handleChange} placeholder="Select a Project" />;
};

export default ProjectDropdown;
