import React from "react";
import { Typography } from "antd";
import DropDownOptions from "./DropDownOptions";
import BtnRemovePath from "./BtnRemovePath";
import BtnAddPath from "./BtnAddPath";
import BtnSetTech from "./BtnSetTech";
import BtnSetProfile from "./BtnSetProfile";
import DropDownProfiles from "./DropDownProfiles";
import InputData from "./InputData";

const { Text } = Typography;
const style = { textAlign: "left", padding: "5px" };

const RowItemTemplate = React.memo(({ trans, onUpdate, rowIdx, techs, startOverallYr, endOverallYr }) => {
    const selectedTech = techs.filter((r) => r.TechnologyID === trans.TechnologyID)[0];
    const canBeReplaced = selectedTech.Replacement.length > 0;
    let defaultReplace = null;
    if (canBeReplaced) {
        const replaceID = selectedTech.Replacement[0];
        defaultReplace = techs.filter((r) => r.TechnologyID === replaceID)[0];
    }

    const headerReplacement = (
        <tr>
            <th></th>
            <th>Replacement</th>
            <th>Profile</th>
            <th></th>
            <th>Start Year</th>
            <th>End Year</th>
            <th>End Value</th>
        </tr>
    );

    const backcolor = rowIdx % 2 === 0 ? "#F8F9F9" : null;

    return (
        <>
            <tr key={`Transition_Row_${rowIdx}`} style={{ textAlign: "top", backgroundColor: backcolor }}>
                <td style={style}>
                    <Text>{trans.Site}</Text>
                </td>
                <td style={style}>
                    <Text>{trans.Function}</Text>
                </td>
                <td style={style}>
                    <Text>{trans.TechName}</Text>
                </td>
                <td>{canBeReplaced ? <BtnAddPath trans={trans} pathIdx={trans.ReplacePaths.length - 1} onUpdate={onUpdate} defaultReplace={defaultReplace} startOverallYr={startOverallYr} endOverallYr={endOverallYr} /> : null}</td>
                <td>
                    <table>
                        <tbody>
                            {trans.ReplacePaths.map((r, idx) => {
                                return (
                                    <>
                                        {rowIdx === 0 && idx === 0 && headerReplacement}

                                        <tr key={`tech_replace_path_${rowIdx}_${idx}`}>
                                            <BtnRemovePath trans={trans} pathIdx={idx} onUpdate={onUpdate} />

                                            <DropDownOptions idx={rowIdx} pathIdx={idx} techs={techs} trans={trans} selectedPath={r} onUpdate={onUpdate} />

                                            <td>
                                                <DropDownProfiles trans={trans} pathIdx={idx} selectedPath={r} onUpdate={onUpdate} />
                                            </td>

                                            <BtnSetProfile trans={trans} selectedPath={r} onUpdate={onUpdate} pathIdx={idx} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />

                                            <InputData trans={trans} pathIdx={idx} onUpdate={onUpdate} selectedPath={r} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </td>
            </tr>
        </>
    );
});

export default RowItemTemplate;
