import React from "react";
import { Message } from "primereact/message";
const styleMsgBox = {
    width: "80%",
    alignContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
};
const Step2Intro = () => {
    const step2Note = (
        <div>
            <p>
                <b>Note</b>:To check or update the forecast production the user must select{" "}
                <span style={{ background: "blue" }}>
                    <b>TMM-SOP</b>
                </span>
                and{" "}
                <span style={{ background: "blue" }}>
                    <b>Data Manager</b>
                </span>{" "}
                on menu on the left. DSL sites only have SOP (Saleable product) as the operation is different to iron ore. To make changes, the user will need to download the data as CSV, make the changes in the CSV and then upload it as new data. IMPORTANT: Making changes to the SOP are permanent
                changes to the database and will impact all calculations in the Energy model and the Dynamic Analysis model going forward.
            </p>
            <br />
        </div>
    );
    const reviewIntro = (
        <div>
            <br />
            <br />
            <h3>
                <b>Step 2 - Review Load Results:</b>
            </h3>
            <h4>
                <b>Yearly Demand</b>
            </h4>
            <p>
                Provides the total forecast energy demand (in GWh) year to year based of the technology transition pathway and the forecast salt and gypsum production. This chart can be filtered by function, site and energy type.
                <br />
                <br />
            </p>
            <div className="p-grid p-justify-center">
                <Message severity="warn" style={styleMsgBox} content={step2Note} />
                <br />
                <br />
            </div>
            <h4>
                <b>Split by Function</b>
            </h4>
            <p>
                Energy demand separated by function. This chart can be filtered by function, site and energy type.
                <br />
                <br />
            </p>
            <h4>
                <b>Monthly Demand</b>
            </h4>
            <p>
                This applies only for DSL; the energy use per function per is not distributed uniformly month to month. Based off the 2021 diesel and electric consumption the variation in energy demand is applied. This chart can be filtered by function, site and energy type.
                <br />
                <br />
            </p>
            <p>
                This monthly variation is important for the assessment of renewable energy deployment, whose resources are season dependent.
                <br />
                <br />
                <br />
                <br />
            </p>
        </div>
    );
    return <>{reviewIntro}</>;
};

export default Step2Intro;
