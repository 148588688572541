import React, { useEffect } from "react";
import { Button, Input, Space, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import * as ApiExperiment from "../../../../../../api/energy-model/experimentServices";
import Chart from "react-apexcharts";
import * as utils from "../../../../../../utils/comm";
import DropDownProfiles from "./DropDownProfiles";
import InputData from "./InputData";
import { COLOR_MAP_DISTINCT_2 } from "../../../../../../store/constant";

const BtnSetProfile = ({ trans, pathIdx, selectedPath, onUpdate, startOverallYr, endOverallYr }) => {
    const [showDiag, setShowDiag] = React.useState(false);
    const [dataSource, setDataSource] = React.useState(null);
    const [profileParams, setProfileParams] = React.useState(global.structuredClone(trans.ReplacePaths[pathIdx].ProfileParams));
    const [configs, setConfigs] = React.useState(null);
    const [showSelectedProfile, setShowSelectedProfile] = React.useState(false);

    const allYears = utils.range(startOverallYr, endOverallYr + 1, 1);

    React.useEffect(() => {
        setProfileParams(global.structuredClone(trans.ReplacePaths[pathIdx].ProfileParams));
    }, [trans]);

    const footer = () => {};

    const header = () => {
        return null;
    };

    const updateData = () => {
        ApiExperiment.getAllItemsProfileUpdate([trans], startOverallYr, endOverallYr).then((res) => {
            const tmpConfig = [];
            const data = [];

            for (let idx = 0; idx < res.data.lstItems[0].length; idx++) {
                if (showSelectedProfile && idx !== pathIdx) {
                    continue;
                }
                const r = res.data.lstItems[0][idx];
                const isSelected = idx === pathIdx;
                const isExisting = r.type === "Existing";
                const selected = isSelected ? "SELECTED - " : "";

                let color = idx + 1 > COLOR_MAP_DISTINCT_2.length ? "#FADBD8" : COLOR_MAP_DISTINCT_2[idx];
                color = isSelected ? "#330099" : color;
                color = isExisting ? "#CC00CC" : color;

                tmpConfig.push({ color: color, stroke: isSelected || r.type === "Existing" ? { dashArray: 0, width: 4 } : { dashArray: 4, width: 2 } });

                data.push({
                    name: `${r.Technology} (${selected} ${r.type})`,
                    data: r.w,
                });
            }

            setConfigs(tmpConfig);
            setDataSource(data);
        });
    };

    const onShowProfile = () => {
        updateData();
        setShowDiag(true);
    };

    const handleOnShowSelectedProfile = (e) => {
        setShowSelectedProfile(e.value);
    };

    useEffect(() => {
        updateData();
    }, [showSelectedProfile]);

    useEffect(() => {
        updateData();
    }, [trans]);

    const handleParamOnBlur = (e, idx) => {
        let tempData = global.structuredClone(trans);
        tempData.ReplacePaths[pathIdx].ProfileParams[idx] = parseFloat(e.target.value);
        onUpdate(tempData);
    };

    const handleParamEntryChange = (e, idx) => {
        let tempData = global.structuredClone(profileParams);
        tempData[idx] = e.target.value;
        setProfileParams(tempData);
    };

    let diagContent = null;
    if (dataSource !== null && profileParams !== null) {
        const series = dataSource;

        let paramEntries = profileParams.map((p, idx) => {
            return <Input style={{ width: "75px", marginLeft: "5px" }} value={p} onChange={(e) => handleParamEntryChange(e, idx)} onBlur={(e) => handleParamOnBlur(e, idx)} />;
        });

        const options = configureChart(allYears, configs);
        diagContent = (
            <>
                <div className="p-grid p-align-center" style={{ marginTop: "20px" }}>
                    <Space>
                        <DropDownProfiles trans={trans} pathIdx={pathIdx} selectedPath={selectedPath} onUpdate={onUpdate} />
                        <div>
                            <tr>
                                <td>Start Year</td>
                                <td>End Year</td>
                                <td>End Value</td>
                            </tr>
                            <tr>
                                <InputData trans={trans} pathIdx={pathIdx} onUpdate={onUpdate} selectedPath={selectedPath} />
                            </tr>
                        </div>

                        {/* <InputSwitch checked={showSelectedProfile} onChange={handleOnShowSelectedProfile} />
                        <span>Show All Profiles</span> */}
                    </Space>
                </div>
                <div className="p-grid p-align-center" style={{ marginTop: "20px" }}>
                    <h5>Parameters:</h5>
                    {paramEntries}
                </div>
                <Chart options={options} series={series} type="line" height="500px" />
            </>
        );
    }

    return (
        <>
            <Dialog visible={showDiag} style={{ width: "1000px" }} header={header} modal className="p-fluid" footer={footer} onHide={() => setShowDiag(false)}>
                {diagContent}
            </Dialog>
            <td>
                <Tooltip placement="top" title="Define transition pathway">
                    <Button shape="circle" icon={<SettingOutlined />} onClick={onShowProfile} />
                </Tooltip>
            </td>
        </>
    );
};

BtnSetProfile.propTypes = {};

export default BtnSetProfile;

const configureChart = (years, configs) => {
    const colors = configs.map((r) => r.color);
    const linewidth = configs.map((r) => r.stroke.width);
    const lineStyle = configs.map((r) => r.stroke.dashArray);
    return {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
            width: linewidth,
            dashArray: lineStyle,
        },
        title: {
            text: "Profile",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    if (value) {
                        return value.toFixed(3);
                    }
                },
            },
            title: {
                // text: "MWh-e"
                text: "weight",
            },
        },
        labels: years,
    };
};
