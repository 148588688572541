import React, { useState } from "react";
import { Button, Typography, Input, Spin, Space, Upload } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

const ImportJson = ({ results, setResults, setTechs, setTransition, setFuncsOnMonthly, setRenewableInputs, setRenewableOutputs, setImportDropdown }) => {
    const [isImportFile, SetIsImportFile] = useState(false);
    const handleImportJson = (file) => {
        SetIsImportFile(true);
        const reader = new FileReader();
        reader.onload = (e) => {
            let tempResultData = null;
            const importedJSON = JSON.parse(e.target.result);
            setTechs(importedJSON["techs"]);
            setTransition(importedJSON["transition"]);
            setFuncsOnMonthly(importedJSON["monthly"]);
            tempResultData = global.structuredClone(results);
            tempResultData.push(importedJSON);
            setResults(tempResultData);
            SetIsImportFile(false);
            if (importedJSON["Renewables"]) {
                setRenewableInputs(importedJSON["Renewables"][0]);
                if (importedJSON["Renewables"][0]) {
                    if (importedJSON["Renewables"][0]["output"]) {
                        setRenewableOutputs(importedJSON["Renewables"][0]["output"]);
                    }
                }
                setImportDropdown(tempResultData.length - 1);
            }
            SetIsImportFile(false);
        };
        reader.readAsText(file);
    };

    return (
        <>
            {isImportFile ? (
                <Spin />
            ) : (
                <Upload
                    accept=".json"
                    showUploadList={false}
                    beforeUpload={(file) => {
                        handleImportJson(file);
                        // Prevent upload
                        return false;
                    }}
                >
                    <Button type="primary">
                        <UploadOutlined /> Import JSON
                    </Button>
                </Upload>
            )}
        </>
    );
};

export default ImportJson;
