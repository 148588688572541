import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

const ChkListBox = (props) => {
    /**
     * @items           list of items. The items should include the attribute "checked"
     * @setItems        React Hook function to set state of items
     * @IdField         Attribute of item to be used as Id
     * @labelField      Attribute of item to be displayed as label.
     * @filter          include filter field
     * @maxHeight       maximum height of the check listbox
     * @allItem         true/false, set to include all items or not.
     */

    const allItem = {};
    allItem[props.labelField] = "all";
    allItem[props.IdField] = -1;
    allItem["checked"] = true;

    const [searchTxt, setSearchTxt] = useState("");
    const [allItemState, setAllItemState] = useState(allItem);

    const setChecked = (e, o) => {
        let updateItems = null;
        if (o[props.IdField] === -1) {
            setAllItemState({ ...allItemState, checked: e.checked });
            updateItems = props.items.map((r) => {
                return { ...r, checked: e.checked };
            });
        } else {
            updateItems = props.items.map((r) => {
                if (r[props.IdField] === o[props.IdField]) {
                    return { ...r, checked: e.checked };
                }
                return r;
            });
        }

        props.setItems(updateItems);
    };

    let search = <InputText style={{ width: "100%" }} value={searchTxt} onChange={(e) => setSearchTxt(e.target.value)} />;

    let dispItems = props.allItem ? [allItemState, ...props.items] : props.items;

    let view = dispItems.map((o, idx) => {
        if (searchTxt === "" || o[props.labelField].toLowerCase().includes(searchTxt)) {
            return (
                <div key={`${o[props.labelField]}_${idx + 1}`} style={{ margin: "5px" }} className="n-grid n-align-center">
                    <Checkbox style={{ zIndex: 500 }} onChange={(e) => setChecked(e, o)} checked={o.checked} />
                    <span style={{ marginLeft: "10px" }}>{o[props.labelField]}</span>
                </div>
            );
        } else {
            return null;
        }
    });

    return (
        <div style={{ maxHeight: props.maxHeight, width: "max-content", overflowY: "scroll" }}>
            {props.filter ? search : null}
            <div>{view}</div>
        </div>
    );
};

export default ChkListBox;
