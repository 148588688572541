import React, {useState} from 'react'
import Loading from '../../../../components/Loading';
import { Card } from 'primereact/card';
import CustomDataTable from '../../../../components/CustomTable/CustomDataTable'
import { Button } from 'primereact/button';
import NetItemDiag from './component/newItemDiag'


const EditPage = (props) => {

    const [showDialog, setShowDialog] = useState(false);
    const [addRowVals, setAddRowVals] = useState([]);

    let view = <Loading />;
    if (props.dataSource) {
        view = <CustomDataTable 
        data={props.dataSource}
        lstUpdate={props.lstUpdate}
        columns={props.columns}
        showOrgVals={false}
        tableType="normal"
        IdCol={props.IdCol}
        setLstUpdate={props.setLstUpdate}
        setDataSource={props.setDataSource}/>;
    } else if (!props.isLoading) {
        view = <h1>There is no data to display!</h1>;
    }

    const AddRow = () => {
        setAddRowVals(props.columns.map(c => { return {...c, value: ''}}))
        setShowDialog(true);
    };

    return (
        <Card className="p-grid p-justify-center p-align-center" title={props.title}>
            <div className='p-grid p-align-center p-justify-between' style={{marginBottom: "10px"}}>
                 <span style={{color:"green"}}><b>* Editable columns are in green</b></span>
                <Button style={{marginRight:"10px"}} onClick={AddRow}>Create New Item</Button>
            </div>
            <NetItemDiag showDialog={showDialog} setShowDialog={setShowDialog} 
            lstNewItems={props.lstNewItems} setLstNewItems={props.setLstNewItems} 
            dataSource={props.dataSource} setDataSource={props.setDataSource}
            addRowVals={addRowVals} setAddRowVals={setAddRowVals}/>
            {view}
        </Card>
    )
}

export default EditPage;
