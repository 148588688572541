import React, { useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as ApiComm from "../../../../api/energy-model/miscServices";
import * as ApiProfile from "../../../../api/energy-model/profileServices";

import * as ST from "../../../../settings/settings";

import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import ProfilePlot from "../../../../components/profilePlot";
import DialogProfile from "./dialogProfile";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";

import ListProfiles from "./listProfiles";

const ManageProfiles = (props) => {
    const toast = useRef(null);

    const lstOfProfiles = useSelector((state) => state.report.lstOfProfiles);
    const lstOfProfileData = useSelector((state) => state.report.lstOfProfileEntries);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    const dispatchComm = useDispatch();

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);

    const [selectedProfile, setSelectedProfile] = useState(lstOfProfiles[0]);

    const [showDiag, setShowDialog] = useState(false);
    const [diagMode, setDiagMode] = useState("");
    const [diagNewProfileData, setDiagNewProfileData] = useState(null);
    const [diagNewProfileName, setDiagNewProfileName] = useState("New Profile");
    const [diagNewProfileDesc, setDiagNewProfileDesc] = useState("New Profile Description");
    const [isLockLoading, setIsLockLoading] = useState(false);

    const [isInProgress, setIsInProgress] = useState(false);

    const role = selectedUserAccess.UserRole;

    const btnStyle = {
        margin: "2px",
    };

    const showWarn = (header, message) => {
        toast.current.show({ severity: "warn", summary: header, detail: message, life: 6000 });
    };

    const onCreate = () => {
        let newProfile = lstOfProfileData
            .filter((r) => r.ProfileID === 1)
            .map((r) => {
                return { ...r };
            });
        setDiagNewProfileName("New Profile");
        setDiagNewProfileDesc("New Profile Description.");
        setDiagNewProfileData(newProfile);
        setDiagMode("Create");
        setShowDialog(true);
    };

    const onEdit = () => {
        if (selectedProfile) {
            let newProfile = lstOfProfileData
                .filter((r) => r.ProfileID === selectedProfile.ProfileID)
                .map((r) => {
                    return { ...r };
                });
            // let newProfile = [...lstOfProfileData.filter((r) => r.ProfileID === selectedProfile.ProfileID)]
            setDiagNewProfileName(selectedProfile.Profile);
            setDiagNewProfileDesc(selectedProfile.Description);
            setDiagNewProfileData(newProfile);
            setDiagMode("Edit");
            setShowDialog(true);
        } else {
            showWarn("Profile","Please select a profile from the list")
            
        }
    };

    const onDuplicate = () => {
        if (selectedProfile) {
            let newProfile = lstOfProfileData
                .filter((r) => r.ProfileID === selectedProfile.ProfileID)
                .map((r) => {
                    return { ...r };
                });
            setDiagNewProfileData(newProfile);
            setDiagNewProfileName(selectedProfile.Profile);
            setDiagNewProfileDesc(selectedProfile.Description);
            setDiagMode("Duplicate");
            setShowDialog(true);
        } else {
            showWarn("Profile","Please select a profile from the list")
        }
    };

    const onLockUnLock = (profile) => {
        if (profile) {
            const isLocked = profile.Lock;
            const diagMsg = isLocked ? `The profile is in Lock state. Unlocking will let users to edit/delete the profile. Do you wish to continue?` : `The profile is in UnLock state. Locking will prevent users to edit/delete the profile. Do you wish to continue?`;

            const accept = () => {
                setAccessToken(instance, accounts).then(
                    (resolve) => {
                        setIsLockLoading(true);
                        ApiProfile.lockUnLockProfile(profile, !isLocked).then((res) => {
                            if (!res.hasError) {
                                ApiProfile.getListOfProfiles(dispatchComm).then((res2) => {
                                    if (!res2.hasError) {
                                        toast.current.show({ severity: "success", summary: "Confirmed", detail: `Profile ${profile.Profile} is ${isLocked ? "unlocked" : "locked"}.`, life: 5000 });
                                    } else {
                                        throw new Error(res2.message);
                                    }
                                });
                                setIsLockLoading(false);
                            } else {
                                setIsLockLoading(false);
                                throw new Error(res.message);
                            }
                        });
                        setHasToken(true);
                    },
                    (err) => {
                        setHasToken(false);
                    }
                );

                return;
            };
            const reject = () => {
                return;
            };
            confirmDialog({
                message: diagMsg,
                header: "Cancel Confirmation",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept,
                reject,
            });
        }
    };

    const onSubmitDiag = () => {
        const diagMsg = `Are you sure you want to proceed with submission?`;

        const accept = () => {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    setIsInProgress(true);
                    switch (diagMode.toLowerCase()) {
                        case "create":
                        case "duplicate":
                            ApiProfile.createProfile(diagNewProfileName, diagNewProfileDesc, diagNewProfileData).then((res) => {
                                if (!res.hasError) {
                                    toast.current.show({ severity: "success", summary: "Confirmed", detail: `New profile ${diagNewProfileName} is created.`, life: 3000 });
                                    ApiComm.getAllComms(dispatchComm, selectedUserAccess.ProjectID).then((res2) => {
                                        if (!res2.hasError) {
                                            setShowDialog(false);
                                            setIsInProgress(false);
                                        } else {
                                            throw new Error(res2.message);
                                        }
                                    });
                                } else {
                                    toast.current.show({ severity: "danger", summary: "Confirmed", detail: `New profile creation unsuccessful. An error occured.`, life: 3000 });
                                    setIsInProgress(false);
                                    setShowDialog(false);
                                }
                            });
                            break;

                        case "edit":
                            ApiProfile.editProfile(diagNewProfileName, diagNewProfileDesc, diagNewProfileData, selectedProfile).then((res) => {
                                if (!res.hasError) {
                                    toast.current.show({ severity: "success", summary: "Confirmed", detail: `Profile ${diagNewProfileName} is updated.`, life: 3000 });
                                    ApiComm.getAllComms(dispatchComm, selectedUserAccess.ProjectID).then((res2) => {
                                        if (!res2.hasError) {
                                            setIsInProgress(false);
                                            setShowDialog(false);
                                        } else {
                                            throw new Error(res2.message);
                                        }
                                    });
                                } else {
                                    toast.current.show({ severity: "danger", summary: "Confirmed", detail: `Updating profile ${selectedProfile.Profile} failed with an error.`, life: 3000 });
                                    setIsInProgress(false);
                                    setShowDialog(false);
                                }
                            });
                            break;

                        // case 'duplicate':
                        //     ApiProfile.createProfile(diagNewProfileName, diagNewProfileDesc,diagNewProfileData).then(res => {
                        //         if (!res.hasError) {
                        //             toast.current.show({ severity: 'success', summary: 'Confirmed', detail: `New profile ${diagNewProfileName} is created.`, life: 3000 });
                        //             ApiComm.getAllComma(dispatchComm, selectedUserAccess.ProjectID).then(res2 => {
                        //                 if (!res2.hasError) {

                        //                     setShowDialog(false);
                        //                 } else {
                        //                 /*Not used*/    alert(res2.message)
                        //                 }
                        //             })
                        //         }
                        //         else {
                        //             toast.current.show({ severity: 'danger', summary: 'Confirmed', detail: `New profile creation unsuccessful. An error occured.`, life: 3000 });

                        //             setShowDialog(false);
                        //         }
                        //     })
                        //     break;

                        default:
                            break;
                    }
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
            return;
        };
        const reject = () => {
            return;
        };
        confirmDialog({
            message: diagMsg,
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };

    const hideDiag = () => {
        setShowDialog(false);
    };

    const lstOfAlwaysVisibleButtons = useMemo(() => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary" disabled={selectedProfile ? selectedProfile.Lock : true} style={btnStyle} tooltip="Edit Profile" onClick={() => onEdit()} />
            </React.Fragment>
        );
    }, [selectedProfile]);

    const lstOfButtons = useMemo(() => {
        if (role.toLowerCase() === ST.ROLES.ADMIN || role.toLowerCase() === ST.ROLES.VIP || role.toLowerCase() === ST.ROLES.CONTRIB) {
            return (
                <React.Fragment>
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-secondary" tooltip="Create New Profile" style={btnStyle} onClick={() => onCreate()} />
                    <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary" tooltip="Duplicate Profile" disabled={selectedProfile ? false : true} style={btnStyle} onClick={() => onDuplicate()} />
                    {lstOfAlwaysVisibleButtons}
                </React.Fragment>
            );
        } else {
            return lstOfAlwaysVisibleButtons;
        }
    }, [selectedProfile]);

    // let lstOfButtons = lstOfAlwaysVisibleButtons
    // if (role.toLowerCase() === ST.ROLES.ADMIN || role.toLowerCase() === ST.ROLES.VIP || role.toLowerCase() === ST.ROLES.CONTRIB) {
    //     lstOfButtons = (
    //     <React.Fragment>
    //         <Button icon="pi pi-plus" className="p-button-rounded p-button-secondary" tooltip="Create New Profile" style={btnStyle} onClick={() => onCreate()} />
    //         <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary" tooltip="Duplicate Profile" disabled={selectedProfile ? false : true} style={btnStyle} onClick={() => onDuplicate()} />
    //         {lstOfAlwaysVisibleButtons}
    //     </React.Fragment>
    //     )
    // }

    const profilePlot = useMemo(() => {
        return (
            <div className="p-col-8">
                <ProfilePlot originalProfile={null} selectedProfile={selectedProfile ? selectedProfile.Profile : null} target={null} plotHeight="50vh" />
            </div>
        );
    }, [selectedProfile]);

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    return (
        <Card title="List of Profiles">
            <Toast ref={toast} />
            <div className="p-grid">
                {/* <ListBox className="p-col-3" listStyle={{ maxHeight: "50vh" }} value={selectedProfile} options={lstOfProfiles.filter(r => r.ProfileID !== "1")} onChange={(e) => setSelectedProfile(e.value)} itemTemplate={listMappingTemplate} /> */}
                <ListProfiles items={lstOfProfiles} selectedProfile={selectedProfile ? selectedProfile.Profile : null} onLockUnLock={onLockUnLock} isLockLoading={isLockLoading} setSelectedProfile={setSelectedProfile} role={role} filter={true} labelField="Profile" maxHeight="80vh" />
                <div className="p-col-fixed" style={{ width: "50px" }}>
                    {lstOfButtons}
                </div>
                {/* <div className="p-col-8">
                    <ProfilePlot originalProfile={null} selectedProfile={selectedProfile ? selectedProfile.Profile : null} target={null} plotHeight="50vh" />
                </div> */}
                {profilePlot}
            </div>
            <DialogProfile
                isInProgress={isInProgress}
                showDiag={showDiag}
                diagMode={diagMode}
                hideDiag={hideDiag}
                profile={selectedProfile}
                profileData={diagNewProfileData ? diagNewProfileData : null}
                setProfileData={setDiagNewProfileData}
                newProfileName={diagNewProfileName}
                setNewProfileName={setDiagNewProfileName}
                newProfileDesc={diagNewProfileDesc}
                setNewProfileDesc={setDiagNewProfileDesc}
                onSubmit={onSubmitDiag}
            />
        </Card>
    );
};

export default ManageProfiles;
