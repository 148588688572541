import { Button } from 'primereact/button';
import React, {useState} from 'react'


const SubmitPage = (props) => {

    const [message, setMessage] = useState(
        <div>
            <div>{`There are ${props.lstUpdate.length} items to be updated.`}</div>
            <div>{`There are ${props.lstNewItems.length} new items to be added.`}</div>
        </div>
    );

    const isSubmitEnabled = (props.lstUpdate.length > 0) || (props.lstNewItems.length > 0);

    return (
        <div>
            <h1>Summary</h1>
            {message}
            <div className='p-grid p-justify-between' style={{marginTop: "20px"}}>
                <span/>
                <div>
                    <Button>Cancel</Button>
                    <Button style={{marginRight:"10px", marginLeft:"10px"}} disabled={!isSubmitEnabled}
                    onClick={() => props.onSubmit(props.lstUpdate, props.lstNewItems, props.setLstUpdate, props.setLstNewItems, setMessage)}>Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default SubmitPage;
