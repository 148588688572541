import React from 'react'

export const showCompletionMessage = (setMessage, hasErrDB) => {
    if (!hasErrDB) {
        setMessage(
            <div>
                <h4 style={{color:'green'}}>Completed successfully!</h4>
            </div>
        );
    } else {
        setMessage(
            <div>
                <h4 style={{color:'red'}}>Action unsuccessful!</h4>
            </div>
        );
    }
}