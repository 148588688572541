import React, { useState } from "react";
//IMPORT COMPONENTS
import { Card } from "primereact/card";
//IMPORT STYLES
import "primeflex/primeflex.css";

import { AzureMapHtmlMarker } from "react-azure-maps";

const HtmlBubbleLayer = (props) => {
    const [showData, setShowData] = useState(false);

    let circleStyle = {
        height: props.radius,
        width: props.radius,
        backgroundColor: props.color,
        borderRadius: "50%",
        opacity: props.opacity,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        transform: "translate(0%,50%)",
    };

    let dataView = <div></div>;
    if (showData) {
        dataView = (
            <div style={{ marginBottom: "25px" }}>
                <Card style={{ opacity: 0.9 }}>
                    <span>
                        <b>Substation: {props.data.Substation}</b>
                    </span>
                    <ul>
                        <li>Demand: {props.data.demand.toFixed(2)} MW</li>
                        <li>Renewable: {props.data.renew.toFixed(2)} MW</li>
                        <li>Balance: {props.data.balance.toFixed(2)} MW</li>
                    </ul>
                </Card>
            </div>
        );
    }

    const onClick = () => {
        setShowData((prev) => !prev);
    };

    let content = (
        <div>
            {/* <span style={{ opacity: 1, zIndex: 200, color: "black", fontSize: "14px" }}>{props.text.toFixed(1)}</span> */}
            <div style={circleStyle}>{/* <span style={{ opacity: 1, zIndex: 200, color: "black", fontSize: "14px" }}>{props.text.toFixed(1)}</span> */}</div>
        </div>
    );

    return (
        <>
            <AzureMapHtmlMarker key={`${props.data.SubstationID}-power-demand-marker`} markerContent={content} options={{ position: [props.lat, props.lng], text: "My text", title: "Title" }} events={[{ eventName: "click", callback: onClick }]} />
            <AzureMapHtmlMarker key={`${props.data.SubstationID}-power-demand-popup`} markerContent={dataView} options={{ position: [props.lat, props.lng], text: "My text", title: "Title" }} events={[{ eventName: "click", callback: onClick }]} />
        </>
    );
};

export default HtmlBubbleLayer;
