export const joinMonthlyProfileData = (funcsOnMonthly, monthlyProfiles) => {
    let tmpMonthlyJoin = global.structuredClone(funcsOnMonthly);
    tmpMonthlyJoin.forEach((item) => {
        Object.assign(item, {
            w_monthly: monthlyProfiles.filter((o) => o.MonthlyProfileID === item.MonthlyProfileID).map((r) => r.w_monthly)[0],
        });
    });

    return tmpMonthlyJoin;
};
