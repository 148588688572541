import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";

const RenewableLoading = ({ apiLoading }) => {
    const messages = [
        "Call is made to the API",
        "Call is made to the API.",
        "Call is made to the API..",
        "Call is made to the API...",
        "Call is made to the API....",
        "Model started running",
        "Model started running.",
        "Model started running..",
        "Model started running...",
        "Model started running....",
        "Model started running.....",
        "Inputs are read",
        "Inputs are read.",
        "Inputs are read..",
        "Inputs are read...",
        "Inputs are read....",
        "Inputs are read.....",
        "Calculating the results",
        "Calculating the results.",
        "Calculating the results..",
        "Calculating the results...",
        "Calculating the results....",
        "Calculating the results.....",
        "Generating results",
        "Generating results.",
        "Generating results..",
        "Generating results...",
        "Generating results....",
        "Generating results.....",
    ];
    const [randState, setRandState] = useState(0);
    // Default to the first message passed
    const [messageIndex, setMessageIndex] = useState(0);
    useEffect(() => {
        // Move on to the next message every `n` milliseconds
        if (apiLoading) {
            setRandState(0);
            let timeout;
            if (messageIndex < messages.length - 1) {
                timeout = setTimeout(() => {
                    setMessageIndex(messageIndex + 1);
                }, 1000);
            }

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [messages, messageIndex, randState]);

    useEffect(() => {
        setMessageIndex(0);
    }, [apiLoading]);

    return (
        <>
            <Dialog closable={false} header={messages[messageIndex]} visible={apiLoading} style={{ width: "30vw" }}>
                Simulation is running, this may take up to few minutes.
            </Dialog>
        </>
    );
};

export default RenewableLoading;
