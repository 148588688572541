import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ dataSource, xValues, yLabel, type, stacked, scale, colors, height, ymin, palette }) => {
    if (dataSource === null) {
        return <span>Loading data</span>;
    }
    const options = configureChart(dataSource, xValues, yLabel, stacked, scale, colors, ymin, palette);
    return (
        <div style={{ height: height, width: "100%" }}>
            <Chart type={type} options={options.options} series={options.series} width="100%" height="100%" />
        </div>
    );
};

export default LineChart;

const configureChart = (dataSource, xValues, yLabel, stacked, scale, colors, ymin, palette) => {
    const factor = scale ? scale : 1;
    return {
        series: dataSource,
        options: {
            chart: {
                stacked,
                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            theme: {
                mode: "light",
                palette: palette,
            },
            colors: colors, //,
            stroke: {
                width: 2,
                curve: "straight",
            },
            tooltip: {
                inverseOrder: true,
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                inverseOrder: true,
            },
            labels: xValues,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return (value / factor).toFixed(2);
                        }
                    },
                },
                title: {
                    // text: "MWh-e"
                    text: yLabel,
                },
                min: ymin,
            },
        },
    };
};
