import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "antd";
import { QuestionOutlined } from "@ant-design/icons";

function Help({ children, width }) {
    const op = useRef(null);

    return (
        <div>
            <Button shape="circle" icon={<QuestionOutlined />} onClick={(e) => op.current.toggle(e)} />
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: width }}>
                {children}
            </OverlayPanel>
        </div>
    );
}

export default Help;
