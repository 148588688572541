import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
// IMPORT STYLES
import "primeflex/primeflex.css";
// IMPORT STORE
import * as actionTypeReport from "../../../../store/report/actionReport";
import * as API from "../../../../api/energy-model/energyServices";
// IMPORT COMPONENTS
import Loading from "../../../../components/Loading";
import { PointSpreadLoading } from "react-loadingg";
import DemandBarChartOverYears from "./demandBarChart";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import { extractYearArray } from "../../../../utils/comm";

const convertToBarChartDataSource = (res) => {
    let data = [];
    if (res) {
        data = [
            {
                name: "Balance",
                type: "column",
                data: res.map((r) => (parseFloat(r.BalanceMWavg) < 0 ? 0 : parseFloat(r.BalanceMWavg))),
            },
            {
                name: "Renewable",
                type: "column",
                data: res.map((r) => parseFloat(r.RenewableMWavg)),
            },
            {
                name: "Grid Import",
                type: "column",
                data: res.map((r) => parseFloat(r.GasTurbineMWavg)),
            },
            {
                name: "Demand",
                type: "line",
                data: res.map((r) => parseFloat(r.PowerDemandMWavg)),
            },
        ];
    }
    return data;
};

const PowerDemandGen = (props) => {
    const lstOfScenarios = useSelector((state) => state.report.lstOfScenarios);
    const selectedScenario = useSelector((state) => state.report.selectedScenario);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    // const years = useSelector((state) => state.report.years);

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);
    const [years, setYears] = useState([]);

    const dispatchScenario = useDispatch();

    const [barChartData, setBarChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [sysBalanceAllData, setSysBalanceAllData] = useState(null);

    const updateData = (scenario) => {
        if (scenario) {
            setIsLoading(true);
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    API.getAllSystemBalancePowerDemand(scenario.PathwayScenarioID).then((res) => {
                        if (!res.hasError) {
                            const syear = 2021;
                            const eyear = selectedUserAccess.ProjectID === 1 ? 2050 : 2030;
                            const fltData = res.data.filter((r) => r.Year >= syear && r.Year <= eyear);
                            let yrs = extractYearArray(fltData, "Year");
                            setYears(yrs);

                            let resSorted = fltData.sort((a, b) => (parseInt(a.Year) > parseInt(b.Year) ? 1 : parseInt(b.Year) > parseInt(a.Year) ? -1 : 0));
                            // setSysBalanceAllData(resSorted);
                            setBarChartData(convertToBarChartDataSource(resSorted));
                            // updateData(selectedScenario, resSorted);
                            setIsLoading(false);
                        } else {
                            throw new Error(res.message);
                        }
                    });
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        }
        // if (scenario) {
        //     let allDataFlt = allData.filter((r) => parseInt(r.PathwayScenarioID) === parseInt(scenario.PathwayScenarioID));
        // }
    };

    const handleChange = (e) => {
        let currScenario = lstOfScenarios.filter((s) => s.PathwayScenarioName === e.target.value)[0];
        updateData(currScenario);
        dispatchScenario({ type: actionTypeReport.SELECT_SCENARIO, payload: { response: currScenario } });
    };

    useEffect(() => {
        // setInterval(setAccessToken(instance, accounts), 3400 * 1000);
        updateData(selectedScenario);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    let dropdown = (
        <div className="p-grid p-align-center">
            <h6>Scenario: </h6>
            <Dropdown style={{ marginLeft: "10px" }} optionLabel="PathwayScenarioName" optionValue="PathwayScenarioName" value={selectedScenario.PathwayScenarioName} options={lstOfScenarios} onChange={handleChange} placeholder="Select a Scenario" />
        </div>
    );

    let view = <Loading comp={PointSpreadLoading} />;
    if (!isLoading) {
        view = <DemandBarChartOverYears dropdown={dropdown} dataSource={barChartData} labels={years} />;
    }

    return <React.Fragment>{view}</React.Fragment>;
};

export default PowerDemandGen;
