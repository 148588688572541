import React from 'react'
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';


const NetItemDiagContent = (props) => {

    const changeRowValues = (e, v) => {
        let updateVals = props.addRowVals.map(r => {
            if (r.name === v.name) {
                return {...v, value: e.target.value}
            } else {
                return {...r}
            }
        })
        props.setAddRowVals(updateVals);
    }

    let view = null
    
    if (props.mode === 'read') {
        view =(
            <table>
                {
                    props.addRowVals.map(v => {
                        return (
                            <tr>
                                <td><b>{v.name}: </b></td>
                                <td style={{width:"10px"}}></td>
                                <td >{v.value}</td>
                            </tr>  
                        )                
                    })
                }
            </table>
            
        )
    }
    

    if (props.mode === 'edit') {
        view = props.addRowVals.map(v => {
            if (v.newRow) {
                let input = <InputText key={v.name} type="text" value={v.value} onChange={(e) => changeRowValues(e,v)} />
                if (v.newRow.type === 'dropdown') {
                    input = <Dropdown 
                        key={v.name} 
                        value={v.value} 
                        options={v.unqVals} 
                        onChange={(e) => changeRowValues(e,v)} />;
                }
                return (
                    <div className='p-grid'>
                        <span className='p-col-4 p-justify-right'>{v.name}</span>
                        <span className='p-col-7'>{input}</span>
                    </div>
                )
            } 
            else {
                return null;
            }      
        })
    }

    return view;
}

export default NetItemDiagContent;
