import React, { useState, useEffect, useMemo,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";
import Papa from "papaparse";
import { Toast } from "primereact/toast";

// import { newProfile } from '../../../api/manageProfileAPI';
import ProfilePlot from "../../../../components/profilePlot";
import Loading from "../../../../components/Loading";

const DialogProfile = (props) => {
    const toast = useRef(null);
    const orgProfile = props.diagMode.toLowerCase() === "create" ? null : props.profile;

    // if (props.diagMode.toLowerCase() === "create") {
    //     setNewProfileData(props.lstOfProfileData.filter((r) => r.ProfileID === 1))
    // }

    useEffect(() => {
        // setNewProfileData(profileData)
    }, []);

    const cellEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const showError = (header,message) => {
        toast.current.show({ severity: "error", summary: header, detail: message, life: 6000 });
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field } = e;

        switch (field) {
            case "Value":
                let newProfileData = props.profileData.map((r) => {
                    if (r.ProfileEntryID === rowData.ProfileEntryID) {
                        r.Value = newValue;
                    }
                    return r;
                });
                props.setProfileData(newProfileData);
                break;
            default:
                break;
        }
    };

    const checkAllYearsInImportedData = (data) => {
        let allYears = data.map((d) => d.Year);
        for (let yr = 2020; yr <= 2050; yr++) {
            if (!allYears.includes(yr)) {
                return false;
            }
        }
        return true;
    };

    const importCSV = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const text = e.target.result;
                let arr = Papa.parse(text)
                    .data.slice(1, -1)
                    .map((r) => {
                        return { Year: parseInt(r[0]), Value: parseFloat(r[1]) };
                    })
                    .sort((a, b) => parseFloat(a.Year) - parseFloat(b.Year));
                if (checkAllYearsInImportedData(arr)) {
                    props.setProfileData(arr);
                } else {
                    showError("Some year values missing","Data should be provided for all years between 2020 to 2050.")
                }
            } catch (err) {
                throw new Error(err);
            }
        };
        reader.readAsText(e.target.files[0]);
    };

    let headerTitle = `${props.diagMode} profile`;

    const dialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDiag} />
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={props.onSubmit} />
        </React.Fragment>
    );

    let diagContent = (
        <div>
            <h6 style={{ margin: "20px" }}>Updating the database. This might take some time.</h6>
            <Loading />
        </div>
    );

    const traceValueChange = (e) => {
        // console.log('triggered')
        props.setNewProfileName(e.target.value);
    };

    const profilePlot = useMemo(() => {
        return (
            <div className="p-col-9">
                <ProfilePlot
                    plotHeight="50vh"
                    originalProfile={orgProfile ? orgProfile.Profile : null}
                    customProfileName="New Profile"
                    // customProfileData={newProfileData? newProfileData.map(r => parseFloat(r.Value)) : null}
                    customProfileData={props.profileData ? props.profileData.map((r) => parseFloat(r.Value)) : null}
                />
            </div>
        );
    }, [props.profileData, orgProfile]);

    if (!props.isInProgress) {
        diagContent = (
            <React.Fragment>
                <div className="p-grid">
                    <span className="p-col-1 p-justify-right">
                        <h5>Name</h5>
                    </span>
                    <span className="p-col-11">
                        <InputText value={props.newProfileName} onChange={traceValueChange} />
                    </span>
                </div>
                <div className="p-grid p-justify-right">
                    <span className="p-col-1">
                        <h5>Description</h5>
                    </span>
                    <span className="p-col-11">
                        <InputTextarea className="p-col-7" value={props.newProfileDesc} onChange={(e) => props.setNewProfileDesc(e.target.value)} rows={5} cols={30} />
                    </span>
                </div>
                <Card title="Data Points">
                    <div className="p-grid">
                        <span className="p-col-3">
                            <DataTable scrollable scrollHeight="45vh" value={props.profileData} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">
                                <Column field="Year" header="Year" />
                                <Column field="Value" header="Value" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                            </DataTable>
                            <Button className="p-button-outlined p-button" style={{ width: "auto", padding: 0, marginTop: "10px" }}>
                                <label style={{ zIndex: 500, padding: "10px", cursor: "pointer" }}>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        accept=".csv"
                                        onClick={(e) => {
                                            e.target.value = null;
                                        }}
                                        onChange={(e) => importCSV(e)}
                                    />
                                    Import CSV
                                </label>
                            </Button>
                            {/* <InputText type="file" accept=".csv" label="Import CSV" onChange={(e) => importCSV(e)}/> */}
                        </span>
                        {profilePlot}
                        {/* <div className="p-col-9">
                            <ProfilePlot 
                            plotHeight= "50vh"
                            originalProfile={orgProfile? orgProfile.Profile : null}
                            customProfileName="New Profile"
                            // customProfileData={newProfileData? newProfileData.map(r => parseFloat(r.Value)) : null}
                            customProfileData={props.profileData? props.profileData.map(r => parseFloat(r.Value)) : null}
                            />
                        </div> */}
                    </div>
                </Card>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Dialog visible={props.showDiag} style={{ width: "70vw" }} header={props.isInProgress ? null : headerTitle} modal className="p-fluid" footer={props.isInProgress ? null : dialogFooter} onHide={props.isInProgress ? null : props.hideDiag}>
                {diagContent}
            </Dialog>
            <Toast ref={toast} />
        </React.Fragment>
    );
};

export default DialogProfile;
