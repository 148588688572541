import React from "react";
import LineChart from "../../../../../../components/Charts/LineChart";
import { range } from "../../../../../../utils/comm";
import { Space, Typography, Spin } from "antd";
import { Slider } from "primereact/slider";
import { predictAIRecommFactors } from "../../../../../../api/energy-model/experimentServices";

const { Text } = Typography;

const options = [
    { name: "Solar", target: "installed_renewables" },
    { name: "Wind", target: "installed_renewables" },
    { name: "Storage", target: "installed_storage" },
    { name: "Cost", target: "total_cost" },
];

function RenewableScheduleChart({ fltData, startAllYear, endAllYear, loadTotalChart, selectedSub }) {
    const [windValue, setWindValue] = React.useState(0);
    const [gridValue, setGridValue] = React.useState(0);
    const [aiFactors, setAIFactors] = React.useState([]);
    const [isApiCalling, setIsApiCalling] = React.useState(false);

    const windShare = parseFloat(windValue) / 100;
    const gridShare = parseFloat(gridValue) / 100;

    const years = React.useMemo(() => {
        return range(startAllYear, endAllYear + 1);
    }, [startAllYear, endAllYear]);

    const dataShortList = React.useMemo(() => {
        return fltData.filter((r) => r.show);
    }, [fltData]);

    const updateAIFactors = () => {
        setIsApiCalling(true);
        predictAIRecommFactors(windShare, gridShare).then(
            (res) => {
                setAIFactors(res.data.aiResult);
                setIsApiCalling(false);
            },
            (err) => {
                setIsApiCalling(false);
            }
        );
    };

    React.useEffect(() => {
        updateAIFactors();
    }, [loadTotalChart]);

    const aiRecommInst = React.useMemo(() => {
        const aiDataChart = {};
        options.forEach((opt) => {
            const fltFactor = aiFactors.filter((r) => r.SubstationID === selectedSub.SubstationID && r.target === opt.target);
            let factor = fltFactor.length > 0 ? fltFactor[0].factor : 0;
            if (opt.name === "Solar") {
                factor = factor * (1 - windShare);
            }

            if (opt.name === "Wind") {
                factor = factor * windShare;
            }
            aiDataChart[opt.name] = loadTotalChart.map((r) => r * factor);
        });

        return aiDataChart;
    }, [aiFactors, fltData]);

    const installationData = React.useMemo(() => {
        const instSolarData = [];
        const instWindData = [];
        const instStorageData = [];
        let installedSolar = 0;
        let installedWind = 0;
        let installedStorage = 0;

        years.forEach((yr) => {
            const installation = dataShortList.filter((r) => r.InstalledYear === yr);
            if (installation.length > 0) {
                installedSolar += installation[0].SolarCap;
                installedWind += installation[0].WindCap;
                installedStorage += installation[0].StorageCap;
            }

            instSolarData.push(installedSolar);
            instWindData.push(installedWind);
            instStorageData.push(installedStorage);
        });

        return {
            Solar: instSolarData,
            Wind: instWindData,
            Storage: instStorageData,
        };
    }, [dataShortList]);

    const dataSources = React.useMemo(() => {
        return options.map((opt) => {
            if (opt.target === "total_cost") {
                return [
                    {
                        data: aiRecommInst[opt.name],
                        name: `${opt.name} - AI Guideline`,
                    },
                ];
            }

            return [
                {
                    data: installationData[opt.name],
                    name: `${opt.name} - Installed`,
                },
                {
                    data: aiRecommInst[opt.name],
                    name: `${opt.name} - AI Guideline`,
                },
                {
                    data: loadTotalChart,
                    name: "Ave. Load",
                },
            ];
        });
    }, [installationData, aiRecommInst, loadTotalChart]);

    const onWindChange = (e) => {
        setWindValue(e.value);
    };

    const onGridChange = (e) => {
        setGridValue(e.value);
    };

    const handleAIUpdate = () => {
        updateAIFactors();
    };

    return (
        <div>
            <div className="p-grid p-align-center p-justify-center">
                <h3>AI Guideline</h3>
            </div>
            <div className="p-grid p-align-center p-justify-center" style={{ marginTop: "10px" }}>
                <Space>
                    <Text>{`Wind ${windValue}%`}</Text>
                    <Slider min={0} max={100} style={{ width: "150px" }} onChange={onWindChange} value={typeof windValue === "number" ? windValue : 0} onSlideEnd={handleAIUpdate} />
                    {isApiCalling ? <Spin /> : null}
                    <Text>{`Grid ${gridValue}%`}</Text>
                    <Slider min={0} max={80} style={{ width: "150px" }} onChange={onGridChange} value={typeof gridValue === "number" ? gridValue : 0} onSlideEnd={handleAIUpdate} />
                    {isApiCalling ? <Spin /> : null}
                </Space>
            </div>

            <div>
                <div className="p-grid" style={{ height: "40vh" }} disabled={isApiCalling}>
                    <div className="p-col-6">
                        <LineChart dataSource={dataSources[0]} xValues={years} yLabel="Installed Solar (MW)" ymin={0} type="line" height="100%" />
                    </div>
                    <div className="p-col-6">
                        <LineChart dataSource={dataSources[1]} xValues={years} yLabel="Installed Wind (MW)" ymin={0} type="line" height="100%" />
                    </div>
                </div>
                <div className="p-grid" style={{ height: "40vh" }} disabled={isApiCalling}>
                    <div className="p-col-6">
                        <LineChart dataSource={dataSources[2]} xValues={years} yLabel="Installed Storage (MWh)" ymin={0} type="line" height="100%" />
                    </div>
                    <div className="p-col-6">
                        <LineChart dataSource={dataSources[3]} xValues={years} yLabel="Total Cost ($M)" ymin={0} type="line" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RenewableScheduleChart;
