import React from "react";

import { Message } from "primereact/message";
import Step1Intro from "./Step1Intro";
import Step2Intro from "./Step2Intro";
import Step3Intro from "./Step3Intro";
import Methodology from "./Methodology";
const styleMsgBox = {
    width: "80%",
    alignContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
};

const DynamicAnalysisIntro = () => {
    const dynamicAnalysis = (
        <div>
            <h3>
                <b>Dynamic Analysis</b>
            </h3>
            <p>
                The Dynamic Analysis platform (DAP) was developed for the Dampier Salt Limited Decarbonisation Study to allow the user to produce, assess, and iterate on various transition’s pathways. The DAP is a self-contained application that contains much of the functionality of the Energy Model
                with additional features and improved user interface, allowing the user to easily change and compare scenarios. DAP is separated into 4 sections:
                <br />
                <br />
            </p>
            <ul>
                <li>Load inputs: Allows the user to set the technology transition pathway (E.g. diesel haul trucks replaced by battery electric haul trucks or biodiesel).</li>
                <li>Load results: Allows the user to view the year to year energy demand profile resulting from the chosen technology pathway and how it breaks down per site, per function and per energy type (Electric, Diesel, Biodiesel).</li>
                <li>Renewables deployment: Allows user to define installation of wind, solar, and storage to meet the electric load. Provides metrics on the network performance, such as dependence on the local grid, installation and O&M costs.</li>
                <li>Export JSON: Allows the user to save the scenario and any calculations conducted in the DAP. The user can then upload a previously exported JSON file to view a previous scenario or pick up where they left off.</li>
            </ul>
            <p>
                <br />
                <br />
                The DAP runs live calculations based on the user inputs. Changes made in the DAP do not impact the calculations found in the Energy Model. If a scenario produced in the DAP needs to be captured in the Energy Model, please contact application manager.
                <br />
                <br />
            </p>
        </div>
    );

    return (
        <>
            {dynamicAnalysis}
            <Step1Intro />
            <Step2Intro />
            <Step3Intro />
            <Methodology />
        </>
    );
};

export default DynamicAnalysisIntro;
