import React, { useState } from "react";
import { useSelector } from "react-redux";
// IMPORT COMPONENTS
import EnergyEmission from "./energyChart";
import FunctionEmission from "./funcChart";
import Loading from "../../../../components/Loading";
import { PointSpreadLoading } from "react-loadingg";
import { Card } from "primereact/card";
import { Space } from "antd";
import SideFilter from "../../../../components/Filters";
import * as tbUtils from "../../../../utils/tables";
// IMPORT API
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as ApiEmit from "../../../../api/energy-model/emissionServices";
//IMPORT STYLES
import "primeflex/primeflex.css";

import { extractYearArray } from "../../../../utils/comm";

const energyLabels = ["Diesel", "GridPower"];

const convertToDataSource = (scenarioDetail, valueKeyName, labels, years, queryCol) => {
    let dataSource = [];
    if (scenarioDetail) {
        labels.forEach((l) => {
            let dataCat = scenarioDetail.filter((r) => r[valueKeyName] === l);

            var dataCur = [];
            years.forEach((yr) => {
                var d = dataCat.filter((r) => parseInt(r.Year) === yr);

                if (d.length > 0) {
                    dataCur.push(parseFloat(d[0][queryCol]));
                } else {
                    dataCur.push(null);
                }
            });

            dataSource.push({
                name: l,
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const convertToDataSourceRenewable = (data) => {
    let dataSource = [
        {
            name: "SolarPower",
            data: data.map((s) => parseFloat(s.SolarPowerMW)),
        },
        {
            name: "WindPower",
            data: data.map((s) => parseFloat(s.WindPowerMW)),
        },
    ];
    return dataSource;
};

const updateDataBySiteFilter = (data, yrs, selectedSites, queryCol, funcLabels, setIsEnergyTagLoading, setIsFuncTagLoading, setChartFunctionDataSource, setChartEnergyDataSource) => {
    let flt_data = data.filter((r) => selectedSites.includes(r.SiteID));

    const rdc_data_by_func = tbUtils.groupByTotal(flt_data, ["Year", "Function", "FunctionID"], [queryCol]);
    let dataSourceFunc = convertToDataSource(rdc_data_by_func, "Function", funcLabels, yrs, queryCol);

    const rdc_data_by_energy = tbUtils.groupByTotal(flt_data, ["Year", "EnergyTag"], [queryCol]);
    let dataSourceEnergy = convertToDataSource(rdc_data_by_energy, "EnergyTag", energyLabels, yrs, queryCol);
    setIsEnergyTagLoading(false);
    setIsFuncTagLoading(false);
    setChartFunctionDataSource(dataSourceFunc);
    setChartEnergyDataSource(dataSourceEnergy);
};

const setScenario = (scenario, lstOfSites, setYears, setIsPowerLoading, setDataSourceAll, setChartRenewableDataSource, queryCol, funcLabels, setIsEnergyTagLoading, setIsFuncTagLoading, setChartFunctionDataSource, setChartEnergyDataSource, projID) => {
    if (scenario) {
        let chartRenewableData = null;
        let yrs = null;

        const eyr = projID === 1 ? 2050 : 2030;

        ApiEnergy.getEnergyEmissionCalculations(scenario.PathwayScenarioID, -1, 2021, eyr, "PathwayScenarioID,Year,Site,SiteID,Function,FunctionID,EnergyTag", queryCol).then((res) => {
            if (!res.hasError) {
                yrs = extractYearArray(res.data, "Year");
                setYears(yrs);
            } else {
                throw new Error(res.message);
            }
            setDataSourceAll(res.data);
            const selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
            updateDataBySiteFilter(res.data, yrs, selectedSites, queryCol, funcLabels, setIsEnergyTagLoading, setIsFuncTagLoading, setChartFunctionDataSource, setChartEnergyDataSource);
        });

        ApiEnergy.getRenewableSysTotalByScenarioID(scenario).then((res) => {
            if (!res.hasError) {
                chartRenewableData = convertToDataSourceRenewable(res.data.filter((r) => r.Year > 2020 && r.Year <= eyr));
            } else {
                throw new Error(res.message);
            }
            setIsPowerLoading(false);
            setChartRenewableDataSource(chartRenewableData);
        });
    }
};

const getTargetEmissions = (setTargetEmissions, selectedUserAccess) => {
    ApiEmit.getAllTargetEmissionsByProjectID(selectedUserAccess.ProjectID).then(
        (res2) => {
            if (!res2.hasError) {
                setTargetEmissions(res2.data);
            }
        },
        (err2) => {
            console.log(err2);
        }
    );
};

function EmissionFigures() {
    const [isEnergyTagLoading, setIsEnergyTagLoading] = useState(false);
    const [isFuncTagLoading, setIsFuncTagLoading] = useState(false);
    const [isPowerLoading, setIsPowerLoading] = useState(false);
    const [chartEnergyDataSource, setChartEnergyDataSource] = useState(null);
    const [chartFunctionDataSource, setChartFunctionDataSource] = useState(null);
    const [chartRenewableDataSource, setChartRenewableDataSource] = useState(null);
    const [dataSourceAll, setDataSourceAll] = useState(null);
    const [years, setYears] = useState([]);
    const [targetEmissions, setTargetEmissions] = useState(null);

    const selectedBaselineEmission = useSelector((state) => state.report.selectedBaselineEmitVer);

    const lstOfSites = useSelector((s) => s.report.lstOfMines);
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);
    const selectedScenario = useSelector((s) => s.report.selectedScenario);
    const lstOfFunctions = useSelector((s) => s.report.lstOfFunctions);
    const selectedTarget = useSelector((s) => s.report.selectedTargetEmitVersion);
    const funcLabels = [...new Set(lstOfFunctions.map((item) => item.Function))];

    const queryCol = React.useMemo(() => {
        return selectedBaselineEmission.Mode === "Operation" ? "tCO2-e" : "tCO2-eRTequity";
    }, [selectedBaselineEmission]);

    const handleScenarioChange = (scenario) => {
        if (selectedScenario) {
            setIsEnergyTagLoading(true);
            setIsFuncTagLoading(true);
            setIsPowerLoading(true);
            setScenario(scenario, lstOfSites, setYears, setIsPowerLoading, setDataSourceAll, setChartRenewableDataSource, queryCol, funcLabels, setIsEnergyTagLoading, setIsFuncTagLoading, setChartFunctionDataSource, setChartEnergyDataSource, selectedUserAccess.ProjectID);
        }
    };

    React.useEffect(() => {
        getTargetEmissions(setTargetEmissions, selectedUserAccess);
    }, []);

    React.useEffect(() => {
        handleScenarioChange(selectedScenario);
    }, [selectedBaselineEmission]);

    const handleSiteFilter = (updatedItems) => {
        const selectedSites = updatedItems.filter((f) => f.checked).map((r) => r.SiteID);
        updateDataBySiteFilter(dataSourceAll, years, selectedSites, queryCol, funcLabels, setIsEnergyTagLoading, setIsFuncTagLoading, setChartFunctionDataSource, setChartEnergyDataSource);
    };

    const dummyFunc = () => {
        console.log("dummy function for filter.");
    };

    const loadingEnergy = (
        <div>
            <p>Loading Data for Emission by Energy Chart ...</p>
            <Loading comp={<PointSpreadLoading />} />
        </div>
    );
    const loadingFuncs = (
        <div>
            <p>Loading Data for Emission by Function Divisions Chart ...</p>
            <Loading comp={<PointSpreadLoading />} />
        </div>
    );

    const sideFilters = (
        <SideFilter
            hasScenarioFilter={true}
            hasBaselineFilter={true}
            hasTargetVerFilter={true}
            hasSiteFilter={true}
            hasBaselineFactorFilter={false}
            hasTMMSOPFilter={false}
            hasSiteSearchBar={true}
            handleScenarioChange={handleScenarioChange}
            handleTargetVerChange={dummyFunc}
            handleBaselineVerChange={dummyFunc}
            onChangeCheckedSites={handleSiteFilter}
            handleTMMSOPVerChange={() => {}}
            isOverlay={false}
            maxHeightSiteFilter="400px"
        />
    );

    const space = "25px";

    return (
        <>
            <Card style={{ height: "80vh", borderRadius: "0px" }}>
                <div className="p-grid p-align-center">
                    <Space>
                        <h4 style={{ marginRight: space }}>
                            <b>CO2 - Emissions by Energy Types</b>
                        </h4>
                        {sideFilters}
                    </Space>

                    <div />
                </div>
                <EnergyEmission
                    projID={selectedUserAccess.ProjectID}
                    dropdown={null}
                    baseFactor={1}
                    filter={null}
                    loadingComp={loadingEnergy}
                    isLoading={isEnergyTagLoading || isPowerLoading}
                    dataSource={chartEnergyDataSource}
                    dataSourceRenwable={chartRenewableDataSource}
                    legends={energyLabels}
                    xaxis={years}
                    selectedBaselineEmission={selectedBaselineEmission}
                    selectedTargetEmission={targetEmissions ? targetEmissions.filter((r) => r.VersionID === selectedTarget.VersionID) : []}
                />
            </Card>

            <Card style={{ height: "80vh", borderRadius: "0px", marginTop: space }}>
                <div className="p-grid p-align-center">
                    <h4 style={{ marginRight: space }}>
                        <b>CO2 - Emissions by Functions</b>
                    </h4>
                    {sideFilters}
                    <div />
                </div>
                <FunctionEmission
                    projID={selectedUserAccess.ProjectID}
                    dropdown={null}
                    baseFactor={1}
                    filter={null}
                    loadingComp={loadingFuncs}
                    isLoading={isFuncTagLoading}
                    dataSource={chartFunctionDataSource}
                    legends={funcLabels}
                    xaxis={years}
                    selectedBaselineEmission={selectedBaselineEmission}
                    selectedTargetEmission={targetEmissions ? targetEmissions.filter((r) => r.VersionID === selectedTarget.VersionID) : []}
                />
            </Card>
        </>
    );
}

export default EmissionFigures;
