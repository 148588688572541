import React from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "primereact/button";
import { loginRequest } from "./authConfig";

function SignInButton() {
    const { instance, inProgress, accounts } = useMsal();

    // await instance.handleRedirectPromise();

    async function getTokenSilent() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const response = await instance.acquireTokenSilent(request);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleLogin() {
        try {
            if (accounts.length === 0 && inProgress === InteractionStatus.None) {
                const response = await instance.loginRedirect(loginRequest);
                console.log(response);
            }
            await getTokenSilent();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Button style={{ fontSize: 16, padding: "5px 25px " }} onClick={() => handleLogin()}>
                Sign In
            </Button>
        </div>
    );
}

export default SignInButton;
