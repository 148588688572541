import React, { useState } from "react";
import LineChart from "../../../../../../components/Charts/LineChart";
import BarChart from "../../../../../../components/Charts/BarChart";
import { Dropdown } from "primereact/dropdown";
import * as CONST from "../../../../../../store/constant";

const ResultChartRenewable = ({ apiRows, substations, resultFields }) => {
    const showFields = resultFields.filter((r) => r.showChart);
    const [selectedY, setSelectedY] = useState(showFields[0]);

    const years = React.useMemo(() => {
        return [...new Set(apiRows.map((r) => r.year))];
    }, [apiRows]);

    const dataSource = React.useMemo(() => {
        return substations.map((sub) => {
            let data = apiRows.filter((r) => r.SubstationID === sub.SubstationID).map((x) => x[selectedY.param]);

            return {
                name: sub.Substation,
                data: data,
            };
        });
    }, [years, apiRows, selectedY]);

    const handleChange = (e) => {
        setSelectedY(e.value);
    };

    if (apiRows?.length === 0) {
        return <div>Charts are displayed here after the Running the simulation.</div>;
    }

    return (
        <>
            <div className="flex">
                <span style={{ marginRight: "10px" }}>Select the Y axis</span>
                <Dropdown options={showFields} optionLabel="title" value={selectedY} onChange={handleChange} />
            </div>
            <div>
                {selectedY.chartType === "line" ? (
                    <LineChart dataSource={dataSource} xValues={years} height="60vh" yLabel={selectedY.title} type="line" scale={1} colors={CONST.COLOR_MAP_DISTINCT_2} />
                ) : (
                    <BarChart dataSource={dataSource} postfix="$" xValues={years} height="60vh" title={selectedY.title} scale={1} />
                )}
            </div>
        </>
    );
};

export default ResultChartRenewable;
