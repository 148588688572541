import * as Api from "axios";

export const GET = (url, reqType, tag) => {
    return Api.get(url).then(
        (res) => {
            return resTag(res, reqType, tag, res.data);
        },
        (err) => {
            return errTag(err, reqType, tag);
        }
    );
};

export const POST = (url, reqBody) => {
    return Api.post(url, reqBody).then(
        (res) => {
            return { hasError: false, message: "", data: res.data };
        },

        (err) => {
            return { hasError: true, message: err };
        }
    );
};

export const PUT = (url, reqBody) => {
    return Api.put(url, reqBody).then(
        (res) => {
            return { hasError: false, message: "" };
        },

        (err) => {
            return { hasError: true, message: err };
        }
    );
};

export const DELETE = (url) => {
    return Api.delete(url).then(
        (res) => {
            return { hasError: false, message: "" };
        },

        (err) => {
            return { hasError: true, message: err };
        }
    );
};

const chkData = (res, tag) => {
    return res.data.length === 0 ? `Error: no data is available for ${tag}` : "";
};

const chkStatus = (res, reqType, tag) => {
    return res.status === 200 ? "" : `Error in ${reqType} ${tag}, status: ${res.status}`;
};

export const resTagData = (res, reqType, tag, data) => {
    let message = chkStatus(res, reqType, tag);
    message += chkData(res, tag);
    if (message === "") {
        return { hasError: false, data: data };
    } else {
        return { hasError: true, message: message, data: [] };
    }
};

export const resTag = (res, reqType, tag, isDataChkReq) => {
    let message = chkStatus(res, reqType, tag);
    message += isDataChkReq ? chkData(res, tag) : "";
    if (message === "") {
        return { hasError: false, data: res.data };
    } else {
        return { hasError: true, message: message, data: [] };
    }
};

export const errTag = (err, reqType, tag) => {
    return { hasError: true, message: `Error occured in ${reqType} ${tag}: ${err}`, data: [] };
};
