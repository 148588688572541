import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { PointSpreadLoading } from "react-loadingg";

import * as CONS from "../../../../store/constant";

import Loading from "../../../../components/Loading";

// const  strokeDashArray = 5

class PowerChart extends React.Component {
    render() {
        let display = <Loading />;
        if (this.props.dataSource) {
            var options = configureChart(this.props);
            let dataLoadingDisp = this.props.isLoading ? <Loading comp={<PointSpreadLoading />} /> : <Chart type="area" options={options.options} series={options.series} width="100%" height="100%" />;
            display = (
                <Card style={{ height: "80vh" }}>
                    <div className="p-grid p-align-center">
                        <h4>
                            <b>Energy Demand By Functions</b>
                        </h4>
                        <span style={{ marginLeft: "15px" }}>{this.props.filter}</span>
                        <div />
                    </div>
                    <div style={{ height: "70vh", width: "100%" }}>{dataLoadingDisp}</div>
                </Card>
            );
        }

        return <React.Fragment>{display}</React.Fragment>;
    }
}

const mapStateToProps = (state) => {
    return {
        statusColors: state.report.statusColors,
        targetPos: state.report.targetPos,
        ymax2018: state.report.ymax2018,
    };
};

export default connect(mapStateToProps, null)(PowerChart);

const configureChart = (props) => {
    return {
        series: props.dataSource,
        options: {
            annotations: {},
            chart: {
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                    },
                },
                stacked: true,
                // height: 700,

                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: CONS.COLOR_MAP_DISTINCT_2, //,
            stroke: {
                width: 1,
                curve: "straight",
            },
            legend: {
                show: true,
                showForSingleSeries: true,
            },
            labels: props.xaxis,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return (value / 1000).toFixed(0);
                        }
                    },
                },
                title: {
                    // text: "MWh-e"
                    text: props.ylabel,
                },
            },
        },
    };
};
