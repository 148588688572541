import React from 'react'
import { WindMillLoading } from 'react-loadingg';

export default function Loading(props) {
    let view = <WindMillLoading/>
    if (props.comp) {
        view = props.comp
    }
    return (
        <div style={{width: "100%", height: "100%", verticalAlign: "center", alignContent:"center"}}>
            {view}
        </div>
    )
}
