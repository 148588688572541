import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ChkListBox from "../CheckListBox/CheckListBox";
import OverlayCheckListBox from "../CheckListBox/OverlayCheckListBox";
import * as actionTypeReport from "../../../store/report/actionReport";

const EnergyFilter = (props) => {
    /**
     * @isOverlay               An overlay view is selected or direct inclusion.
     * @onChangeCheckedSites    function implementation when checked items are ticked or unticked. The function includes the updated items after the event.
     * @filter                  include filter field at the top.
     * @maxHeight               maximum height of the check listbox
     * @btnWidth                if Overlay is selected.
     */

    const lstOfEnergies = useSelector((s) => s.report.lstOfEnergies);
    const dispatch = useDispatch();

    const onChangeCheckedEnergies = (updatedItems) => {
        dispatch({ type: actionTypeReport.SET_CHK_STATE_ENERGIES, payload: { response: updatedItems } });
        props.onChangeCheckedEnergies(updatedItems);
    };

    let energies = lstOfEnergies.map((r) => {
        return { ...r };
    });

    let energyFilter = null;
    if (energies !== null && energies.length > 0) {
        energyFilter = props.isOverlay ? (
            <OverlayCheckListBox btnLabel="Energies" btnWidth={props.btnWidth} filter allItem items={energies} maxHeight={props.maxHeight} IdField="EnergyID" labelField="EnergyTag" setItems={onChangeCheckedEnergies} />
        ) : (
            <ChkListBox btnLabel="Energies" filter={props.filter} allItem items={energies} maxHeight={props.maxHeight} IdField="EnergyID" labelField="EnergyTag" setItems={onChangeCheckedEnergies} />
        );
    }

    return energyFilter;
};

export default EnergyFilter;
