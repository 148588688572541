import React, { useState } from "react";
import SiteFilter from "./SiteFilter";
import EnergyFilter from "./EnergyFilter";
import FuncFilter from "./FuncFilter";
import MineDataVerDropdown from "./Dropdowns/MineDataVerDropdown";
import ScenarioDropdown from "./Dropdowns/ScenarioDropdown";
import TargetVerDropdown from "./Dropdowns/TargetVerDropdown";
import BaselineEmitVerDropdown from "./Dropdowns/BaselineEmitVerDropdown";
import { Divider } from "primereact/divider";
import { Sidebar } from "primereact/sidebar";
import { Button, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const FilterComponent = ({ hasFilter, title, component, space }) => {
    if (hasFilter) {
        return (
            <>
                <Divider style={{ marginTop: space }} align="left" type="solid">
                    <b>{title}</b>
                </Divider>
                {component}
            </>
        );
    } else {
        return null;
    }
};

const SideFilter = (props) => {
    /**
     * This component is used to accomodate filters.
     *
     * Side Filter is basically a button that can be embedded in any view.
     * It is an overlay menu and it contains a series of dropdowns and checkboxes.
     *
     * @hasScenarioFilter
     * @hasBaselineFilter
     * @hasBaselineFactorFilter
     * @hasTargetVerFilter
     * @hasTMMSOPFilter
     * @hasSiteFilter
     * @hasSiteSearchBar
     * @handleScenarioChange
     * @handleTargetVerChange
     * @handleBaselineVerChange
     * @handleTMMSOPVerChange
     * @onChangeCheckedSites
     * @factor
     * @setFactor
     * @isOverlay
     * @maxHeightSiteFilter
     * @btnWidthSiteFilter
     * @sideFilterWidth
     * @sideFilterPosition
     */

    const [showSideFilter, setShowSideFilter] = useState(false);

    const scenarioFilt = <FilterComponent hasFilter={props.hasScenarioFilter} title="Scenario" component={<ScenarioDropdown onChange={props.handleScenarioChange} />} />;

    const targetFilt = <FilterComponent hasFilter={props.hasTargetVerFilter} title="Target Emission" component={<TargetVerDropdown onChange={props.handleTargetVerChange} />} />;

    const baselineFilt = <FilterComponent hasFilter={props.hasBaselineFilter} title="Baseline Emission" component={<BaselineEmitVerDropdown onChange={props.handleBaselineVerChange} />} />;

    const tmmSopFilt = <FilterComponent hasFilter={props.hasTMMSOPFilter} title="TMM/SOP Version" component={<MineDataVerDropdown onChange={props.handleTMMSOPVerChange} />} />;

    const siteFilt = (
        <FilterComponent hasFilter={props.hasSiteFilter} title="Sites" space="25px" component={<SiteFilter isOverlay={props.isOverlay} filter={props.hasSiteSearchBar} maxHeight={props.maxHeightSiteFilter} btnWidth={props.btnWidthSiteFilter} onChangeCheckedSites={props.onChangeCheckedSites} />} />
    );

    const energyFilt = <FilterComponent hasFilter={props.hasEnergyFilter} title="Energies" component={<EnergyFilter isOverlay={props.isOverlay} filter={null} maxHeight="300px" btnWidth={props.btnWidthEnergyFilter} onChangeCheckedEnergies={props.onChangeCheckedEnergies} />} />;

    const funcFilt = <FilterComponent hasFilter={props.hasFuncFilter} title="Functions" component={<FuncFilter isOverlay={props.isOverlay} filter={null} maxHeight="500px" btnWidth={props.btnWidthFuncFilter} onChangeCheckedFuncs={props.onChangeCheckedFuncs} />} />;

    return (
        <React.Fragment>
            <Tooltip placement="topLeft" title="Apply filters to the chart.">
                <Button icon={<FilterOutlined />} type="primary" size="middle" onClick={() => setShowSideFilter(true)}>
                    Filter
                </Button>
            </Tooltip>
            <Sidebar style={{ width: props.sideFilterWidth ? props.sideFilterWidth : "300px" }} visible={showSideFilter} position={props.sideFilterPosition} onHide={() => setShowSideFilter(false)}>
                <h2>Filters</h2>
                {scenarioFilt}
                {tmmSopFilt}
                {baselineFilt}
                {targetFilt}
                {props.customFilt}
                {funcFilt}
                {energyFilt}
                {siteFilt}
            </Sidebar>
        </React.Fragment>
    );
};

export default SideFilter;
