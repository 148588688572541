import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { CSVLink } from "react-csv";
import ItemDiag from './itemDialog';


const MineDataToolbar = (props) => {

    const [showDialog, setShowDialog] = useState(false);
    const lstOfSites = useSelector(state => state.report.lstOfMines)

    const style = {marginLeft: '5px'}
    
    const leftTools = (
        <React.Fragment>
            <Button onClick={(e) => setShowDialog(true)}>New Data</Button>
        </React.Fragment>
    )

    const csvData = props.dataSource? props.dataSource.map(r => {
        return {
            Site: r.SiteName,
            Year: r.Year,
            TMM_MTPA: r.TMM_MTPA,
            SOP_MTPA: r.SOP_MTPA
        }
    }) : []

    const csvTemplate = [
        ["Site", "Year", "TMM_MTPA","SOP_MTPA"],
        ["Bakers Main", "2028", "130", "30"]
      ];

    const rightTools = (
        <React.Fragment>
            <h5>Downloads:</h5>
            <CSVLink data={csvTemplate} style={style} filename={"TMM_SOP_Template_Input.csv"} target="_blank"><Button className="p-button-success" >Template</Button></CSVLink>
            <CSVLink data={csvData} style={style} filename={"TMM_SOP_Data_Download.csv"}    target="_blank"><Button className="p-button-success" >Current Data</Button></CSVLink>
            <CSVLink data={lstOfSites} style={style} filename={"ListOfMines.csv"} target="_blank"><Button className="p-button-success" >List of Sites</Button></CSVLink>
        </React.Fragment>
    )

    let toolbar = (
        <div>
            <Toolbar left={leftTools} right={rightTools} />
            <ItemDiag showDialog={showDialog} setShowDialog={setShowDialog} updateData={props.updateData} row={{DataVersion: 'New Version', DataDescription: 'New Description'}}/>
        </div>
    )

    return toolbar
}

export default MineDataToolbar;