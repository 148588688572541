import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import * as ApiEmit from "../../../../api/energy-model/emissionServices";
import * as actionTypeReport from "../../../../store/report/actionReport";
import Loading from "../../../../components/Loading";

const VersionDiag = (props) => {
    const toast = useRef(null);
    const lstVersionNames = useSelector((s) => s.report.lstOfTargetEmitVersions).map((r) => r.VersionName);
    const selectedTargetEmitVersion = useSelector((s) => s.report.selectedTargetEmitVersion);
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const hideDialog = () => {
        props.setShowDialog(false);
    };

    // if (props.version !== null) {
    //     if (version === null) {
    //         setVersion({...props.version})
    //     }
    //     // else if (props.version.VersionName !== version.VersionName) {
    //     //     setVersion({...version})
    //     // }
    // }

    // const inpDataVersion = useMemo(() => {
    //     if (props.version === null) {
    //         return null;
    //     }
    //     return <InputText type="text" value={props.version.VersionName} onChange={(e) => props.setVersion({...props.version, VersionName: e.target.value})}/>
    // }, [props.version]);

    // const inpDataDesc = useMemo(() => {
    //     if (props.version === null) {
    //         return null;
    //     }
    //     return <InputTextarea type="text" rows={5} cols={50} value={props.version.VersionDescription} onChange={(e) => props.setVersion({...props.version, VersionDescription: e.target.value})}/>
    // }, [props.version]);

    // const content = useMemo(() => {
    //     if (isLoading) {
    //         return <Loading />
    //     }
    //     return (
    //         <div>
    //             <table>
    //                 <tr>
    //                     <td>Version Name</td>
    //                     <td>{inpDataVersion}</td>
    //                 </tr>
    //                 <tr>
    //                     <td style={{alignContent:'top'}}>Description</td>
    //                     <td>{inpDataDesc}</td>
    //                 </tr>
    //             </table>
    //         </div>
    //     )
    // }, [props.version, isLoading])

    let content = null;
    if (props.version) {
        let inpDataVersion = <InputText type="text" value={props.version.VersionName} onChange={(e) => props.setVersion({ ...props.version, VersionName: e.target.value })} />;
        let inpDataDesc = <InputTextarea type="text" rows={5} cols={50} value={props.version.VersionDescription} onChange={(e) => props.setVersion({ ...props.version, VersionDescription: e.target.value })} />;

        content = (
            <div>
                <table>
                    <tr>
                        <td>Version Name</td>
                        <td>{inpDataVersion}</td>
                    </tr>
                    <tr>
                        <td style={{ alignContent: "top" }}>Description</td>
                        <td>{inpDataDesc}</td>
                    </tr>
                </table>
            </div>
        );
    }

    if (isLoading) {
        content = <Loading />;
    }

    const hasError_uniqueVersionName = () => {
        return lstVersionNames.includes(props.version.VersionName);
    };

    const submitData = () => {
        if (hasError_uniqueVersionName()) {
            toast.current.show({ severity: "error", summary: "Record with same name", detail: "There is already a record with the same version name. The operation cannot proceed!", life: 6000 });
            return;
        } else {
            setIsLoading(true);
            if (props.mode === "edit") {
                ApiEmit.updateTargetEmissionPrimary({ ...props.version, ProjectID: selectedUserAccess.ProjectID }).then(
                    (res) => {
                        ApiEmit.getAllTargetEmissionsPrimaryByProjectID(selectedUserAccess.ProjectID).then(
                            (res2) => {
                                dispatch({ type: actionTypeReport.SET_LIST_TARGET_EMIT_VER, payload: { response: res2.data } });
                                dispatch({ type: actionTypeReport.SELEC_TARGET_EMIT_VER, payload: { response: res2.data.filter((r) => r.VersionID === selectedTargetEmitVersion.VersionID)[0] } });
                                setIsLoading(false);
                                props.setShowDialog(false);
                            },
                            (err2) => {}
                        );
                    },
                    (err) => {}
                );
            }

            if (props.mode === "new") {
                ApiEmit.createTargetPrimary({ ...props.version, ProjectID: selectedUserAccess.ProjectID }).then(
                    (res) => {
                        ApiEmit.getAllTargetEmissionsPrimaryByProjectID(selectedUserAccess.ProjectID).then(
                            (res2) => {
                                dispatch({ type: actionTypeReport.SET_LIST_TARGET_EMIT_VER, payload: { response: res2.data } });
                                dispatch({ type: actionTypeReport.SELEC_TARGET_EMIT_VER, payload: { response: res2.data[res2.data.length - 1] } });
                                props.updateDataSource();
                                setIsLoading(false);
                                props.setShowDialog(false);
                            },
                            (err2) => {}
                        );
                    },
                    (err) => {}
                );
            }
        }
    };

    let header = "Not Defined";
    if (props.mode === "edit") {
        header = "Edit Version";
    }
    if (props.mode === "new") {
        header = "New Version";
    }

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
            <Button label={props.diagMode === "edit" ? "Update" : "Submit"} icon="pi pi-check" className="p-button-outlined" onClick={submitData} />
        </React.Fragment>
    );

    // const dialogComp = useMemo(() => {
    //     return (
    //         <Dialog visible={props.showDialog} style={{ width: "40vw" }} header={header} modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
    //             {content}
    //         </Dialog>
    //     )
    // }, [props.showDialog, props.version])

    return (
        <Dialog visible={props.showDialog} style={{ width: "40vw" }} header={header} modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
            {content}
            <Toast ref={toast} />
        </Dialog>
    );
};

export default VersionDiag;
