import React, { useState } from "react";
import { Button, Spin, Space, Upload } from "antd";
import { Toolbar } from "primereact/toolbar";
import * as ApiExperiment from "../../../../../api/energy-model/experimentServices";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import HelpIntro from "./help/HelpIntro";
import { range } from "../../../../../utils/comm";
import ImportJson from "../common/import_result";

const ToolbarRenewable = (props) => {
    const [isRunning, setIsRunning] = useState(false);
    let exportData = null;

    const onRunAnalysis = () => {
        /*
        IMPORTANT NOTE: CURRENTLY WE ARE ASSUMING ZERO BIO DIESEL. THE BELOW CODE IS JUST TO GENERATE
        A DEFAULT VALUE FOR BIO-DIESEL. LATER WE NEED TO REPLACE BELOW WEIGHTS WITH WHAT THE UI PROVIDES.
        */
        const bio_diesel_w = props.substations.map((r) => {
            return { SubstationID: r.SubstationID, w: range(props.startOverallYr, props.endOverallYr + 1).map((yr) => 0.0) };
        });

        setIsRunning(true);
        props.apiCallLoad(true);
        const data = {
            max_rand_change: props.dailyRand,
            syr: props.startOverallYr,
            eyr: props.endOverallYr,
            tbl_sites: props.sharedData.tbl_sites,
            tbl_energies: props.sharedData.tbl_energies,
            bio_diesel_w: bio_diesel_w,
            tbl_renew_schl: props.renewableTable,
            res: props.selectedResult["result"],
            WInstUnitCost: props.costInput[0].value,
            SInstUnitCost: props.costInput[1].value,
            StInstUnitCost: props.costInput[2].value,
            StInstUnitCostRate: props.costInput[3].value,
            WOMUnitCost: props.costInput[4].value,
            SOMUnitCost: props.costInput[5].value,
            StOMUnitCost: props.costInput[6].value,
            grid_cost: props.costInput[7].value,
            diesel_cost: props.costInput[8].value,
            bio_diesel_cost: props.costInput[9].value,
            run_life: props.costInput[10].value,
        };
        const exportinputRenewData = {
            max_rand_change: props.dailyRand,
            syr: props.startOverallYr,
            eyr: props.endOverallYr,
            tbl_sites: props.sharedData.tbl_sites,
            tbl_renew_schl: props.renewableTable,
            res: props.selectedResult,
            costInput: props.costInput
        };
        ApiExperiment.calculateRenewables(data).then(
            (res) => {
                props.onApiCall(res);
                setIsRunning(false);
                props.apiCallLoad(false);
                exportData = global.structuredClone(props.results);
                exportData.map((row, idx) => {
                    if (row["dateTime"] === props.selectedResult["dateTime"]) {
                        props.setImportDropdown(idx)
                        exportData[idx]["Renewables"] = [{ input: null, output: null }];
                        exportData[idx]["Renewables"][0]["input"] = exportinputRenewData;
                        exportData[idx]["Renewables"][0]["output"] = res;
                    }
                });
                props.setResults(exportData);
            },
            (err) => {
                setIsRunning(false);
                props.apiCallLoad(false);
            }
        );
    };

    const left = (
        <React.Fragment>
            <Space>
                {isRunning ? (
                    <Spin />
                ) : (
                    <Button type="primary" onClick={onRunAnalysis}>
                        Run Simulation
                    </Button>
                )}

                
                <ImportJson
                    results={props.results}
                    setResults={props.setResults}
                    setTransition={props.setTransition}
                    setFuncsOnMonthly={props.setFuncsOnMonthly}
                    setRenewableInputs={props.setRenewableInputs}
                    setRenewableOutputs = {props.setRenewableOutputs}
                    setImportDropdown = {props.setImportDropdown}
                />
                
                <HelpIntro />
            </Space>
        </React.Fragment>
    );

    return <Toolbar style={{ marginBottom: "15px" }} left={left} />;
};

ToolbarRenewable.propTypes = {};

export default ToolbarRenewable;
