import React from "react";
import Chart from "react-apexcharts";

import Loading from "../../../../components/Loading";
import { Card } from "primereact/card";

class DemandBarChartOverYears extends React.Component {
    render() {
        let display = <Loading />;
        if (this.props.dataSource) {
            var options = configureChart(this.props);
            display = (
                <Card style={{ height: "80vh" }}>
                    <div className="p-grid p-justify-between p-align-center">
                        <h4>
                            <b>Power - Demand and Generation</b>
                        </h4>
                        {this.props.dropdown}
                        <div />
                    </div>
                    <div style={{ height: "70vh", width: "100%" }}>
                        <Chart options={options.options} series={options.series} width="100%" height="100%" />
                    </div>
                </Card>
            );
        }

        return <React.Fragment>{display}</React.Fragment>;
    }
}

export default DemandBarChartOverYears;

const configureChart = (props) => {
    return {
        series: props.dataSource,
        options: {
            chart: {
                // height: 350,
                type: "line",
            },
            stroke: {
                width: [0, 0, 0, 4],
            },
            title: {
                // text: 'Traffic Sources'
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [3],
                formatter: function (val, opts) {
                    return val.toFixed(0);
                },
                style: {
                    fontSize: "10px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "light",
                    colors: undefined, // ["#2C3E50"]
                },
                background: {
                    enabled: true,
                },
            },
            labels: props.labels,
            colors: ["#1976D2", "#16A085", "#F44336", "#FFC107"], //  
            xaxis: {
                // type: 'datetime'
            },
            yaxis: [
                {
                    labels: {
                        formatter: function (value) {
                            if (value) {
                                return value.toFixed(2);
                            }
                        },
                    },
                    title: {
                        text: "Annual Average (MW)",
                    },
                },
            ],
        },
    };
};
