import * as Api from "axios";
import { FASTAPI_URL } from "../../settings/settings";
import * as ApiUtils from "../utils/ApiUtils";
import * as actionReport from "../../store/report/actionReport";

const prefix = "/profile";

export const getListOfProfiles = async (dispatch) => {
    let tag = "Profiles";
    let message = "";
    return Api.get(FASTAPI_URL + prefix + "/").then(
        (res) => {
            message += res.status === 200 ? "" : `Error in getting ${tag} data, status: ${res.status}`;
            message += res.data.length === 0 ? "Error: no data is available" : "";
            if (message === "") {
                if (dispatch) {
                    dispatch({
                        type: actionReport.SET_LIST_PROFILES,
                        payload: {
                            response: res.data,
                        },
                    });
                }
                return { hasError: false, data: res.data };
            } else {
                return { hasError: true, message: message, data: [] };
            }
        },
        (err) => {
            return { hasError: true, message: `Error occured in getting List of ${tag}: ${err}`, data: [] };
        }
    );
};

export const getListOfProfileEntries = async () => {
    let tag = "ProfileEntries";
    let message = "";
    return Api.get(FASTAPI_URL + prefix + "/data-entry").then(
        (res) => {
            message += res.status === 200 ? "" : `Error in getting ${tag} data, status: ${res.status}`;
            message += res.data.length === 0 ? "Error: no data is available" : "";
            if (message === "") {
                return { hasError: false, data: res.data };
            } else {
                return { hasError: true, message: message, data: [] };
            }
        },
        (err) => {
            return { hasError: true, message: `Error occured in getting List of ${tag}: ${err}`, data: [] };
        }
    );
};

export const deleteProfile = async (p) => {
    return Api.delete(FASTAPI_URL + prefix + `/?id=${p.ProfileID}`);
};

export const duplicateProfile = async (p) => {
    return true;
};

export const editProfile = async (name, desc, data, profile) => {
    let reqBody = data.map((r) => {
        return {
            Year: parseInt(r.Year),
            Value: parseFloat(r.Value),
            ProfileEntryID: parseInt(r.ProfileEntryID),
        };
    });

    return ApiUtils.PUT(FASTAPI_URL + prefix + `/?profile_id=${profile.ProfileID}&profile_name=${name}&profile_description=${desc}`, reqBody);
};

export const createProfile = async (name, desc, data) => {
    let reqBody = data.map((r) => {
        return {
            Year: parseInt(r.Year),
            Value: parseFloat(r.Value),
        };
    });
    return ApiUtils.POST(FASTAPI_URL + prefix + `/?profile_name=${name}&profile_description=${desc}`, reqBody);
};

export const lockUnLockProfile = async (p, locked) => {
    const url = `${FASTAPI_URL}${prefix}/lock-hide?profile_id=${parseInt(p.ProfileID)}&lock=${locked}&hide=${false}`;
    return ApiUtils.PUT(url, null);
    // let body = {
    //     ProfileID: parseInt(p.ProfileID),
    //     Hide: false,
    //     Lock: locked,
    // };

    // if (p) {
    //     return ApiUtils.PUT(FASTAPI_URL + prefix + "/lock-hide", body);
    // }
    // return { hasError: true, message: `Profile is Null!`, data: [] };
};
