import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import classNames from "classnames";
import { SignOutButton } from "./views/auth/SignOutButton";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import * as ST from "./settings/settings";
import ProjectDropdown from "./components/Filters/Dropdowns/ProjectDropdown";
import { getAllComms } from "./api/energy-model/miscServices";
import * as actionTypesReport from "./store/report/actionReport";

export const AppTopbar = (props) => {
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    const menu = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const roleDescription = {
        readers: `Readers can view the dashboard and key results.`,
        writer: `Higher level of access with a limited permissions to edit data.`,
        admin: `Full access and permissions are granted.`,
    };

    const dispatch = useDispatch();
    const dispatchProjChange = useDispatch();
    const history = useHistory();

    const showRoleDescription = () => {
        setDisplayBasic(true);
    };

    const onHide = () => {
        setDisplayBasic(false);
    };

    const onProjectChange = (selecUserAccess) => {
        dispatchProjChange({ type: actionTypesReport.SET_IS_PROJECT_ON_CHANGE, payload: { response: true } });
        history.push({
            pathname: "/home",
        });
        getAllComms(dispatch, selecUserAccess.ProjectID).then(
            (res) => {
                dispatchProjChange({ type: actionTypesReport.SET_IS_PROJECT_ON_CHANGE, payload: { response: false } });
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const items = [
        { label: "Profile", icon: "pi pi-user" },
        {
            label: "Project",
            template: (item, options) => {
                return (
                    <div>
                        <Divider align="left" style={{ marginRight: "15px" }}>
                            <b>Projects</b>
                        </Divider>
                        <ProjectDropdown onChange={onProjectChange} />
                    </div>
                );
            },
        },
        {
            label: "Sign Out",
            template: (item, options) => {
                return (
                    <div>
                        <Divider align="left">
                            <b>Sign Out</b>
                        </Divider>
                        <SignOutButton />
                    </div>
                );
            },
        },
    ];

    const username = selectedUserAccess.Username;
    const initials = (username.split(".")[0][0] + username.split(".")[1][0]).toUpperCase();
    const role = selectedUserAccess.UserRole;

    return (
        <div className="layout-topbar">
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <div className="layout-topbar-logo" style={{ marginLeft: "25px" }}>
                <Link to="/">
                    <img src={ST.LOGO_WORLEY} alt="logo" />
                </Link>
                <span style={{ marginLeft: "25px" }}>Project</span>
                <ProjectDropdown onChange={onProjectChange} />
            </div>

            <div className="p-link layout-topbar-menu-button layout-topbar-button">
                <button type="button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>
            </div>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <Menu model={items} popup ref={menu} />
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <img style={{ marginRight: "25px", height: "30px", verticalAlign: "middle" }} src={ST.LOGO_RIO} alt="logo" />
                            {selectedUserAccess.Username}
                            <div className="p-text-right" style={{ fontSize: "xx-small", color: "blue", cursor: "pointer" }} onClick={() => showRoleDescription()}>
                                {role}
                            </div>
                            <Dialog header="Role Description" visible={displayBasic} style={{ width: "50vw" }} onHide={onHide}>
                                <p>{roleDescription[role]}</p>
                            </Dialog>
                        </div>
                        <Avatar style={{ marginLeft: "15px", backgroundColor: "#2196F3", color: "#ffffff" }} label={initials} onClick={(event) => menu.current.toggle(event)} shape="circle" size="large" />
                    </span>
                </li>
            </ul>
        </div>
    );
};
