import * as Api from "axios";
import { FASTAPI_URL } from "../../settings/settings";
import { SET_TOKEN } from "../../store/user/actionUser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import * as ApiUtils from "../utils/ApiUtils";

export const getAccessToken = async (instance, accounts) => {
    const accessTokenRequest = {
        scopes: [`api://${process.env.REACT_APP_APP_ID_NET_ZERO_DEV_WORLEY}/rtioem-dev`],
        account: accounts[0],
    };

    return new Promise((resolve, reject) => {
        let accessToken = null;

        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                accessToken = accessTokenResponse.accessToken;
                resolve(accessToken);
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance
                        .acquireTokenPopup(accessTokenRequest)
                        .then(function (accessTokenResponse) {
                            // Acquire token interactive success
                            accessToken = accessTokenResponse.accessToken;
                            resolve(accessToken);
                        })
                        .catch(function (err) {
                            // Acquire token interactive failure
                            reject(err);
                        });
                }
            });
    });
};

export const setAccessToken = async (instance, accounts, dispatch) => {
    return new Promise((resolve, reject) => {
        getAccessToken(instance, accounts).then(
            (token) => {
                attachAccessTokenToHeader(token);
                if (dispatch) {
                    dispatch({
                        type: SET_TOKEN,
                        payload: { accessToken: token },
                    });
                }
                resolve("ok");
            },
            (err) => {
                reject(err);
            }
        );
    });
};

export const attachAccessTokenToHeader = (AUTH_TOKEN) => {
    Api.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
};

export const getUserByName = async (username) => {
    return ApiUtils.GET(FASTAPI_URL + `/user/all/${username.toLowerCase()}`, "GET", "Get user by name for all projects");
};

export const getUserByNameByProject = async (username, projectID) => {
    return ApiUtils.GET(FASTAPI_URL + `/user/project/${username.toLowerCase()}?project_id=${projectID}`, "GET", "Get user by name for a specific project");
};
