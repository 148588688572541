import React, { useEffect, useMemo, useState } from "react";
import { range } from "../../../../../../utils/comm";
import DemandChart from "./yearlyDemandChart";

const convertToDataSource = (fltResults, years) => {
    let dataSource = [];
    if (fltResults) {
        fltResults.forEach((res) => {
            let dataCur = [];

            years.forEach((yr) => {
                const idx = res.years.indexOf(yr);

                let E_tot = idx < 0 ? null : 0.0;
                if (idx >= 0) {
                    res.result.forEach((r) => {
                        E_tot += r.energy_demand[idx];
                    });
                }
                dataCur.push(E_tot);
            });

            dataSource.push({
                name: res.dateTime,
                // type: 'area',
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const EnergyDemandCharts = ({ results, fltResults }) => {
    const [dataSource, setDataSource] = useState(null);
    const [years, setYears] = useState(null);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        setIsloading(true);
        if (fltResults !== null) {
            let yrMin = 10000000;
            let yrMax = 0;
            fltResults.forEach((res) => {
                yrMin = yrMin < res.years[0] ? yrMin : res.years[0];
                yrMax = yrMax > res.years[res.years.length - 1] ? yrMax : res.years[res.years.length - 1];
            });
            const yrs = range(yrMin, yrMax + 1, 1);
            setYears(yrs);
            setDataSource(convertToDataSource(fltResults, yrs));
            setIsloading(false);
        }
    }, [results, fltResults]);

    if (fltResults === null) {
        return <div>No Results!</div>;
    }

    return (
        <React.Fragment>
            <div className="p-grid p-align-center">
                {/* <h4>
                    <b>Energy Demand</b>
                </h4> */}
                <div />
            </div>
            <DemandChart filter={null} dataSource={dataSource} xaxis={years} ylabel="Energy Demand (GWh-e)" isLoading={isLoading} />
        </React.Fragment>
    );
};

EnergyDemandCharts.propTypes = {};

export default EnergyDemandCharts;
