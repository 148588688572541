import React, { useState, useEffect, useRef } from "react";
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as ApiMisc from "../../../../api/energy-model/miscServices";
import * as tableUtils from "../../../../utils/tables";
import { showCompletionMessage } from "../common/helpers";
import { Toast } from "primereact/toast";
import MainTabView from "../common/main";

const RenewableEdit = (props) => {
    const toast = useRef(null);
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasErrDB, setHasErrDB] = useState(false);
    const [substationMap, setSubstationMap] = useState(null);
    const [renewableTypeMap, setRenewableTypeMap] = useState(null);

    useEffect(() => {
        updateView();
    }, []);

    const showWarnError = (level, header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const updateView = () => {
        setIsLoading(true);
        ApiEnergy.getListOfRenewables().then((r) => {
            if (!r.hasError) {
                setDataSource(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiEnergy.getRenewableTypesMapName2Id().then((r) => {
            if (!r.hasError) {
                setRenewableTypeMap(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiMisc.getSubstationMapName2Id().then((r) => {
            if (!r.hasError) {
                setSubstationMap(r.data);
            } else {
                setHasError(true);
            }
        });

        if (dataSource && substationMap && renewableTypeMap) {
            setIsLoading(false);
        }
    };

    if (hasError) {
        return <h3>An error occured when loading the data. Please refresh the page.</h3>;
    }

    const onSubmit = (lstUpdate, lstNewItems, setLstUpdate, setLstNewItems, setMessage) => {
        setHasErrDB(false);
        if (lstNewItems.length > 0) {
            ApiEnergy.createRenewables(lstNewItems, substationMap, renewableTypeMap).then((r) => {
                if (!r.hasError) {
                    setLstNewItems([]);
                    updateView();
                } else {
                    showWarnError("error", "Error occured during creating new items", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }

        if (lstUpdate.length > 0) {
            ApiEnergy.updateRenewables(lstUpdate, substationMap, renewableTypeMap).then((r) => {
                if (!r.hasError) {
                    setLstUpdate([]);
                    updateView();
                } else {
                    showWarnError("error", "Error occured during update", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }
    };

    let IdCol = "RenewableID";

    let columns = [
        { name: "Tag", label: "Tag", sortable: false, filter: true, type: "dropdown", newRow: { type: "text", isUnique: true } },
        { name: "Substation", label: "Substation", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "RenewableType", label: "Renewable Type", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "ratedMW", label: "Rated (MW)", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 2 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "CapacityFactor", label: "Capacity Factor", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 3 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "Available", label: "Available", sortable: false, filter: true, type: "normal", editable: { type: "text" }, newRow: { type: "text" } },
    ];

    let mainView = null;
    if (dataSource) {
        columns = tableUtils.extractUnqVals(dataSource, columns);
        mainView = <MainTabView dataSource={dataSource} setDataSource={setDataSource} title="Renewables" IdCol={IdCol} columns={columns} isLoading={isLoading} onSubmit={onSubmit} />;
    }

    return (
        <>
            {mainView}
            <Toast ref={toast} />
        </>
    );
};

export default RenewableEdit;
