import { FASTAPI_URL } from "../../settings/settings";
import { getAllScenariosByProjectID } from "./scenarioServices";
import { getListOfProfiles, getListOfProfileEntries } from "./profileServices";
import { getListOfSitesByProjectID, getListOfSiteDataPrimaryByProjectID } from "./siteServices";
import { getAllTargetEmissionsPrimaryByProjectID, getAllBaselineEmissionsByProjectID } from "./emissionServices";
import { getListOfEnergies } from "./energyServices";
import * as actionReport from "../../store/report/actionReport";
import * as ApiUtils from "../utils/ApiUtils";

const prefix = "/misc";

export const getListOfFunctionsByProjectID = async (projectID) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/functions/project?project_id=${projectID}`, "GET", "Functions");
};

export const getListOfTechnologiesByProjectID = async (projectID) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/technologies/project?project_id=${projectID}`, "GET", "Technologies");
};

export const createTechnologies = async (items, energyMap, funcMap) => {
    const reqBody = items.map((r) => {
        return {
            Name: r.Name,
            Description: r.Description,
            Function: parseInt(funcMap[r.Function]),
            Energy: parseInt(energyMap[r.EnergyTag]),
            SpecificEnergyRate: parseFloat(r.SpecificEnergyRate),
            Mtpa: parseFloat(r.Mtpa),
        };
    });

    return ApiUtils.POST(FASTAPI_URL + prefix + "/technologies", reqBody);
};

export const updateTechnologies = async (items, energyMap, funcMap) => {
    let reqBody = items.map((r) => {
        return {
            TechnologyID: parseInt(r.TechnologyID),
            Name: r.Name,
            Description: r.Description,
            Function: parseInt(funcMap[r.Function]),
            Energy: parseInt(energyMap[r.EnergyTag]),
            SpecificEnergyRate: parseFloat(r.SpecificEnergyRate),
            Mtpa: parseFloat(r.Mtpa),
        };
    });

    return ApiUtils.PUT(FASTAPI_URL + prefix + "/technologies", reqBody);
};

export const getAllComms = async (dispatch, projectID) => {
    let resAll = [
        await getAllScenariosByProjectID(null, projectID),
        await getListOfFunctionsByProjectID(projectID),
        await getListOfSitesByProjectID(projectID),
        await getListOfProfiles(null),
        await getListOfProfileEntries(),
        await getListOfTechnologiesByProjectID(projectID),
        await getListOfSiteDataPrimaryByProjectID(projectID),
        await getAllTargetEmissionsPrimaryByProjectID(projectID),
        await getAllBaselineEmissionsByProjectID(projectID),
        await getListOfEnergies(),
    ];

    let message = "";
    resAll = resAll.map((r) => {
        message += r.hasError ? r.message : "";
        return { ...r, data: r.hasError ? [] : r.data };
    });

    dispatch({
        type: actionReport.SET_LIST_COMMS,
        payload: {
            response: {
                scenarios: resAll[0].data,
                functions: resAll[1].data,
                mines: resAll[2].data,
                profiles: resAll[3].data,
                profileEntries: resAll[4].data,
                technologies: resAll[5].data,
                mineDataRecords: resAll[6].data,
                targetEmitVers: resAll[7].data,
                baselineEmitVers: resAll[8].data,
                lstOfEnergies: resAll[9].data,
            },
        },
    });

    if (message !== "") {
        return { hasError: true, message: message, data: [] };
    } else {
        return { hasError: false, data: [] };
    }
};

// MAPPINGS
export const getTechMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/misc/id-to-name/technologies/name", "GET", "gettingTechMapName");
};

export const getTechMapId2Name = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/misc/id-to-name/technologies/id", "GET", "gettingTechMapId");
};

export const getFuncMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/misc/id-to-name/functions/name", "GET", "gettingFuncMapName");
};

export const getSubstationMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/misc/id-to-name/functions/name", "GET", "gettingFuncMapId");
};
