import React, { useState, useRef, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import * as API from "../../../../../api/energy-model/scenarioServices";
import * as ST from "../../../../../settings/settings";
import GoBackButton from "./components/GoBackButton";

import EditSingle from "./editSingle";
import EditBatch from "./editBatch";
import SubmitPage from "./submitPage";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../../api/auth/userServices";
import RefreshSignIn from "../../../../../components/StartUp/RefreshSignIn";
import { Badge } from "primereact/badge";

const MainTabView = (props) => {
    const history = useHistory();

    const [activeIndex, setActiveIndex] = useState(0);

    // GETTING STORE STATES

    const siteMap = useSelector((state) => state.report.mineMap);
    const techMap = useSelector((state) => state.report.techMap);
    const functionMap = useSelector((state) => state.report.functionMap);
    const profileMap = useSelector((state) => state.report.profileMap);

    // DEFINING LOCAL STATES
    // 1. ALL PATHWAYDATA
    const [scenarioMappings, setScenarioMappings] = useState(null);
    // 2. QUEUED CHANGES
    const [lstOfUpdates, setListOfUpdates] = useState([]);
    const [lstOfDeletes, setListOfDeletes] = useState([]);

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);

    // **************************************************************************************
    // SECTION 1:
    //  LOAD PATHWAY MAPPING DATA FOR THE SELECTED SCENARIO
    // **************************************************************************************
    useEffect(() => {
        if (history.location.state) {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    API.getPathwayMappingsViewByScenId(scenario.PathwayScenarioID, siteMap, techMap, functionMap, profileMap).then((r) => {
                        if (!r.hasError) {
                            setScenarioMappings(r.data);
                        } else {
                            throw new Error(r.message);
                        }
                    });
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // **************************************************************************************
    // NOTE:
    // IF STATE IS EMPTY IN HISTORY THEN IT MEANS USER HAS MANAULLY CAME TO THIS END POINT,
    // HENCE THE APP SHOULD REDIRECT
    // **************************************************************************************
    if (!history.location.state) {
        history.push(ST.ROUTES.SCENARIO_MANAGE.PATH);
        return <div className="p-grid">Going back to manage.</div>;
    }

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    const scenario = history.location.state.scenario;
    const isScenarioLocked = scenario.Lock; // === 1;

    let editSingle = null;
    let editBatch = null;
    let submit = null;
    if (scenarioMappings) {
        editSingle = <EditSingle scenarioMappings={scenarioMappings} setScenarioMappings={setScenarioMappings} scenario={scenario} lstOfUpdates={lstOfUpdates} setListOfUpdates={setListOfUpdates} lstOfDeletes={lstOfDeletes} setListOfDeletes={setListOfDeletes} isScenarioLocked={isScenarioLocked} />;

        editBatch = <EditBatch scenarioMappings={scenarioMappings} lstOfUpdates={lstOfUpdates} setListOfUpdates={setListOfUpdates} lstOfDeletes={lstOfDeletes} setListOfDeletes={setListOfDeletes} isScenarioLocked={isScenarioLocked} />;

        if (!isScenarioLocked) {
            submit = <SubmitPage lstOfUpdates={lstOfUpdates} setListOfUpdates={setListOfUpdates} lstOfDeletes={lstOfDeletes} setListOfDeletes={setListOfDeletes} sScenarioLocked={isScenarioLocked} />;
        }
    }

    return (
        <div>
            <div className="p-grid">
                <GoBackButton hasConfirmDiag={lstOfUpdates.length > 0 || lstOfDeletes.length > 0} />
                <Badge value={isScenarioLocked ? "View Mode" : "Edit Mode"} severity="success" style={{ margin: "10px" }} />
            </div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Single Mode">{editSingle}</TabPanel>
                <TabPanel header="Batch Mode">{editBatch}</TabPanel>
                {isScenarioLocked ? null : <TabPanel header="Review & Submit"> {submit} </TabPanel>}
            </TabView>
        </div>
    );
};

export default MainTabView;
