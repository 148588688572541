import React from "react";
import Chart from "react-apexcharts";

import Loading from "../../../../components/Loading";
import { PointSpreadLoading  } from 'react-loadingg';
import { Card } from "primereact/card";

import * as CONS from '../../../../store/constant';

class TechCountChartOverYears extends React.Component {
    render() {

        let content = <Loading comp={<PointSpreadLoading />}/> 
        if (this.props.dataSource && !this.props.isLoading) {
            var options = configureChart(this.props);
            content =  <Chart options={options.options} series={options.series} width="100%" height="100%" />
        }

        return (
        <React.Fragment>
            <Card style={{ height: "80vh" }}>
                    <div className="p-grid p-align-center">
                        <h4>
                            <b>Technology Count</b>
                        </h4>
                        <span style={{marginLeft:'15px'}}>{this.props.filter}</span>
                        <div />
                    </div>
                    <div style={{ height: "70vh", width: "100%" }}>
                        {content}
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}

export default TechCountChartOverYears;

const configureChart = (props) => {
    return {
        series: props.dataSource,
        options: {
            chart: {
                // height: 350,
                type: "line",
            },
            stroke: {
                width: [0, 0],
            },
            title: {
                // text: 'Traffic Sources'
            },
            dataLabels: {
                enabled: true,
                // enabledOnSeries: [0,1],
                // formatter: function (val, opts) {
                //     return val.toFixed(0);
                // },
                style: {
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "light",
                    colors: undefined, // ["#2C3E50"]
                },
                background: {
                    enabled: true,
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true
            }, 
            labels: props.labels,
            colors: CONS.COLOR_MAP_DISTINCT_2, // ["#F44336", "#27AE60"],//, , "#FFC107"], //  
            xaxis: {
                // type: 'datetime'
            },
            yaxis: [
                {
                    // labels: {
                    //     formatter: function (value) {
                    //         if (value) {
                    //             return value.toFixed(2);
                    //         }
                    //     },
                    // },
                    title: {
                        text: props.ylabel,
                    },
                },
            ],
        },
    };
};
