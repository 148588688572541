import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import * as actionTypeReport from "../../../store/report/actionReport";

const MineDataVerDropdown = (props) => {
    const lstOfAllMineDataRecords = useSelector((state) => state.report.lstOfAllMineDataRecords);
    const selectedMineDataRecord = useSelector((state) => state.report.selectedMineDataRecord);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let selecTar = lstOfAllMineDataRecords.filter((s) => s.VersionName === e.target.value)[0];
        dispatch({ type: actionTypeReport.SELECT_MINE_DATA_RECORD, payload: { response: selecTar } });

        props.onChange(selecTar); // Extra implementation of onChange event in parent component.
    };

    return <Dropdown style={{ marginLeft: "0px" }} optionLabel="VersionName" optionValue="VersionName" value={selectedMineDataRecord.VersionName} options={lstOfAllMineDataRecords} onChange={handleChange} placeholder="Select a TMM/SOP" />;
};

export default MineDataVerDropdown;
