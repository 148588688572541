export const actionTypes = {
    RESET: "RESET",
    ERROR: "ERROR",
    locationChanged: "@@router/ON_LOCATION_CHANGED",
};

export const reset = () => ({
    type: actionTypes.RESET,
});

export const error = (msg) => ({
    type: actionTypes.ERROR,
    msg,
});
