import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import * as actionTypeReport from "../../../store/report/actionReport";

const TargetVerDropdown = (props) => {
    const lstOfTargetEmitVersions = useSelector((state) => state.report.lstOfTargetEmitVersions);
    const selectedTargetEmitVersion = useSelector((state) => state.report.selectedTargetEmitVersion);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let selecTar = lstOfTargetEmitVersions.filter((s) => s.VersionName === e.target.value)[0];
        dispatch({ type: actionTypeReport.SELEC_TARGET_EMIT_VER, payload: { response: selecTar } });

        props.onChange(selecTar); // Extra implementation of onChange event in parent component.
    };

    return <Dropdown style={{ marginLeft: "0px" }} optionLabel="VersionName" optionValue="VersionName" value={selectedTargetEmitVersion.VersionName} options={lstOfTargetEmitVersions} onChange={handleChange} placeholder="Select a Target Emission" />;
};

export default TargetVerDropdown;
