import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { range } from "../../../../../../utils/comm";
import { Dropdown } from "primereact/dropdown";
import LineChart from "../../../../../../components/Charts/LineChart";

const convertToDataSource = (res, years, lstOfFunctions) => {
    let dataSource = [];
    if (res) {
        lstOfFunctions.forEach((f) => {
            let dataCur = [];
            years.forEach((yr) => {
                const idx = res.years.indexOf(yr);
                const resFlt = res.result.filter((r) => r.FunctionID === f.FunctionID);

                let E_tot = idx < 0 ? null : 0.0;
                if (idx >= 0) {
                    resFlt.forEach((r) => {
                        E_tot += r["energy_demand"][idx];
                    });
                }
                dataCur.push(E_tot);
            });

            dataSource.push({
                name: f.Function,
                // type: 'area',
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const YearlyByFuncResults = ({ results, fltResults }) => {
    const [dataSource, setDataSource] = useState(null);
    const [years, setYears] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [selectedIdx, setSelectedIdx] = useState(0);

    const lstOfFunctions = useSelector((s) => s.report.lstOfFunctions);

    const options = React.useMemo(() => {
        return fltResults;
    }, [fltResults]);

    const updateData = (resFlt) => {
        setIsloading(true);
        if (fltResults !== null) {
            let yrMin = 10000000;
            let yrMax = 0;
            fltResults.forEach((res) => {
                yrMin = yrMin < res.years[0] ? yrMin : res.years[0];
                yrMax = yrMax > res.years[res.years.length - 1] ? yrMax : res.years[res.years.length - 1];
            });
            const yrs = range(yrMin, yrMax + 1, 1);

            setYears(yrs);
            setDataSource(convertToDataSource(resFlt, yrs, lstOfFunctions));
            setIsloading(false);
        }
    };

    useEffect(() => {
        const currRes = fltResults[selectedIdx];
        setSelectedResult(currRes);
        updateData(currRes);
    }, [results, fltResults]);

    const handleResultChange = (e) => {
        setSelectedResult(e.target.value);
        const index = options.indexOf(e.target.value);
        setSelectedIdx(index);
        updateData(e.target.value);
    };

    if (fltResults === null) {
        return <div>No Results!</div>;
    }

    return (
        <React.Fragment>
            <Dropdown options={options} value={selectedResult} optionLabel="dateTime" onChange={handleResultChange} />

            <LineChart dataSource={dataSource} xValues={years} height="60vh" yLabel="Energy Demand (GWh-e)" type="area" stacked scale={1000} palette="palette7" />
        </React.Fragment>
    );
};

YearlyByFuncResults.propTypes = {};

export default YearlyByFuncResults;
