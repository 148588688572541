import { FASTAPI_URL } from "../../settings/settings";
import * as ApiUtils from "../utils/ApiUtils";
import techsDep from "./data/techs_all_new.json";
import transIron from "./data/transition_solutions.json";
import transDSL from "./data/transition_solutions_DSL_modified.json";
import monthlyProfiles from "./data/monthly_profiles.json";
import funcsOnMonthly from "./data/functions_monthly_profiles.json";
import monthlyProfilesBySiteID from "./data/monthly_profile_by_site.json";

const prefix = "/experiment";

export const getSingleItemProfileUpdate = async (item) => {
    const reqBody = {
        trans: item,
    };
    return ApiUtils.POST(FASTAPI_URL + prefix + `/generate-weights/single-replace`, reqBody);
};

export const getAllItemsProfileUpdate = async (lstTrans, startYr, endYr) => {
    const reqBody = {
        lst_trans: lstTrans,
    };

    return ApiUtils.POST(FASTAPI_URL + prefix + `/generate-weights/all-transition?start_year=${startYr}&end_year=${endYr}`, reqBody);
};

export const predictAIRecommFactors = (windShare, gridShare) => {
    return ApiUtils.GET(FASTAPI_URL + prefix + `/wind-grid-model?wind_share=${windShare}&grid_share=${gridShare}`, "GET", "Predict AI Recommended Factors");
};

export const calculateEnergyDemand = async (data) => {
    const reqBody = {
        tbl_energies: data.tbl_energies,
        tbl_techs: data.tbl_techs,
        tbl_funcs: data.tbl_funcs,
        tbl_sites: data.tbl_sites,
        tbl_site_data: data.tbl_site_data,
        lst_trans: data.lst_trans,
        monthly: data.monthly,
    };

    return ApiUtils.POST(FASTAPI_URL + prefix + `/energy-calculations?start_overall=${data.syr}&end_overall=${data.eyr}&proj_id=${data.projID}&agg_cols=${data.aggCols}&query_cols=${data.queryCols}&get_profiles=${data.get_profiles}`, reqBody);
};

export const getTechDependencies = async (lstDBTechs) => {
    // 1. loop over techsDep
    // 2. Find the matching TechID from lstDBTechs
    const updatedTech = techsDep.map((r) => {
        let eqvTech = lstDBTechs.filter((x) => r.TechnologyID === x.TechnologyID);
        if (eqvTech.length > 0) {
            r.SpecificEnergyRate = eqvTech[0].SpecificEnergyRate;
            r.Mtpa = eqvTech[0].Mtpa;
        }

        return r;
    });
    console.log(lstDBTechs);
    return { hasError: false, data: updatedTech };
};

export const getBaseTransSolByProjectID = async (projID) => {
    if (projID === 1) {
        return { hasError: false, data: transIron };
    }

    if (projID === 2) {
        return { hasError: false, data: transDSL };
    }

    return { hasError: true };
};

export const getMonthlyProfiles = async (projID) => {
    return { hasError: false, data: monthlyProfiles.filter((r) => r.ProjectID === null || r.ProjectID === projID) };
};

export const getMonthlyProfilesBySiteID = async () => {
    return { hasError: false, data: monthlyProfilesBySiteID };
};

export const getFuncOnMonthly = async (projID) => {
    return { hasError: false, data: funcsOnMonthly.filter((r) => r.ProjectID === projID) };
};

export const calculateRenewables = async (data) => {
    const reqBody = {
        tbl_sites: data.tbl_sites,
        tbl_renew_schl: data.tbl_renew_schl,
        res: data.res,
        tbl_energies: data.tbl_energies,
        bio_diesel_w: data.bio_diesel_w,
        WInstUnitCost: data.WInstUnitCost,
        SInstUnitCost: data.SInstUnitCost,
        StInstUnitCost: data.StInstUnitCost,
        StInstUnitCostRate: data.StInstUnitCostRate,
        WOMUnitCost: data.WOMUnitCost,
        SOMUnitCost: data.SOMUnitCost,
        StOMUnitCost: data.StOMUnitCost,
        grid_cost: data.grid_cost,
        diesel_cost: data.diesel_cost,
        bio_diesel_cost: data.bio_diesel_cost,
        run_life: data.run_life,
    };

    return ApiUtils.POST(FASTAPI_URL + prefix + `/renewable-analysis-all-years-cost-emission-included?start_year=${data.syr}&end_year=${data.eyr}&max_rand_change=${data.max_rand_change}`, reqBody);
};

export const aggregateNestedArray = async (data, queryCol, aggCols) => {
    const reqBody = {
        data: data,
        queryCol: queryCol,
        aggCols: aggCols,
    };

    return ApiUtils.POST(FASTAPI_URL + prefix + "/aggregate-nested-object", reqBody);
};
