import React from "react";
import RowTechItem from "./RowTechItem";

function TechTable({ groupedTechs, allTechs, setTechs }) {
    return (
        <table>
            <tr>
                <th>Name</th>
                <th>Function</th>
                <th>Possible Replacements</th>
                <th>Specific Energy Rate</th>
                <th></th>
                {/* <th>Mtpa</th> */}
            </tr>
            <tbody>
                {groupedTechs.map((r, idx) => {
                    return <RowTechItem tech={r} allTechs={allTechs} setTechs={setTechs} idx={idx} />;
                })}
            </tbody>
        </table>
    );
}

export default TechTable;
