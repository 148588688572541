// let REDIRECT_URI = "https://rtioem-dev.azurewebsites.net";
// switch (process.env.REACT_APP_DEPLOYMENT_SLOT) {
//     case "PREDEV":
//         REDIRECT_URI = "https://rtioem-dev.azurewebsites.net";
//         break;

//     case "DEV":
//         REDIRECT_URI = "https://rtioem-dev.azurewebsites.net";
//         break;

//     case "STAGE":
//         REDIRECT_URI = "https://rtioem-stage.azurewebsites.net";
//         break;

//     case "TEST":
//         REDIRECT_URI = "https://rtioem-test.azurewebsites.net";
//         break;

//     case "PROD":
//         REDIRECT_URI = "https://rtioem.azurewebsites.net";
//         break;

//     default:
//         REDIRECT_URI = "https://rtioem-dev.azurewebsites.net";
//         break;
// }

// // const AzureUri = "https://rtioem-dev.azurewebsites.net"; // "https://rtio-dashboard-test.azurewebsites.net";
// const redirectUri = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:3000" : REDIRECT_URI;
// // const redirectUri = REDIRECT_URI;

// console.log(REDIRECT_URI);

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphGetUserEndpoint: "https://graph.microsoft.com/v1.0/users",
};

export const msalConfig = {
    auth: {
        // clientId: process.env.REACT_APP_APP_ID_GRAPH_TEST, //,
        // authority: process.env.REACT_APP_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        clientId: process.env.REACT_APP_APP_ID_NET_ZERO_DEV_WORLEY,
        authority: process.env.REACT_APP_TENANT_ID_NET_ZERO_DEV_WORLEY, //,  // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // redirectUri: REDIRECT_URI,
        redirectUri: window.location.origin,
        accessTokenAcceptedVersion: 2,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"], //["User.Read"]//, `api://${process.env.REACT_APP_APP_ID_NET_ZERO_DEV_WORLEY}/rtioem-dev:rtioem-dev`]
};
