import React from "react";
import { Input } from "antd";

const rowCellStyle = { verticalAlign: "baseline", padding: "10px" };

function InputESpec({ setTechs, selectedTech, allTechs }) {
    const [eSpec, setESpec] = React.useState(selectedTech.SpecificEnergyRate);
    const [mtpa, setMtpa] = React.useState(selectedTech.Mtpa);

    const handleTechUpdate = () => {
        // const tmp = global.structuredClone(allTechs);
        const idx = allTechs.findIndex((x) => x.TechnologyID === selectedTech.TechnologyID);
        allTechs[idx].SpecificEnergyRate = parseFloat(eSpec);
        allTechs[idx].Mtpa = parseFloat(mtpa);
        setTechs(allTechs);
    };

    const unit = selectedTech.Energy === 2 ? "kWh/t" : "L/t";

    return (
        <>
            <td style={rowCellStyle}>
                <Input value={eSpec} onChange={(e) => setESpec(e.target.value)} onBlur={handleTechUpdate} />
            </td>
            <td>{unit}</td>
            {/* <td style={rowCellStyle}>
                <Input value={mtpa} onChange={(e) => setMtpa(e.target.value)} onBlur={handleTechUpdate} />
            </td> */}
        </>
    );
}

export default InputESpec;
