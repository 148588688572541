import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "primereact/card";
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as ApiScenario from "../../../../api/energy-model/scenarioServices";
import * as commAPI from "../../../../api/energy-model/miscServices";
import { Button } from "primereact/button";
import * as ST from "../../../../settings/settings";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "./scenario.css";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";

const ManageScenarios = () => {
    let lstOfScenarios = useSelector((state) => state.report.lstOfAllScenarios);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);

    const history = useHistory();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [showCalcDialog, setShowCalcDialog] = useState(false);
    const [contentCalcDialog, setContentCalcDialog] = useState("");
    const toast = useRef(null);
    const [newScenarioName, setNewScenarioName] = useState("");
    const [newScenarioDesc, setNewScenarioDesc] = useState("");
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [isLockLoading, setIsLockLoading] = useState(false);
    const [isHideLoading, setIsHideLoading] = useState(false);
    const [isCalcLoading, setIsCalcLoading] = useState(false);

    // const {accounts} = useMsal();
    const role = selectedUserAccess.UserRole;
    const accessLvl1 = role.toLowerCase() === ST.ROLES.VIP || role.toLowerCase() === ST.ROLES.ADMIN;
    const accessLvl2 = role.toLowerCase() === ST.ROLES.VIP || role.toLowerCase() === ST.ROLES.ADMIN || role.toLowerCase() === ST.ROLES.CONTRIB;

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);

    if (!hasToken) {
        return <RefreshSignIn />;
    }
    const showWarn = (header, message) => {
        toast.current.show({ severity: "warn", summary: header, detail: message, life: 6000 });
    };

    const onHideDialog = () => {
        setNewScenarioName("");
        setNewScenarioDesc("");
        setShowDialog(false);
    };

    const onHideCalcDialog = () => {
        setShowCalcDialog(false);
    };

    const createNewScenario = () => {
        return (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onHideDialog} />
                <Button label="Create" icon="pi pi-check" className="p-button-text" onClick={() => createScenario()} />
            </React.Fragment>
        );
    };

    const runCalcLogDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={onHideCalcDialog} />
                <Button
                    label="Copy"
                    icon="pi pi-copy"
                    className="p-button-text"
                    onClick={() => {
                        navigator.clipboard.writeText(contentCalcDialog);
                    }}
                />
            </React.Fragment>
        );
    };

    const createScenario = () => {
        if (newScenarioName === "") {
            showWarn("Scenario Name", "cannot be empty.");
        } else {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    ApiScenario.createScenario(selectedScenario, newScenarioName, newScenarioDesc).then((res) => {
                        if (!res.hasError) {
                            commAPI.getAllComms(dispatch, selectedUserAccess.ProjectID);
                            setShowDialog(false);
                            setNewScenarioName("");
                            setNewScenarioDesc("");
                            setSelectedScenario(null);
                        } else {
                            throw new Error(res.message);
                        }
                    });
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        }
    };

    const onCreate = (s) => {
        setSelectedScenario(s);
        setShowDialog(true);
    };

    const onEdit = (s) => {
        history.push({
            pathname: ST.ROUTES.SCENARIO_EDIT.PATH,
            state: {
                scenario: { ...s },
            },
        });
    };

    // const onDelete = (s) => {
    //     const accept = () => {
    //         ApiScenario.deleteScenario(s).then(res => {
    //             if (!res.hasError) {
    //                 toast.current.show({ severity: 'info', summary: 'Confirmed', detail: `Scenario ${s.PathwayScenarioName} successfully deleted.`, life: 3000 });
    //                 commAPI.getAllComms(dispatch);
    //             } else {
    //               /* Not used*/  alert(res.message)
    //             }
    //         })
    //     }
    //     const reject = () => {
    //         // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    //     }
    //     confirmDialog({
    //         message: `Scenario ${s.PathwayScenarioName} will be deleted. Do you wish to continue?`,
    //         header: 'Cancel Confirmation',
    //         icon: 'pi pi-info-circle',
    //         acceptClassName: 'p-button-danger',
    //         accept,
    //         reject
    //     });
    // }

    const onLockUnlock = (s) => {
        let locked = s.Lock.toString() === "1" ? true : false;
        let diagMsg = locked ? `Are you sure you want to unlock scenario ${s.PathwayScenarioName}?` : `Are you sure you want to lock scenario ${s.PathwayScenarioName}?`;
        let confirmMsg = locked ? `Scenario ${s.PathwayScenarioName} is set to UNLOCK` : `Scenario ${s.PathwayScenarioName} is set to LOCK`;
        const accept = () => {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    setIsLockLoading(true);
                    ApiScenario.updateLockHideScenario(s, true, false).then((res) => {
                        if (!res.hasError) {
                            ApiScenario.getAllScenariosByProjectID(dispatch, selectedUserAccess.ProjectID).then((res2) => {
                                if (!res2.hasError) {
                                    toast.current.show({ severity: "info", summary: "Confirmed", detail: confirmMsg, life: 3000 });
                                } else {
                                    throw new Error(res2.message);
                                }
                                setIsLockLoading(false);
                            });
                        } else {
                            setIsLockLoading(false);
                            throw new Error(res.message);
                        }
                    });
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        };
        const reject = () => {
            // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        };
        confirmDialog({
            message: diagMsg,
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };

    const onHideUnHideScenario = (s) => {
        let isHidden = s.Hide.toString() === "1" ? true : false;
        let diagMsg = isHidden ? `Are you sure you want to unhide scenario ${s.PathwayScenarioName}?` : `Are you sure you want to hide scenario ${s.PathwayScenarioName}?`;
        let confirmMsg = isHidden ? `Scenario ${s.PathwayScenarioName} is set to Unhidden` : `Scenario ${s.PathwayScenarioName} is set to Hidden`;
        const accept = () => {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    setIsHideLoading(true);
                    ApiScenario.updateLockHideScenario(s, false, true).then((res) => {
                        if (!res.hasError) {
                            ApiScenario.getAllScenariosByProjectID(dispatch, selectedUserAccess.ProjectID).then((res2) => {
                                if (!res2.hasError) {
                                    toast.current.show({ severity: "info", summary: "Confirmed", detail: confirmMsg, life: 3000 });
                                } else {
                                    throw new Error(res2.message);
                                }
                                setIsHideLoading(false);
                            });
                        } else {
                            setIsHideLoading(false);
                            throw new Error(res.message);
                        }
                    });
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        };
        const reject = () => {
            // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        };
        confirmDialog({
            message: diagMsg,
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };

    const onRunRenewGenCalc = (s) => {
        setAccessToken(instance, accounts).then(
            (resolve) => {
                setIsCalcLoading(true);
                setSelectedScenario(s);
                ApiEnergy.getAllocateRenewableStorage(s).then((res) => {
                    if (!res.hasError) {
                        setIsCalcLoading(false);
                        setShowCalcDialog(true);
                        setContentCalcDialog(res.data);
                    } else {
                        setIsCalcLoading(false);
                        setShowCalcDialog(false);
                        throw new Error(res.message);
                    }
                });
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );
    };

    let view = lstOfScenarios.map((s, idx) => {
        const locked = s.Lock;
        const isHidden = s.Hide;
        const styleHeader = {
            marginLeft: "2px",
        };
        const hiddenIcon = isHidden ? "pi pi-eye-slash" : "pi pi-eye";
        const btnClass = "p-button-rounded  p-button-raised p-button-secondary";

        const btnLoading = <Button style={styleHeader} className="p-button-rounded p-button-text" loading />;

        const editBtn = accessLvl2 ? (
            <Button style={styleHeader} className={btnClass} tooltip="Edit Scenario" disabled={!accessLvl2} onClick={() => onEdit(s)}>
                {locked ? "View" : "Edit"}
            </Button>
        ) : null;

        const hideBtn = isHideLoading ? btnLoading : <Button style={styleHeader} icon={hiddenIcon} className={btnClass} tooltip="Hide/Unhide this scenario" disabled={!accessLvl1 || locked} onClick={() => onHideUnHideScenario(s)} />;

        const icon = locked ? <i className="pi pi-lock" /> : <i className="pi pi-lock-open" />;
        const lockBtn = isLockLoading ? btnLoading : <Button icon={icon} style={styleHeader} className={btnClass} tooltip="Lock/Unlock the scenario" disabled={!accessLvl1} onClick={() => onLockUnlock(s)} />;

        const copyBtn = <Button style={styleHeader} icon="pi pi-copy" className={btnClass} tooltip="Duplicate this scenario" disabled={!accessLvl2} onClick={() => onCreate(s)} />;

        const runBtn = isCalcLoading ? (
            btnLoading
        ) : (
            <Button
                style={styleHeader}
                // icon="pi pi-caret-right"
                className={btnClass}
                tooltip="Renewable Power Generation & Power Storage"
                // disabled={!(accessLvl1)}
                onClick={() => onRunRenewGenCalc(s)}
            >
                Log
            </Button>
        );

        const header = (
            <div style={{ margin: "10px" }}>
                {copyBtn}
                {hideBtn}
                {lockBtn}
            </div>
        );

        const footer = (
            <div className="p-grid p-justify-between">
                {runBtn}
                {editBtn}
            </div>
        );

        return (
            <React.Fragment>
                <Card
                    key={`Card_Scenario_${s.PathwayScenarioID}`}
                    title={s.PathwayScenarioName}
                    subTitle={idx + 1} //{s.PathwayScenarioID}
                    style={{ width: "25em", margin: "20px" }} //, background: '#14249F', color:'white'}}
                    footer={footer}
                    header={header}
                >
                    <div style={{ minHeight: "120px" }} className="p-align-center">
                        <p className="p-m-0" style={{ lineHeight: "1.5" }}>
                            {s.PathwayScenarioDescription ? s.PathwayScenarioDescription : "No description provided for this item."}
                        </p>
                    </div>
                </Card>
            </React.Fragment>
        );
    });

    return (
        <div className="p-grid">
            <Toast ref={toast} />
            {/* <div className="p-col-3">
                <b>Scenarios</b>
                <ListBox 
                listStyle={{ maxHeight: '60vh'}}
                style={{marginBottom: '15px'}}
                filter 
                optionLabel="PathwayScenarioName" 
                optionValue="PathwayScenarioName" 
                value={selectedScenario} 
                options={lstOfScenarios} 
                itemTemplate={scenarioItemTemplate}
                onChange={scenarSelectionChange} />
            </div> */}
            <Dialog visible={showDialog} style={{ width: "30vw" }} header="Create New Scenario" modal className="p-fluid" footer={createNewScenario} onHide={onHideDialog}>
                <span className="p-float-label" style={{ marginTop: "30px" }}>
                    <InputText id="inputtext" value={newScenarioName} onChange={(e) => setNewScenarioName(e.target.value)} />
                    <label htmlFor="inputtext">Enter Scenario Name</label>
                </span>
                <span className="p-float-label" style={{ marginTop: "30px" }}>
                    <InputTextarea id="textarea" value={newScenarioDesc} onChange={(e) => setNewScenarioDesc(e.target.value)} rows={10} />
                    <label htmlFor="textarea">Scenario Description</label>
                </span>
            </Dialog>
            <Dialog visible={showCalcDialog} style={{ width: "50vw" }} header="Renewable Power Generation & Power Storage" modal className="p-fluid" footer={runCalcLogDialogFooter} onHide={onHideCalcDialog}>
                <h4>Scenario: {selectedScenario ? selectedScenario.PathwayScenarioName : ""}</h4>
                <h4>Log Info:</h4>
                <span style={{ whiteSpace: "pre-line" }}>{contentCalcDialog}</span>
            </Dialog>
            {view}
        </div>
    );
};

export default ManageScenarios;
