import React from "react";
import Chart from "react-apexcharts";
import { Card } from "primereact/card";

import * as CONS from "../../../../store/constant";

import Loading from "../../../../components/Loading";

class SOPChart extends React.Component {
    render() {
        let display = <Loading />;
        if (this.props.dataSource) {
            var options = configureChart(this.props);
            display = (
                <Card title="SOP" style={{ height: "80vh" }}>
                    <div style={{ height: "70vh", width: "100%" }}>
                        <Chart type="area" options={options.options} series={options.series} width="100%" height="100%" />
                    </div>
                </Card>
            );
        }

        return <React.Fragment>{display}</React.Fragment>;
    }
}

export default SOPChart;

const configureChart = (props) => {
    return {
        series: props.dataSource,
        options: {
            chart: {
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                    },
                },
                stacked: true,
                // height: 700,

                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: CONS.COLOR_MAP_DISTINCT_1,
            stroke: {
                width: 1,
                curve: "straight",
            },
            labels: props.xaxis,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            if (value < 100) {
                                return value.toFixed(1);
                            } else {
                                return value.toFixed(0);
                            }
                        }
                    },
                },
                title: {
                    text: props.ProjectID === 1 ? "Saleable Ore Product (SOP) Mtpa" : "Saleable Product (Mtpa)",
                },
            },
        },
    };
};
