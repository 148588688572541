import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from 'primereact/button';

export function handleLogout(instance) {
    instance.logout().then(r => {
        console.log('signout')
    }).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt dfdf
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button icon="pi pi-sign-out" className="p-button-rounded p-button-text" tooltip="Sign Out" onClick={() => handleLogout(instance)} />
    );
}