import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SetInputs from "./pages/inputs";
import Results from "./pages/results";
import { Tabs } from "antd";
import { Card } from "antd";

import * as ApiSiteData from "../../../api/energy-model/siteServices";
import * as ApiEnergy from "../../../api/energy-model/energyServices";
import * as ApiExperiment from "../../../api/energy-model/experimentServices";

import Loading from "../../../components/Loading";
import Renewables from "./pages/renewables";

import { joinMonthlyProfileData } from "./comm";
import ErrorPage from "../../../components/error/ErrorPage";
import ExportMainTab from "./pages/results/export-results";
import renewable_inputs from "../../../api/energy-model/data/renewable_inputs.json";
import ListMonthlyProfiles from "./pages/inputs/monthly/ListMonthlyProfiles";

const { TabPane } = Tabs;

const LiveExperiment = (props) => {
    /* Multi-Tabpage
     Tab 1: Set Profiles + Set Pathways
     Tab 2...x: Plots and Results
     Other features:
     - profile settings
     - tech settings
     - hide and show weight values over years. 
     - keep the two last set of data (input/outputs)
     - capability to undo to the last input/output
     - capability to compare against existing scenarios in the DB (probably last thing to add)
     - 
    */

    //  const transDeepCopy = global.structuredClone(trans);
    const [transition, setTransition] = useState(null);
    const [techs, setTechs] = useState(null);
    const [siteData, setSiteData] = useState(null);
    const [energies, setEnergies] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [funcsOnMonthly, setFuncsOnMonthly] = useState(null);
    const [monthlyProfiles, setMonthlyProfiles] = React.useState(null);

    const [profiles, setProfiles] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [results, setResults] = useState([]);

    const lstSites = useSelector((s) => s.report.lstOfMines);
    const lstFuncs = useSelector((s) => s.report.lstOfFunctions);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    const lstDBTechs = useSelector((s) => s.report.lstOfTechnologies);
    // const [renewableInputs, setRenewableInputs]
    const syr0 = 2021;
    const eyr0 = React.useMemo(() => {
        return selectedUserAccess.ProjectID === 1 ? 2050 : 2030;
    }, [selectedUserAccess]);
    const tempRenewableInputs = global.structuredClone(renewable_inputs);
    const [renewableInputs, setRenewableInputs] = useState(tempRenewableInputs);
    const [renewableOutputs, setRenewableOutputs] = useState([]);
    const [importDropdown, setImportDropdown] = useState(0);
    useEffect(() => {
        setIsLoading(true);

        ApiExperiment.getTechDependencies(lstDBTechs).then(
            (resTechDep) => {
                if (!resTechDep.hasError) {
                    setTechs(resTechDep.data);
                    ApiExperiment.getBaseTransSolByProjectID(selectedUserAccess.ProjectID).then(
                        (resTrans) => {
                            if (!resTrans.hasError) {
                                setTransition(resTrans.data);
                                ApiSiteData.getTmmSopCalcByProjectID(selectedUserAccess.ProjectID).then(
                                    (resSiteData) => {
                                        setSiteData(resSiteData.data);
                                        ApiExperiment.getFuncOnMonthly(selectedUserAccess.ProjectID).then(
                                            (resFuncsMonthly) => {
                                                setFuncsOnMonthly(resFuncsMonthly.data);

                                                ApiExperiment.getMonthlyProfilesBySiteID(selectedUserAccess.ProjectID).then(
                                                    (resLstMonthlyProfiles) => {
                                                        setMonthlyProfiles(resLstMonthlyProfiles.data);

                                                        // let tmpMonthly = joinMonthlyProfileData(resFuncsMonthly.data, resLstMonthlyProfiles.data);

                                                        ApiEnergy.getListOfEnergies().then(
                                                            (resEnergy) => {
                                                                setEnergies(resEnergy.data);
                                                                const data = {
                                                                    syr: syr0,
                                                                    eyr: eyr0,
                                                                    projID: selectedUserAccess.ProjectID,
                                                                    aggCols: "Energy,SiteID,FunctionID",
                                                                    queryCols: "energy_demand",
                                                                    tbl_energies: resEnergy.data,
                                                                    tbl_techs: resTechDep.data,
                                                                    tbl_funcs: lstFuncs,
                                                                    tbl_sites: lstSites,
                                                                    tbl_site_data: resSiteData.data,
                                                                    lst_trans: resTrans.data,
                                                                    monthly: resLstMonthlyProfiles.data,
                                                                    get_profiles: 1,
                                                                };

                                                                ApiExperiment.calculateEnergyDemand(data).then(
                                                                    (resCalc) => {
                                                                        let temp_result_name = "";
                                                                        let temp_description = "";
                                                                        if (selectedUserAccess.ProjectID == 1) {
                                                                            temp_result_name = "RTIO_Central_Case";
                                                                            temp_description = "Rio Tinto Pilbara decarbonisation central case.";
                                                                        } else {
                                                                            temp_result_name = "DSL_Central_Case";
                                                                            temp_description =
                                                                                "Electrification of Haul Trucks and light vehicles starting at 2024. Electrification of other heavy vehicles and functions starting 2026. Renewables sized for optimal cost. Damper: solar and storage, Port Hedland: solar and storage, Lake Macleod: wind and solar, no storage.";
                                                                        }
                                                                        setProfiles(resCalc.data.profiles);
                                                                        setResults([
                                                                            {
                                                                                techs: resTechDep.data,
                                                                                transition: resTrans.data,
                                                                                result: resCalc.data.energy_calc,
                                                                                monthly: resLstMonthlyProfiles.data,
                                                                                years: resCalc.data.arr_all_yrs,
                                                                                dateTime: new Date().toLocaleString(),
                                                                                result_name: temp_result_name,
                                                                                nameDateTime: temp_result_name + "\xa0\xa0\xa0" + new Date().toLocaleString(),
                                                                                description: temp_description,
                                                                            },
                                                                        ]);
                                                                        setIsLoading(false);
                                                                    },
                                                                    (err3) => {
                                                                        setIsLoading(false);
                                                                        setHasError(true);
                                                                    }
                                                                );
                                                            },
                                                            (err2) => {
                                                                setIsLoading(false);
                                                                setHasError(true);
                                                            }
                                                        );
                                                    },
                                                    (err) => {
                                                        setIsLoading(false);
                                                        setHasError(true);
                                                    }
                                                );
                                            },
                                            (errFuncsMonthly) => {
                                                setIsLoading(false);
                                                setHasError(true);
                                            }
                                        );
                                    },
                                    (err) => {
                                        setIsLoading(false);
                                        setHasError(true);
                                    }
                                );
                            } else {
                                setIsLoading(false);
                            }
                        },
                        (errTrans) => {
                            setIsLoading(false);
                            setHasError(true);
                        }
                    );
                } else {
                    setIsLoading(false);
                }
            },
            (errDep) => {
                setIsLoading(false);
                setHasError(true);
            }
        );
    }, [selectedUserAccess]);

    if (hasError) {
        return <ErrorPage errMessage={"Something went wrong."} />;
    }

    const onTabChange = (key) => {
        console.log(key);
    };

    if (isLoading) {
        return <Loading />;
    }

    if (techs === null || transition === null || siteData === null) {
        return <h3>Required data not available!</h3>;
    }

    const sharedData = {
        projID: selectedUserAccess.ProjectID,
        tbl_energies: energies,
        tbl_techs: techs,
        tbl_funcs: lstFuncs,
        tbl_sites: lstSites,
        tbl_site_data: siteData,
    };

    return (
        <Card>
            <Tabs defaultActiveKey="1" onChange={onTabChange} type="card">
                <TabPane tab="Step 1 - Load Inputs" key="1-main">
                    <SetInputs
                        monthlyProfiles={monthlyProfiles}
                        setMonthlyProfiles={setMonthlyProfiles}
                        funcsOnMonthly={funcsOnMonthly}
                        setFuncsOnMonthly={setFuncsOnMonthly}
                        transition={transition}
                        techs={techs}
                        setTechs={setTechs}
                        setTransition={setTransition}
                        sharedData={sharedData}
                        results={results}
                        setResults={setResults}
                        setProfiles={setProfiles}
                        renewableInputs={renewableInputs}
                        setRenewableInputs={setRenewableInputs}
                        renewableOutputs={renewableOutputs}
                        setRenewableOutputs={setRenewableOutputs}
                        setImportDropdown={setImportDropdown}
                    />
                </TabPane>
                <TabPane tab="Step 2 - Review Load Results" key="2-main">
                    <Results results={results} />
                </TabPane>
                {sharedData.projID == 2 && (
                    <TabPane tab="Step 3 - Renewables Deployment" key="3-main">
                        <Renewables
                            renewableInputs={renewableInputs}
                            setRenewableInputs={setRenewableInputs}
                            renewableOutputs={renewableOutputs}
                            setRenewableOutputs={setRenewableOutputs}
                            results={results}
                            setResults={setResults}
                            sharedData={sharedData}
                            transition={transition}
                            setTransition={setTransition}
                            funcsOnMonthly={funcsOnMonthly}
                            setFuncsOnMonthly={setFuncsOnMonthly}
                            importDropdown={importDropdown}
                            setImportDropdown={setImportDropdown}
                        />
                    </TabPane>
                )}
                <TabPane tab="Export JSON" key="4-main">
                    <ExportMainTab transition={transition} results={results} setResults={setResults} sharedData={sharedData} />
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default LiveExperiment;
