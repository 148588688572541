import React, { useState,useRef } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import ImportCSV from "../../../components/Buttons/importCSV";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Toast } from "primereact/toast";
import * as ApiSite from "../../../api/energy-model/siteServices";
import CustomDataTable from "../../../components/CustomTable/CustomDataTable";

import Loading from "../../../components/Loading";

const ItemDiag = (props) => {
    const toast = useRef(null);
    const [primaryData, setPrimaryData] = useState(null);
    const [fileMeta, setFileMeta] = useState(null);
    const [dataImport, setDataImport] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const style = { margin: "10px" };

    const expectedHeaders = ["Site", "Year", "TMM-MTPA", "SOP-MTPA", "RecordID"];

    const lstOfSites = useSelector((state) => state.report.lstOfMines);
    const mapSiteName2Id = lstOfSites.reduce((r, { SiteID, Site }) => ((r[Site.trim()] = SiteID), r), {});
    const siteNames = lstOfSites.map((r) => r.Site);

    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);

    const hasError_Headers = (headers) => {
        headers.forEach((h, i) => {
            if (h !== expectedHeaders[i]) {
                return { hasError: true, message: "Header mismatch. Invalid format. Please recheck your data." };
            }
        });

        return { hasError: false };
    };

    const showWarnError = (level,header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const hasError_SiteNames = (data) => {
        data.forEach((r, i) => {
            // r[1] contains the site name from the imported data, for example.
            if (!siteNames.includes(r[0]) && i > 0) {
                return { hasError: true, message: `Site name mismatch. Name ${r[1]} in row ${i + 1} does not match any of the names on the site list. Please make sure there is an exact match.` };
            }
        });
        return { hasError: false };
    };

    const validateData = (res) => {
        // CHECK HEADERS
        let err = hasError_Headers(res[0]);
        if (err.hasError) {
            showWarnError("error","Error",err.message)
            return false;
        }
        // CHECK MINES IN THE TABLE - THEY SHOULD BE FROM AN ACCEPTABLE LIST
        err = hasError_SiteNames(res);
        if (err.hasError) {
            showWarnError("error","Error",err.message)
            return false;
        }
        return true;
    };

    const uploadData = (res) => {
        let headers = null;
        let data = [];
        if (validateData(res)) {
            res.forEach((r, i) => {
                if (i === 0) {
                    headers = r;
                } else {
                    data.push({
                        SiteName: r[0],
                        Site: parseInt(mapSiteName2Id[r[0]]),
                        Year: r[1],
                        TMM_MTPA: r[2],
                        SOP_MTPA: r[3],
                    });
                }
            });
        }
        setDataImport(data);
    };

    const hideDialog = () => {
        setDataImport(null);
        props.setShowDialog(false);
    };

    if (props.row !== null && primaryData === null) {
        setPrimaryData({ ...props.row });
    }

    let content = (
        <div>
            <Loading />
            <h6>Data is uploading. This might take some time ...</h6>
        </div>
    );

    if (primaryData !== null && !isUploading) {
        let inpDataVersion = <InputText type="text" value={primaryData.DataVersion} onChange={(e) => setPrimaryData({ ...primaryData, DataVersion: e.target.value })} />;
        let inpDataDesc = <InputTextarea type="text" rows={5} cols={50} value={primaryData.DataDescription} onChange={(e) => setPrimaryData({ ...primaryData, DataDescription: e.target.value })} />;

        content = (
            <div>
                <table>
                    <tr>
                        <td>Data Version</td>
                        <td>{inpDataVersion}</td>
                    </tr>
                    <tr>
                        <td style={{ alignContent: "top" }}>Description</td>
                        <td>{inpDataDesc}</td>
                    </tr>
                </table>
                <ImportCSV style={style} label="Import CSV" btnClassName="p-button-primary" setFileMeta={setFileMeta} onImport={uploadData} />
            </div>
        );
    }

    let columns = [
        // {name: 'ScenarioID', label: 'ScenarioID', sortable: true, filter: true, type: 'normal'},
        { name: "SiteName", label: "Site", sortable: false, filter: true, type: "normal" },
        { name: "Year", label: "Year", sortable: false, filter: true, type: "normal" },
        { name: "TMM_MTPA", label: "TMM (Mtpa)", sortable: false, filter: true, type: "normal" },
        { name: "SOP_MTPA", label: "SOP (Mtpa)", sortable: false, filter: true, type: "normal" },
    ];

    let dataTableViewImport = null;
    if (dataImport !== null && !isUploading) {
        dataTableViewImport = (
            <div style={style}>
                <Accordion>
                    <AccordionTab header={`Preview Imported Data - ${dataImport.length} rows of data`}>
                        <CustomDataTable data={dataImport} columns={columns} showOrgVals={false} tableType="normal" />
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }

    const submitData = () => {
        setIsUploading(true);
        ApiSite.uploadNewTmmSopData(dataImport, primaryData, selectedUserAccess.ProjectID).then(
            (res) => {
                setDataImport(null);
                setIsUploading(false);
                props.setShowDialog(false);
                props.updateData();
            },
            (err) => {
                setIsUploading(false);
                throw new Error(err);
            }
        );
    };

    let header = "Upload New Version of Site Data";

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" disabled={isUploading} icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
            <Button label={props.diagMode === "edit" ? "Update" : "Upload"} disabled={isUploading} icon="pi pi-upload" className="p-button-outlined" onClick={submitData} />
        </React.Fragment>
    );

    return (
        <Dialog visible={props.showDialog} disabled={isUploading} style={{ width: "40vw" }} header={header} modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
            {content}
            {dataTableViewImport}
            <Toast ref={toast} />
        </Dialog>
    );
};

export default ItemDiag;
