import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Card } from "primereact/card";

import * as CONS from "../../../../store/constant";
import * as DataHelper from "../../utils/dataHelpers";

import targetImg from "../../../../assets/images/target.png";
//IMPORT STYLES
import "primeflex/primeflex.css";

const strokeDashArray = 5;

class EmitChart extends React.Component {
    /**
     * @cardTitle       Title of the chart
     * @dataSource      Datasource for the chart. It should be prepared in form acceptable for the chart
     * @loadingComp     The Loading Component.
     * @chartType       'area' or 'line'
     * @targetPos       includes two target positions: tar1 targets year 2030. tar2 targets year 2050.
     * @ymax        Year 2018 used as the baseline for emission.
     * @statusColors    Used to color the constant lines.
     * @colors          Used for the colors of the chart
     * @lineStroke      Used for line stroke of the chart
     * @toFixed         set number of decimal points to be cut-off
     * @xaxis           values for xaxis
     * @yaxisTitle      title of yaxis
     */

    render() {
        let display = this.props.loadingComp;
        if (this.props.dataSource && !this.props.isLoading) {
            var options = configureChart(this.props);

            display = (
                <div style={{ height: "70vh", width: "100%" }}>
                    <Chart type={this.props.chartType} stacked={this.props.stacked} options={options.options} series={options.series} width="100%" height="100%" />
                </div>
            );
        }

        return (
            <React.Fragment>
                <Card style={{ height: "80vh", marginTop: "20px" }}>
                    <div className="p-grid p-align-center">
                        <h4 style={{ marginRight: "15px" }}>
                            <b>{this.props.cardTitle}</b>
                        </h4>
                        {this.props.filter}
                        <div />
                    </div>
                    {display}
                </Card>
            </React.Fragment>
        );
    }
}

export default EmitChart;

const configureChart = (props) => {
    let baseFactor = props.baseFactor ? props.baseFactor : 1.0;
    let baseline = baseFactor * props.selectedBaselineEmission.Value;
    return {
        series: props.dataSource,
        options: {
            annotations: {
                points: DataHelper.returnTargetEmissionAnnotate(props.selectedTargetEmission, props.selectedBaselineEmission, targetImg),
                yaxis: [
                    {
                        y: baseline,
                        borderColor: CONS.statusColors.hazard,
                        strokeDashArray: 10,
                        label: {
                            position: "left",
                            offsetX: "10%",
                            borderColor: CONS.statusColors.hazard,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.hazard,
                            },
                            text: `Baseline: ${(baseFactor * 100).toFixed(0)}% (${(baseline / 1000000).toFixed(2)} Mt)`,
                        },
                    },
                    {
                        y: 0.75 * baseline, //props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.hazard,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.hazard,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.hazard,
                            },
                            text: "25% reduction",
                        },
                    },
                    {
                        y: 0.5 * baseline, // props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.moderate,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.moderate,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.moderate,
                            },
                            text: "50% reduction",
                        },
                    },
                    {
                        y: 0.25 * baseline, // props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.calm,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.calm,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.calm,
                            },
                            text: "75% reduction",
                        },
                    },
                ],
                xaxis: [
                    {
                        x: 2030,
                        borderColor: CONS.statusColors.neutral,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.neutral,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.neutral,
                            },
                            text: "2030",
                        },
                    },
                    {
                        x: 2040,
                        borderColor: CONS.statusColors.neutral,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.neutral,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.neutral,
                            },
                            text: "2040",
                        },
                    },
                ],
            },
            chart: {
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                    },
                },
                stacked: props.stacked,
                // height: 700,
                //   type: 'area',
                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: CONS.COLOR_MAP_DISTINCT_2,
            stroke: {
                width: props.lineStroke,
                curve: "straight",
            },
            labels: props.xaxis,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return (value / 1000000).toFixed(3);
                        }
                    },
                },
                title: {
                    text: props.yaxisTitle,
                },
            },
        },
    };
};
