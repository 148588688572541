import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Input, Spin, Button } from "antd";
import Icon from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import cloneDeep from 'lodash/cloneDeep';

const ExportMainTab = ({ transition, results, setResults, sharedData }) => {
    const [exportName, setExportName] = useState(results ? results[0].result_name:"New");
    const [exportselectedResult, setExportSelectedResult] = useState(results ? results[0] : null);
    const [description, setDescription] = useState(results ? results[0].description:null);
    const [isExportFile, SetIsExportFile] = useState(false);

    const handleResultChange = (e) => {
        setExportSelectedResult(e.target.value);
        setExportName(e.target.value.result_name?e.target.value.result_name:"New")
        setDescription(e.target.value.description?e.target.value.description:"")

    };

    useEffect(() => {
        setExportSelectedResult(results[0]);
        setExportName(results ? results[0].result_name:"New")
        setDescription(results ? results[0].description:"")
    }, [results]);

    const exportTransitionJson = () => {
        let exportData = null;
        let exportSelResult = null;
        SetIsExportFile(true);
        let fileName = "liveExperiment.json";
        if (exportName) {
            fileName = exportName + ".json";
        }
        // exportData = global.structuredClone(results);
        exportData = cloneDeep(results);
        for (let index = 0; index < exportData.length; index++) {
            if (exportData[index]["nameDateTime"] == exportselectedResult.nameDateTime) {
                exportData[index]["nameDateTime"] = exportName + " " + exportData[index]["dateTime"];
                exportData[index]["result_name"] = exportName;
                exportData[index]["description"] = description;
                exportSelResult = exportData[index];
                break;
            }
        }
        setResults(exportData);
        const data = new Blob([JSON.stringify(exportSelResult)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
        SetIsExportFile(false);
    };

    return (
        <>
            <tr>
                <td>
                    <span style={{ marginRight: "10px" }}>Select Load Result</span>
                </td>
                <td>
                    <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                        <Dropdown options={results} value={exportselectedResult} optionLabel="nameDateTime" onChange={handleResultChange} />
                    </div>
                </td>
            </tr>
            <tr>
                <td>Name</td>
                <td >
                    <InputText style={{ margin: "10px" }} value={exportName} onChange={(e) => setExportName(e.target.value)} />
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{ paddingBottom: "40%", display: "table-caption" }}>Description</span>
                </td>
                <td>
                <InputTextarea style={{ margin: "10px" }} rows={1} cols={80} value={description} onChange={(e) => setDescription(e.target.value)} autoResize />
                </td>
            </tr>
            <tr>
                <td></td>
                <td>{isExportFile ? (
                <Spin />
            ) : (
                <Button type="primary" onClick={exportTransitionJson}>
                    <DownloadOutlined />
                    <Icon type="upload" />
                    Export JSON
                </Button>
            )}</td>
            </tr>
            
        </>
    );
};

export default ExportMainTab;
