import React from "react";

import SignInButton from "../../views/auth/SignInButton";

const RefreshSignIn = (props) => {
    /**
     * This component is used when access token refresher does not work.
     * user needs to sign in a get a new token.
     */

    return (
        <div style={{ width: "100%", height: "100vh" }} className="p-grid p-justify-center">
            <h4>Session access is expired. Please sign in again.</h4>
            <SignInButton />
            <div />
        </div>
    );
};

export default RefreshSignIn;
