import React from "react";
import Chart from "react-apexcharts";
import { Card } from "primereact/card";

import * as CONS from "../../../../store/constant";
import * as DataHelper from "../../utils/dataHelpers";
import targetImg from "../../../../assets/images/target.png"; //IMPORT STYLES
import "primeflex/primeflex.css";

const strokeDashArray = 5;

class FunctionChart extends React.Component {
    render() {
        let display = this.props.loadingComp;
        if (this.props.dataSource && !this.props.isLoading) {
            var options = configureChart(this.props);

            display = (
                <div style={{ height: "70vh", width: "100%" }}>
                    <Chart type="area" options={options.options} series={options.series} width="100%" height="100%" />
                </div>
            );
        }

        return (
            <React.Fragment>
                {/* <Card style={{height: "80vh", marginTop: "20px", borderRadius: '0px'}}>
                <div className="p-grid p-justify-between p-align-center">
                    <h4 ><b>CO2 - Function Emissions</b></h4> 
                    <span>{this.props.dropdown}</span>
                    <span>{this.props.filter}</span>
                    <div/>                  
                </div> */}
                {display}
                {/* </Card> */}
            </React.Fragment>
        );
    }
}

export default FunctionChart;

const configureChart = (props) => {
    const baseFactor = props.baseFactor ? props.baseFactor : 1.0;
    const baseline = baseFactor * props.selectedBaselineEmission.Value;
    const baselineName = props.selectedBaselineEmission.VersionName;
    return {
        series: props.dataSource,
        options: {
            annotations: {
                points: DataHelper.returnTargetEmissionAnnotate(props.selectedTargetEmission, props.selectedBaselineEmission, targetImg),
                yaxis: [
                    {
                        y: baseline,
                        borderColor: CONS.statusColors.hazard,
                        strokeDashArray: 10,
                        label: {
                            position: "left",
                            offsetX: "10%",
                            borderColor: CONS.statusColors.hazard,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.hazard,
                            },
                            text: `${baselineName}: ${(baseFactor * 100).toFixed(0)}% (${(baseline / 1000000).toFixed(2)} Mt)`,
                        },
                    },
                    {
                        y: 0.75 * baseline, //props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.hazard,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.hazard,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.hazard,
                            },
                            text: "25% reduction",
                        },
                    },
                    {
                        y: 0.5 * baseline, // props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.moderate,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.moderate,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.moderate,
                            },
                            text: "50% reduction",
                        },
                    },
                    {
                        y: 0.25 * baseline, // props.selectedBaselineEmission.Value,
                        borderColor: CONS.statusColors.calm,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.calm,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.calm,
                            },
                            text: "75% reduction",
                        },
                    },
                ],
                xaxis: [
                    {
                        x: 2030,
                        borderColor: CONS.statusColors.neutral,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.neutral,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.neutral,
                            },
                            text: "2030",
                        },
                    },
                    {
                        x: 2040,
                        borderColor: CONS.statusColors.neutral,
                        strokeDashArray: strokeDashArray,
                        label: {
                            borderColor: CONS.statusColors.neutral,
                            style: {
                                color: "#fff",
                                background: CONS.statusColors.neutral,
                            },
                            text: "2040",
                        },
                    },
                ],
            },
            chart: {
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                    },
                },
                stacked: true,
                // height: 700,
                //   type: 'area',
                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#1f77b4", "#ff7f0e", "#98df8a", "#9467bd", "#c49c94", "#7f7f7f", "#dbdb8d", "#9edae5"],
            stroke: {
                width: 2,
                curve: "straight",
            },
            labels: props.xaxis,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return (value / 1000000).toFixed(3);
                        }
                    },
                },
                // max: baseline,
                title: {
                    text: "M tCO2-e",
                },
            },
        },
    };
};
