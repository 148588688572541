import React, {useState, useMemo} from 'react'
import { useSelector } from 'react-redux';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import * as ApiEmit from '../../../../api/energy-model/emissionServices'

const ItemDiag = (props) => {

    
    const lstTargetTypes = ['Reduction', 'ActualValue']
    const selectedUserAccess = useSelector(s => s.user.selectedUserAccess)

    console.log(props.item)


    const hideDialog = () => {
        props.setShowDialog(false);
    };

    
    // const content = useMemo(() => {
    //     if (props.item) {
    //         let inpYear = <InputText type="text" value={props.item.Year} onChange={(e) => props.setItem({...props.item, Year: e.target.value})}/>
    //         let inpType = <Dropdown 
    //             value={props.item.TargetType} 
    //             options={lstTargetTypes} 
    //             onChange={(e) => props.setItem({...props.item, TargetType: e.target.value})} />;
    //         let inpReduc = <InputText  type="text" value={props.item.Reduction} onChange={(e) => props.setItem({...props.item, Reduction: e.target.value})} />
    //         let inpActualValue = <InputText type="text" value={props.item.ActualValue} onChange={(e) => props.setItem({...props.item, ActualValue: e.target.value})} />

    //         let inpVal = props.item.TargetType === 'Reduction'? inpReduc : inpActualValue
    //         let inpHeader = props.item.TargetType === 'Reduction'? 'Reduction (%)' : 'Value (tones)'

    //         return (
    //             <div>
    //                 <table>
    //                     <tr>
    //                         <td>Year</td>
    //                         <td>{inpYear}</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Target Type</td>
    //                         <td>{inpType}</td>
    //                     </tr>
    //                     <tr>
    //                         <td>{inpHeader}</td>
    //                         <td>{inpVal}</td>
    //                     </tr>
    //                 </table>
    //             </div>
    //         )
    //     }
    //     return null;
    // }, [props.item])

    
    let content = null 
    if (props.item) {
        let inpYear = <InputText type="text" value={props.item.Year} onChange={(e) => props.setItem({...props.item, Year: e.target.value})}/>
        let inpType = <Dropdown 
            value={props.item.TargetType} 
            options={lstTargetTypes} 
            onChange={(e) => props.setItem({...props.item, TargetType: e.target.value})} />;
        let inpReduc = <InputText  type="text" value={props.item.Reduction} onChange={(e) => props.setItem({...props.item, Reduction: e.target.value})} />
        let inpActualValue = <InputText type="text" value={props.item.ActualValue} onChange={(e) => props.setItem({...props.item, ActualValue: e.target.value})} />

        let inpVal = props.item.TargetType === 'Reduction'? inpReduc : inpActualValue
        let inpHeader = props.item.TargetType === 'Reduction'? 'Reduction (%)' : 'Value (tones)'

        content = (
            <div>
                <table>
                    <tr>
                        <td>Year</td>
                        <td>{inpYear}</td>
                    </tr>
                    <tr>
                        <td>Target Type</td>
                        <td>{inpType}</td>
                    </tr>
                    <tr>
                        <td>{inpHeader}</td>
                        <td>{inpVal}</td>
                    </tr>
                </table>
            </div>
        )
    }


    const submitData = () => {
        const submitItem = {...props.item, ProjectID: selectedUserAccess.ProjectID}
        if (props.mode === 'edit') {
            ApiEmit.updateTargetEmission([submitItem]).then(
                res => {props.updateDataSource()},
                err => {}
            )
            props.setShowDialog(false);
        }

        if (props.mode==='new') {
            ApiEmit.createTarget([submitItem]).then(
                res => {props.updateDataSource()},
                err => {}
            )
            props.setShowDialog(false);
        }        
    };

    let header = "Not Defined"
    if (props.mode === 'edit') {
        header = 'Edit Item'
    }
    if (props.mode === 'new') {
        header = 'New Item'
    }
    

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
            <Button label={(props.diagMode === 'edit')? "Update":"Submit"} icon="pi pi-check" className="p-button-outlined" onClick={submitData} />
        </React.Fragment>
    );

    return (
        <Dialog visible={props.showDialog} style={{ width: "40vw" }} header={header} modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
            {content}
        </Dialog>
    )
}

export default ItemDiag;