import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";

const ExportAllJson = ({ results }) => {
    const [isExportAll, SetIsExportAll] = useState(false);

    const exportAllTransition = () => {
        SetIsExportAll(true);
        let i = 1;
        results.forEach((res) => {
            const fileName = "liveExperiment_" + i + ".json";
            // const data = new Blob([JSON.stringify(res.transition)], { type: "text/json" });
            const data = new Blob([JSON.stringify(res)], { type: "text/json" });
            const jsonURL = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.href = jsonURL;
            link.setAttribute("download", fileName);
            link.click();
            document.body.removeChild(link);
            i += 1;
        });
        SetIsExportAll(false);
    };

    return (
        <>
            {/* {isExportAll ? (
                <Spin />
            ) : (
                <Button type="primary" onClick={exportAllTransition} style={{ marginLeft: "15px" }}>
                    <DownloadOutlined />
                    <Icon type="upload" />
                    Export JSON
                </Button>
            )} */}
        </>
    );
};

ExportAllJson.propTypes = {};

export default ExportAllJson;
