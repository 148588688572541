import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as ApiMisc from "../../../../api/energy-model/miscServices";
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as tableUtils from "../../../../utils/tables";
import { showCompletionMessage } from "../common/helpers";
import { Toast } from "primereact/toast";
import MainTabView from "../common/main";

const TechEdit = (props) => {
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasErrDB, setHasErrDB] = useState(false);
    const [energyMap, setEnergyMap] = useState(null);
    const [funcMap, setFuncMap] = useState(null);
    const toast = useRef(null);
    const lstFuncs = useSelector((state) => state.report.lstOfFunctions);
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);

    useEffect(() => {
        updateView();
    }, []);

    const showWarnError = (level, header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const updateView = () => {
        setIsLoading(true);
        ApiEnergy.getEnergyMapName2Id().then((r) => {
            if (!r.hasError) {
                setEnergyMap(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiMisc.getFuncMapName2Id().then((r) => {
            if (!r.hasError) {
                setFuncMap(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiMisc.getListOfTechnologiesByProjectID(selectedUserAccess.ProjectID).then((r) => {
            if (!r.hasError) {
                setDataSource(r.data);
            } else {
                showWarnError("error", r.message, "");
                setHasError(true);
            }
        });

        if (dataSource && funcMap && energyMap) {
            setIsLoading(false);
        }
    };

    if (hasError) {
        return <h3>An error occured when loading the data. Please refresh the page.</h3>;
    }

    const onSubmit = (lstUpdate, lstNewItems, setLstUpdate, setLstNewItems, setMessage) => {
        setHasErrDB(false);
        if (lstNewItems.length > 0) {
            ApiMisc.createTechnologies(lstNewItems, energyMap, funcMap).then((r) => {
                if (!r.hasError) {
                    setLstNewItems([]);
                    updateView();
                } else {
                    showWarnError("error", "Error creating new items", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }

        if (lstUpdate.length > 0) {
            ApiMisc.updateTechnologies(lstUpdate, energyMap, funcMap).then((r) => {
                if (!r.hasError) {
                    updateView();
                    setLstUpdate([]);
                } else {
                    showWarnError("error", "Error during update", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }
    };

    let IdCol = "TechnologyID";

    let columns = [
        { name: "Name", label: "Name", sortable: false, filter: true, type: "dropdown", newRow: { type: "text", isUnique: true } },
        { name: "Function", label: "Function", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown", items: lstFuncs, field: "Function" }, newRow: { type: "dropdown" } },
        { name: "EnergyTag", label: "Energy Tag", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "SpecificEnergyRate", label: "Specific Energy Rate", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 2 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "SpecificEnergyRateUnits", label: "Specific Energy Rate Units", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "Mtpa", label: "Mtpa", filter: true, type: "normal", sortable: false, dataFormat: { type: "float", format: 2 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "Description", label: "Description", sortable: false, filter: true, type: "normal", newRow: { type: "text" }, editable: { type: "text" } },
    ];

    let mainView = null;
    if (dataSource) {
        columns = tableUtils.extractUnqVals(dataSource, columns);
        mainView = <MainTabView dataSource={dataSource} setDataSource={setDataSource} title="Technologies" IdCol={IdCol} columns={columns} isLoading={isLoading} onSubmit={onSubmit} />;
    }

    return (
        <>
            {mainView}
            <Toast ref={toast} />
        </>
    );
};

export default TechEdit;
