import React from "react";
import { Input, Tooltip } from "antd";

const RenewableCostInput = ({ costInput, setCostInput }) => {
    const handleUpdateInputs = (idx, value) => {
        let tempData = global.structuredClone(costInput);
        tempData[idx]["value"] = value;
        setCostInput(tempData);
    };
    const convertToFloat = (idx, value) => {
        let tempData = global.structuredClone(costInput);
        tempData[idx]["value"] = parseFloat(value);
        setCostInput(tempData);
    };

    const view = costInput.map((item, idx) => {
        return (
            <tr key={item.name}>
                <td>{item.dispName}</td>
                <td>
                    <Tooltip placement="topRight" title={item.tooltipData}>
                        <Input style={{ width: "100px" }} value={item.value} onChange={(e) => handleUpdateInputs(idx, e.target.value)} onBlur={(e) => convertToFloat(idx, e.target.value)} />
                    </Tooltip>
                </td>
                <td>
                    <span style={{ marginLeft: "6px" }}>{item.unit}</span>
                </td>
            </tr>
        );
    });

    return (
        <>
            <table>
                <tbody>{view}</tbody>
            </table>
        </>
    );
};

export default RenewableCostInput;
