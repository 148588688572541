import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import * as editUtils from "./components/editUtils";
import EditDialog from "./components/editDialog";

import Loading from "../../../../../components/Loading";
import ListPathwayItemTemplate from "./components/listPathwayItemTemplate";
import ProfilePlot from "../../../../../components/profilePlot";

import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";

const EditSinglePathway = (props) => {
    const toast = useRef(null);

    // GETTING STORE STATES
    const lstOfProfiles = useSelector((state) => state.report.lstOfProfiles);
    const lstOfSite = useSelector((state) => state.report.lstOfMines);
    const lstOfFunctions = useSelector((state) => state.report.lstOfFunctions);
    const lstOfTechs = useSelector((state) => state.report.lstOfTechnologies);

    console.log(lstOfTechs);

    // DEFINING LOCAL STATES
    // 2. QUEUED CHANGES
    // 3. VIEW CHANGES
    // 4. EDIT DIALOGE FORM + SELECTED PROFILE FOR UPDATE
    const [editRowDialog, setEditRowDialog] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [selectedTech, setSelectedTech] = useState(null);
    // 5. SELECTED ROW OF TABLE
    const [selectedRow, setSelectedRow] = useState(null);
    // 6. FILTER DATA BY MINE/FUNCTION
    const [selectedFunc, setSelectedFunc] = useState(lstOfFunctions[0]);
    const [selectedSite, setSelectedSite] = useState(lstOfSite[0]);

    // ****************************************************************************************************************************************************************************

    // **************************************************************************************
    // SECTION 2:
    //  LISTBOX TEMPLATE ITEM
    // **************************************************************************************

    useEffect(() => {}, [selectedRow]);

    const editRowPrompt = (rowData) => {
        setSelectedRow({ ...rowData });
        let lstFlt = props.lstOfUpdates.filter((r) => r.PathwayMappingID === rowData.PathwayMappingID);
        setSelectedProfile(lstFlt.length > 0 ? lstFlt[0].NewProfile : rowData.Profile);
        setSelectedTech(lstFlt.length > 0 ? lstFlt[0].NewTech : rowData.Technology);
        setEditRowDialog(true);
    };

    const deleteRow = (rowData) => {
        editUtils.deleteRows([rowData], props.lstOfDeletes, props.lstOfUpdates, props.setListOfUpdates, props.setListOfDeletes, toast, setSelectedRow);
    };

    const cancelUpdateDelete = (rowData) => {
        editUtils.cancelUpdateDelete([rowData], props.lstOfDeletes, props.lstOfUpdates, props.setListOfUpdates, props.setListOfDeletes, toast);
    };
    // ****************************************************************************************************************************************************************************

    // **************************************************************************************
    // SECTION 3:
    //  EDIT DIALOG VIEW
    // **************************************************************************************

    let diagView = selectedRow ? (
        <EditDialog
            showEditDiag={editRowDialog}
            setShowEditDiag={setEditRowDialog}
            lstOfUpdates={props.lstOfUpdates}
            lstOfDeletes={props.lstOfDeletes}
            setListOfUpdates={props.setListOfUpdates}
            setListOfDeletes={props.setListOfDeletes}
            lstOfProfiles={lstOfProfiles}
            lstOfTechs={lstOfTechs}
            editField="both"
            lstRows={[selectedRow]}
        />
    ) : null;

    // ****************************************************************************************************************************************************************************
    // **************************************************************************************
    // SECTION 4:
    //  MAIN VIEW
    // **************************************************************************************

    const handleSiteChange = (e) => {
        setSelectedSite(lstOfSite.filter((r) => r.SiteID === e.target.value.SiteID)[0]);
    };

    const handleFuncChange = (e) => {
        setSelectedFunc(lstOfFunctions.filter((r) => r.FunctionID === e.target.value.FunctionID)[0]);
    };

    let view = <Loading />;

    if (props.scenarioMappings) {
        let data = props.scenarioMappings;
        data = selectedFunc.FunctionID === "-1" ? data : data.filter((r) => r.FunctionID === selectedFunc.FunctionID);
        data = selectedSite.SiteID === "-1" ? data : data.filter((r) => r.SiteID === selectedSite.SiteID);

        let profilePreview = null;
        if (selectedRow) {
            let isItemInUpdates = props.lstOfUpdates.filter((r) => r.PathwayMappingID === selectedRow.PathwayMappingID).length > 0;
            let item = isItemInUpdates ? props.lstOfUpdates.filter((r) => r.PathwayMappingID === selectedRow.PathwayMappingID)[0] : null;
            profilePreview = (
                <Card className="p-col-5" title="Profile Preview" style={{ margin: "20px", height: "70vh" }}>
                    <ProfilePlot plotHeight="60vh" originalProfile={selectedRow.Profile} selectedProfile={isItemInUpdates ? item.NewProfile : null} target={null} />
                </Card>
            );
        }

        let CustomListItems = (
            <Panel header="Pathways">
                <ListPathwayItemTemplate
                    items={data}
                    lstOfUpdates={props.lstOfUpdates}
                    lstOfDeletes={props.lstOfDeletes}
                    isSubmitPage={false}
                    isScenarioLocked={props.isScenarioLocked}
                    onSelection={setSelectedRow}
                    editRowPrompt={editRowPrompt}
                    deleteRow={deleteRow}
                    cancelUpdateDelete={cancelUpdateDelete}
                />
            </Panel>
        );

        view = (
            <div>
                <Toast ref={toast} />
                <div className="p-grid">
                    <Card className="p-col-5" style={{ height: "100%", margin: "20px" }}>
                        <div className="p-grid p-align-center" style={{ marginBottom: "20px" }}>
                            <span style={{ marginLeft: "30px" }}>
                                <b> Scenario Name: </b>
                                {props.scenario.PathwayScenarioName}
                            </span>
                        </div>
                        <div className="p-grid p-align-center" style={{ marginBottom: "20px" }}>
                            <span>
                                <b>Select a Site:</b>
                            </span>
                            <Dropdown style={{ marginLeft: "10px" }} value={selectedSite} options={lstOfSite} onChange={handleSiteChange} optionLabel="Site" filter filterBy="Site" placeholder="Select a Site" />
                            <span style={{ marginLeft: "10px" }}>
                                <b>Select a Function:</b>
                            </span>
                            <Dropdown style={{ marginLeft: "10px" }} value={selectedFunc} options={lstOfFunctions} onChange={handleFuncChange} optionLabel="Function" filter filterBy="Function" placeholder="Select a Function" />
                        </div>
                        {CustomListItems}
                    </Card>
                    {profilePreview}
                </div>
                {diagView}
            </div>
        );
    }

    return <>{view}</>;
};

export default EditSinglePathway;
