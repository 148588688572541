import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ xValues, dataSource, scale, title, height, postfix }) => {
    const factor = scale ? scale : 1;

    const options = {
        chart: {
            type: "bar",
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: xValues,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    if (value) {
                        return (value * factor).toFixed(2);
                    }
                },
            },
            title: {
                text: title,
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return `${(val * factor).toFixed(2)} ${postfix}`;
                },
            },
        },
    };

    return (
        <div style={{ height: height, width: "100%" }}>
            <Chart options={options} series={dataSource} type="bar" height="100%" width="100%" />
        </div>
    );
};

export default BarChart;
