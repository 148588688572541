import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SideFilter from "../../../../../../components/Filters";

const FilterResults = ({ results, setFltResults }) => {
    const lstOfFuncs = useSelector((s) => s.report.lstOfFunctions);
    const lstOfEnergies = useSelector((s) => s.report.lstOfEnergies);
    const lstOfSites = useSelector((s) => s.report.lstOfMines);

    useEffect(() => {
        if (results !== null) {
            let selectedSites = lstOfSites.filter((r) => r.checked).map((r) => r.SiteID);
            let selectedEnergies = lstOfEnergies.filter((r) => r.checked).map((r) => r.EnergyID);
            let selectedFuncs = lstOfFuncs.filter((r) => r.checked).map((r) => r.FunctionID);

            let filtResults = [];
            results.forEach((res) => {
                let filtData = res.result.filter((r) => selectedSites.includes(r.SiteID) && selectedEnergies.includes(r.Energy) && selectedFuncs.includes(r.FunctionID));
                filtResults.push({
                    years: res.years,
                    transition: res.transition,
                    result: filtData,
                    dateTime: res.dateTime,
                    nameDateTime:res.nameDateTime
                });
            });

            setFltResults(filtResults);
        }
    }, [results]);

    const dummyFunc = () => {};

    const handleSiteFilter = (updateItems) => {
        let selectedSites = updateItems.filter((r) => r.checked).map((r) => r.SiteID);
        let selectedEnergies = lstOfEnergies.filter((r) => r.checked).map((r) => r.EnergyID);
        let selectedFuncs = lstOfFuncs.filter((r) => r.checked).map((r) => r.FunctionID);

        let filteredResults = [];
        results.forEach((res) => {
            let filtData = res.result.filter((r) => selectedSites.includes(r.SiteID) && selectedEnergies.includes(r.Energy) && selectedFuncs.includes(r.FunctionID));
            filteredResults.push({
                years: res.years,
                transition: res.transition,
                result: filtData,
                dateTime: res.dateTime,
                nameDateTime:res.nameDateTime
            });
        });

        setFltResults(filteredResults);
    };

    const handleEnergyChange = (updateItems) => {
        let selectedSites = lstOfSites.filter((r) => r.checked).map((r) => r.SiteID);
        let selectedEnergies = updateItems.filter((r) => r.checked).map((r) => r.EnergyID);
        let selectedFuncs = lstOfFuncs.filter((r) => r.checked).map((r) => r.FunctionID);

        let filteredResults = [];
        results.forEach((res) => {
            let filtData = res.result.filter((r) => selectedSites.includes(r.SiteID) && selectedEnergies.includes(r.Energy) && selectedFuncs.includes(r.FunctionID));
            filteredResults.push({
                years: res.years,
                transition: res.transition,
                result: filtData,
                dateTime: res.dateTime,
                nameDateTime:res.nameDateTime
            });
        });

        setFltResults(filteredResults);
    };

    const handleFuncChange = (updateItems) => {
        let selectedSites = lstOfSites.filter((r) => r.checked).map((r) => r.SiteID);
        let selectedEnergies = lstOfEnergies.filter((r) => r.checked).map((r) => r.EnergyID);
        let selectedFuncs = updateItems.filter((r) => r.checked).map((r) => r.FunctionID);

        let filteredResults = [];
        results.forEach((res) => {
            let filtData = res.result.filter((r) => selectedSites.includes(r.SiteID) && selectedEnergies.includes(r.Energy) && selectedFuncs.includes(r.FunctionID));
            filteredResults.push({
                years: res.years,
                transition: res.transition,
                result: filtData,
                dateTime: res.dateTime,
                nameDateTime:res.nameDateTime
            });
        });

        setFltResults(filteredResults);
    };

    return (
        <SideFilter
            hasScenarioFilter={false}
            hasBaselineFilter={false}
            hasTargetVerFilter={false}
            hasSiteFilter={true}
            hasTMMSOPFilter={false}
            hasSiteSearchBar={false}
            hasFuncFilter={true}
            hasEnergyFilter={true}
            handleScenarioChange={dummyFunc}
            handleTargetVerChange={dummyFunc}
            handleBaselineVerChange={dummyFunc}
            onChangeCheckedSites={handleSiteFilter}
            onChangeCheckedEnergies={handleEnergyChange}
            onChangeCheckedFuncs={handleFuncChange}
            handleTMMSOPVerChange={dummyFunc}
            isOverlay={false}
            maxHeightSiteFilter="400px"
            customFilt={null}
        />
    );
};

FilterResults.propTypes = {};

export default FilterResults;
