import React from "react";
import Chart from "react-apexcharts";
import { Card } from "primereact/card";

import * as CONS from "../../../../store/constant";
import * as DataHelper from "../../utils/dataHelpers";
import targetImg from "../../../../assets/images/target.png";
//IMPORT STYLES
import "primeflex/primeflex.css";

const strokeDashArray = 5;

class EnergyChart extends React.Component {
    render() {
        let display = this.props.loadingComp;
        if (this.props.dataSource && !this.props.isLoading) {
            var options = configureChart(this.props);
            var optionsRenewable = configureRenewableChart(this.props);
            display = (
                <div style={{ height: "70vh", width: "100%" }}>
                    {this.props.dataSourceRenwable ? (
                        <div style={{ height: "25%", width: "100%" }}>
                            <Chart type="area" options={optionsRenewable.options} series={optionsRenewable.series} width="100%" height="100%" />
                        </div>
                    ) : null}

                    <div style={{ height: "75%", width: "100%" }}>
                        <Chart type="area" options={options.options} series={options.series} width="100%" height="100%" />
                    </div>
                </div>
            );
        }

        return display;
    }
}

export default EnergyChart;

const configureRenewableChart = (props) => {
    let annotations = {};
    if (props.projID === 1) {
        annotations = {
            xaxis: [
                {
                    x: 2025,
                    borderColor: CONS.statusColors.neutral,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.neutral,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.neutral,
                        },
                        text: "2030",
                    },
                },
                {
                    x: 2030,
                    borderColor: CONS.statusColors.neutral,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.neutral,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.neutral,
                        },
                        text: "2030",
                    },
                },
                {
                    x: 2040,
                    borderColor: CONS.statusColors.neutral,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.neutral,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.neutral,
                        },
                        text: "2040",
                    },
                },
            ],
        };
    }
    return {
        series: props.dataSourceRenwable,
        options: {
            annotations: annotations,
            chart: {
                group: "energy",
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                        // console.log(e.target)
                    },
                },
                stacked: true,
                // height: 700,

                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0, 1],
                formatter: function (val, opts) {
                    return val.toFixed(0);
                },
                style: {
                    fontSize: "9px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "light",
                    colors: undefined, // ["#2C3E50"]
                },
                background: {
                    enabled: true,
                },
            },
            grid: {
                show: false,
            },
            colors: ["#F1C40F", "#5499C7"],
            stroke: {
                width: 1,
                curve: "straight",
            },
            legend: {
                position: "right",
            },
            labels: props.xaxis,
            title: {},
            xaxis: {
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },

            yaxis: {
                labels: {
                    show: false,
                    formatter: function (value) {
                        if (value) {
                            return value.toFixed(2);
                        }
                    },
                },
                axisTicks: {
                    show: false,
                },
                title: {
                    text: "Installed Renewable (MW)",
                },
            },
        },
    };
};

const configureChart = (props) => {
    const baseFactor = props.baseFactor ? props.baseFactor : 1.0;
    const baseline = baseFactor * props.selectedBaselineEmission.Value;
    const baselineName = props.selectedBaselineEmission.VersionName;
    let annotations = {};
    if (props.projID === 1) {
        annotations = {
            points: DataHelper.returnTargetEmissionAnnotate(props.selectedTargetEmission, props.selectedBaselineEmission, targetImg),
            yaxis: [
                {
                    y: baseline,
                    borderColor: CONS.statusColors.hazard,
                    strokeDashArray: 10,
                    label: {
                        position: "left",
                        offsetX: "40%",
                        borderColor: CONS.statusColors.hazard,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.hazard,
                        },
                        text: `${baselineName}: ${(baseFactor * 100).toFixed(0)}% (${(baseline / 1000000).toFixed(2)} Mt)`,
                    },
                },
                {
                    y: 0.75 * baseline, //props.selectedBaselineEmission.Value,
                    borderColor: CONS.statusColors.hazard,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.hazard,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.hazard,
                        },
                        text: "25% reduction",
                    },
                },
                {
                    y: 0.5 * baseline, // props.selectedBaselineEmission.Value,
                    borderColor: CONS.statusColors.moderate,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.moderate,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.moderate,
                        },
                        text: "50% reduction",
                    },
                },
                {
                    y: 0.25 * baseline, // props.selectedBaselineEmission.Value,
                    borderColor: CONS.statusColors.calm,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.calm,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.calm,
                        },
                        text: "75% reduction",
                    },
                },
            ],
            xaxis: [
                {
                    x: 2030,
                    borderColor: CONS.statusColors.neutral,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.neutral,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.neutral,
                        },
                        text: "2030",
                    },
                },
                {
                    x: 2040,
                    borderColor: CONS.statusColors.neutral,
                    strokeDashArray: strokeDashArray,
                    label: {
                        borderColor: CONS.statusColors.neutral,
                        style: {
                            color: "#fff",
                            background: CONS.statusColors.neutral,
                        },
                        text: "2040",
                    },
                },
            ],
        };
    }

    return {
        series: props.dataSource,
        options: {
            annotations: annotations,
            chart: {
                group: "energy",
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                        // console.log(e.target)
                    },
                },
                stacked: true,
                // height: 700,

                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: "right",
            },
            colors: ["#F44336", "#7B1FA2"],
            stroke: {
                width: 1,
                curve: "straight",
            },
            labels: props.xaxis,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return (value / 1000000).toFixed(3);
                        }
                    },
                },
                // max: baseline,
                title: {
                    text: "M tCO2-e",
                },
            },
        },
    };
};
