import React, {useState} from 'react';

import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';


const PathwayItemTemplate = (props) => {

    /**
     * @rowData                 A single record of the pathway.          
     * @isItemInUpdates
     * @isItemInDeletes
     * @isSubmitPage
     * @index                   Index of the item
     * @selectedIdx             Index of selected item
     * 
     * 
     * @editRowPrompt           Implementation of Edit Row Click Button
     * @deleteRow               Implementation of Delete Row Click Button
     * @cancelUpdateDelete      Implementation of Cancel Delete/Update Button
     */

    let headerFontStyle = { fontSize: "xx-small", color: "gray" };
    

    const editRowPrompt = () => {
        props.editRowPrompt(props.rowData);
    };

    const deleteRow = () => {
        props.deleteRow(props.rowData);
    }

    const cancelUpdateDelete = () => {
        props.cancelUpdateDelete(props.rowData);
    }

    const onSelection = () => {
        props.selected(props.rowData, props.index);
    }

    return (
        <div style={{ margin: "0px", padding: "30px", backgroundColor: props.backColor, borderRadius: "5px", cursor:'pointer'}} key={props.key} onClick={onSelection}>
            <div className="p-grid" style={{ marginBottom: "15px" }}>
                <span style={headerFontStyle}>ID: {props.rowData.PathwayMappingID}</span>
                <span style={{ ...headerFontStyle, marginLeft: "15px" }}>Function: {props.rowData.Function}</span>
                <span style={{ ...headerFontStyle, marginLeft: "15px" }}>Site: {props.rowData.Site}</span>
            </div>

            <div className="p-grid p-justify-between" style={{ marginBottom: "15px" }}>              
                <div>
                    <h4 className="p-text-success" style={{margin: "0"}}>{props.rowData.Technology}</h4> 
                    <span style={{...headerFontStyle, color:'blue'}}>(Technology) </span>
                </div>  
                {props.isItemInUpdates ? <Badge value={props.rowData.NewTech === props.rowData.Technology ? null : props.rowData.NewTech} severity="danger" /> : null}
            </div>
            <div className="p-grid p-justify-between" style={{ marginBottom: "15px" }}>
                <div>
                    <h4 style={{margin: "0"}}>{props.rowData.Profile}</h4> 
                    <span style={{...headerFontStyle, color:'blue'}}>(Profile) </span>
                </div>
                {props.isItemInUpdates ? <Badge value={props.rowData.NewProfile === props.rowData.Profile ? null : props.rowData.NewProfile} severity="danger" /> : null}
            </div>
            <div className="p-align-center">
                {((props.isScenarioLocked) || (props.isSubmitPage))? null : <Button icon="pi pi-pencil" tooltip="Edit Item" className="p-button-rounded p-button-success p-mr-2" style={{ zIndex: 500 }} onClick={() => editRowPrompt()} />}
                {((props.isScenarioLocked) || (props.isSubmitPage))? null : <Button icon="pi pi-trash" tooltip="Delete Item" className="p-button-rounded p-button-danger p-mr-2" style={{ zIndex: 500 }} onClick={() => deleteRow()} />}
                {props.isItemInDeletes || props.isItemInUpdates ? <Button tooltip="Cancel Changes" icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-danger p-mr-2" style={{ zIndex: 500 }} onClick={() => cancelUpdateDelete()} /> : null}
                {((props.isItemInDeletes) && (!props.isSubmitPage)) ? <Badge value="delete" severity="danger" /> : null}
                {((props.isItemInUpdates) && (!props.isSubmitPage)) ? <Badge value="update" severity="danger" /> : null}
            </div>
        </div>
    );
}

export default PathwayItemTemplate;