import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";

import CheckListBox from "./CheckListBox";

const OverlayCheckListBox = (props) => {
    /**
     * SPECIFIC TO THIS COMPONENT
     *
     * @btnWidth        Width of the filter button
     * @btnLabel        Label of the filter button
     * @btnClassName    Class Style for button. Default is: p-button-raised p-button-secondary
     *
     * REQUIRED FOR CHECKBOX LIST
     * @items           list of items. The items should include the attribute "checked"
     * @setItems        React Hook function to set state of items
     * @IdField         Attribute of item to be used as Id
     * @labelField      Attribute of item to be displayed as label.
     * @filter          include filter field
     * @maxHeight       maximum height of the check listbox
     * @allItem         true/false, set to include all items or not.
     */

    const op = useRef(null);

    let isFilterOn = props.items.filter((r) => r.checked).length < props.items.length;
    let btnIcon = isFilterOn ? "pi pi-filter-slash" : "pi pi-filter";

    return (
        <div>
            <Button style={{ width: props.btnWidth }} className={props.btnClassName ? props.btnClassName : "p-button-raised p-button-secondary p-button-outlined"} type="button" icon={btnIcon} label={props.btnLabel} onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" />

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: "max-content" }} className="overlaypanel-demo">
                <CheckListBox filter={props.filter} allItem={props.allItem} items={props.items} maxHeight={props.maxHeight} IdField={props.IdField} labelField={props.labelField} setItems={props.setItems} />
            </OverlayPanel>
        </div>
    );
};

export default OverlayCheckListBox;
