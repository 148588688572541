import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import ResultChartRenewable from "./ResultChartRenewable";
import ResultRenewable from "./ResultRenewable";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import ResultEmissions from "./ResultEmissions";

/*
    Fields:
    -------
        'year', 'E_rand_hourly_sum', 'E_rand_hourly_max', 'GridImport_sum',
       'GridImport_max', 'RenewableTotals_sum', 'RenewableTotals_max',
       'StorageOut_sum', 'StorageOut_max', 'StorageCharging_sum',
       'StorageCharging_max', 'ImportCounterMax_sum', 'ImportCounterMax_max',
       'SubstationID', 'instSolarCapCost', 'instWindCapCost',
       'instStorageCapCost', 'omSolarCapCost', 'omWindCapCost',
       'omStorageCapCost', 'GridCost', 'StorageUsePercent', 'GridUsePercent',
       'RenewableUsePercent', 'totalCost'
*/

const resultFields = [
    { param: "SubstationID", title: "SubstationID", show: false, showTotal: false, showChart: false },
    { param: "year", title: "Year", show: true, showTotal: false, showChart: false },
    { param: "RenewableUsePercent", title: "Renewable Util. (%)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "line" },
    { param: "StorageUsePercent", title: "Storage Util. (%)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "line" },
    { param: "GridUsePercent", title: "Grid Util. (%)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "line" },
    { param: "GridExportPercent", title: "Grid Export (%)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "line" },
    { param: "E_rand_hourly_sum", title: "Total Load (MWh)", show: false, showTotal: false, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "line" },
    { param: "instWindCapCost", title: "Wind Inst. Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "instSolarCapCost", title: "Solar Inst. Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "instStorageCapCost", title: "Storage Inst. Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "omWindCapCost", title: "O&M Wind Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "omSolarCapCost", title: "O&M Solar Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "omStorageCapCost", title: "O&M Storage Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "GridCost", title: "Grid Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "DieselCost", title: "Diesel Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "BioDieselCost", title: "Bio Diesel Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "totalCost", title: "Total Cost ($M)", show: true, showTotal: true, showChart: true, dataFormat: { type: "float", format: 2 }, chartType: "bar" },
    { param: "ImportCounterMax_sum", title: "Hours of Grid Import (hr)", show: true, showTotal: true, showChart: true, chartType: "line" },
    { param: "ImportCounterMax_max", title: "Maximum Consecutive Hours of Grid Import (hr)", show: false, showTotal: false, showChart: false },
];

const cardStyle = { marginTop: "25px", borderRadius: 0 };

function RenewableResults({ apiRows, apiResult, apiSummaryRows, apiEmissionResults, substations, startOverallYr, endOverallYr }) {
    const [selectedSubstation, setSelectedSubstation] = React.useState(substations[0]);
    const [resultRows, setResultRows] = useState(apiRows);
    useEffect(() => {
        setResultRows(apiRows);
    }, [apiRows]);
    return (
        <Accordion multiple activeIndex={0}>
            <AccordionTab header="Results">
                <Card title="Tables - Renewable" style={cardStyle}>
                    {apiRows.length > 0 ? <Dropdown options={substations} value={selectedSubstation} optionLabel="Substation" onChange={(e) => setSelectedSubstation(e.target.value)} /> : null}
                    {apiRows.length > 0 ? <ResultRenewable apiRows={apiRows} apiResult={apiResult} apiSummaryRows={apiSummaryRows} resultFields={resultFields} selectedSubstation={selectedSubstation} /> : "No results"}
                </Card>
                <Card title="Charts - Renewable" style={cardStyle}>
                    {apiRows.length > 0 ? <ResultChartRenewable apiRows={apiRows} substations={substations} resultFields={resultFields} /> : "No results"}
                </Card>
                <Card title="Charts - Emission" style={cardStyle}>
                    {apiRows.length > 0 ? <ResultEmissions apiEmissionResults={apiEmissionResults} startOverallYr={startOverallYr} endOverallYr={endOverallYr} /> : "No results"}
                </Card>
            </AccordionTab>
        </Accordion>
    );
}

export default RenewableResults;
