import React, { useState } from "react";
//IMPORT LIBS
import { Card } from "primereact/card";
//IMPORT STYLES
import "primeflex/primeflex.css";
import * as ST_MAP from "../mapSettings";
import "./legend.css";

import { AzureMapHtmlMarker } from "react-azure-maps";

const colors = ST_MAP.COLORS_PIE_CHART;

const HtmlPieChartLayer = (props) => {
    const [showData, setShowData] = useState(false);

    let demand = props.data.demand;
    let wind = props.data.wind;
    let solar = props.data.solar;
    let gas = props.data.gas;
    let supply = props.data.supply;
    let excess = props.data.balance > 0 ? props.data.balance : 0;

    let demandPer = 0.0;
    let excessPer = 0.0;

    let solarPer = 0.0;
    let windPer = 0.0;
    let gasPer = 0.0;

    if (supply > 0) {
        demandPer = (demand / supply) * 100;
        excessPer = (excess / supply) * 100;

        solarPer = (solar / supply) * 100;
        windPer = (wind / supply) * 100;
        gasPer = (gas / supply) * 100;
    }

    let sectors =
        supply > 0
            ? `conic-gradient(${colors[0]} 0.00% ${solarPer.toFixed(2)}%,
     ${colors[1]}  ${solarPer.toFixed(2)}% ${(solarPer + windPer).toFixed(2)}%, 
     ${colors[2]}  ${(solarPer + windPer).toFixed(2)}% ${(solarPer + windPer + gasPer).toFixed(2)}%)`
            : "black";
    // sectors = 'conic-gradient(brown 0.00%, black 0.00% 0.55%, blue 0.55% 6.08%, green 6.08% 13.68%, yellow 13.68% 23.27%, orange 23.27% 40.47%, red 40.47%)'

    let pieChartStyle = {
        height: `${props.radius}px`,
        width: `${props.radius}px`,
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "black",
        opacity: props.opacity,
        background: sectors,
        cursor: "pointer",
        position: "relative",
        // top: "50%",
        // left: "50%",
        transform: "translate(0%,50%)",
    };

    let dataView = <div></div>;
    if (showData) {
        dataView = (
            <div style={{ marginBottom: "25px", cursor: "pointer" }}>
                <Card style={{ opacity: 0.9 }}>
                    <span>
                        <b>Substation: {props.data.Substation}</b>
                    </span>
                    <br />
                    <br />
                    <span>
                        <b>Total Supply: </b>
                        {supply.toFixed(2)} MW
                    </span>
                    <br />
                    <br />
                    <span>
                        <b>Energy Types: </b>
                    </span>
                    <ul>
                        <li>Solar: {solarPer.toFixed(2)}%</li>
                        <li>Wind: {windPer.toFixed(2)}%</li>
                        <li>Grid Power: {gasPer.toFixed(2)}%</li>
                    </ul>
                    <span>
                        <b>Demand: </b>
                    </span>
                    <ul>
                        <li>Demand: {demandPer.toFixed(2)}%</li>
                        <li>Excess: {excessPer.toFixed(2)}%</li>
                    </ul>
                </Card>
            </div>
        );
    }

    const onClick = () => {
        setShowData((prev) => !prev);
    };

    let content = <div style={pieChartStyle} />;
    if (demand <= 0) {
        content = (
            <div style={{ cursor: "pointer", position: "relative", transform: "translate(0%,50%)" }}>
                <h1>
                    <b>X</b>
                </h1>
            </div>
        );
    }

    return (
        <>
            <AzureMapHtmlMarker key={`${props.data.SubstationID}-renew-demand-gas-marker`} markerContent={content} options={{ position: [props.lat, props.lng], text: "My text", title: "Title" }} events={[{ eventName: "click", callback: onClick }]} />
            <AzureMapHtmlMarker key={`${props.data.SubstationID}-renew-demand-gas-popup`} markerContent={dataView} options={{ position: [props.lat, props.lng], text: "My text", title: "Title" }} events={[{ eventName: "click", callback: onClick }]} />
        </>
    );
};

export default HtmlPieChartLayer;
