import * as actionTypes from "./actionUser";


let initialState = {
  userDB: null,
  accessToken: null,
  selectedUserAccess: null,
};

let userState = JSON.parse(sessionStorage.getItem("userState"));
if (userState) {
  initialState = {
    ...initialState,
    userDB: userState.userDB,
    selectedUserAccess: userState.selectedUserAccess
  }
}


const reducerUser = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      let userDB = action.payload.userDB;
      let selectedUserAccess = userDB[0]

      var objStore = {
        userDB: userDB,
        selectedUserAccess: selectedUserAccess
      }
      sessionStorage.setItem('userState', JSON.stringify(objStore));
      
      return {
        ...state,
        userDB: userDB,
        selectedUserAccess: selectedUserAccess
      }

    case actionTypes.SET_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken
      }

    case actionTypes.SELEC_USER_ACCESS:
      return {
        ...state,
        selectedUserAccess: action.payload.response
      }

    default:
      return state;
  }
};

export default reducerUser;
