import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import { HashRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./views/auth/authConfig";
import reducerReport from "./store/report/reducerReport";
import reducerUser from "./store/user/reducerUser";
import reducerError from "./store/error/reducerError";

import "antd/dist/antd.css";

const reducer = combineReducers({
    report: reducerReport,
    user: reducerUser,
    error: reducerError,
});

const store = createStore(reducer);

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <HashRouter>
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                </HashRouter>
            </Provider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
