import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

const ProfilePlot = (props) => {
    /*
    List of Properties:
    plotHeight: Height of plot.
    originalProfile: Original Profile (useful when a profile needs to be changed to another profile)
    selectedProfile: New Profile
    target:    list of other profiles to be shown for comparison purposes.
    customProfileName: a name given to the custom profile.
    customProfileData: the list of profile values (parsed in float). The list should be the same length as the years.
    */

    const profileEntries = useSelector((state) => state.report.lstOfProfileEntries);
    const lstProfiles = useSelector((state) => state.report.lstOfProfiles);
    const years = useSelector((state) => state.report.years);

    let profileID = null;
    let profileEntryFlt = null;

    if (props && lstProfiles.length > 0) {
        let dataSource = [];
        if (props.originalProfile) {
            let orgProfile = lstProfiles.filter((r) => r.Profile === props.originalProfile);
            if (orgProfile.length > 0) {
                profileID = orgProfile[0].ProfileID;
                profileEntryFlt = profileEntries.filter((r) => r.ProfileID === profileID);
                dataSource.push({
                    name: "Original - " + props.originalProfile,
                    data: profileEntryFlt.map((r) => parseFloat(r.Value)),
                });
            }
        }
        if (props.selectedProfile) {
            let currProfile = lstProfiles.filter((r) => r.Profile === props.selectedProfile);
            if (currProfile.length > 0) {
                profileID = currProfile[0].ProfileID;
                profileEntryFlt = profileEntries.filter((r) => r.ProfileID === profileID);
                dataSource.push({
                    name: "Selected - " + props.selectedProfile,
                    data: profileEntryFlt.map((r) => parseFloat(r.Value)),
                });
            }
        }
        if (props.target) {
            props.target.forEach((item) => {
                profileID = lstProfiles.filter((r) => r.Profile === item.Profile)[0].ProfileID;
                profileEntryFlt = profileEntries.filter((r) => r.ProfileID === profileID);
                dataSource.push({
                    name: item.Profile,
                    data: profileEntryFlt.map((r) => parseFloat(r.Value)),
                });
            });
        }

        if (props.customProfileName) {
            dataSource.push({
                name: props.customProfileName,
                data: props.customProfileData,
            });
        }

        var options = configureChart(dataSource, years);

        let display = (
            <div style={{ height: props.plotHeight }}>
                <Chart type="line" options={options.options} series={options.series} width="100%" height="100%" />
            </div>
        );
        return <React.Fragment>{display}</React.Fragment>;
    } else {
        return (
            <React.Fragment>
                <h1>The data was not properly loaded to show the profiles</h1>
            </React.Fragment>
        );
    }
};

export default ProfilePlot;

// const configureItemChart = (dataSource, years) => {
//     return {
//         series: dataSource,
//         options: {
//             chart: {
//                 toolbar: {
//                     show: false,
//                     tools: {
//                         download: false,
//                     },
//                 },
//                 stacked: false,
//                 selection: {
//                     enabled: false,
//                 },
//                 zoom: {
//                     enabled: false,
//                 },
//                 animations: {
//                     enabled: false,
//                 },
//             },
//             tooltip: {
//                 enabled: false,
//             },
//             legend: {
//                 show: false,
//             },
//             dataLabels: {
//                 enabled: false,
//             },
//             grid: {
//                 show: false,
//             },
//             colors: ["#F44336"],
//             stroke: {
//                 width: [3],
//                 curve: "straight",
//             },
//             labels: years,
//             title: {},
//             xaxis: {
//                 labels: {
//                     show: false,
//                 },
//                 axisBorder: {
//                     show: false,
//                 },
//                 axisTicks: {
//                     show: false,
//                 },
//             },
//             yaxis: {
//                 labels: {
//                     show: false,
//                 },
//                 axisBorder: {
//                     show: false,
//                 },
//                 axisTicks: {
//                     show: false,
//                 },
//             },
//         },
//     };
// };

const configureChart = (dataSource, years) => {
    return {
        series: dataSource,
        options: {
            chart: {
                events: {
                    click: function (e, ctx, options) {
                        // check e.target here
                    },
                },
                stacked: false,
                // height: 700,
                //   type: 'area',
                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#000000", "#4CAF50", "#F44336", "#9467bd", "#c49c94", "#7f7f7f", "#dbdb8d", "#9edae5"],
            stroke: {
                width: [4, 4, 2],
                curve: "straight",
                dashArray: [8, 0, 0],
            },
            labels: years,
            title: {},
            xaxis: {},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value) {
                            return value.toFixed(2);
                        }
                    },
                },
                title: {
                    text: "Amount",
                },
            },
        },
    };
};
