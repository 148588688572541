import * as actionTypes from "./actionReport";

let years = [];
for (let i = 2020; i <= 2050; i++) {
    years.push(i);
}

const ymax2018 = 3979215 / 1000000;
const targetPos = {
    tar1: { x: 2030, y: ymax2018 * 0.5 },
    tar2: { x: 2050, y: 0 },
};

const statusColors = {
    hazard: "#F44336",
    moderate: "#FFB300",
    calm: "#43A047",
    neutral: "#34495E",
};

let initialState = {
    selectedScenario: null,
    selectedMineDataRecord: null,
    selectedTargetEmitVersion: null,
    selectedBaselineEmitVer: null,
    scenarioNames: null,
    lstOfAllScenarios: null,
    lstOfScenarios: null,
    lstOfFunctions: null,
    lstOfMines: null,
    lstOfProfiles: null,
    lstOfProfileEntries: null,
    lstOfTechnologies: null,
    lstOfAllMineDataRecords: null,
    lstOfTargetEmitVersions: null,
    lstOfBaselineEmitVer: null,
    lstOfEnergies: null,
    statusColors: statusColors,
    targetPos: targetPos,
    ymax2018: ymax2018,
    years: years,
    isProjectOnChange: false,
};

let commState = JSON.parse(sessionStorage.getItem("commState"));
if (commState) {
    initialState = {
        ...initialState,
        selectedScenario: commState.selectedScenario,
        selectedMineDataRecord: commState.selectedMineDataRecord,
        selectedTargetEmitVersion: commState.selectedTargetEmitVersion,
        selectedBaselineEmitVer: commState.selectedBaselineEmitVer,
        scenarioNames: commState.scenarioNames,
        lstOfAllScenarios: commState.lstOfAllScenarios,
        lstOfScenarios: commState.lstOfScenarios,
        lstOfAllMineDataRecords: commState.lstOfAllMineDataRecords,
        lstOfFunctions: commState.lstOfFunctions,
        lstOfMines: commState.lstOfMines,
        lstOfProfiles: commState.lstOfProfiles,
        lstOfProfileEntries: commState.lstOfProfileEntries,
        lstOfTechnologies: commState.lstOfTechnologies,
        lstOfTargetEmitVersions: commState.lstOfTargetEmitVersions,
        lstOfBaselineEmitVer: commState.lstOfBaselineEmitVer,
        lstOfEnergies: commState.lstOfEnergies,
        scenarioMap: commState.scenarioMap,
        functionMap: commState.functionMap,
        mineMap: commState.mineMap,
        techMap: commState.techMap,
        profileMap: commState.profileMap,
    };
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_SCENARIOS:
            let scenarios_ = action.payload.response;
            let unHideScenarios_ = scenarios_.filter((r) => !r.Hide);
            let scenarioNames_ = unHideScenarios_.map((s) => s.PathwayScenarioName);
            return {
                ...state,
                lstOfAllScenarios: scenarios_,
                lstOfScenarios: unHideScenarios_,
                scenarioNames: scenarioNames_,
                selectedScenario: unHideScenarios_[0],
            };

        case actionTypes.SET_IS_PROJECT_ON_CHANGE:
            return {
                ...state,
                isProjectOnChange: action.payload.response,
            };

        case actionTypes.SET_LIST_TARGET_EMIT_VER:
            return {
                ...state,
                lstOfTargetEmitVersions: action.payload.response,
                selectedTargetEmitVersion: action.payload.response[0],
            };

        case actionTypes.SET_LIST_BASELINE_EMIT_VER:
            return {
                ...state,
                lstOfBaselineEmitVer: action.payload.response,
                selectedBaselineEmitVer: action.payload.response[0],
            };

        case actionTypes.SET_LIST_MINE_DATA_RECORDS:
            return {
                ...state,
                lstOfAllMineDataRecords: action.payload.response,
                selectedMineDataRecord: action.payload.response[0],
            };

        // case actionTypes.SET_LIST_FUNCTIONS:
        //     return {
        //         ...state,
        //         lstOfFunctions: action.payload.response,
        //     };

        case actionTypes.SET_LIST_PROFILES:
            return {
                ...state,
                lstOfProfiles: action.payload.response,
            };
        case actionTypes.SELECT_SCENARIO:
            return {
                ...state,
                selectedScenario: action.payload.response,
            };

        case actionTypes.SELECT_MINE_DATA_RECORD:
            return {
                ...state,
                selectedMineDataRecord: action.payload.response,
            };

        case actionTypes.SELEC_TARGET_EMIT_VER:
            return {
                ...state,
                selectedTargetEmitVersion: action.payload.response,
            };

        case actionTypes.SELEC_BASELINE_EMIT_VER:
            return {
                ...state,
                selectedBaselineEmitVer: action.payload.response,
            };

        case actionTypes.SET_CHK_STATE_ENERGIES:
            return {
                ...state,
                lstOfEnergies: action.payload.response,
            };

        case actionTypes.SET_CHK_STATE_FUNCTIONS:
            return {
                ...state,
                lstOfFunctions: action.payload.response,
            };

        case actionTypes.SET_CHK_STATE_SITES:
            return {
                ...state,
                lstOfMines: action.payload.response,
            };

        case actionTypes.SET_LIST_COMMS:
            let scenarios = action.payload.response.scenarios;
            let unHideScenarios = scenarios.filter((r) => !r.Hide);
            let scenarioNames = unHideScenarios.map((s) => s.PathwayScenarioName);
            let functions = action.payload.response.functions.map((r) => {
                return { ...r, checked: true };
            });
            let mines = action.payload.response.mines.map((r) => {
                return { ...r, checked: true };
            });
            let profiles = action.payload.response.profiles;
            let profileEntries = action.payload.response.profileEntries;
            let technologies = action.payload.response.technologies;
            let mineDataRecords = action.payload.response.mineDataRecords;
            let targetEmitVers = action.payload.response.targetEmitVers;
            let baselineEmitVers = action.payload.response.baselineEmitVers;
            let lstOfEnergies = action.payload.response.lstOfEnergies.map((r) => {
                return { ...r, checked: true };
            });

            let scenarioMap = scenarios.reduce((r, { PathwayScenarioID, PathwayScenarioName }) => ((r[PathwayScenarioID] = PathwayScenarioName.trim()), r), {});
            let functionMap = functions.reduce((r, { FunctionID, Function }) => ((r[FunctionID] = Function.trim()), r), {});
            let mineMap = mines.reduce((r, { SiteID, Site }) => ((r[SiteID] = Site.trim()), r), {});
            let techMap = technologies.reduce((r, { TechnologyID, Name }) => ((r[TechnologyID] = Name.trim()), r), {});
            let profileMap = profiles.reduce((r, { ProfileID, Profile }) => ((r[ProfileID] = Profile.trim()), r), {});

            const objStore = {
                lstOfAllScenarios: scenarios,
                lstOfScenarios: unHideScenarios,
                lstOfAllMineDataRecords: mineDataRecords,
                lstOfTargetEmitVersions: baselineEmitVers,
                lstOfBaselineEmitVer: targetEmitVers,
                lstOfEnergies: lstOfEnergies,
                selectedTargetEmitVersion: targetEmitVers[0],
                selectedBaselineEmitVer: baselineEmitVers[0],
                selectedMineDataRecord: mineDataRecords[0],
                scenarioNames: scenarioNames,
                lstOfFunctions: functions,
                lstOfMines: mines,
                selectedScenario: unHideScenarios[0],
                lstOfProfiles: profiles,
                lstOfProfileEntries: profileEntries,
                lstOfTechnologies: technologies,
                scenarioMap: scenarioMap,
                functionMap: functionMap,
                mineMap: mineMap,
                techMap: techMap,
                profileMap: profileMap,
            };
            sessionStorage.setItem("commState", JSON.stringify(objStore));

            return {
                ...state,
                lstOfAllScenarios: scenarios,
                lstOfScenarios: unHideScenarios,
                lstOfAllMineDataRecords: mineDataRecords,
                lstOfTargetEmitVersions: targetEmitVers,
                lstOfBaselineEmitVer: baselineEmitVers,
                scenarioNames: scenarioNames,
                lstOfFunctions: functions,
                lstOfMines: mines,
                lstOfEnergies: lstOfEnergies,
                selectedScenario: unHideScenarios[0],
                selectedMineDataRecord: mineDataRecords[0],
                selectedTargetEmitVersion: targetEmitVers[0],
                selectedBaselineEmitVer: baselineEmitVers[0],
                lstOfProfiles: profiles,
                lstOfProfileEntries: profileEntries,
                lstOfTechnologies: technologies,
                scenarioMap: scenarioMap,
                functionMap: functionMap,
                mineMap: mineMap,
                techMap: techMap,
                profileMap: profileMap,
            };

        default:
            return state;
    }
};

export default reducer;
