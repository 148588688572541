import { actionTypes } from "./actionError";

const initialState = {
    msg: undefined,
};

function reset() {
    return { ...initialState };
}

function error(state, action) {
    return {
        ...state,
        msg: action.msg,
    };
}

const actionsMap = {
    [actionTypes.reset]: reset,
    [actionTypes.error]: error,
    [actionTypes.locationChanged]: reset,
};

export default function reducerError(state = initialState, action) {
    const reducerFunction = actionsMap[action.type];
    return reducerFunction ? reducerFunction(state, action) : state;
}
