export const SELECT_SCENARIO = "SELECT_SCENARIO";
export const SET_LIST_SCENARIOS = "SET_LIST_SCENARIOS";
export const SET_LIST_FUNCTIONS = "SET_LIST_FUNCTIONS";
export const SET_LIST_PROFILES = "SET_LIST_PROFILES";
export const SET_LIST_MINE_DATA_RECORDS = "SET_LIST_MINE_DATA_RECORDS";
export const SELECT_MINE_DATA_RECORD = "SELECT_MINE_DATA_RECORD";
export const SET_LIST_COMMS = "SET_LIST_COMMS";
export const SET_LIST_TARGET_EMIT_VER = "SET_LIST_TARGET_EMIT_VER";
export const SELEC_TARGET_EMIT_VER = "SELEC_TARGET_EMIT_VER";
export const SET_LIST_BASELINE_EMIT_VER = "SET_LIST_BASELINE_EMIT_VER";
export const SELEC_BASELINE_EMIT_VER = "SELEC_BASELINE_EMIT_VER";
export const SET_CHK_STATE_SITES = "SET_CHK_STATE_SITES";
export const SET_IS_PROJECT_ON_CHANGE = "SET_IS_PROJECT_LOADING";
export const SET_CHK_STATE_ENERGIES = "SET_CHK_STATE_ENERGIES";
export const SET_CHK_STATE_FUNCTIONS = "SET_CHK_STATE_FUNCTIONS";
