import React from "react";
import { useSelector } from "react-redux";
import RenewableTable from "./RenewableTable";
import { Dropdown } from "primereact/dropdown";
import RenewableScheduleChart from "./RenewableScheduleChart";

const updateShow = (sub, renewableTable) => {
    return renewableTable.map((r) => {
        if (r.SubstationID === sub.SubstationID) {
            return { ...r, show: true };
        } else {
            return { ...r, show: false };
        }
    });
};

const totalHoursInYear = 365.25 * 24;

function RenewableSchedule({ renewableTable, onUpdate, startOverallYr, endOverallYr, selectedResult, subs }) {
    const [selectedSub, setSelectedSub] = React.useState(subs[0]);
    const [fltTable, setFltTable] = React.useState(null);
    const sites = useSelector((s) => s.report.lstOfMines);

    const selectedSites = React.useMemo(() => {
        return sites.filter((r) => r.Substation === selectedSub.SubstationID);
    }, [selectedSub]);

    React.useEffect(() => {
        setFltTable(updateShow(selectedSub ? selectedSub : subs[0], renewableTable));
    }, [renewableTable]);

    const handleSubChange = (e) => {
        const sub = e.target.value;
        setSelectedSub(sub);
        setFltTable(updateShow(sub, renewableTable));
    };

    const loadTotalChart = React.useMemo(() => {
        let data = [];

        let counter = 0;
        selectedSites.forEach((site) => {
            selectedResult.result
                .filter((r) => r.SiteID === site.SiteID && r.Energy === 2)
                .forEach((res) => {
                    if (counter === 0) {
                        data = res.energy_demand.map((r) => r / totalHoursInYear);
                        counter += 1;
                    } else {
                        data = data.map((num, i) => num + res.energy_demand[i] / totalHoursInYear);
                    }
                });
        });

        return data;
    }, [selectedResult, selectedSites]);

    if (fltTable === null) {
        return <div>Loading ...</div>;
    }

    return (
        <>
            <div className="p-grid">
                <div className="p-col-4">
                    <div className="p-grid p-align-center">
                        <span style={{ marginRight: "10px" }}>Select Substation </span>
                        <Dropdown options={subs} value={selectedSub} optionLabel="Substation" onChange={handleSubChange} />
                    </div>

                    <RenewableTable renewableTable={fltTable} onUpdate={onUpdate} selectedSub={selectedSub} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />
                </div>
                <div className="p-col-8">
                    <RenewableScheduleChart fltData={fltTable} startAllYear={startOverallYr} endAllYear={endOverallYr} loadTotalChart={loadTotalChart} selectedSub={selectedSub} />
                </div>
            </div>
        </>
    );
}

export default RenewableSchedule;
