import React from "react";
import RowItemTemplate from "./RowItemTemplate";

const INITIAL_THINGS = [
    {
        id: 1,
        name: "Alex",
        height: "short",
    },
    {
        id: 2,
        name: "Beatriz",
        height: "tall",
    },
];

const TransitionTable = ({ transitions, setTransitions, techs, startOverallYr, endOverallYr }) => {
    const onUpdate = React.useCallback(
        (trans) => {
            setTransitions((prevTransitions) => prevTransitions.map((t) => (t.TransID === trans.TransID ? trans : t)));
        },
        [setTransitions]
    );

    return (
        <table>
            <tbody>
                <tr style={{ textAlign: "left" }}>
                    <th>Site</th>
                    <th>Function</th>
                    <th>Technology</th>
                </tr>
                {transitions.map((trans, idx) => (
                    <RowItemTemplate key={idx} rowIdx={idx} trans={trans} onUpdate={onUpdate} techs={techs} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />
                ))}
            </tbody>
        </table>
    );
};

export default TransitionTable;
