import React, { useState, useEffect } from "react";
import { range } from "../../../../../../utils/comm";
import { Dropdown } from "primereact/dropdown";
import LineChart from "../../../../../../components/Charts/LineChart";
import * as CONST from "../../../../../../store/constant";

const monthMapper = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

const convertToDataSource = (res, years) => {
    const months = range(1, 13, 1);
    let dataSource = [];
    if (res) {
        months.forEach((month) => {
            let dataCur = [];
            years.forEach((yr) => {
                const idx = res.years.indexOf(yr);

                let E_tot = idx < 0 ? null : 0.0;
                if (idx >= 0) {
                    res.result.forEach((r) => {
                        E_tot += r[month][idx];
                    });
                }
                dataCur.push(E_tot);
            });

            dataSource.push({
                name: monthMapper[month],
                // type: 'area',
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const MonthlyDemandResult = ({ results, fltResults }) => {
    const [dataSource, setDataSource] = useState(null);
    const [years, setYears] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [selectedIdx, setSelectedIdx] = useState(0);

    const options = React.useMemo(() => {
        return fltResults;
    }, [fltResults]);

    const updateData = (resFlt) => {
        setIsloading(true);
        if (fltResults !== null) {
            let yrMin = 10000000;
            let yrMax = 0;
            fltResults.forEach((res) => {
                yrMin = yrMin < res.years[0] ? yrMin : res.years[0];
                yrMax = yrMax > res.years[res.years.length - 1] ? yrMax : res.years[res.years.length - 1];
            });
            const yrs = range(yrMin, yrMax + 1, 1);

            setYears(yrs);
            setDataSource(convertToDataSource(resFlt, yrs));
            setIsloading(false);
        }
    };

    useEffect(() => {
        const currRes = fltResults[selectedIdx];
        setSelectedResult(currRes);
        updateData(currRes);
    }, [results, fltResults]);

    const handleResultChange = (e) => {
        setSelectedResult(e.target.value);
        const index = options.indexOf(e.target.value);
        setSelectedIdx(index);
        updateData(e.target.value);
    };

    if (fltResults === null) {
        return <div>No Results!</div>;
    }

    return (
        <React.Fragment>
            <Dropdown options={options} value={selectedResult} optionLabel="nameDateTime" onChange={handleResultChange} />

            <LineChart dataSource={dataSource} height="60vh" xValues={years} yLabel="Energy Demand (GWh-e)" type="line" scale={1000} colors={CONST.COLOR_MAP_DISTINCT_2} />
        </React.Fragment>
    );
};

MonthlyDemandResult.propTypes = {};

export default MonthlyDemandResult;
