// TOOLS COMPONENTS
// SCENARIO
import SelectEditType from "../views/tools/scenarios/edit/editScenario/MainEdit";
import ManageScenarios from "../views/tools/scenarios/edit/ListScenarios";
import ViewScenario from "../views/tools/scenarios/view";
import LiveExperiment from "../views/tools/live-experiment";
// PROFILES
import ViewProfiles from "../views/tools/profiles/view";
import EditProfiles from "../views/tools/profiles/edit";
// TOOLS - TABLES
import EditTech from "../views/tools/tables/technology";
import EditRenew from "../views/tools/tables/renewable";
import EditRenewSch from "../views/tools/tables/renewable-schedule";
import EditStorage from "../views/tools/tables/storage";
import EditStorageSch from "../views/tools/tables/storage-schedule";
import EditTargetEmissions from "../views/tools/target-emissions/target";
import EditBaselineEmissions from "../views/tools/target-emissions/baseline";
// HOME PAGE COMPONENTS
import HomePage from "../views/home";
// DASHBOARD COMPONENTS
// CHARTS
import EmissionFigures from "../views/dashboards/charts/emission-figures";
import PowerFigures from "../views/dashboards/charts/power-figures";
import PowerGeneration from "../views/dashboards/charts/power-generation";
import SOP from "../views/dashboards/charts/SOP";
import TMM from "../views/dashboards/charts/TMM";
import TmmSopDataManager from "../views/tools/tmm-sop";
import TechCountChart from "../views/dashboards/charts/tech-count";
// MAPS
import DemandAzure from "../views/dashboards/maps/demand-azure";
// import AzureMapKML from '../views/dashboards/maps/kml-azure';
// TABLES
// import MainViewData from '../views/dashboards/tables/dataView';
// COMPARES
import CompareEmission from "../views/dashboards/compare/emissions";

// HAVE CHANGED THE URI
// export const BACKEND_BASE_URL = 'https://rtio-energymodel.azurewebsites.net/getJSON.php?';
// export const URL_ADMIN_API = 'https://rtioem-dev-admin-api.azurewebsites.net/api'; //  'http://localhost:5000/api'
let back_url = "https://riotinto.worley.com/rtioem-api"; // "https://rtioem-api-dev.azurewebsites.net/rtioem-api"; // 'http://localhost:5000' //
// switch (process.env.REACT_APP_DEPLOYMENT_SLOT) {
//     case "PREDEV":
//         back_url = "http://localhost:5000/rtioem-api";
//         break;

//     case "TEST":
//         back_url = "https://riotinto.worley.com/rtioem-api";
//         break;

//     case "DEV":
//         back_url = "https://riotinto.worley.com/rtioem-api";
//         break;

//     case "STAGE":
//         back_url = "https://riotinto.worley.com/rtioem-api";
//         break;

//     case "PROD":
//         back_url = "https://riotinto.worley.com/rtioem-api";
//         break;

//     default:
//         back_url = "https://riotinto.worley.com/rtioem-api";
//         break;
// }

export const FASTAPI_URL = back_url;
export const LOGO_WORLEY = "https://www.worley.com/~/media/Images/W/Worley-V3/content/news/multimedia-gallery/logos/Worley_Logo_2019_1000x303_RGB.png";
export const LOGO_RIO = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Rio_Tinto.svg/2560px-Rio_Tinto.svg.png";

// HAVE CHANGED THE URI
// export const BACKEND_BASE_URL = 'https://rtio-energymodel.azurewebsites.net/getJSON.php?';
// export const URL_ADMIN_API = 'https://rtioem-dev-admin-api.azurewebsites.net/api'; //  'http://localhost:5000/api'
// export const FASTAPI_URL =  'https://rtioem-api.azurewebsites.net'; // 'http://localhost:5000' //

// export const LOGO_WORLEY = 'https://www.worley.com/~/media/Images/W/Worley-V3/content/news/multimedia-gallery/logos/Worley_Logo_2019_1000x303_RGB.png'
// export const LOGO_RIO = 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Rio_Tinto.svg/2560px-Rio_Tinto.svg.png'

export const GROUPS = {
    ADMIN: "RTIOEM_DEV_ADMINISTRATORS",
    USER: "RTIOEM_DEV_USERS",
};

export const ROLES = {
    ADMIN: "admin",
    VIP: "vip",
    CONTRIB: "contributor",
    REVIEWER: "reviewer",
    ALL: "all",
};

export const ROUTES = {
    HOME: {
        PATH: "/",
        EXACT: true,
        NAME: "home-page",
        TYPE: "menu",
        COMPONENT: HomePage,
        ROLES: [ROLES.ALL],
    },
    EMISSION: {
        PATH: "/dashboard/charts/emissions",
        EXACT: true,
        NAME: "dash-emissions",
        TYPE: "menu",
        COMPONENT: EmissionFigures,
        ROLES: [ROLES.ALL],
    },
    POWER: {
        PATH: "/dashboard/charts/power",
        EXACT: true,
        NAME: "dash-power",
        TYPE: "menu",
        COMPONENT: PowerFigures,
        ROLES: [ROLES.ALL],
    },
    POWER_GEN: {
        PATH: "/dashboard/charts/power&generation",
        EXACT: true,
        NAME: "dash-power-and-generation",
        TYPE: "menu",
        COMPONENT: PowerGeneration,
        ROLES: [ROLES.ALL],
    },
    SOP: {
        PATH: "/dashboard/charts/sop",
        EXACT: true,
        NAME: "dash-sop",
        TYPE: "menu",
        COMPONENT: SOP,
        ROLES: [ROLES.ALL],
    },
    TMM: {
        PATH: "/dashboard/charts/tmm",
        EXACT: true,
        NAME: "dash-tmm",
        TYPE: "menu",
        COMPONENT: TMM,
        ROLES: [ROLES.ALL],
    },
    TECHCOUNT: {
        PATH: "/dashboard/charts/tech-count",
        EXACT: true,
        NAME: "dash-charts-tech-count",
        TYPE: "menu",
        COMPONENT: TechCountChart,
        ROLES: [ROLES.ALL],
    },
    COMPARE_EMISSION: {
        PATH: "/dashboard/compare/emissions",
        EXACT: true,
        NAME: "dash-compare-emissions",
        TYPE: "menu",
        COMPONENT: CompareEmission,
        ROLES: [ROLES.ALL],
    },
    MAP_DEMAND_AZURE: {
        PATH: "/dashboard/map/demand-azure",
        EXACT: true,
        NAME: "dash-map",
        TYPE: "menu",
        COMPONENT: DemandAzure,
        ROLES: [ROLES.ALL],
    },
    // SCENARIO_VIEW: {
    //     PATH: "/tool/scenarios/view",
    //     EXACT: true,
    //     NAME: "tool-scenario-view",
    //     TYPE: "menu",
    //     COMPONENT: ViewScenario,
    //     ROLES: [ROLES.ALL],
    // },
    SCENARIO_MANAGE: {
        PATH: "/tool/scenarios/manage",
        EXACT: true,
        NAME: "tool-scenario-manage",
        TYPE: "menu",
        COMPONENT: ManageScenarios,
        ROLES: [ROLES.ALL],
    },
    SCENARIO_EDIT: {
        PATH: "/tool/scenarios/manage/edit",
        EXACT: true,
        NAME: "tool-scenario-manage-edit",
        TYPE: "menu",
        COMPONENT: SelectEditType,
        ROLES: [ROLES.ALL],
    },
    LIVE_EXPERIMENT: {
        PATH: "/tool/live-experiment",
        EXACT: true,
        NAME: "tool-live-experiment",
        TYPE: "menu",
        COMPONENT: LiveExperiment,
        ROLES: [ROLES.ALL],
    },
    PROFILE_VIEW: {
        PATH: "/tool/profiles/view",
        EXACT: true,
        NAME: "tool-profiles-view",
        TYPE: "menu",
        COMPONENT: ViewProfiles,
        ROLES: [ROLES.ALL],
    },
    PROFILE_EDIT: {
        PATH: "/tool/profiles/edit",
        EXACT: true,
        NAME: "tool-profiles-edit",
        TYPE: "menu",
        COMPONENT: EditProfiles,
        ROLES: [ROLES.ALL],
    },
    TECH_EDIT: {
        PATH: "/tool/tables/tech/edit",
        EXACT: true,
        NAME: "tool-tables-tech-edit",
        TYPE: "menu",
        COMPONENT: EditTech,
        ROLES: [ROLES.ALL],
    },
    TARGET_EMIT_EDIT: {
        PATH: "/tool/target-emissions/target/edit",
        EXACT: true,
        NAME: "tool-target-emissions-target-edit",
        TYPE: "menu",
        COMPONENT: EditTargetEmissions,
        ROLES: [ROLES.ALL],
    },
    BASE_EMIT_EDIT: {
        PATH: "/tool/target-emissions/baseline/edit",
        EXACT: true,
        NAME: "tool-target-emissions-baseline-edit",
        TYPE: "menu",
        COMPONENT: EditTargetEmissions,
        ROLES: [ROLES.ALL],
    },
    RENEW_EDIT: {
        PATH: "/tool/tables/renewable/edit",
        EXACT: true,
        NAME: "tool-tables-renewable-edit",
        TYPE: "menu",
        COMPONENT: EditRenew,
        ROLES: [ROLES.ALL],
    },
    RENEW_SCHEDULE_EDIT: {
        PATH: "/tool/tables/renewable-schedule/edit",
        EXACT: true,
        NAME: "tool-tables-renewable-schedule-edit",
        TYPE: "menu",
        COMPONENT: EditRenewSch,
        ROLES: [ROLES.ALL],
    },
    STORAGE_EDIT: {
        PATH: "/tool/tables/storage/edit",
        EXACT: true,
        NAME: "tool-tables-storage-edit",
        TYPE: "menu",
        COMPONENT: EditStorage,
        ROLES: [ROLES.ALL],
    },
    STORAGE_SCHEDULE_EDIT: {
        PATH: "/tool/tables/storage-schedule/edit",
        EXACT: true,
        NAME: "tool-tables-storage-schedule-edit",
        TYPE: "menu",
        COMPONENT: EditStorageSch,
        ROLES: [ROLES.ALL],
    },
    TMM_SOP_DATA_MANAGER: {
        PATH: "/tool/tmm-sop/data-manager",
        EXACT: true,
        NAME: "tool-tmm-sop-data-manager",
        TYPE: "menu",
        COMPONENT: TmmSopDataManager,
        ROLES: [ROLES.ALL],
    },
};

export const MENU_ITEMS = [
    {
        label: "Home",
        roles: [ROLES.ALL],
        items: [{ label: "Home", icon: "pi pi-fw pi-home", to: "/", roles: [ROLES.ALL] }],
    },
    {
        label: "Dashboard",
        roles: [ROLES.ALL],
        items: [
            {
                label: "Charts",
                icon: "pi pi-fw pi-chart-pie",
                roles: [ROLES.ALL],
                items: [
                    { label: "TMM", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/tmm", roles: [ROLES.ALL] },
                    { label: "SOP", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/sop", roles: [ROLES.ALL] },
                    { label: "Energy Demand", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/power", roles: [ROLES.ALL] },
                    { label: "Power Demand & Generation", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/power&generation", roles: [ROLES.ALL] },
                    { label: "Emissions", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/emissions", roles: [ROLES.ALL] },
                    { label: "Technology Count", icon: "pi pi-fw pi-chart-line", to: "/dashboard/charts/tech-count", roles: [ROLES.ALL] },
                ],
            },
            {
                label: "Maps",
                icon: "pi pi-fw pi-map",
                roles: [ROLES.ALL],
                items: [{ label: "Demand", icon: "pi pi-fw pi-map-marker", to: "/dashboard/map/demand-azure", roles: [ROLES.ALL] }],
            },
            {
                label: "Compare Scenarios",
                icon: "pi pi-fw pi-chart-pie",
                roles: [ROLES.ALL],
                items: [{ label: "Emissions", icon: "pi pi-fw pi-chart-line", to: "/dashboard/compare/emissions", roles: [ROLES.ALL] }],
            },
        ],
    },
    {
        label: "Tools",
        roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
        items: [
            {
                label: "Scenarios",
                icon: "pi pi-fw pi-sliders-v",
                roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
                items: [
                    // { label: "View Pathways", icon: "pi pi-fw pi-eye", to: "/tool/scenarios/view", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Edit Pathways", icon: "pi pi-fw pi-pencil", to: "/tool/scenarios/manage", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                ],
            },
            {
                label: "Profiles",
                icon: "pi pi-fw pi-chart-bar",
                roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
                items: [
                    { label: "View Profiles", icon: "pi pi-fw pi-eye", to: "/tool/profiles/view", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Edit Profiles", icon: "pi pi-fw pi-pencil", to: "/tool/profiles/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                ],
            },
            {
                label: "Tables",
                icon: "pi pi-fw pi-table",
                roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
                items: [
                    { label: "Technologies", icon: "pi pi-fw pi-pencil", to: "/tool/tables/tech/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Renewables", icon: "pi pi-fw pi-pencil", to: "/tool/tables/renewable/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Renewables-Schedule", icon: "pi pi-fw pi-pencil", to: "/tool/tables/renewable-schedule/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Storage", icon: "pi pi-fw pi-pencil", to: "/tool/tables/storage/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                    { label: "Storage-Schedule", icon: "pi pi-fw pi-pencil", to: "/tool/tables/storage-schedule/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] },
                ],
            },
            {
                label: "Target-Emissions",
                icon: "pi pi-fw pi-book",
                roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
                items: [{ label: "Set Targets", icon: "pi pi-fw pi-upload", to: "/tool/target-emissions/target/edit", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] }],
            },
            {
                label: "TMM-SOP",
                icon: "pi pi-fw pi-database",
                roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
                items: [{ label: "Data Manager", icon: "pi pi-fw pi-upload", to: "/tool/tmm-sop/data-manager", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] }],
            },
        ],
    },
    {
        label: "Dynamic Analysis",
        roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB],
        items: [{ label: "Dynamic Analysis", icon: "pi pi-fw pi-chart-bar", to: "/tool/live-experiment", roles: [ROLES.ADMIN, ROLES.VIP, ROLES.CONTRIB] }],
    },
];
