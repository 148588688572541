import React from "react";
import CalculateLoadImg from "../../assets/home/CalculateLoad.png";
import RenewableStorageImg from "../../assets/home/RenewableStorage.png";
import AIPredictorImg from "../../assets/home/AIPredictor.png";
const Methodology = () => {
    const methodologyIntro = (
        <div>
            <h3>
                <b>Methodology</b>
            </h3>
            <p>
                Here is the how the renewable calculation is performed.
                <br />
                <br />
            </p>
            <h4>
                <b>1. Calculating the Load</b>
            </h4>
            <center>
                <br />
                <img src={CalculateLoadImg} style={{ width: "100%" }} alt="Featured" />
                <br />
                <br />
            </center>
            <h4>
                <b>2. Simulating the renewable/storage supply</b>
            </h4>
            <center>
                <br />
                <img src={RenewableStorageImg} style={{ width: "100%" }} alt="Featured" />
                <br />
                <br />
            </center>
            <h4>
                <b>3. AI guideline</b>
            </h4>
            <p>
                Defining the installation schedule (i.e. how much to install and when to install) has a great impact on the outcome of the simulation. To follow a desired scenario, it is not easy to adjust the installation schedule to get close to the scenario without any guidelines in hand. This
                process is usually trial and error. Since we run the simulation on an hourly basis, this may not be convenient for users to make the adjustments manually. Or even get close to desired scenario with few iterations. As such we have run a large number of simulation for different
                scenarios of Grid Dependencies and percentage of Wind/Solar installation. Based on that data, we have trained a number of machine learning (ML) models to predict the installation requirements based on the given load and a scenario in mind. The ML model is a great tool to provide
                users with a guideline to adjust installation schedule.
                <br />
                <br />
            </p>
            <center>
                <img src={AIPredictorImg} style={{ width: "100%" }} alt="Featured" />
                <br />
            </center>
        </div>
    );
    return <>{methodologyIntro}</>;
};

export default Methodology;
