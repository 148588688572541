import React, { useMemo } from "react";
//IMPORT LIBS
//IMPORT COMPONENTS
import HtmlPieChartLayer from "./HtmlPieChartLayer";
//IMPORT STYLES
import "primeflex/primeflex.css";
import "./legend.css";
import * as ST_MAP from "../mapSettings";

import { AzureMap, AzureMapDataSourceProvider, AzureMapsProvider } from "react-azure-maps";
import { AuthenticationType } from "azure-maps-control";

const returnRadius = (value, valueMax) => {
    let scale = 100;
    return parseInt((value / valueMax) * scale) + parseInt(scale * 0.1);
};

const renderPoints = (dataSource, maxValue, field) => {
    return dataSource.map((r) => {
        let size = returnRadius(r[field], maxValue);
        return <HtmlPieChartLayer key={r.SubstationID} radius={size * 2} opacity={0.8} lat={r.Longitude} lng={r.Latitude} text={r[field]} data={r} />;
    });
};

const MapPieChartSupplyDivision = (props) => {
    let view = <h1>There is no data to display</h1>;
    let posCenter = props.dataSource ? [props.dataSource[0].Longitude, props.dataSource[0].Latitude] : [120.9, -26.67];

    const option = useMemo(() => {
        return {
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: process.env.REACT_APP_AZURE_MAP,
            },
            center: posCenter,
            zoom: 6,
            view: "Auto",
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const legend = (
        <div className="legend">
            <table>
                <tr>
                    <td>
                        <div className="color-box" style={{ backgroundColor: ST_MAP.COLORS_PIE_CHART[0] }}></div>
                    </td>
                    <td>Solar</td>
                </tr>
                <tr>
                    <td>
                        <div className="color-box" style={{ backgroundColor: ST_MAP.COLORS_PIE_CHART[1] }}></div>
                    </td>
                    <td>Wind</td>
                </tr>
                <tr>
                    <td>
                        <div className="color-box" style={{ backgroundColor: ST_MAP.COLORS_PIE_CHART[2] }}></div>
                    </td>
                    <td>Grid Power</td>
                </tr>
                <tr>
                    <td>
                        <b>X</b>
                        {/* <div className="color-box" style={{backgroundColor: 'black'}}></div> */}
                    </td>
                    <td>Zero Supply</td>
                </tr>
            </table>
            <span style={{ color: "blue", fontSize: "10px" }}>(size is power supply)</span>
        </div>
    );

    if (props.dataSource) {
        view = (
            <AzureMapsProvider>
                <div style={{ height: "60vh" }}>
                    <AzureMap options={option}>
                        <AzureMapDataSourceProvider id={"MultiplePoint AzureMapDataSourceProvider"}>{renderPoints(props.dataSource, props.maxValue, props.field)}</AzureMapDataSourceProvider>
                        {legend}
                    </AzureMap>
                </div>
            </AzureMapsProvider>
        );
    }

    return <>{view}</>;
};

export default MapPieChartSupplyDivision;
