import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { formatValueData } from "../../../../../../utils/comm";
import HelpResultTable from "../help/HelpResultTable";
import { Space } from "antd";

const ResultRenewable = ({ apiRows, apiSummaryRows, resultFields, selectedSubstation }) => {
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(10);

    const colsToShow = resultFields.filter((r) => r.show);

    const onCustomPage2 = (event) => {
        setFirst2(event.first);
        setRows2(event.rows);
    };

    const apiRowsSub = React.useMemo(() => {
        return apiRows ? apiRows.filter((r) => r.SubstationID === selectedSubstation.SubstationID) : [];
    }, [selectedSubstation, apiRows]);

    const apiSummaryRowsSub = React.useMemo(() => {
        return apiSummaryRows ? apiSummaryRows.filter((r) => r.SubstationID === selectedSubstation.SubstationID) : [];
    }, [selectedSubstation, apiSummaryRows]);

    const template2 = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                        Items per page:{" "}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    const mainBodyTemplate = (rowData, options) => {
        const col = colsToShow.filter((c) => c.param === options.field)[0];

        let val = rowData[options.field];

        if (col === null) {
            console.log("hi");
        }
        if (col.dataFormat) {
            val = formatValueData(val, col.dataFormat);
        }
        return (
            <>
                <span>{val}</span>
            </>
        );
    };

    const colsToDisplay = colsToShow.map((item) => {
        return <Column key={item.param} category="detailed" field={item.param} header={item.title} body={mainBodyTemplate} dataFormat={item.dataFormat} />;
    });

    return (
        <div>
            {apiRowsSub?.length > 0 && (
                <div>
                    <div className="p-grid p-align-center" style={{ marginTop: "25px" }}>
                        <Space>
                            <h3>Table - Totals</h3>
                            <HelpResultTable />
                        </Space>
                    </div>

                    <div>
                        <DataTable value={apiSummaryRowsSub} responsiveLayout="scroll">
                            {colsToDisplay}
                        </DataTable>
                    </div>
                    <div className="p-grid p-align-center" style={{ marginTop: "25px" }}>
                        <Space>
                            <h3>Table - Results By Year</h3>
                            <HelpResultTable />
                        </Space>
                    </div>

                    <div>
                        <DataTable value={apiRowsSub} paginator paginatorTemplate={template2} first={first2} rows={rows2} onPage={onCustomPage2} paginatorClassName="justify-content-end" className="mt-6" responsiveLayout="scroll">
                            {colsToDisplay}
                        </DataTable>
                    </div>
                </div>
            )}
            {apiRowsSub?.length === 0 && <div>After running the simulation, the result will display here</div>}
        </div>
    );
};

export default ResultRenewable;
