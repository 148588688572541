import React, {useState} from 'react';
import PathwayItemTemplate from './PathwayItemTemplate';

import { Divider } from 'primereact/divider';


const ListPathwayItemTemplate = (props) => {

    const [selectedIdx, setSelectedIdx] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    /**
     * @items                   Items in the list.          
     * @lstOfUpdates
     * @lstOfDeletes
     * @isSubmitPage
     * 
     * @editRowPrompt           Implementation of Edit Row Click Button
     * @deleteRow               Implementation of Delete Row Click Button
     * @cancelUpdateDelete      Implementation of Cancel Delete/Update Button
     */


    const onSelection = (rowData, idx) => {
        if ((selectedItem === null) || (selectedItem.PathwayMappingID !== rowData.PathwayMappingID)) {
            setSelectedIdx(idx);
            setSelectedItem(rowData);
            props.onSelection(rowData);
        }        
    }

    return (
        props.items.map((rowData, idx) => {

            const isItemInUpdates = props.lstOfUpdates.filter((r) => r.PathwayMappingID === rowData.PathwayMappingID).length > 0;
            const isItemInDeletes = props.lstOfDeletes.filter((r) => r.PathwayMappingID === rowData.PathwayMappingID).length > 0;
            let item = isItemInUpdates ? props.lstOfUpdates.filter((r) => r.PathwayMappingID === rowData.PathwayMappingID)[0] : null;
            const key = `${rowData.PathwayMappingID}_Pathway`
    
            let backColor = null;
            if ((selectedItem) && (selectedItem.PathwayMappingID === rowData.PathwayMappingID)){
                backColor = 'rgb(0.1,0.1,0.1,0.2)'
            };
    
            return (
                <>
                    <PathwayItemTemplate 
                        key={key}
                        index={idx}
                        selectedIdx={selectedIdx}
                        rowData={item? item : rowData}
                        isItemInUpdates={isItemInUpdates}
                        isItemInDeletes={isItemInDeletes}
                        isSubmitPage={props.isSubmitPage}
                        backColor={backColor}
                        isScenarioLocked={props.isScenarioLocked}
                        selected={onSelection}
                        editRowPrompt={props.editRowPrompt}
                        deleteRow={props.deleteRow}
                        cancelUpdateDelete={props.cancelUpdateDelete}
                    />
                    <Divider />
                </>
            )
        })
    )
}

export default ListPathwayItemTemplate;