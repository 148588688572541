import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import Loading from "../../../../../components/Loading";

import { confirmDialog } from "primereact/confirmdialog";

import * as mngScenAPI from "../../../../../api/energy-model/scenarioServices";
import * as editUtils from "./components/editUtils";
import ProfilePlot from "../../../../../components/profilePlot";
import * as ST from "../../../../../settings/settings";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../../api/auth/userServices";
import RefreshSignIn from "../../../../../components/StartUp/RefreshSignIn";

const SubmitPageScenario = (props) => {
    const history = useHistory();
    const toast = useRef(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [lstExpandView, setLstExpandView] = useState(Array(props.lstOfUpdates.length).fill(false));
    const [expandAllUpdates, setExpandAllUpdates] = useState(false);

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);

    /**
     * @lstOfUpdates
     * @lstOfDeletes
     * @setListOfDeletes
     * @setListOfUpdates
     */

    const onSelection = (rowData, idx) => {
        if (selectedItem === null || selectedItem.PathwayMappingID !== rowData.PathwayMappingID) {
            setSelectedIdx(idx);
            setSelectedItem(rowData);
        }
    };
    const showWarnError = (level,header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const editRowPrompt = () => {};

    const deleteRow = () => {};

    const expandAllItem = (value) => {
        setExpandAllUpdates(value);
        setLstExpandView(Array(props.lstOfUpdates.length).fill(value));
    };

    const expandItem = (value, idx) => {
        let updateExpandLst = lstExpandView.map((r, i) => {
            if (i === idx) {
                return value;
            } else {
                return r;
            }
        });

        setLstExpandView(updateExpandLst);
    };

    const cancelUpdateDelete = (rowData) => {
        editUtils.cancelUpdateDelete([rowData], props.lstOfDeletes, props.lstOfUpdates, props.setListOfUpdates, props.setListOfDeletes, toast);
        setLstExpandView(Array(props.lstOfUpdates.length).fill(false));
    };

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    const updateView = props.lstOfUpdates.map((r, idx) => {
        const key = `${r.PathwayMappingID}_Pathway`;

        let header = (
            <div className="p-grid p-align-center p-justify-between">
                <span>
                    {`Site: ${r.Site} | Function: ${r.Function} | Updates: `}
                    {r.NewProfile !== r.Profile ? (
                        <span style={{ color: "blue" }}>
                            <b>Profile,</b>
                        </span>
                    ) : null}
                    {r.NewTech !== r.Technology ? (
                        <span style={{ color: "blue" }}>
                            <b> Technology</b>
                        </span>
                    ) : null}
                </span>
                <div>
                    <ToggleButton checked={lstExpandView[idx]} onChange={(e) => expandItem(e.value, idx)} onLabel="Collapse" offLabel="Expand" onIcon="pi pi-times" offIcon="pi pi-check" />
                    <Button style={{ marginLeft: "30px" }} tooltip="Cancel Changes" label="Remove" className="p-button-danger p-mr-2" onClick={() => cancelUpdateDelete(r)} />
                </div>
            </div>
        );

        let contentPanel = lstExpandView[idx] ? (
            <React.Fragment>
                <div>
                    {r.NewProfile !== r.Profile ? (
                        <span style={{ color: "blue", margin: "25px" }}>
                            <b> Change in Profile:</b> {`${r.Profile} ---> ${r.NewProfile}`}
                        </span>
                    ) : null}
                    {r.NewTech !== r.Technology ? (
                        <span style={{ color: "blue", margin: "25px" }}>
                            <b> Change in Tech:</b> {`${r.Technology} ---> ${r.NewTech}`}
                        </span>
                    ) : null}
                </div>
                {r.NewProfile !== r.Profile ? <ProfilePlot plotHeight="40vh" originalProfile={r.Profile} selectedProfile={r.NewProfile} target={null} /> : null}
            </React.Fragment>
        ) : null;

        return (
            <div key={key} className="card">
                {header}
                {contentPanel}
            </div>
        );
    });

    const deleteView = props.lstOfDeletes.map((r, idx) => {
        const key = `${r.PathwayMappingID}_Pathway_delete`;

        let header = (
            <div className="p-grid p-align-center p-justify-between">
                <span>{`Site: ${r.Site} | Function: ${r.Function} | Profile: ${r.Profile} | Technology: ${r.Technology}`}</span>
                <div>
                    <ToggleButton checked={lstExpandView[idx]} onChange={(e) => expandItem(e.value, idx)} onLabel="Collapse" offLabel="Expand" onIcon="pi pi-times" offIcon="pi pi-check" />
                    <Button style={{ marginLeft: "30px" }} tooltip="Cancel Changes" label="Remove" className="p-button-danger p-mr-2" onClick={() => cancelUpdateDelete(r)} />
                </div>
            </div>
        );

        let contentPanel = lstExpandView[idx] ? (
            <React.Fragment>
                <ProfilePlot plotHeight="40vh" originalProfile={r.Profile} target={null} />
            </React.Fragment>
        ) : null;

        return (
            <div key={key} className="card">
                {header}
                {contentPanel}
            </div>
        );
    });

    const submitChanges = () => {
        const anyDeletes = props.lstOfDeletes.length > 0;
        const anyUpdates = props.lstOfUpdates.length > 0;

        let dialogMsg = "";
        if (!anyDeletes && !anyUpdates) {
            dialogMsg += "No item is selected for update/delete. Do you wish to go back to Scenario Page?";
        } else {
            if (anyUpdates) {
                dialogMsg += `There are ${props.lstOfUpdates.length} records for updates. `;
            }
            if (anyDeletes) {
                dialogMsg += `There are ${props.lstOfDeletes.length} records for deletes. `;
            }
            dialogMsg += "Do you wish to proceed with submitting the changes?";
        }

        const accept = () => {
            setAccessToken(instance, accounts).then(
                (resolve) => {
                    if (!anyDeletes && !anyUpdates) {
                        history.push(ST.ROUTES.SCENARIO_MANAGE.PATH);
                        return;
                    }

                    if (anyUpdates) {
                        setIsUpdating(true);
                        mngScenAPI.updateMappingPathway(props.lstOfUpdates).then((res) => {
                            // if (!res.hasError) {
                            //     // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: `Submitted records were successfully updated.`, life: 3000 });
                            //     /*Not used*/ alert(`${props.lstOfUpdates.length} records were successfully UPDATED. `);

                            // } else {
                            //     /*Not used*/ alert("Something went wrong: UPDATEs unsuccessful! ");
                            // }
                            setIsUpdating(false);
                        });
                    }

                    if (anyDeletes) {
                        setIsDeleting(true);
                        mngScenAPI.deleteMappingPathway(props.lstOfDeletes).then((res) => {
                            if (!res.hasError) {
                                toast.current.show({ severity: 'info', summary: 'Confirmed', detail: `Submitted records were successfully deleted.`, life: 6000 });
                                setIsDeleting(false);
                            } else {
                                setIsDeleting(false);
                                showWarnError("error","Error","Something went wrong: DELETEs unsuccessful!")
                                // throw new Error("Something went wrong: DELETEs unsuccessful!");
                            }
                        });
                    }

                    if (!isDeleting && !isUpdating) {
                        history.push(ST.ROUTES.SCENARIO_MANAGE.PATH);
                    }
                    setHasToken(true);
                },
                (err) => {
                    setHasToken(false);
                }
            );
        };
        const reject = () => {
            // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        };
        confirmDialog({
            message: dialogMsg,
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };

    const cancelAllChanges = () => {
        if (props.lstOfUpdates.length > 0 || props.lstOfDeletes.length > 0) {
            const accept = () => {
                props.setListOfUpdates([]);
                props.setListOfDeletes([]);
            };
            const reject = () => {
                // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            };
            confirmDialog({
                message: "Are you sure you want to cancel all updates/deletes?",
                header: "Cancel Confirmation",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept,
                reject,
            });
        } else {
        }
    };

    if (isUpdating || isDeleting) {
        return <Loading />;
    }

    return (
        <div>
            <Toast ref={toast} />
            <Accordion activeIndex={0}>
                <AccordionTab header="List of Updates">
                    <ToggleButton style={{ marginBottom: "10px" }} checked={expandAllUpdates} onChange={(e) => expandAllItem(e.value)} onLabel="Collapse All" offLabel="Expand All" onIcon="pi pi-times" offIcon="pi pi-check" />
                    {updateView}
                </AccordionTab>
                <AccordionTab header="List of Deletes">{deleteView}</AccordionTab>
            </Accordion>
            <div className="p-grid p-justify-end" style={{ marginBottom: "20px" }}>
                <Button icon="pi pi-times" label="Cancel All Changes" style={{ margin: "10px" }} onClick={() => cancelAllChanges()} className="p-button-warning" />
                {props.isScenarioLocked ? null : <Button icon="pi pi-check" label="Submit Changes" style={{ margin: "10px" }} onClick={submitChanges} />}
            </div>
        </div>
    );
};

export default SubmitPageScenario;
