import React, {useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import Chart from 'react-apexcharts'
import { Card } from 'primereact/card';
import { PickList } from 'primereact/picklist';
import ProfilePlot from './profilePlot';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
// import {ListBox} from 'primereact/listbox';

const ShowProfile = (props) => {

    const profileEntries = useSelector(state => state.report.lstOfProfileEntries )
    const lstProfiles = useSelector(state => state.report.lstOfProfiles )
    const years = useSelector(state => state.report.years)

    const op = useRef(null);

    // const [showProfileItems, setShowProfileItems] = useState(false)
    const showProfileItems = false;
    const [source, setSource] = useState(useSelector(state => state.report.lstOfProfiles ));
    const [target, setTarget] = useState([]);
    const onChange = (event) => {
      setSource(event.source);
      setTarget(event.target);
  }

  let profileID = null
  let profileEntryFlt = null
  const itemTemplate = (item) => {
    let viewItem = null
    if (showProfileItems) {
      profileID = lstProfiles.filter(r => r.Profile === item.Profile)[0].ProfileID.toString()
      profileEntryFlt = profileEntries.filter(r => r.ProfileID === profileID)
      let dataSourceItem = [{
        name: item.Profile,
        data: profileEntryFlt.map(r => parseFloat(r.Value))
      }]

      var optionsItem = configureItemChart(dataSourceItem, years);
      viewItem = <Chart type='line' options={optionsItem.options} series={optionsItem.series} width="100%" height="100%" />
    }
    
    return (
        <div>
          {item.Profile}
          {viewItem}
        </div>
    );
  }

    if (props) {           
      let dataSource = []
      if (props.originalProfile) {
        profileID = lstProfiles.filter(r => r.Profile === props.originalProfile)[0].ProfileID
        profileEntryFlt = profileEntries.filter(r => r.ProfileID === profileID)
        dataSource.push({
          name: "Original - " + props.originalProfile,
          data: profileEntryFlt.map(r => parseFloat(r.Value))
        })
      }
      if (props.selectedProfile) {
        profileID = lstProfiles.filter(r => r.Profile === props.selectedProfile)[0].ProfileID
        profileEntryFlt = profileEntries.filter(r => r.ProfileID === profileID)
        dataSource.push({
          name: "Selected - " + props.selectedProfile,
          data: profileEntryFlt.map(r => parseFloat(r.Value))
        })
      }
      target.forEach(item => {
        profileID = lstProfiles.filter(r => r.Profile === item.Profile)[0].ProfileID
        profileEntryFlt = profileEntries.filter(r => r.ProfileID === profileID)
        dataSource.push({
          name: item.Profile,
          data: profileEntryFlt.map(r => parseFloat(r.Value))
        })
      })


      // const toggleShowProfile = () => {
      //   setShowProfileItems(!showProfileItems);
      // }

      let viewPickList = null
      if (props.showPickList) {
        viewPickList = (<div className="p-col-5">
          <PickList 
                source={source} 
                target={target} 
                itemTemplate={itemTemplate}
                sourceHeader="Available Profiles" targetHeader="Selected for View"
                showSourceControls={false}
                showTargetControls={false}
                sourceStyle={{ height: `${props.plotHeight}vh` }} targetStyle={{ height: `${props.plotHeight}vh` }}
                onChange={onChange}/>
                </div>)
      }

      let viewContent = (
        <div className="p-grid" style={{marginTop:"20px"}}>
                  
          {viewPickList}
          <div className="p-col-5">
          <Card style={{height: `${props.plotHeight}vh`}}>
            <ProfilePlot 
            plotHeight={`${props.plotHeight - 5}vh`}
            originalProfile = {props.originalProfile} 
            selectedProfile = {props.selectedProfile}
            target = {target}
            />
          </Card>
          </div>
        </div>
      )

       const helpView = (
        <div>
            <Button type="button" style={{maxWidth:'max-content'}} icon="pi pi-info-circle" label='Help' onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" />

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{width: '500px'}} >
            <p>The purpose of the tool is to help users to compare profiles and have an insight of their selected profile. The panel
                  on the left lists all available profiles. The panel on the right lists the profiles that are selected to be included in the graph. Adding profiles to the
                  view list does not mean any actions will be taken on those profiles. It is only for view purposes. By default, usually the original profile of the record and 
                  the selected profile will be displayed in the graph.</p>
            </OverlayPanel>
        </div>
       )

      return (
          <React.Fragment>
            {props.isAccordOn? (
            <Accordion>
              <AccordionTab header="Profile View Tool - Compare Profiles">
                <div className='p-grid p-justify-between'>
                  <span/>
                  {helpView}
                </div>
                {viewContent}
              </AccordionTab>
            </Accordion>): viewContent}               
          </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <h1>The data was not properly loaded to show the profiles</h1>
        </React.Fragment>
      )
    }
    
}

export default ShowProfile;

const configureItemChart = (dataSource, years) => {
  return (
    {  
      series: dataSource,
      options: {
          chart: {
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          },
          stacked: false,
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        colors: ['#F44336'],
        stroke: {
          width: [3],
          curve: 'straight'
        },
        labels: years,
        title: {},
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
      }
    }
  );
}
