import React from "react";
import * as ApiExperiment from "../../../../../../api/energy-model/experimentServices";
import { Dropdown } from "primereact/dropdown";

const DropDownProfiles = ({ trans, pathIdx, selectedPath, onUpdate }) => {
    function getDefaultParams(path, profile) {
        const { StartYear, EndYear, EndValue } = path;

        switch (profile) {
            case "Linear":
                return [];

            case "Exp":
                return [5];

            case "InvExp":
                return [5];

            case "Bilinear":
                return [Math.floor((EndYear - StartYear) / 2) + StartYear, 0.25 * EndValue];

            case "Trilinear":
                const delta = Math.floor((EndYear - StartYear) / 3);
                const x1 = StartYear + delta;
                const x2 = x1 + delta;
                const e1 = 0.1 * EndValue;
                const e2 = 0.9 * EndValue;

                return [x1, x2, e1, e2];

            case "Step":
                return [];

            default:
                return [];
        }
    }

    const lstTemplateProfiles = ["Linear", "Exp", "InvExp", "Bilinear", "Trilinear", "Step", "Custom"];

    const handleChange = (e) => {
        const r = e.target.value;
        let tempData = global.structuredClone(trans);
        tempData.ReplacePaths[pathIdx].Profile = r;
        let params = getDefaultParams(selectedPath, r);

        if (r === "Custom") {
            ApiExperiment.getSingleItemProfileUpdate(selectedPath).then(
                (res) => {
                    let params = res.data.w;
                    tempData.ReplacePaths[pathIdx].ProfileParams = params;
                    onUpdate(tempData);
                },
                (err) => {
                    console.log(err);
                }
            );
        } else {
            tempData.ReplacePaths[pathIdx].ProfileParams = params;
            onUpdate(tempData);
        }
    };

    return <Dropdown style={{ width: "100px", padding: "0px" }} options={lstTemplateProfiles} value={trans.ReplacePaths[pathIdx].Profile} onChange={handleChange} />;
};

export default DropDownProfiles;
