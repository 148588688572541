import React from "react";
import LoadInputsImg from "../../assets/home/LoadInputs.png";
import TransitionProfileGraphImg from "../../assets/home/TransitionProfileGraph.png";
import TechnologySetupImg from "../../assets/home/TechnologySetup.png";
import { Message } from "primereact/message";
const styleMsgBox = {
    width: "80%",
    alignContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
};
const Step1Intro = () => {
    const step1Note = (
        <div>
            <p>
                <b>Note</b>: Whilst Lake Macleod Gypsum operation is handled as separate site in the model, by choosing an electrification pathway the model assumes that the remote gypsum operation is then connected to the Lake MacLeod Salt electric network to supply that electric demand rather than
                the gypsum diesel generator. Any electrical load from either Salt or Gypsum sites feed into a common substation which can be supplied by existing network provider and/or installed renewable energy.
            </p>
            <br />
        </div>
    );

    const techNote = (
        <div>
            <p>
                <b>Note</b>: As this Dynamic Analysis calculation is working at an order of magnitude level, it is only worth overwriting these specific energy calculations if there is significant deviation from the default values.
            </p>
            <br />
        </div>
    );
    const loadInputs = (
        <div>
            <h3>
                <b>Step 1 - Load inputs:</b>
            </h3>
            <h4>
                <b>Transition Pathways</b>
            </h4>
            <p>
                The energy demand (or Load) for DSL site is broken down to individual functions, such as Crystalliser service pumps, Dozers, Hauling and much more. Each of these functions are served by an existing technology based on operational data obtained for 2021. This technology consumes
                either diesel or Electric energy to perform its function and the amount of energy demanded by this technology is proportional to the salt (or gypsum) production. The user is able to add, delete and edit the technology replacement pathway for each function (excluding those that are
                already electric).
                <br />
            </p>
            <center>
                <img src={LoadInputsImg} style={{ width: "100%" }} alt="Featured" />
            </center>
            <p>
                <br />
                <br />
                There are pop out tool tips as you hover over some inputs. When defining or editing the deployment timeline a separate window will pop out allowing the user to view or edit the specific technology deployment timeline. It also shows any other technologies associated with that
                function, such as the existing technology (the one it is replacing) and other replacement technologies. E.g. whilst Battery electric vehicle might ultimately replace the existing technology, Biodiesel may be used during the transition to further reduce emissions.
                <br />
                <br />
            </p>
            <center>
                <img src={TransitionProfileGraphImg} style={{ width: "100%" }} alt="Featured" />
                <br />
                <br />
            </center>
            <div className="p-grid p-justify-center">
                <Message severity="warn" style={styleMsgBox} content={step1Note} />
                <br />
                <br />
                <br />
            </div>
            <h4>
                <br />
                <br />
                <b>Technology setup</b>
            </h4>
            <center>
                <img src={TechnologySetupImg} style={{ width: "100%" }} alt="Featured" />
            </center>
            <div className="p-grid p-justify-center">
                <br />
                <br />
                <Message severity="warn" style={styleMsgBox} content={techNote} />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );

    return <>{loadInputs}</>;
};

export default Step1Intro;
