import * as Api from "axios";
import * as ApiUtils from "../utils/ApiUtils";
import { FASTAPI_URL } from "../../settings/settings";
import * as actionReport from "../../store/report/actionReport";

const prefix = "/scenario";

export const getAllScenariosByProjectID = async (dispatch, projectID) => {
    const tag = "PathwayScenarios";
    let message = "";
    return Api.get(FASTAPI_URL + prefix + `/project?project_id=${projectID}`).then(
        (res) => {
            message += res.status === 200 ? "" : `Error in getting ${tag} data, status: ${res.status}`;
            message += res.data.length === 0 ? "Error: no data is available" : "";
            if (message === "") {
                if (dispatch) {
                    dispatch({
                        type: actionReport.SET_LIST_SCENARIOS,
                        payload: {
                            response: res.data,
                        },
                    });
                }
                return { hasError: false, data: res.data };
            } else {
                return { hasError: true, message: message, data: [] };
            }
        },
        (err) => {
            return { hasError: true, message: `Error occured in getting List of ${tag}: ${err}`, data: [] };
        }
    );
};

export const createScenario = async (s, name, desc) => {
    if (s !== null && name !== null && desc !== null) {
        const reqBody = {
            CopyPathwayScenarioID: parseInt(s.PathwayScenarioID),
            PathwayScenarioName: name.toString(),
            PathwayScenarioDescription: desc.toString(),
        };

        return ApiUtils.POST(FASTAPI_URL + prefix + "/", reqBody);
    } else {
        return { hasError: true, message: "Null values are inputted. Please check a scenario is selected and name and description is not empty!", data: [] };
    }
};

export const deleteScenario = async (s) => {
    return ApiUtils.DELETE(FASTAPI_URL + prefix + `/?scenarioID=${s.PathwayScenarioID}`);
};

export const updateLockHideScenario = async (s, altLock, altHide) => {
    if (s) {
        const currHide = s.Hide;
        const currLock = s.Lock;
        const updateHide = altHide ? !currHide : currHide;
        const updateLock = altLock ? !currLock : currLock;

        const url = `${FASTAPI_URL}${prefix}/lock-hide?scenario_id=${s.PathwayScenarioID}&lock=${updateLock}&hide=${updateHide}`;

        return ApiUtils.PUT(url, null);
    }
    return { hasError: true, message: `Scenario is Null.`, data: [] };
};

export const updateMappingPathway = async (lstOfUpdates) => {
    const reqBody = lstOfUpdates.map((r) => {
        return {
            PathwayMappingID: parseInt(r.PathwayMappingID),
            PathwayScenarioID: parseInt(r.Scenario),
            SiteID: parseInt(r.SiteID),
            FunctionID: parseInt(r.FunctionID),
            TechnologyID: parseInt(r.NewTechId),
            ProfileID: parseInt(r.NewProfileId),
        };
    });

    const urlPut = FASTAPI_URL + prefix + "/pathway-mapping";
    if (lstOfUpdates.length > 0) {
        return ApiUtils.PUT(urlPut, reqBody);
    }
    return { hasError: true, message: "List of updates is empty!", data: [] };
};

export const deleteMappingPathway = async (lstOfDeletes) => {
    let url = FASTAPI_URL + prefix + "/pathway-mapping/?" + lstOfDeletes.map((r) => `pathway_ids=${r.PathwayMappingID}`).join("&");

    if (lstOfDeletes.length > 0) {
        return ApiUtils.DELETE(url);
    }
    return { hasError: true, message: `List of deletes is empty!`, data: [] };
};

export const getPathwayMappingsViewByScenId = async (scenarioId, mineMap, techMap, functionMap, profileMap) => {
    const tag = "PathwayMappings";
    const reqType = "getting";
    if (scenarioId) {
        return Api.get(FASTAPI_URL + prefix + "/pathway-mapping?scenarioId=" + scenarioId.toString()).then(
            (res) => {
                let data = res.data.map((r) => {
                    r["SiteID"] = r.Site;
                    r["FunctionID"] = r.Function;
                    r["TechnologyID"] = r.Technology;
                    r["ProfileID"] = r.Profile;
                    r["Site"] = mineMap[r.Site];
                    r["Function"] = functionMap[r.Function];
                    r["Technology"] = techMap[r.Technology];
                    r["Profile"] = profileMap[r.Profile];
                    return r;
                });
                return ApiUtils.resTagData(res, reqType, tag, data);
            },
            (err) => {
                return ApiUtils.errTag(err, reqType, tag);
            }
        );
    }
    return { hasError: true, message: "Scenario is Null!", data: [] };
};
