import React, { useState, useEffect } from "react";
import { Input, Tooltip } from "antd";

const InputData = ({ trans, pathIdx, selectedPath, onUpdate }) => {
    const [startYr, setStartYr] = useState(selectedPath.StartYear);
    const [endYr, setEndYr] = useState(selectedPath.EndYear);
    const [endVal, setEndVal] = useState(selectedPath.EndValue);

    useEffect(() => {
        setStartYr(selectedPath.StartYear);
        setEndYr(selectedPath.EndYear);
        setEndVal(selectedPath.EndValue);
    }, [trans]);

    const updateTrans = (colName, colValue) => {
        let tempData = global.structuredClone(trans);
        tempData.ReplacePaths[pathIdx][colName] = colValue;
        if (tempData.ReplacePaths[pathIdx].Profile === "Custom") {
            const { StartYear, EndYear } = tempData.ReplacePaths[pathIdx];
            const newParams = [];
            for (let i = StartYear; i <= EndYear; i++) {
                const idx = i - StartYear;
                if (idx < tempData.ReplacePaths[pathIdx].ProfileParams.length) {
                    newParams.push(tempData.ReplacePaths[pathIdx].ProfileParams[idx]);
                } else {
                    newParams.push(0);
                }
            }
            tempData.ReplacePaths[pathIdx].ProfileParams = newParams;
        }
        onUpdate(tempData);
    };

    return (
        <React.Fragment>
            <td>
                <Input size="small" placeholder="start year" value={startYr} key={"1" + pathIdx} onChange={(e) => setStartYr(e.target.value)} onBlur={() => updateTrans("StartYear", parseInt(startYr))} style={{ width: "75px" }} />
            </td>
            <td>
                <Input size="small" placeholder="end year" key={"2" + pathIdx} value={endYr} onChange={(e) => setEndYr(e.target.value)} onBlur={() => updateTrans("EndYear", parseInt(endYr))} style={{ width: "75px" }} />
            </td>
            <td>
                <Input size="small" placeholder="end value" key={"3" + pathIdx} value={endVal} onChange={(e) => setEndVal(e.target.value)} onBlur={() => updateTrans("EndValue", parseFloat(endVal))} style={{ width: "75px" }} />
            </td>
        </React.Fragment>
    );
};

export default InputData;
