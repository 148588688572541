import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

export const updateSingleRow = (selectedRow, lstOfProfiles, selectedProfileName, lstOfTechs, selectedTechName, lstOfUpdates, setListOfUpdates) => {
    let selectedProfInfo = lstOfProfiles.filter((r) => r.Profile === selectedProfileName)[0];
    let selectedTechInfo = lstOfTechs.filter((r) => r.Name === selectedTechName)[0];
    let filtered = lstOfUpdates.filter((r) => r.PathwayMappingID === selectedRow.PathwayMappingID);
    if (filtered.length > 0) {
        let currLst = lstOfUpdates.map((el) =>
            el.PathwayMappingID === selectedRow.PathwayMappingID
                ? {
                      ...el,
                      NewProfile: selectedProfileName,
                      NewProfileId: selectedProfInfo.ProfileID,
                      NewTech: selectedTechName,
                      NewTechId: selectedTechInfo.TechnologyID,
                      EditPage: "single",
                  }
                : el
        );
        setListOfUpdates(currLst);
    } else {
        let newItem = {
            ...selectedRow,
            NewProfile: selectedProfileName,
            NewProfileId: selectedProfInfo.ProfileID,
            NewTech: selectedTechName,
            NewTechId: selectedTechInfo.TechnologyID,
            EditPage: "single",
        };
        setListOfUpdates([...lstOfUpdates, newItem]);
    }
};

export const updateRows = (lstRowData, selectedProfile, selectedTech, lstOfUpdates, setListOfUpdates, lstOfDeletes, setListOfDeletes) => {
    // GET IDS OF THE TARGETED RECORDS AND THE QUEUED RECORDS
    const { lstRowIds, lstUpdateIds, lstDeleteIds } = getListTripleIds(lstRowData, [], lstOfUpdates);

    // SPLIT INTO THREE GROUPS OF RECORDS: NEWLY ADDED, EXISTING ITEMS IN UPDATES WITH NEW CHANGES, EXISTING ITEMS IN UPDATE WITHOUT CHANGE.
    let newAddition = removeRecordsFromQueue(lstUpdateIds, lstRowData).map((r) => {
        return {
            ...r,
            NewProfile: selectedProfile ? selectedProfile.Profile : "NewProfile" in r ? r.NewProfile : r.Profile,
            NewProfileId: selectedProfile ? selectedProfile.ProfileID : "NewProfileId" in r ? r.NewProfileId : r.ProfileID,
            NewTech: selectedTech ? selectedTech.Name : "NewTech" in r ? r.NewTech : r.Technology,
            NewTechId: selectedTech ? selectedTech.TechnologyID : "NewTechId" in r ? r.NewTechId : r.TechnologyID,
        };
    });

    let changingUpdates = getRecordsInQueue(lstRowIds, lstOfUpdates).map((r) => {
        return {
            ...r,
            NewProfile: selectedProfile ? selectedProfile.Profile : "NewProfile" in r ? r.NewProfile : r.Profile,
            NewProfileId: selectedProfile ? selectedProfile.ProfileID : "NewProfileId" in r ? r.NewProfileId : r.ProfileID,
            NewTech: selectedTech ? selectedTech.Name : "NewTech" in r ? r.NewTech : r.Technology,
            NewTechId: selectedTech ? selectedTech.TechnologyID : "NewTechId" in r ? r.NewTechId : r.TechnologyID,
        };
    });

    let noChangeUpdates = removeRecordsFromQueue(lstRowIds, lstOfUpdates);

    setListOfUpdates([...changingUpdates, ...noChangeUpdates, ...newAddition]);
    setListOfDeletes(removeRecordsFromQueue(lstRowIds, lstOfDeletes));
};

export const deleteRows = (lstRowData, lstOfDeletes, lstOfUpdates, setListOfUpdates, setListOfDeletes, toast, setSelectedRow) => {
    const Num = lstRowData.length;
    if (Num === 0) {
        toast.current.show({ severity: "warn", summary: "Deletion", detail: "No record was selected for deletion!", life: 6000 });

        return;
    }

    if (setSelectedRow) {
        setSelectedRow({ ...lstRowData[0] });
    }

    const { lstRowIds, lstUpdateIds, lstDeleteIds } = getListTripleIds(lstRowData, lstOfDeletes, lstOfUpdates);

    // CHECK IF ITEM IS ALREADY SELECTED FOR DELETE
    // const isItemInDeletes = lstOfDeletes.filter((r) => r.PathwayMappingID === rowData.PathwayMappingID).length > 0;
    // if (isItemInDeletes) {
    //    /* Not used */ alert(`This record (ID: ${rowData.PathwayMappingID}) is already queued to be deleted!`);
    //     return;
    // }

    // CHECK IF ITEM IS ALREADY IN LIST OF UPDATES. IF SO REMOVE ITEM FROM LIST OF UPDATES.
    const areItemsInUpdates = getRecordsInQueue(lstRowIds, lstOfUpdates).length > 0;

    let dialogMsg = areItemsInUpdates ? `There are records that are already selected for update.\nAre you sure you want to cancel the updates and add these records to delete list?` : "Are you sure you want to delete these records?";

    const accept = () => {
        if (areItemsInUpdates) {
            setListOfUpdates(removeRecordsFromQueue(lstRowIds, lstOfUpdates));
        }

        setListOfDeletes([...removeRecordsFromQueue(lstRowIds, lstOfDeletes), ...lstRowData]);
        toast.current.show({ severity: "info", summary: "Confirmed", detail: `The selected records are queued for delete!`, life: 3000 });
    };
    const reject = () => {
        // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };
    confirmDialog({
        message: dialogMsg,
        header: "Cancel Confirmation",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
    });
};

export const cancelUpdateDelete = (lstRowData, lstOfDeletes, lstOfUpdates, setListOfUpdates, setListOfDeletes, toast) => {
    const Num = lstRowData.length;
    if (Num === 0) {
        toast.current.show({ severity: "warn", summary: "No record", detail: "No record was selected for deletion!", life: 6000 });
        return;
    }

    const { lstRowIds, lstUpdateIds, lstDeleteIds } = getListTripleIds(lstRowData, lstOfDeletes, lstOfUpdates);
    const deleteItems = getRecordsInQueue(lstRowIds, lstOfDeletes);
    const updateItems = getRecordsInQueue(lstRowIds, lstOfUpdates);

    const totalQueue = deleteItems.length + updateItems.length;

    let dialogMsg = "For this selection, there are no items in queue to cancel!";
    if (totalQueue > 0) {
        dialogMsg = `Are you sure you want to cancel changes for these selections?`;
    }

    const confirmMsg = "The changes for the selected items are discarded.";
    const accept = () => {
        if (deleteItems.length > 0) {
            setListOfDeletes(removeRecordsFromQueue(lstRowIds, lstOfDeletes));
        }

        if (updateItems.length > 0) {
            setListOfUpdates(removeRecordsFromQueue(lstRowIds, lstOfUpdates));
        }

        toast.current.show({ severity: "info", summary: "Confirmed", detail: confirmMsg, life: 3000 });
    };
    const reject = () => {
        // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };
    confirmDialog({
        message: dialogMsg,
        header: "Cancel Confirmation",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
    });
};

export const chkItemsInDelete = async (lstRows, lstDeletes, applyUpdate) => {
    const lstRowIds = getListIds(lstRows);
    const deleteItems = getRecordsInQueue(lstRowIds, lstDeletes);

    if (deleteItems.length > 0) {
        const accept = () => {
            applyUpdate();
        };

        const reject = () => {};
        confirmDialog({
            message: "Some of the items selected for update are already queued for delete. Proceeding with remove these items from the delete list.\nDo you wish to continue?",
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    } else {
        applyUpdate();
    }
};

export const chkItemsInUpdate = (lstRows, lstUpdates, setListOfUpdates, applyDelete) => {
    const lstRowIds = getListIds(lstRows);
    const updateItems = getRecordsInQueue(lstRowIds, lstUpdates);

    const promptMsg = updateItems.length > 0 ? "Some of the items selected for delete are already queued for update. Proceeding with remove these items from the update list.\nDo you wish to continue?" : "Are you sure you want to queue these items for delete?";

    const accept = () => {
        applyDelete();
    };
    const reject = () => {
        // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };
    confirmDialog({
        message: promptMsg,
        header: "Cancel Confirmation",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
    });
};

export const getListIds = (lst) => {
    return lst.map((r) => r.PathwayMappingID);
};

export const getListTripleIds = (lstRowData, lstOfDeletes, lstOfUpdates) => {
    return {
        lstDeleteIds: getListIds(lstOfDeletes),
        lstUpdateIds: getListIds(lstOfUpdates),
        lstRowIds: getListIds(lstRowData),
    };
};

export const getRecordsInQueue = (lstRowIds, lstQueue) => {
    return lstQueue.filter((r) => lstRowIds.includes(r.PathwayMappingID));
};

export const removeRecordsFromQueue = (lstRowIds, lstQueue) => {
    return lstQueue.filter((r) => !lstRowIds.includes(r.PathwayMappingID));
};
