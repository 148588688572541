import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";

const ListFuncsOnProfile = ({ funcsOnMonthly, setFuncsOnMonthly, monthlyProfiles, setSelectedProfile }) => {
    const options = monthlyProfiles !== null && monthlyProfiles.length > 0 ? monthlyProfiles.map((r) => r.MonthlyProfileName) : null;

    const style = { padding: "5px" };

    const onMonthlyProfileChange = (e, idx) => {
        let tmpData = global.structuredClone(funcsOnMonthly);
        let item = global.structuredClone(tmpData[idx]);
        let selectedProfile = monthlyProfiles.filter((r) => r.MonthlyProfileName === e.target.value)[0];
        item.MonthlyProfileName = selectedProfile.MonthlyProfileName;
        item.MonthlyProfileID = selectedProfile.MonthlyProfileID;
        tmpData[idx] = item;
        setFuncsOnMonthly(tmpData);
        setSelectedProfile(selectedProfile);
    };
    return (
        <table style={{ border: "1px solid #EAECEE", borderRadius: "5px" }}>
            <tbody>
                <tr>
                    <th>Function</th>
                    <th>Profile</th>
                </tr>
                {funcsOnMonthly.map((r, idx) => {
                    return (
                        <tr key={`monthly-row-${idx}`}>
                            <td style={style}>{r.Function}</td>
                            <td style={style}>
                                <Dropdown style={{ width: "150px" }} value={r.MonthlyProfileName} options={options} onChange={(e) => onMonthlyProfileChange(e, idx)} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

ListFuncsOnProfile.propTypes = {};

export default ListFuncsOnProfile;
