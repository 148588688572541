export const updateLstOfUpdate = (lstUpdate, newRecord, keyId) => {
    let filtered = lstUpdate.filter((r) => r[keyId] === newRecord[keyId]);
    let currLst = [];
    if (filtered.length > 0) {
        currLst = lstUpdate.map((el) => (el[keyId] === newRecord[keyId] ? { ...newRecord } : el));
    } else {
        currLst = [...lstUpdate, newRecord];
    }

    return currLst;
};

export const range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);

export const formatScientific = (value, format) => {
    if (value) {
        return parseFloat(value).toExponential(format);
    }
    return value;
};

export const formatFloat = (value, format) => {
    if (value) {
        return parseFloat(value).toFixed(format);
    }
    return value;
};

export const formatDate = (value) => {
    if (value) {
        return value.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }
    return value;
};

export const formatCurrency = (value) => {
    if (value) {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }
    return value;
};

export const formatValueData = (val, dataFormat) => {
    switch (dataFormat.type) {
        case "float":
            return formatFloat(val, dataFormat.format);
        case "sci":
            return formatScientific(val, dataFormat.format);
        case "currency":
            return formatCurrency(val);
        case "date":
            return formatDate(val);
        default:
            break;
    }
};

export const extractUnqVals = (data, field) => {
    /**
     * @data        array of objects containing the data
     * @field       target attribute in the object
     */
    return [...new Set(data.map((item) => item[field]))];
};

export const extractYearArray = (data, yearCol) => {
    return extractUnqVals(data, yearCol)
        .sort()
        .map((y) => parseInt(y));
};

export const sortArrayObj = (objs, field) => {
    return objs.sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
};
