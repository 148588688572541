import { FASTAPI_URL } from "../../settings/settings";
import * as ApiUtils from "../utils/ApiUtils";

const prefix = "/energy";

export const getEnergyEmissionCalculations = async (scenarioID, record_id, minYear, maxYear, aggCols, queryCols) => {
    let url = FASTAPI_URL + prefix + `/amount/?scenario=${scenarioID}&site_data_record_id=${record_id}&min_year=${minYear}&max_year=${maxYear}&agg_cols=${aggCols}&query_cols=${queryCols}`;
    return ApiUtils.GET(url, "calculating", "Energy-Emission Calculations");
};

export const getAllocateRenewableStorage = async (s) => {
    let url = FASTAPI_URL + prefix + `/renewable/allocate-storage?scenarioId=${s.PathwayScenarioID}`;
    return ApiUtils.GET(url, "calculating", "renewable storage");
};

export const getRenewableSysTotalByScenarioID = async (scenario) => {
    const url = `${FASTAPI_URL}${prefix}/renewable/sys-total?scenarioId=${scenario.PathwayScenarioID}`;
    return ApiUtils.GET(url, "GET", "RenewablesSysTotalsAnnual");
};

export const getSubstationBalanceByScenarioID = async (scenario) => {
    const url = `${FASTAPI_URL}${prefix}/renewable/substation-balance?scenarioId=${scenario.PathwayScenarioID}`;
    return ApiUtils.GET(url, "GET", "SubstationBalance");
};

export const getAllSubstationBalance = async () => {
    const url = `${FASTAPI_URL}${prefix}/renewable/substation-balance?scenarioId=-1`;
    return ApiUtils.GET(url, "GET", "AllSubstationBalance");
};

export const getAllSystemBalancePowerDemand = async (scenarioId) => {
    let url = `${FASTAPI_URL}${prefix}/power/annual-balance?scenarioId=${scenarioId}`;
    return ApiUtils.GET(url, "GET", "AllSystemBalancePowerDemand");
};

export const getListOfEnergies = async () => {
    return ApiUtils.GET(FASTAPI_URL + prefix + "/types", "GET", "Energies");
};

export const getListOfRenewables = async () => {
    return ApiUtils.GET(FASTAPI_URL + prefix + "/renewable", "GET", "Renewables");
};

export const getListOfStorage = async () => {
    return ApiUtils.GET(FASTAPI_URL + prefix + "/storage", "GET", "Storage");
};

export const getListOfRenewableSchedule = async () => {
    return ApiUtils.GET(FASTAPI_URL + prefix + "/renewable/schedule", "GET", "RenewableSchedule");
};

export const getListOfStorageSchedule = async () => {
    return ApiUtils.GET(FASTAPI_URL + prefix + "/storage/schedule", "GET", "StorageSchedule");
};

// ===============================================================
// MAPPINGS
// ===============================================================
export const getEnergyMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/energy/id-to-name/energy/name", "GET", "EnergyMapName");
};

export const getRenewableTypesMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/energy/id-to-name/renewable-types/name", "GET", "EnergyMapId");
};

export const getRenewablesMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/energy/id-to-name/renewable/name", "GET", "RenewMapName");
};

export const getStorageTypesMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/energy/id-to-name/storage-types/name", "GET", "storageTypes");
};

export const getStorageMapName2Id = async () => {
    return ApiUtils.GET(FASTAPI_URL + "/energy/id-to-name/storage/name", "GET", "storageMapName");
};

// ===============================================================
// CREATE AND UPDATE RENEWABLES
// ===============================================================
export const createRenewables = async (items, substationMap, renewableTypeMap) => {
    let reqBody = items.map((r) => {
        return {
            Tag: r.Tag,
            Substation: parseInt(substationMap[r.Substation]),
            Type: parseInt(renewableTypeMap[r.RenewableType]),
            ratedMW: parseFloat(r.ratedMW),
            CapacityFactor: parseFloat(r.CapacityFactor),
            Available: r.Available ? parseInt(r.Available) : null,
        };
    });

    const url = FASTAPI_URL + prefix + "/renewable";

    return ApiUtils.POST(url, reqBody);
};

export const updateRenewables = async (items, substationMap, renewableTypeMap) => {
    let reqBody = items.map((r) => {
        return {
            RenewableID: parseInt(r.RenewableID),
            Tag: r.Tag,
            Substation: parseInt(substationMap[r.Substation]),
            Type: parseInt(renewableTypeMap[r.RenewableType]),
            ratedMW: parseFloat(r.ratedMW),
            CapacityFactor: parseFloat(r.CapacityFactor),
            Available: r.Available ? parseInt(r.Available) : null,
        };
    });

    const url = FASTAPI_URL + prefix + "/renewable";

    return ApiUtils.PUT(url, reqBody);
};

export const createRenewableSchedule = async (items, renewableMap) => {
    let reqBody = items.map((r) => {
        return {
            Scenario: parseInt(r.Scenario),
            Renewable: parseInt(renewableMap[r.RenewableTag]),
            Year: parseInt(r.Year),
        };
    });

    const url = FASTAPI_URL + prefix + "/renewable/schedule";

    return ApiUtils.POST(url, reqBody);
};

export const updateRenewableSchedule = async (items, renewableMap) => {
    let reqBody = items.map((r) => {
        return {
            RenewableScheduleID: parseInt(r.RenewableScheduleID),
            Scenario: parseInt(r.Scenario),
            Renewable: parseInt(renewableMap[r.RenewableTag]),
            Year: parseInt(r.Year),
        };
    });

    const url = FASTAPI_URL + prefix + "/renewable/schedule";

    return ApiUtils.PUT(url, reqBody);
};

// ===============================================================
// CREATE AND UPDATE STORAGE
// ===============================================================
export const createStorage = async (items, substationMap, storageTypeMap) => {
    let reqBody = items.map((r) => {
        return {
            Tag: r.Tag,
            Substation: parseInt(substationMap[r.Substation]),
            StorageType: parseInt(storageTypeMap[r.StorageType]),
            RatingMW: parseFloat(r.RatingMW),
            CapacityMWh: parseFloat(r.CapacityMWh),
            Available: r.Available ? parseInt(r.Available) : null,
        };
    });

    const url = FASTAPI_URL + prefix + "/storage";

    return ApiUtils.POST(url, reqBody);
};

export const updateStorage = async (items, substationMap, storageTypeMap) => {
    let reqBody = items.map((r) => {
        return {
            StorageID: parseInt(r.StorageID),
            Tag: r.Tag,
            Substation: parseInt(substationMap[r.Substation]),
            StorageType: parseInt(storageTypeMap[r.StorageType]),
            RatingMW: parseFloat(r.RatingMW),
            CapacityMWh: parseFloat(r.CapacityMWh),
            Available: r.Available ? parseInt(r.Available) : null,
        };
    });

    const url = FASTAPI_URL + prefix + "/storage";

    return ApiUtils.PUT(url, reqBody);
};

export const createStorageSchedule = async (items, storageMap) => {
    let reqBody = items.map((r) => {
        return {
            Scenario: parseInt(r.PathwayScenarioID),
            Storage: parseInt(storageMap[r.Tag]),
            Year: parseInt(r.Year),
        };
    });

    const url = FASTAPI_URL + prefix + "/storage/schedule";

    return ApiUtils.POST(url, reqBody);
};

export const updateStorageSchedule = async (items, storageMap) => {
    let reqBody = items.map((r) => {
        return {
            StorageScheduleID: parseInt(r.StorageScheduleID),
            Scenario: parseInt(r.PathwayScenarioID),
            Storage: parseInt(storageMap[r.Tag]),
            Year: parseInt(r.Year),
        };
    });

    const url = FASTAPI_URL + prefix + "/storage/schedule";

    return ApiUtils.PUT(url, reqBody);
};
