import React from "react";
import { Button,Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const BtnAddPath = ({ onUpdate, trans, pathIdx, defaultReplace, startOverallYr, endOverallYr }) => {
    const handleAddItem = () => {
        const tempData = global.structuredClone(trans);
        let content =
            pathIdx >= 0
                ? global.structuredClone(tempData.ReplacePaths[pathIdx])
                : {
                      Profile: "Linear",
                      ProfileParams: [],
                      StartYear: startOverallYr,
                      EndYear: endOverallYr,
                      EndValue: 1,
                      ReplaceTechName: defaultReplace.Name,
                      ReplacedTechID: defaultReplace.TechnologyID,
                  };
        content["status"] = "new";
        tempData.ReplacePaths.push(content);
        onUpdate(tempData);
    };

    return (
        <>
            <Tooltip placement="left" title="Add transition pathway">
                <Button icon={<PlusOutlined />} primary type="text" onClick={handleAddItem} />
            </Tooltip>
        </>
    );
};

BtnAddPath.propTypes = {};

export default BtnAddPath;
