import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux';
import CustomDataTable from '../../../../../components/CustomTable/CustomDataTable'
import Loading from '../../../../../components/Loading';

import EditDialog from './components/editDialog';

import { Panel } from 'primereact/panel';
import { ToggleButton } from 'primereact/togglebutton';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';

import OverlayCheckListBox from '../../../../../components/Filters/CheckListBox/OverlayCheckListBox';

import * as editUtils from './components/editUtils'


const EditBatch = (props) => {

    // =============================================================
    // 1. GET DATA, SET STATES
    // =============================================================

    let lstOfFunctions = useSelector((state) => state.report.lstOfFunctions);
    let lstOfSites = useSelector(state => state.report.lstOfMines);
    let lstOfProfiles = useSelector(state => state.report.lstOfProfiles);
    let lstOfTechs = useSelector(state => state.report.lstOfTechnologies)

    const toast = useRef(null);


    lstOfFunctions = lstOfFunctions.map(r => {
        return {...r, checked:true}
    });

    lstOfSites = lstOfSites.map(r => {
        return {...r, checked:true}
    });

    lstOfProfiles = lstOfProfiles.map(r => {
        return {...r, checked:true}
    });


    const [funcs, setFuncs] = useState(lstOfFunctions);
    const [profs, setProfs] = useState(lstOfProfiles);
    const [sites, setSites] = useState(lstOfSites);
    const [showEditDiag, setShowEditDiag] = useState(false);
    const [editField, setEditField] = useState("");
    const [showOrigVals, setShowOrigVals] = useState(false);

    const [toggled, setToggled] = useState(false);

    let dataSource = null;
    if (props.scenarioMappings) {
        
        let updatedScenarioMapp = props.scenarioMappings.map(r => {
            let flt = props.lstOfUpdates.filter(u => u.PathwayMappingID === r.PathwayMappingID);
            if (flt.length > 0) {
                return {...flt[0]}
            } else {
                return {...r}
            }
        })

        dataSource = toggled ? [...props.lstOfUpdates, ...props.lstOfDeletes] : updatedScenarioMapp;

        let selectedFuncs = funcs.filter(f => f.checked).map(r => r.FunctionID);
        let selectedProfs = profs.filter(f => f.checked).map(r => r.ProfileID);
        let selectedSites = sites.filter(f => f.checked).map(r => r.SiteID);
        
        dataSource = dataSource.filter(m => (
                selectedFuncs.includes(m.FunctionID) && 
                selectedSites.includes(m.SiteID) && 
                selectedProfs.includes(m.ProfileID)
            )
        );
    }

    // =============================================================
    // 2. VIEW DATA - TABLE
    // =============================================================
    const bodyTemplate = (rowData, options) => {

        let field = options.column.props.header;

        const isInUpdates = editUtils.getRecordsInQueue([rowData.PathwayMappingID], props.lstOfUpdates).length > 0;
        const isInDeletes = editUtils.getRecordsInQueue([rowData.PathwayMappingID], props.lstOfDeletes).length > 0;

        let isChanged = false;
        let colNew = null;
        if (isInUpdates) {
            switch (field) {
                case 'Profile':
                    isChanged = ("NewProfile" in rowData) && (rowData.NewProfile !== rowData.Profile);
                    colNew = "NewProfile";
                    break;
    
                case 'Technology':
                    isChanged = ("NewTech" in rowData) && (rowData.NewTech !== rowData.Technology);
                    colNew = "NewTech";
                    break;
    
                default:
                    break;
            }
        }
        
        let color = isChanged? 'blue' : 'black'; 
        color = isInDeletes? 'red' : color;
        let oldVal = rowData[field];
        let newVal = isChanged? rowData[colNew] : oldVal;

        let badge = (showOrigVals && isChanged)? <Badge value={oldVal} severity="info" style={{ margin: "2px"}} /> : null

        return (
            <div>
                <span style={{color:color}}>{newVal}</span>
                {badge}
            </div>
        ) 
    }
    
    let margin = "10px";
    let btnWidth = "150px";

    let columns = [
        // {name: 'PathwayScenarioID', label: 'Scenario ID', filter: false, type: 'dropdown'},
        // {name: 'Mine', label: 'Mine', filter: false, type: 'dropdown'}, 
        // {name: 'Function', label: 'Function', filter: false, type: 'dropdown' }, 
        {name: 'Site', customCol: <Column key="SiteCol" header="Site" sortable={false} body={bodyTemplate} />}, 
        {name: 'Function', customCol: <Column key="FuncCol" header="Function" sortable={false} body={bodyTemplate} />}, 
        {name: 'Technology', customCol: <Column key="TechCol" header="Technology" sortable={false} body={bodyTemplate} />}, 
        {name: 'Profile', customCol: <Column key="ProfCol" header="Profile" sortable={false} body={bodyTemplate} />},
        // {name: 'Action', customCol: actionCol, type: 'dropdown'}
    ]
    
    let VIEW = <Loading />;
    if (dataSource) {        
        VIEW = (
            <div>
                <div className='p-grid'>
                    <ToggleButton style={{margin: margin}} className='p-button-raised' 
                    checked={toggled} onChange={(e) => setToggled(e.value)} 
                    onLabel="Show All" offLabel="Show Updates" onIcon="pi pi-filter-slash" offIcon="pi pi-filter" />
                    <ToggleButton  style={{margin: margin}} className='p-button-raised' 
                    checked={showOrigVals} onChange={(e) => setShowOrigVals(e.value)} 
                    onLabel="Hide Original Values" offLabel="Show Original Values" onIcon="pi pi-times" offIcon="pi pi-check" />
                </div>
                
                <CustomDataTable 
                    data={dataSource}
                    columns={columns}
                    tableType="normal"
                    IdCol='PathwayMappingID' />
            </div>
        
        );
    } else {
        VIEW = <h1>There is no data to display!</h1>;
    }

    
    // =============================================================
    // 3. FILTERS
    // =============================================================
    let FILTERS = (
        <Panel header="Filters">
            <span style={{margin: margin}}>
                <OverlayCheckListBox btnLabel="Sites" btnWidth={btnWidth} filter allItem items={sites} maxHeight='400px' IdField="SiteID" labelField="Site" setItems={setSites}/>
            </span>
            <span style={{margin: margin}}>
                <OverlayCheckListBox btnLabel="Functions" btnWidth={btnWidth} filter allItem items={funcs} maxHeight='400px' IdField="FunctionID" labelField="Function" setItems={setFuncs}/>
            </span>
            <span style={{margin: margin}}>
                <OverlayCheckListBox btnLabel="Profiles"  btnWidth={btnWidth} filter allItem items={profs} maxHeight='400px' IdField="ProfileID" labelField="Profile" setItems={setProfs}/>
            </span>            
        </Panel>
    )

    
    // =============================================================
    // 4. ACTIONS
    // =============================================================
    const setProfileAct = () => {
        setShowEditDiag(true);
        setEditField('profile');
    };

    const setTechAct = () => {
        setShowEditDiag(true);
        setEditField('tech');
    };

    const setDeleteAct = () => {
        editUtils.deleteRows(
            dataSource, 
            props.lstOfDeletes,
            props.lstOfUpdates,
            props.setListOfUpdates,
            props.setListOfDeletes,
            toast,
            null
        )
    };

    const setCancelAct = () => {
        editUtils.cancelUpdateDelete(dataSource, props.lstOfDeletes, props.lstOfUpdates, props.setListOfUpdates, props.setListOfDeletes, toast);
    }

    

    let ACTIONS = (
        <>
            <Panel header="َActions">
                <div>
                    < Button style={{margin: margin, width: btnWidth}} label='Set Profile' icon='pi pi-pencil' onClick={setProfileAct}
                    className='p-button-secondary p-button-raised p-button-outlined' disabled={props.isScenarioLocked}/>
                </div>
                <div >
                    < Button label='Set Technology' style={{margin: margin, width: btnWidth}} icon='pi pi-pencil' onClick={setTechAct}
                    className='p-button-secondary p-button-raised p-button-outlined' disabled={props.isScenarioLocked}/>
                </div> 
                <div>
                    < Button label='Cancel Changes' style={{margin: margin, width: btnWidth}} icon='pi pi-times' onClick={setCancelAct}
                    className='p-button-warning p-button-raised p-button-outlined' disabled={props.isScenarioLocked}/>
                </div> 
                <div>
                    < Button label='Delete Records' style={{margin: margin, width: btnWidth}} icon='pi pi-trash' onClick={setDeleteAct}
                    className='p-button-danger p-button-raised p-button-outlined' disabled={props.isScenarioLocked}/>
                </div>                          
            </Panel>
        </>
    )


    let DIAG = ((dataSource) && (dataSource.length > 0))? <EditDialog 
        showEditDiag={showEditDiag}
        setShowEditDiag={setShowEditDiag}
        lstOfUpdates={props.lstOfUpdates}
        lstOfDeletes={props.lstOfDeletes}
        setListOfUpdates={props.setListOfUpdates}
        setListOfDeletes={props.setListOfDeletes}
        lstOfProfiles={lstOfProfiles}
        lstOfTechs={lstOfTechs}
        editField={editField}
        lstRows={dataSource}/> : null;


    // =============================================================
    // 5. LAYOUT
    // =============================================================
    return (
        <div className='p-grid'>
            <Toast ref={toast} />
            <div style={{marginRight:"25px"}}>
                {FILTERS}
                {ACTIONS}
            </div>
            <div>
                <Card className="p-grid p-justify-center p-align-center" title="Scenario Pathways" style={{ width: "max-content" }}>
                    {VIEW}
                </Card>
            </div>
            {DIAG}
        </div>
    );
}

export default EditBatch;
