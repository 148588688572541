import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import * as actionTypeReport from "../../../store/report/actionReport";

const ScenarioDropdown = (props) => {
    const lstOfScenarios = useSelector((state) => state.report.lstOfScenarios);
    const selectedScenario = useSelector((state) => state.report.selectedScenario);
    const dispatchScenario = useDispatch();

    const handleChange = (e) => {
        let selecScenario = lstOfScenarios.filter((s) => s.PathwayScenarioName === e.target.value)[0];
        dispatchScenario({ type: actionTypeReport.SELECT_SCENARIO, payload: { response: selecScenario } });

        props.onChange(selecScenario); // Extra implementation of onChange event in parent component.
    };

    return <Dropdown style={{ marginLeft: "0px" }} optionLabel="PathwayScenarioName" optionValue="PathwayScenarioName" value={selectedScenario.PathwayScenarioName} options={lstOfScenarios} onChange={handleChange} placeholder="Select a Scenario" />;
};

export default ScenarioDropdown;
