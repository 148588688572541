import React, { useState, useEffect } from "react";
import { Input } from "antd";

const InputData = ({ renewable, rowIdx, renewableTable, onUpdate }) => {
    const style = { textAlign: "left", padding: "1px" };
    const [installedYear, setInstalledYear] = useState(renewable.InstalledYear);
    const [solarCap, setSolarCap] = useState(renewable.SolarCap);
    const [windCap, setWindCap] = useState(renewable.WindCap);
    const [storageCap, setStorageCap] = useState(renewable.StorageCap);

    useEffect(() => {
        setInstalledYear(renewable.InstalledYear);
        setSolarCap(renewable.SolarCap);
        setWindCap(renewable.WindCap);
        setStorageCap(renewable.StorageCap);
    }, [renewable]);

    const updateTrans = (colName, colValue) => {
        let tempData = global.structuredClone(renewableTable);
        tempData[rowIdx][colName] = colValue;
        onUpdate(tempData);
    };

    return (
        <>
            <td style={style}>
                <Input value={installedYear} onChange={(e) => setInstalledYear(e.target.value)} onBlur={() => updateTrans("InstalledYear", parseInt(installedYear))} />
            </td>
            <td style={style}>
                <Input value={solarCap} onChange={(e) => setSolarCap(e.target.value)} onBlur={() => updateTrans("SolarCap", parseFloat(solarCap))} />
            </td>
            <td style={style}>
                <Input value={windCap} onChange={(e) => setWindCap(e.target.value)} onBlur={() => updateTrans("WindCap", parseFloat(windCap))} />
            </td>
            <td style={style}>
                <Input value={storageCap} onChange={(e) => setStorageCap(e.target.value)} onBlur={() => updateTrans("StorageCap", parseFloat(storageCap))} />
            </td>
        </>
    );
};

export default InputData;
