import React, { useEffect, useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import CustomDataTable from '../../../../components/CustomTable/CustomDataTable';
import { confirmDialog } from "primereact/confirmdialog";
import ItemDiag from './itemDialog';
import VersionDiag from './versionDialog';
import { Toast } from "primereact/toast";
import * as actionTypeReport from '../../../../store/report/actionReport';
import * as commUtils from '../../../../utils/comm';
import { Column } from 'primereact/column';
import TargetVerDropdown from '../../../../components/Filters/Dropdowns/TargetVerDropdown';

//IMPORT STYLES
import "primeflex/primeflex.css";

import * as ApiEmit from '../../../../api/energy-model/emissionServices';

const marginBig = {margin: '10px'}
const marginMed = {margin: '5px'}

const TargetEmissions = (props) => {
    const toast = useRef(null);
    const [targetData, setTargetData] = useState(null);
    const [dataView, setDataView] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasErrDB, setHasErrDB] = useState(false);
    const [showDiag, setShowDiag] = useState(false);
    const [showDiagVer, setShowDiagVer] = useState(false);
    const [item, setItem] = useState(null);
    const [mode, setMode] = useState(null);
    const [version, setVersion] = useState(null);
    
    const lstOfTargetVers = useSelector(state => state.report.lstOfTargetEmitVersions);
    const selectedTargetVer = useSelector(s => s.report.selectedTargetEmitVersion);  
    const selectedUserAccess = useSelector(s => s.user.selectedUserAccess)
    const dispatch = useDispatch();

    
    const handleVersionChange = (tarVer) => {
        updateDataByFilter(targetData, tarVer);
    }

    const editRowPrompt = (e,row) => {
        setItem(row);
        setMode('edit')
        setShowDiag(true);
    }

    const newRowPrompt = (e) => {
        
        setItem({
            VersionID: selectedTargetVer.VersionID,
            Year: 2050,
            ActualValue: 0.0,
            Reduction: 100,
            TargetType: 'Reduction'
        });
        setMode('new')
        setShowDiag(true);
    }

    const newVersionPrompt = (e) => {
        setVersion({
            VersionName: 'New Version',
            VersionDescription: 'New Description'
        })
        setMode('new')
        setShowDiagVer(true);
    }

    const editVersionPrompt = (e) => {
        setVersion(selectedTargetVer)
        setMode('edit')
        setShowDiagVer(true);
    }

    const deleteVersion = () => {
        const accept = () => {
            ApiEmit.deleteTargetEmissionPrimary(selectedTargetVer).then(
                res => {
                    ApiEmit.getAllTargetEmissionsPrimaryByProjectID(selectedUserAccess.ProjectID).then(
                        res2 => {
                            dispatch({type: actionTypeReport.SET_LIST_TARGET_EMIT_VER, payload: {response: res2.data}});
                            dispatch({type: actionTypeReport.SELEC_TARGET_EMIT_VER, payload: {response: res2.data.length>0? res2.data[0] : null}})
                            updateDataSource(res2.data[0]);
                        },
                        err2 => {}
                    )
                },
                err => {}
            )
        };
        const reject = () => {
            // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        };
        confirmDialog({
            message: 'Are you sure you want to delete this target version?',
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    }


    const deleteRow = (e,row) => {
        const accept = () => {
            ApiEmit.deleteTargetEmission(row.ID).then(
                res => {
                    updateDataSource();
                },
                err => {}
            )
        };
        const reject = () => {
            // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        };
        confirmDialog({
            message: 'Are you sure you want to delete this target Item?',
            header: "Cancel Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    }


    const actionColumn = (row) => {
        return (
            <div className='p-grid p-align-center'>
                <Button icon="pi pi-pencil" tooltip="Edit Item" className="p-button-rounded p-button-success p-mr-2" onClick={(e) => editRowPrompt(e,row)} />
                <Button icon="pi pi-trash" tooltip="Delete Item" className="p-button-rounded p-button-danger p-mr-2" onClick={(e) => deleteRow(e,row)} />
            </div>
        )
    }
    
    

    useEffect(() => {
        updateDataSource();
    }, []);


    const updateDataByFilter = (data, targetVer) => {
        // if (data !== null && targetVer !== null) {
            
        // }
        setDataView(data.filter(r => r.VersionID === parseInt(targetVer.VersionID)))
    }

    const updateDataSource = (selecTarVer) => {
        setIsLoading(true);
        ApiEmit.getAllTargetEmissionsByProjectID(selectedUserAccess.ProjectID).then((r) => {
            if (!r.hasError) {

                setTargetData(commUtils.sortArrayObj(r.data, 'VersionID'));
                updateDataByFilter(r.data, selecTarVer? selecTarVer:selectedTargetVer);
            } else {
                toast.current.show({severity: 'error', summary: 'Error', detail: r.message,life:6000});
                setHasError(true);
            }
            setIsLoading(false);
        });
    };


    let columns = [
        // {name: 'ScenarioID', label: 'ScenarioID', sortable: true, filter: true, type: 'normal'},
        {name: 'Year', label: 'Year', sortable: false, filter: true, type: 'normal'},
        {name: 'TargetType', label: 'Target Type', sortable: false, filter: true, type: 'normal'},
        {name: 'Reduction', label: 'Reduction (%)', sortable: false, filter: true, type: 'normal'},
        {name: 'ActualValue', label: 'ActualValue (tones)', sortable: false, filter: true, type: 'normal'},
        {customCol: <Column header="Action" body={actionColumn}></Column>},
    ]
    

    return (
    <div className='card'>
        <Toast ref={toast} />
        <div className='p-grid p-align-center' style={marginBig}>
            <h5 style={marginBig}>Target Version: </h5>
            <TargetVerDropdown onChange={handleVersionChange}/>
            <Button style={marginBig} onClick={newVersionPrompt}>Create New</Button>
            <Button style={marginBig} className='p-button-success' onClick={editVersionPrompt}>Edit</Button>
            <Button style={marginBig} className='p-button-danger' onClick={deleteVersion}>Delete</Button>
        </div>
        <div className='p-align-center p-justify-center'>
            <CustomDataTable 
                data={dataView}
                columns={columns}
                showOrgVals={false}
                tableType="normal"
                />
            <Button style={marginMed} onClick={newRowPrompt}>Create New Target Item</Button>
            <ItemDiag 
                showDialog={showDiag}
                setShowDialog={setShowDiag}
                item={item}
                setItem={setItem}
                mode={mode}
                updateDataSource={updateDataSource}
            />
            <VersionDiag 
                showDialog={showDiagVer}
                setShowDialog={setShowDiagVer}
                mode={mode}
                version={version}
                setVersion={setVersion}
                updateDataSource={updateDataSource}
            />
        </div>
    </div>
    )
}

export default TargetEmissions;