import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import LineChart from "../../../../../../components/Charts/LineChart";
import { range } from "../../../../../../utils/comm";
import { Space, Spin } from "antd";
import { aggregateNestedArray } from "../../../../../../api/energy-model/experimentServices";
import { COLOR_MAP_DISTINCT_2 } from "../../../../../../store/constant";

const queryCol = "emit_tCO2";

// const prepareChartDataSource = (data, years, splitBy) => {
//     let dataSource = [];
//     if (data) {
//         data.forEach((res) => {
//             let dataCur = [];

//             years.forEach((yr) => {
//                 const idx = res.years.indexOf(yr);

//                 let E_tot = idx < 0 ? null : 0.0;
//                 if (idx >= 0) {
//                     res.result.forEach((r) => {
//                         E_tot += r.energy_demand[idx];
//                     });
//                 }
//                 dataCur.push(E_tot);
//             });

//             dataSource.push({
//                 name: res.dateTime,
//                 // type: 'area',
//                 data: dataCur,
//             });
//         });
//     }
//     return dataSource;
// };

function ResultEmissions({ apiEmissionResults, startOverallYr, endOverallYr }) {
    const mapFuncs = useSelector((s) => s.report.functionMap);
    const options = [
        { name: "Energy Type", colName: "EnergyID", map: { 1: "Diesel", 2: "Grid", 6: "Bio Diesel" } },
        { name: "Functions", colName: "FunctionID", map: mapFuncs },
        { name: "Substations", colName: "SubstationID", map: { 23: "Dampier", 24: "Port Hedland", 25: "Lake MacLeod" } },
    ];

    const [selectedOpt, setSelectedOpt] = React.useState(options[0]);
    const [isApiCalling, setIsApiCalling] = React.useState(false);
    const [chartData, setChartData] = React.useState(null);

    const years = React.useMemo(() => {
        return range(startOverallYr, endOverallYr + 1);
    }, [startOverallYr, endOverallYr]);

    React.useEffect(() => {
        setIsApiCalling(true);
        aggregateNestedArray(apiEmissionResults, queryCol, [selectedOpt.colName]).then(
            (res) => {
                if (!res.hasError) {
                    const dataSource = res.data.agg_res.map((r) => {
                        if (selectedOpt.map) {
                            return {
                                data: r[queryCol],
                                name: selectedOpt.map[r[selectedOpt.colName]],
                            };
                        } else {
                            return {
                                data: r[queryCol],
                                name: r[selectedOpt.colName],
                            };
                        }
                    });

                    setChartData(dataSource);

                    setIsApiCalling(false);
                }
            },
            (err) => {
                setIsApiCalling(false);
            }
        );
    }, [selectedOpt, apiEmissionResults]);

    if (chartData === null) {
        return <h3>Chart Data Not Available</h3>;
    }

    return (
        <>
            <div className="p-grid p-align-center">
                <Space>
                    <span>Split by</span>
                    <Dropdown options={options} value={selectedOpt} optionLabel="name" onChange={(e) => setSelectedOpt(e.target.value)} />
                </Space>
            </div>
            <div style={{ height: "60vh" }} className="p-grid p-align-center p-justify-center">
                {" "}
                {isApiCalling ? <Spin /> : <LineChart dataSource={chartData} xValues={years} height="100%" yLabel="kt-CO2-e" stacked type="area" scale={1000} colors={COLOR_MAP_DISTINCT_2} />}
            </div>
        </>
    );
}

export default ResultEmissions;
