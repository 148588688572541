import React from "react";
import { Button } from "primereact/button";
import Papa from "papaparse";

const ImportCSV = (props) => {
    const import_csv = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const text = e.target.result;
                let arr = Papa.parse(text).data;
                props.onImport(arr);
            } catch (err) {
                throw new Error(err);
            }
        };
        const metafile = reader.readAsText(event.target.files[0]);
        props.setFileMeta(metafile);
    };

    return (
        <Button className={props.btnClassName} style={props.btnStyle ? props.btnStyle : { width: "auto", padding: 0, marginTop: "10px" }}>
            <label style={props.lblStyle ? props.lblStyle : { zIndex: 500, padding: "10px", cursor: "pointer" }}>
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".csv"
                    onClick={(e) => {
                        e.target.value = null;
                    }}
                    onChange={(e) => import_csv(e)}
                />
                {props.label}
            </label>
        </Button>
    );
};

export default ImportCSV;
