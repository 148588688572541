import React, {useState, useMemo} from 'react';
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';

import * as ST from "../../../../settings/settings"

const ListProfiles = (props) => {
    /**
     * @items           list of items. The items should include the attribute "checked"
     * @setItems        React Hook function to set state of items
     * @IdField         Attribute of item to be used as Id
     * @labelField      Attribute of item to be displayed as label.
     * @filter          include filter field
     * @maxHeight       maximum height of the check listbox
     * @allItem         true/false, set to include all items or not.
    */

     const [selectedIdx, setSelectedIdx] = useState(null);
     const [selectedItem, setSelectedItem] = useState(null);


    const [searchTxt, setSearchTxt] = useState("");


    let search = (
        <span className="p-input-icon-left" style={{marginBottom:'15px', width:'100%'}}>
            <i className="pi pi-search" />
            <InputText  style={{width:'100%'}} value={searchTxt} onChange={(e) => setSearchTxt(e.target.value)} placeholder="Search"/>
        </span>
    )


    const onItemSelection = (item,idx) => {
        if ((selectedItem === null) || (selectedItem.ProfileID !== item.ProfileID)) {
            setSelectedIdx(idx);
            setSelectedItem(item);
            props.setSelectedProfile(item)
        }
    }


    const view = useMemo(() => {
        return props.items.map((item,idx) => {
            if ((searchTxt === "") || item[props.labelField].toLowerCase().includes(searchTxt)) {
                const isLocked = item.Lock;
                const icon = isLocked ? <i className="pi pi-lock" /> : <i className="pi pi-lock-open" />;
                const toolTip = isLocked ? "Click to unlock profile" : "Click to lock profile";
                let lockUnLockBtn = icon;
                if ([ST.ROLES.ADMIN, ST.ROLES.VIP].includes(props.role.toLowerCase())) {
                    if (props.isLockLoading) {
                        lockUnLockBtn = <Button className="p-button-rounded p-button-text p-button-warning" loading/>
                    } else {
                        lockUnLockBtn = <Button icon={icon} className="p-button-rounded p-button-text p-button-warning" style={{ zIndex: 500 }} tooltip={toolTip} 
                        onClick={() => props.onLockUnLock(item)} /> 
                    }
                }

                let backColor = null;
                if ((selectedItem) && (item.ProfileID === selectedItem.ProfileID)) {
                    backColor = 'rgb(0.1,0.1,0.1,0.2)';
                }

                return (
                    <div key={`profile_list_item_${idx}`} className="p-grid p-align-center" >
                        {lockUnLockBtn}
                        <span style={{margin: "5px", width: 'auto', padding: "10px", backgroundColor: backColor, borderRadius: "5px", cursor:'pointer'}} onClick={(e) => onItemSelection(item,idx)}>{item.Profile}</span>
                    </div>
                )
            } else {
                return null;
            }
            
        })
    }, [props.items, props.selectedProfile, searchTxt]) 

    // const view = (
    //     props.items.map((item,idx) => {
    //         if ((searchTxt === "") || item[props.labelField].toLowerCase().includes(searchTxt)) {
    //             const isLocked = item.Lock;
    //             const icon = isLocked ? <i className="pi pi-lock" /> : <i className="pi pi-lock-open" />;
    //             const toolTip = isLocked ? "Click to unlock profile" : "Click to lock profile";
    //             let lockUnLockBtn = icon;
    //             if ([ST.ROLES.ADMIN, ST.ROLES.VIP].includes(props.role.toLowerCase())) {
    //                 if (props.isLockLoading) {
    //                     lockUnLockBtn = <Button className="p-button-rounded p-button-text p-button-warning" loading/>
    //                 } else {
    //                     lockUnLockBtn = <Button icon={icon} className="p-button-rounded p-button-text p-button-warning" style={{ zIndex: 500 }} tooltip={toolTip} 
    //                     onClick={() => props.onLockUnLock(item)} /> 
    //                 }
    //             }

    //             let backColor = null;
    //             if ((selectedItem) && (item.ProfileID === selectedItem.ProfileID)) {
    //                 backColor = 'rgb(0.1,0.1,0.1,0.2)';
    //             }

    //             return (
    //                 <div className="p-grid p-align-center" >
    //                     {lockUnLockBtn}
    //                     <span style={{margin: "5px", width: 'auto', padding: "10px", backgroundColor: backColor, borderRadius: "5px", cursor:'pointer'}} onClick={(e) => onItemSelection(item,idx)}>{item.Profile}</span>
    //                 </div>
    //             )
    //         } else {
    //             return null;
    //         }
            
    //     })
    // )

//className='p-grid'
    return (
        <div>
            {props.filter? search : null}
            <div style={{maxHeight:props.maxHeight, width:'100%', border: '2px solid rgb(0.1,0.1,0.1,0.2)', borderRadius:'5px', overflowY: 'scroll'}}>         
                {view}
            </div>
        </div>        
    )
}

export default ListProfiles;
