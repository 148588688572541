import React from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import TechTable from "./TechTable";

function TechnologyInputs({ techs, setTechs }) {
    const lstOfFunctions = useSelector((s) => s.report.lstOfFunctions);

    return (
        <Accordion multiple>
            {lstOfFunctions.map((func) => {
                const groupedTechs = techs.filter((r) => r.Function === func.FunctionID);
                return (
                    <AccordionTab header={func.Function}>
                        <TechTable groupedTechs={groupedTechs} allTechs={techs} setTechs={setTechs} />
                    </AccordionTab>
                );
            })}
        </Accordion>
    );
}

export default TechnologyInputs;
