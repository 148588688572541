import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

export default function ErrorPage({ msg }) {
    const history = useHistory();

    function handleReload() {
        window.location.reload();
    }

    function handleGoHome() {
        history.push("/");
    }

    function handleGoBack() {
        window.history.back();
    }

    return (
        <>
            <p>Sorry, an unexpected error occurred.</p>
            <p>
                Error message: <i>{msg}</i>
            </p>
            <div>
                <Button className="mr-2" title="Reload this page" onClick={handleReload}>
                    Reload
                </Button>
                <Button className="mr-2" title="Return to home page" onClick={handleGoHome}>
                    Home
                </Button>
                <Button title="Go back to previous page" onClick={handleGoBack}>
                    Go Back
                </Button>
            </div>
        </>
    );
}
