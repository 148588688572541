const DEMO = {
    BLANK_LINK: "#!",
};

export default DEMO;

export const MWhToDieselLitre = 1000 / 0.010722222;

export let years = []
for(let i = 2020; i <=2050; i++) {
  years.push(i);
}


export const ymax2018 = 3979215; // / 1000000

export const targetPos = {
  tar1: {x: 2030, y: ymax2018 * 0.5},
  tar2: {x: 2050, y: 0}
}

export const statusColors = {
    hazard: "#F44336",
    moderate: "#FFB300",
    calm: "#43A047",
    neutral: "#34495E"
  }


export const COLOR_MAP_DISTINCT_1 = ['#1f77b4', '#1f77b4','#aec7e8','#ff7f0e',
'#ff7f0e','#ffbb78','#2ca02c','#2ca02c','#98df8a',
'#d62728','#d62728','#ff9896','#9467bd','#9467bd',
'#c5b0d5','#8c564b','#8c564b','#c49c94','#e377c2',
'#e377c2','#f7b6d2','#7f7f7f','#7f7f7f','#c7c7c7',
'#bcbd22','#bcbd22','#dbdb8d','#17becf','#17becf',
'#9edae5','#9edae5'];


export const COLOR_MAP_DISTINCT_2 = ['#1f77b4', '#ff7f0e', '#98df8a','#9467bd', '#c49c94', '#7f7f7f', '#dbdb8d', '#9edae5' ];

