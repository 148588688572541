import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Typography, Input, Spin, Space, Upload, Tooltip } from "antd";
import { Toolbar } from "primereact/toolbar";
import * as ApiExperiment from "../../../../../api/energy-model/experimentServices";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { joinMonthlyProfileData } from "../../comm";
import ImportJson from "../common/import_result";
import { Toast } from "primereact/toast";

const { Text } = Typography;

const handleStartEndYear = (toast, e, paramName, startYear, endYear, setStartYear, setEndYear, props) => {
    const showWarn = (header, message) => {
        toast.current.show({ severity: "warn", summary: header, detail: message, life: 6000 });
    };

    const val = parseInt(e.target.value, 10);
    if (val < 2020) {
        showWarn("Year Value", "less than 2020 is not acceptable");
        if (paramName === "start") {
            setStartYear(props.startOverallYr);
            return;
        } else {
            setEndYear(props.endOverallYr);
            return;
        }
    }

    if (val > 2060) {
        showWarn("Year Value", "greater than 2060 is not acceptable");
        if (paramName === "start") {
            setStartYear(props.startOverallYr);
            return;
        } else {
            setEndYear(props.endOverallYr);
            return;
        }
    }

    if (paramName === "start" && val > endYear) {
        showWarn("Year Value", "Start Year cannot be larger than End Year");
        setStartYear(props.startOverallYr);
        return;
    }

    if (paramName === "end" && val < startYear) {
        showWarn("Year Value", "End Year cannot be smaller than Start Year");
        setEndYear(props.endOverallYr);
        return;
    }

    if (paramName === "start") {
        props.setStartOverallYr(startYear);
        return;
    }

    props.setEndOverallYr(endYear);
};

const ToolbarPathways = (props) => {
    const toast = useRef(null);
    const [isRunning, setIsRunning] = useState(false);
    const [maxResStore, setMaxResStore] = useState(5);

    const [startYear, setStartYear] = useState(props.startOverallYr);
    const [endYear, setEndYear] = useState(props.endOverallYr);
    const [caseCount, setCaseCount] = useState(0);

    const onRunAnalysis = (result_name = "New Case", result_description = "") => {
        // let tmpMonthly = joinMonthlyProfileData(props.funcsOnMonthly, props.monthlyProfiles);

        setIsRunning(true);
        const data = {
            syr: props.startOverallYr,
            eyr: props.endOverallYr,
            projID: props.sharedData.projID,
            aggCols: "Energy,SiteID,FunctionID",
            queryCols: "energy_demand",
            tbl_energies: props.sharedData.tbl_energies,
            tbl_techs: props.sharedData.tbl_techs,
            tbl_funcs: props.sharedData.tbl_funcs,
            tbl_sites: props.sharedData.tbl_sites,
            tbl_site_data: props.sharedData.tbl_site_data,
            lst_trans: props.transition,
            monthly: props.monthlyProfiles,
            get_profiles: 0,
        };

        ApiExperiment.calculateEnergyDemand(data).then(
            (res) => {
                let results = global.structuredClone(props.results);
                while (results.length >= maxResStore) {
                    results.shift();
                }
                setCaseCount(caseCount + 1);
                results.push({
                    techs: props.sharedData.tbl_techs,
                    transition: props.transition,
                    result: res.data.energy_calc,
                    years: res.data.arr_all_yrs,
                    funcsOnMonthly: props.monthlyProfiles,
                    dateTime: new Date().toLocaleString(),
                    result_name: "New_Case_" + caseCount.toString(),
                    nameDateTime: "New_Case_" + caseCount.toString() + "\xa0\xa0\xa0" + new Date().toLocaleString(),
                    description: result_description,
                });

                props.setResults(results);
                setIsRunning(false);
            },
            (err) => {
                setIsRunning(false);
            }
        );
    };

    const left = (
        <React.Fragment>
            <Space>
                {isRunning ? (
                    <Spin />
                ) : (
                    <Button type="primary" onClick={onRunAnalysis}>
                        Run Analysis
                    </Button>
                )}

                <ImportJson
                    results={props.results}
                    setResults={props.setResults}
                    setTechs={props.setTechs}
                    setTransition={props.setTransition}
                    setFuncsOnMonthly={props.setFuncsOnMonthly}
                    setRenewableInputs={props.setRenewableInputs}
                    setRenewableOutputs={props.setRenewableOutputs}
                    setImportDropdown={props.setImportDropdown}
                />
            </Space>
        </React.Fragment>
    );

    const handleMaxStore = (e) => {
        const val = parseInt(e.target.value, 10);
        if (val < 1) {
            setMaxResStore(1);
            toast.current.show({ severity: "warn", summary: "Max Store", detail: "The minimum value is 1", life: 6000 });
        }

        if (val > 20) {
            setMaxResStore(20);
            toast.current.show({ severity: "warn", summary: "Max Store", detail: "The maximum value is 20", life: 6000 });
        }
    };
    const onChangeSetState = (e, valName) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e.target.value === "" || re.test(e.target.value)) {
            if (valName == "maxResStore") {
                setMaxResStore(e.target.value, 10);
            } else if (valName == "startYear") {
                setStartYear(e.target.value, 10);
            } else if (valName == "endYear") {
                setEndYear(e.target.value, 10);
            }
        }
    };
    const right = (
        <React.Fragment>
            <Space>
                <Text>Max Store:</Text>

                <Tooltip placement="top" title="The maximum number of load results stored in memory and displayed in the current live session. Number between 1 and 20.">
                    <Input style={{ marginRight: "15px", width: "75px" }} size="small" min={1} max={10} value={maxResStore} onChange={(e) => onChangeSetState(e, "maxResStore")} onBlur={handleMaxStore} />
                </Tooltip>
                <Text>Time Span:</Text>
                <Input style={{ width: "75px" }} size="small" min={2020} max={2050} value={startYear} onChange={(e) => onChangeSetState(e, "startYear")} onBlur={(e) => handleStartEndYear(toast, e, "start", startYear, endYear, setStartYear, setEndYear, props)} />
                <Input style={{ width: "75px" }} size="small" min={2040} max={2100} value={endYear} onChange={(e) => onChangeSetState(e, "endYear")} onBlur={(e) => handleStartEndYear(toast, e, "end", startYear, endYear, setStartYear, setEndYear, props)} />
            </Space>
            <Toast ref={toast} />
        </React.Fragment>
    );

    return <Toolbar style={{ marginBottom: "15px" }} left={left} right={right} />;
};

export default ToolbarPathways;
