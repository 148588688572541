import React, { useState, useEffect, useRef } from "react";
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as tableUtils from "../../../../utils/tables";
import { showCompletionMessage } from "../common/helpers";
import { Toast } from "primereact/toast";
import MainTabView from "../common/main";

const StorageSchEdit = (props) => {
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [storageMap, setStorageMap] = useState(null);
    const [hasErrDB, setHasErrDB] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        updateView();
    }, []);

    const showWarnError = (level, header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const updateView = () => {
        setIsLoading(true);
        ApiEnergy.getListOfStorageSchedule().then((r) => {
            if (!r.hasError) {
                setDataSource(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiEnergy.getStorageMapName2Id().then((r) => {
            if (!r.hasError) {
                setStorageMap(r.data);
            } else {
                setHasError(true);
            }
        });

        if (dataSource && storageMap) {
            setIsLoading(false);
        }
    };

    if (hasError) {
        return <h3>An error occured when loading the data. Please refresh the page.</h3>;
    }

    const onSubmit = (lstUpdate, lstNewItems, setLstUpdate, setLstNewItems, setMessage) => {
        setHasErrDB(false);
        if (lstNewItems.length > 0) {
            ApiEnergy.createStorageSchedule(lstNewItems, storageMap).then((r) => {
                if (!r.hasError) {
                    setLstNewItems([]);
                    updateView();
                } else {
                    showWarnError("warn", "Creating New item", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }

        if (lstUpdate.length > 0) {
            ApiEnergy.updateStorageSchedule(lstUpdate, storageMap).then((r) => {
                if (!r.hasError) {
                    setLstUpdate([]);
                    updateView();
                } else {
                    showWarnError("error", "Error during update", r.message);
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }
    };

    let IdCol = "StorageScheduleID";

    let columns = [
        { name: "PathwayScenarioID", label: "Scenario ID", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "Substation", label: "Substation", sortable: false, filter: true, type: "dropdown" },
        { name: "Tag", label: "Tag", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "StorageType", label: "Storage Type", sortable: false, filter: true, type: "dropdown" },
        { name: "RatingMW", label: "Rating (MW)", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 1 } },
        { name: "CapacityMWh", label: "Capacity (MWh)", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 1 } },
        { name: "Year", label: "Year", sortable: false, filter: true, type: "normal", editable: { type: "text" }, newRow: { type: "text" } },
    ];

    let mainView = null;
    if (dataSource) {
        columns = tableUtils.extractUnqVals(dataSource, columns);
        mainView = <MainTabView dataSource={dataSource} setDataSource={setDataSource} title="Storage-Schedule" IdCol={IdCol} columns={columns} isLoading={isLoading} onSubmit={onSubmit} />;
    }

    return (
        <>
            {mainView}
            <Toast ref={toast} />
        </>
    );
};

export default StorageSchEdit;
