export const extractUnqVals = (data, cols) => {
    return cols.map((v) => {
        let unqVals = [];
        if (v.type.includes("dropdown")) {
            unqVals = [...new Set(data.map((item) => item[v.name]))];
        }
        return { ...v, unqVals: unqVals };
    });
}



const subSet = (o, keys) => keys.reduce((r, k) => (r[k] = o[k], r), {})

export const groupByTotal = (arr, groupByCols, aggregateCols) => {
  let grouped = {};

  arr.forEach(o => {
    const values = groupByCols.map(k => o[k]).join("|");
    if (grouped[values])
      grouped[values][aggregateCols] += o[aggregateCols]
    else
      grouped[values] = { ...subSet(o, groupByCols), [aggregateCols]: o[aggregateCols] }
  })

  return Object.values(grouped);
}