import React from "react";
import { useSelector } from "react-redux";
//IMPORT STYLES
import "primeflex/primeflex.css";
//IMPORT LIBS
// import { CodeHighlight } from "../../components/CodeHighLight";
import { Message } from "primereact/message";
import DampierSaltImg from "../../assets/home/DampierSalt.png";
import ProcessDSLImg from "../../assets/home/process.png";
import DampierSaltIntroImg from "../../assets/home/DampierSaltIntro.jpg";
import DynamicAnalysisIntro from "./DynamicAnalysisIntro";

const styleMsgBox = {
    width: "80%",
    alignContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
};

const IronOrePage = () => {
    const note1 = (
        <div>
            <b>Note: Key points to not regarding the scenarions/pathway selections:</b>
            <ol>
                <li>The Port, Rail and Processing functions all depend on SOP, all other functions use TMM as the basis of the calculation of the specific energy requirement per tonne (i.e. L/t for Diesel or kWh/t for electric supplied functions)</li>
                <br />
                <li>Rail has three components: Refuelling/Charging @ Mine loading; Refuelling/Charging @ Cape Lambert; and Refuelling/Charging @ Dampier</li>
            </ol>
            <br />
        </div>
    );

    const note2 = (
        <p>
            <b>Note:</b>
            <br />
            <br />
            To create a new scenario, it is recommended to duplicate an existing scenario by selecting the duplicate scenario icon on each of the scenario tiles viewed from the Manage Scenarios menu item.
        </p>
    );

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-8">
                <div className="card docs" style={{ padding: "80px" }}>
                    <h1 className="p-grid p-justify-center" style={{ marginBottom: "40px" }}>
                        <b>Welcome to the Rio Tinto Iron Ore Pilbara wide energy model</b>
                    </h1>
                    <h3>
                        <b>Model Overview</b>
                    </h3>
                    <p>
                        The model is based on supplied information along with study outcomes delivered by Worley.
                        <br />
                        <br />
                        The model is an activity driven model, it takes the tonnes moved during mining (TMM) or saleable ore product (SOP) to calculate the amount of energy required to perform the various functions within the operation.
                        <br />
                        <br />
                        The energy is calculated by a specific energy rate, for diesel this is litres per tonne and for electrical functions kWh per tonne.
                        <br />
                        <br />
                        To allow for transitions over time, profiles are used to indicate the amount of a particular function. These profiles can relate to the count of vehicles, the percentage or fraction. The model performs a count of all the profile values for a year in the particular function
                        and mine to determine the fraction to allocate to each technology (as such as diesel haul trucks vs electric haul trucks).
                        <br />
                        <br />
                        Emissions are calculated based on the energy usage. A calculation is performed for the electrical energy to reduce the CO2 emissions based on the amount of renewable power generation installed.
                    </p>
                    <h3>
                        <b>Model Usage</b>
                    </h3>
                    <p>
                        There are different levels of access, the most basic is read only, which will not allow you to change inputs to the model, just view the outputs. The level is indicated in the top right hand corner of the window.
                        <br />
                        <br />
                        Under the dashboard menu is a range of plots/charts. Please select the scenario in the drop-down box. There is also a map-based option to view the distribution of the energy/emissions across the operations.
                        <br />
                        <br />
                        Under the tools menu, you can view the input data, and those with access can modify or assign values to the various mines/functions within the model.
                        <br />
                        <br />
                        Under the view pathways option, the different scenarios can be viewed along with the profiles assigned to each combination of mine and function.
                    </p>
                    <div className="p-grid p-justify-center">
                        <Message severity="warn" style={styleMsgBox} content={note1} />
                    </div>
                    <div className="p-grid p-justify-center">
                        <Message severity="warn" style={styleMsgBox} content={note2} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const VSpace = ({ h }) => {
    return <div style={{ height: h }} />;
};

const DampierSaltPage = () => {
    const note1 = (
        <div>
            <b>Note: Key points to not regarding the scenarions/pathway selections:</b>
            <ol>
                <li>All Functions and Technologies in Dampier Salt are related to Saleable Product index.</li>
            </ol>
            <br />
        </div>
    );

    const note2 = (
        <p>
            <b>Note:</b>
            <br />
            <br />
            To create a new scenario, it is recommended to duplicate an existing scenario by selecting the duplicate scenario icon on each of the scenario tiles viewed from the Manage Scenarios menu item. In addition we have added a series of new functionalities to Dynamically set transitional
            pathways and perform the calculations. These new features, provide users with extreme flexibility in quickly tweak pathways and see the results.
        </p>
    );

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-8">
                <div className="card docs" style={{ padding: "80px" }}>
                    <h1 className="p-grid p-justify-center" style={{ marginBottom: "40px" }}>
                        <b>Welcome to Dampier Salt Limited Decarbonisation Study</b>
                    </h1>
                    <center>
                        <img src={DampierSaltIntroImg} style={{ width: "100%" }} alt="Featured" />
                    </center>
                    <VSpace h="30px" />
                    <h3>Introduction</h3>
                    Dampier Salt Limited (DSL) operations are based around the natural evaporation process. As such, the vast majority of energy demand already comes from renewable resources. DSL’s carbon footprint currently comes from diesel and electric energy usage in harvesting, transport,
                    washing, and ship loading. These are the primary areas to decarbonise the DSL operations.
                    <br />
                    <br />
                    This Net zero tool and the accompanied Dynamic analysis platform have been developed alongside the Dampier Salt Limited Decarbonisation Study conducted by Worley that considered a carbon neutral (or negative) DSL by the year 2030.
                    <br />
                    <br />
                    DSL Operations consists of three separated locations:
                    <ul>
                        <li>Dampier</li>
                        <li>Port Hedland, and</li>
                        <li>Lake MacLeod</li>
                    </ul>
                    Salt is produced utilising natural evaporation of the ocean water in the system of staged ponds. Once sufficiently concentrated, salt crystals are grown in the crystallisers and subsequently harvested when they have reached the sufficient size. The wet product is then transported
                    to the stockpiles located at the port, washed, dried, and transferred to the ships for export. There are some differences between operations at three DSL locations, but overall process is relatively similar. Lake MacLeod has an additional site with separate processing for gypsum.
                    <br />
                    <br />
                    DSL uses mix of “off the shelf” and bespoke equipment. In many cases standard equipment needs to undergo modifications to be adopted for hash operating environment.
                    <br />
                    Electrical power is predominantly provided from the local grid owned by Rio Tinto or Horizon Power depending on the site, with some power supplied by the diesel generators in remote areas (mainly brine pumping away from the grid).
                    <br />
                    <br />
                    <center>
                        <img src={ProcessDSLImg} style={{ width: "100%" }} alt="Processing Steps" />
                    </center>
                    <VSpace h="30px" />
                    <h3>
                        <b>Energy Model</b>
                    </h3>
                    <h4>
                        <b>Model Overview</b>
                    </h4>
                    <p>
                        The model is based on supplied information along with study outcomes delivered by Worley.
                        <br />
                        <br />
                        The model is an activity driven model, it takes the saleable product (SOP) to calculate the amount of energy required to perform the various functions within the operation.
                        <br />
                        <br />
                        The energy is calculated by a specific energy rate, for diesel this is litres per tonne and for electrical functions kWh per tonne.
                        <br />
                        <br />
                        To allow for transitions over time, profiles are used to indicate the amount of a particular function. These profiles can relate to the count of vehicles, the percentage or fraction. The model performs a count of all the profile values for a year in the particular function
                        and mine to determine the fraction to allocate to each technology (as such as diesel haul trucks vs electric haul trucks).
                        <br />
                        <br />
                        Emissions are calculated based on the energy usage. A calculation is performed for the electrical energy to reduce the CO2 emissions based on the amount of renewable power generation installed.
                    </p>
                    <h3>
                        <b>Model Usage</b>
                    </h3>
                    <p>
                        There are different levels of access, the most basic is read only, which will not allow you to change inputs to the model, just view the outputs. The level is indicated in the top right-hand corner of the window.
                        <br />
                        <br />
                        Under the dashboard menu is a range of plots/charts. Please select the scenario in the drop-down box. There is also a map-based option to view the distribution of the energy/emissions across the operations.
                        <br />
                        <br />
                        Under the tools menu, you can view the input data, and those with access can modify or assign values to the various mines/functions within the model.
                        <br />
                        <br />
                        Under the Dynamic Analysis menu, you can dynamically and quickly adjust complex pathways and run the analysis. Also, the renewable section in Dynamic Analysis performs simulations at an hourly level.
                    </p>
                    <div className="p-grid p-justify-center">
                        <Message severity="warn" style={styleMsgBox} content={note1} />
                    </div>
                    <div className="p-grid p-justify-center">
                        <Message severity="warn" style={styleMsgBox} content={note2} />
                    </div>
                    <DynamicAnalysisIntro />
                </div>
            </div>
        </div>
    );
};

const HomePage = () => {
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);

    if (selectedUserAccess.ProjectID === 1) {
        return <IronOrePage />;
    }

    return <DampierSaltPage />;
};

export default HomePage;
