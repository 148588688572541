import React, {useState} from 'react'
import { TabView, TabPanel } from 'primereact/tabview';

import EditPage from '../common/editPage';
import ChkNewItems from '../common/chkNewItems';
import ChkUpdate from '../common/chkUpdate';
import SubmitPage from '../common/submitPage';

const MainTabView = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [lstUpdate, setLstUpdate] = useState([]);
    const [lstNewItems, setLstNewItems] = useState([]);    

    let editView = null
    let chkNewItem = null
    let chkUpdates = null
    if (props.dataSource) {
        editView = <EditPage dataSource = {props.dataSource} title={props.title} setDataSource={props.setDataSource} lstUpdate = {lstUpdate} setLstUpdate={setLstUpdate} 
        lstNewItems={lstNewItems} setLstNewItems={setLstNewItems} columns={props.columns} IdCol={props.IdCol} isLoading={props.isLoading}/>

        chkNewItem = <ChkNewItems lstNewItems={lstNewItems} setLstNewItems={setLstNewItems} 
        dataSource = {props.dataSource} setDataSource={props.setDataSource}  columns={props.columns}/>

        chkUpdates = <ChkUpdate dataSource = {props.dataSource} setDataSource={props.setDataSource} lstUpdate = {lstUpdate} setLstUpdate={setLstUpdate} 
        columns={props.columns} IdCol={props.IdCol} isLoading={props.isLoading} />
    }

    return (
        <div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Edit Page">
                    {editView}
                </TabPanel>
                <TabPanel header="Check Updates">
                    {chkUpdates}
                </TabPanel>
                <TabPanel header="Check New Items">
                    {chkNewItem}
                </TabPanel>
                <TabPanel header="Submit Page">
                    <SubmitPage lstUpdate={lstUpdate} lstNewItems={lstNewItems} 
                    setLstNewItems={setLstNewItems} setLstUpdate={setLstUpdate}
                    onSubmit={props.onSubmit}/>
                </TabPanel>
            </TabView>
        </div>
    );
}

export default MainTabView;
