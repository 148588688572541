const subSet = (o, keys) => keys.reduce((r, k) => ((r[k] = o[k]), r), {});

export const groupByTotal = (arr, groupByCols, aggregateCols) => {
    let grouped = {};

    arr.forEach((o) => {
        const values = groupByCols.map((k) => o[k]).join("|");
        if (grouped[values]) grouped[values][aggregateCols] += o[aggregateCols];
        else grouped[values] = { ...subSet(o, groupByCols), [aggregateCols]: o[aggregateCols] };
    });

    return Object.values(grouped);
};

export const returnTargetEmissionAnnotate = (selectedTargetEmission, selectedBaselineEmission, targetImg) => {
    return selectedTargetEmission.map((r) => {
        return {
            x: r.Year,
            y: r.TargetType === "Reduction" ? (1 - r.Reduction / 100) * selectedBaselineEmission.Value : r.ActualValue,
            marker: {
                size: 5,
                opacity: 100,
                strokeWidth: 0,
            },
            image: {
                path: targetImg,
                width: 30,
                height: 30,
                offsetX: 0,
                offsetY: 0,
            },
        };
    });
};
