import React from "react";
import Help from "../../../../../../components/Buttons/Help";
import energyDemandImg from "../../../../../../assets/help/energy_demand_calc.png";
import renewableCalcImg from "../../../../../../assets/help/renewable_calc.png";
import aipredictorImg from "../../../../../../assets/help/ai_predictor.png";

function HelpIntro() {
    return (
        <Help width="800px">
            <div style={{ overflowY: "scroll", height: "600px" }}>
                <h3>Introduction</h3>
                This page helps with planning renewable schedule to mitigate the load demand. Users can select one of the load scenarios from the Transition analysis. And based on that load, they can schedule installation of renewable. The performed simulation is conducted at a detailed level, i.e.,
                at an hourly-base, over the whole selected period of time. For example, if the time span is selected for 30 years, the simulation runs for every hour of every day of all the 30 years.
                <h3>Methodology</h3>
                <h5>1. Calculating the Load</h5>
                <img src={energyDemandImg} alt="Energy Demand Calculations" style={{ width: "700px" }} />
                <h5>2. Simulating the renewable/storage supply</h5>
                <img src={renewableCalcImg} alt="Energy Demand Calculations" style={{ width: "700px" }} />
                <h5>3. How to define the schedule</h5>
                Defining the installation schedule (i.e. how much to install and when to install) has a great impact on the outcome of the simulation. To follow a desired scenario, it is not easy to adjust the installation schedule to get close to the scenario without any guidelines in hand. This
                process is usually try and error. And since we run the simulation on an hourly basis, this may not be convenient for users to make the adjustments manually. Or even get close to desired scenario with few iterations. As such we have run a large number of simulation for different
                scenarios of Grid Dependecies and percentage of Wind/Solar installation. Based on that data, we have trained a number of machine learning (ML) models to predict the installation requirements based on the given load and a scenario in mind. The ML model is a great tool to provide users
                with a guideline to adjust installation schedule.
                <img src={aipredictorImg} alt="AI Guideline" style={{ width: "700px" }} />
            </div>
        </Help>
    );
}

export default HelpIntro;
