import React, {useState, useMemo} from 'react';

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";
import { Badge } from 'primereact/badge';
import { Button} from 'primereact/button'

import ShowProfile from "../../../../../../components/showProfile";
import * as editUtils from "./editUtils";


const EditScenarioDialog = (props) => {

    /**
     * @lstRows             Rows of data to be updated
     * @showEditDiag        Shows or Hides the Dialog.
     * @setShowEditDiag     Sets showEditDiag
     * @editField           Determines which field is being edited: profile, tech, or both
     * @lstOfUpdates        List of updates
     * @setListOfUpdates    Sets the state of the list of updates
     * @lstOfDeletes        list of deletes
     * @setListOfDeletes    Sets the state of the list of deletes
     * @lstOfProfiles   
     * @lstOfTechs
     */


    const [topRow, setTopRow] = useState(null)
    const [selectedProfileInfo, setSelectedProfileInfo] = useState(null);
    const [selectedTechInfo, setSelectedTechInfo] = useState(null);
    

     useMemo(() => {
        if ((typeof props.lstRows !== 'undefined') && (props.lstRows.length > 0)) {
            setTopRow(props.lstRows[0]);
            setSelectedProfileInfo(props.lstOfProfiles.filter(r => r.Profile === props.lstRows[0].Profile)[0]);
            setSelectedTechInfo(props.lstOfTechs.filter(r => r.Name === props.lstRows[0].Technology)[0]);
        }
    }, [props.lstRows])
    
       

    // let selectedProfileName = "NewProfile" in topRow ? topRow.NewProfile : topRow.Profile;
    // let selectedTechName = "NewTech" in topRow ? topRow.NewTech : topRow.Technology;


    const hideDialog = () => {
        props.setShowEditDiag(false);
        setTopRow(null);
    };


    const applyUpdate = () => {

        let profInfo = null;
        let techInfo = null;

        switch (props.editField) {
            case 'both':
                profInfo = selectedProfileInfo;
                techInfo = selectedTechInfo;
                break;

            case 'profile':
                profInfo = selectedProfileInfo;
                break;
    
            case 'tech':
                techInfo = selectedTechInfo;
                break;
    
            default:
                break;
        }
        editUtils.updateRows(props.lstRows, profInfo, techInfo, props.lstOfUpdates, props.setListOfUpdates, props.lstOfDeletes, props.setListOfDeletes);

        props.setShowEditDiag(false);
        setTopRow(null);
    };

    const pushData = () => {
        editUtils.chkItemsInDelete(props.lstRows, props.lstOfDeletes, applyUpdate);
    }

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Apply" icon="pi pi-check" className="p-button-text" onClick={pushData} />
        </React.Fragment>
    );

    if (topRow) {
        let diagTitleView = (
            <>
                <span style={{ margin: "10px" }}>
                    ID: <b>{topRow.PathwayMappingID}</b>
                </span>
                <span style={{ margin: "10px" }}>
                    Site: <b>{topRow.Site}</b>
                </span>
                <span style={{ margin: "10px" }}>
                    Function: <b>{topRow.Function}</b>
                </span>
                <span style={{ margin: "10px" }}>
                    Technology: <b>{topRow.Technology}</b>
                </span>
                <span style={{ margin: "10px" }}>
                    Profile: <b>{topRow.Profile}</b>
                </span>
            </>
        );
    
    
        let profileDropDown = (
            <>
                <span style={{ margin: "10px" }}>
                    <b>Select A Profile: </b>
                </span>
                <Dropdown style={{ margin: "10px" }} filter filterBy="Profile" optionLabel="Profile" optionValue="Profile" value={selectedProfileInfo.Profile} 
                options={props.lstOfProfiles} onChange={(e) => setSelectedProfileInfo(props.lstOfProfiles.filter(r => r.Profile === e.target.value)[0])} placeholder="Select a Profile" />
            </>
        )
    
        let techDropDown = (
            <>
                <span style={{ margin: "10px" }}>
                    <b>Select A Technology: </b>
                </span>
                <Dropdown style={{ margin: "10px" }} filter filterBy="Name" optionLabel="Name" optionValue="Name" value={selectedTechInfo.Name} 
                options={props.lstOfTechs} onChange={(e) => setSelectedTechInfo(props.lstOfTechs.filter(r => r.Name === e.target.value)[0])} placeholder="Select a Technology" />              
            </>
        )
    
        let origInfo = (
            <>
                <b style={{ margin: "10px" }}>Originals: </b>
                <Badge value={`${topRow.Profile}`} severity="info" style={{ margin: "10px" }} />
                <Badge value={`${topRow.Technology}`} severity="info" style={{ margin: "10px" }} />
            </>
        )
    
        let editDiagContent = null;
        switch (props.editField) {
            case 'both':
                editDiagContent = (
                    <>
                        <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                            {diagTitleView}
                        </div>
                        <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                            {profileDropDown}
                            {techDropDown}
                            {origInfo}
                        </div>
                        <ShowProfile isAccordOn={true} plotHeight={60} plotColWidth="p-col-7" showPickList={true} selectedProfile={selectedProfileInfo? selectedProfileInfo.Profile : null} 
                        originalProfile={topRow ? topRow.Profile : null} />
                    </>
                )
                break;
    
            case 'profile':
                editDiagContent = (
                    <>
                        <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                            {profileDropDown}
                        </div>
                        <ShowProfile isAccordOn={true} plotHeight={60} plotColWidth="p-col-7" showPickList={true} selectedProfile={selectedProfileInfo? selectedProfileInfo.Profile : null} 
                        originalProfile={topRow ? topRow.Profile : null} />
                    </>
                )
                break;
    
            case 'tech':
                editDiagContent = (
                    <>
                        <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                            {techDropDown}
                        </div>
                    </>
                )
                break;
    
            default:
                break;
        }
            
        return (
            <Dialog visible={props.showEditDiag} style={{ width: "70vw" }} header="Edit Item" modal className="p-fluid" footer={editDialogFooter} onHide={hideDialog}>
                {editDiagContent }
            </Dialog>
        )
    }
    
    return null;

}


export default EditScenarioDialog;