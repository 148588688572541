import { FASTAPI_URL } from "../../settings/settings";
import * as ApiUtils from "../utils/ApiUtils";

const prefix = "/emission";

export const getEmissionByEnergyByScenarioID = async (scenario, minYear, maxYear) => {
    /**
     * Returns Emissions grouped by Energy for a specific scenario.
     *
     * If Scenario is null, then it returns all Emissions for all scenarios.
     */

    const url = `${FASTAPI_URL}${prefix}/energy-type?min_year=${minYear}&max_year=${maxYear}&scenarioId=${scenario.PathwayScenarioID}`;

    return ApiUtils.GET(url, "GET", "EmissionsByEnergyType");
};

export const getEmissionByFunctionByScenarioID = async (scenario, minYear, maxYear) => {
    /**
     * Returns Emissions grouped by Function for a specific scenario.
     *
     * If Scenario is null, then it returns all Emissions grouped by functions for all scenarios.
     */

    const url = `${FASTAPI_URL}${prefix}/functions?min_year=${minYear}&max_year=${maxYear}&scenarioId=${scenario.PathwayScenarioID}`;

    return ApiUtils.GET(url, "GET", "EmissionsByFunctions");
};

export const getAllBaselineEmissions = async () => {
    /**
     * Returns Baseline emissions.
     *
     * There are two types of baseline emissions: Operational, Equity Share.
     */
    const url = `${FASTAPI_URL}${prefix}/baseline-emissions/all`;
    return ApiUtils.GET(url, "GET", "AllBaselineEmissions");
};

export const getAllBaselineEmissionsByProjectID = async (projectID) => {
    /**
     * Returns Baseline emissions for a specific project.
     */
    const url = `${FASTAPI_URL}${prefix}/baseline-emissions/project?project_id=${projectID}`;
    return ApiUtils.GET(url, "GET", "AllBaselineEmissionsByProjID");
};

export const getAllTargetEmissions = async () => {
    /**
     * Returns all target emissions.
     */
    const url = `${FASTAPI_URL}${prefix}/target-emissions/all`;
    return ApiUtils.GET(url, "GET", "AllTargetEmissions");
};

export const getAllTargetEmissionsByProjectID = async (projectID) => {
    /**
     * Returns all target emissions for specific Project.
     */
    const url = `${FASTAPI_URL}${prefix}/target-emissions/project?project_id=${projectID}`;
    return ApiUtils.GET(url, "GET", "AllTargetEmissionsByProjID");
};

export const getAllTargetEmissionsPrimaryByProjectID = async (projectID) => {
    /**
     * Returns all primary target emissions (including names and versions) for specific Project.
     */
    const url = `${FASTAPI_URL}${prefix}/target-emissions/primary/project?project_id=${projectID}`;
    return ApiUtils.GET(url, "GET", "AllTargetEmissionsPrimary");
};

export const createBaseline = async (items) => {
    /**
     * Creates a new Baseline emission.
     */
    const reqBody = items.map((r) => {
        return {
            Year: parseInt(r.Year),
            Value: parseInt(r.Value),
            ProjectID: parseInt(r.ProjectID),
        };
    });

    const url = FASTAPI_URL + prefix + "/baseline-emissions";

    return ApiUtils.POST(url, reqBody);
};

export const updateBaselineEmission = async (items) => {
    /**
     * updates an existing Baseline emission.
     */
    const reqBody = items.map((r) => {
        return {
            VersionID: parseInt(r.VersionID),
            Year: parseInt(r.Year),
            Value: parseInt(r.Value),
            ProjectID: parseInt(r.ProjectID),
        };
    });

    return ApiUtils.PUT(FASTAPI_URL + prefix + "/baseline-emissions", reqBody);
};

export const createTarget = async (items) => {
    /**
     * Create Target emissions.
     */
    let reqBody = items.map((r) => {
        return {
            Year: parseInt(r.Year),
            TargetType: r.TargetType,
            Reduction: r.Reduction === null || r.Reduction === "" ? null : parseFloat(r.Reduction),
            ActualValue: r.ActualValue === null || r.ActualValue === "" ? null : parseFloat(r.ActualValue),
            VersionID: parseInt(r.VersionID),
        };
    });

    const url = FASTAPI_URL + prefix + "/target-emissions";

    return ApiUtils.POST(url, reqBody);
};

export const updateTargetEmission = async (items) => {
    /**
     * updates an existing Target emission.
     */

    const reqBody = items.map((r) => {
        return {
            ID: parseInt(r.ID),
            Year: parseInt(r.Year),
            TargetType: r.TargetType,
            Reduction: r.Reduction === null || r.Reduction === "" ? null : parseFloat(r.Reduction),
            ActualValue: r.ActualValue === null || r.ActualValue === "" ? null : parseFloat(r.ActualValue),
            VersionID: parseInt(r.VersionID),
        };
    });

    return ApiUtils.PUT(FASTAPI_URL + prefix + "/target-emissions", reqBody);
};

export const deleteTargetEmission = async (targetId) => {
    /**
     * Deletes an existing Target emission.
     */
    return ApiUtils.DELETE(FASTAPI_URL + prefix + `/target-emissions?targetId=${targetId}`);
};

export const createTargetPrimary = async (item) => {
    /**
     * Creates a primary Target emission to keep targets meta data such as name, version.
     */
    let reqBody = {
        VersionName: item.VersionName,
        VersionDescription: item.VersionDescription,
        ProjectID: item.ProjectID,
    };

    const url = FASTAPI_URL + prefix + "/target-emissions/primary";

    ApiUtils.POST(url, reqBody);
};

export const updateTargetEmissionPrimary = async (item) => {
    /**
     * Update primary target emissions.
     */
    let reqBody = {
        VersionName: item.VersionName,
        VersionDescription: item.VersionDescription,
        VersionID: item.VersionID,
        ProjectID: item.ProjectID,
    };

    return ApiUtils.PUT(FASTAPI_URL + prefix + "/target-emissions/primary", reqBody);
};

export const deleteTargetEmissionPrimary = async (versionId) => {
    /**
     * Delete a primary Target emission.
     */
    return ApiUtils.DELETE(FASTAPI_URL + prefix + `/target-emissions/primary?version_id=${versionId}`);
};
