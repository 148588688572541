import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ApiSite from '../../../api/energy-model/siteServices'
//IMPORT STYLES
import 'primeflex/primeflex.css';
import './TimelineDemo.css';
import {  useMsal } from "@azure/msal-react";
import { setAccessToken } from '../../../api/auth/userServices';

import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';

import CustomDataTable from '../../../components/CustomTable/CustomDataTable';
import Loading from '../../../components/Loading';
import MineDataToolbar from './mineDataToolbar';

const TmmSopDataManager = (props) => {

    const { instance, accounts} = useMsal();
    const [dataSource, setDataSource] = useState(null);
    const [primaryData, setPrimaryData] = useState(null)

    const [hasToken, setHasToken] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const lstRecords = useSelector(state => state.report.lstOfAllMineDataRecords);
    const lstOfSites = useSelector(state => state.report.lstOfMines);
    const mapSiteId2Name = useSelector(state => state.report.mineMap);
    const selectedUserAccess = useSelector(s => s.user.selectedUserAccess)

    const updateData = () => {
        setIsLoading(true);
        ApiSite.getTmmSopCalcByProjectID(selectedUserAccess.ProjectID).then(
            res => {
                if (!res.hasError) {
                    setDataSource(res.data.map(r => { 
                        return {...r, SiteName: mapSiteId2Name[r.Site]}
                    }))
                    let recordId = res.data[0].RecordID;
                    ApiSite.getListOfSiteDataPrimaryByRecordId(recordId).then(
                        res => {
                            if (!res.hasError) {
                                setPrimaryData(res.data.filter(r => r.RecordID === recordId)[0]);
                            }
                        },
                        err => {}
                    )
                } 
                setIsLoading(false);
            },
            err => {setIsLoading(false);}
        )
    }

    useEffect(() => {
        setAccessToken(instance, accounts).then(
            (resolve) => {
                updateData();
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    // let events = []
    // if (!isLoading && lstRecords && lstRecords.length > 0) {
    //     events = lstRecords.map(r => {
    //         return { status: 'Uploaded', date: r.UploadDate, version: r.DataVersion, icon: 'pi pi-check', color: '#52BE80' }
    //     })
    //     events = [...events, { status: 'New', date: null, version: 'start new record', icon: 'pi pi-upload', color: '#673AB7' }]
    // }

    if (isLoading || dataSource === null || primaryData === null) {
        return <Loading />
    }
    
    let columns = [
        // {name: 'ScenarioID', label: 'ScenarioID', sortable: true, filter: true, type: 'normal'},
        {name: 'SiteName', label: 'Site', sortable: false, filter: true, type: 'normal'},
        {name: 'Year', label: 'Year', sortable: false, filter: true, type: 'normal'},
        {name: 'TMM_MTPA', label: 'TMM (Mtpa)', sortable: false, filter: true, type: 'normal'},
        {name: 'SOP_MTPA', label: 'SOP (Mtpa)', sortable: false, filter: true, type: 'normal'}
    ]

    
    const style = {margin: "10px"}

    return (
        <div className="card">
            <MineDataToolbar dataSource={dataSource} updateData={updateData} row={primaryData}/>
            <h4 style={style}>Site Data - {primaryData.DataVersion} ({new Date(primaryData.UploadDate).toLocaleDateString('en-US')})</h4>            
            <CustomDataTable 
                data={dataSource}
                columns={columns}
                showOrgVals={false}
                tableType="normal"
                />
        </div>
    )
}

export default TmmSopDataManager;