import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ChkListBox from '../CheckListBox/CheckListBox';
import OverlayCheckListBox from '../CheckListBox/OverlayCheckListBox';
import * as actionTypeReport from '../../../store/report/actionReport';

const SiteFilter = (props) => {
    /**
     * @isOverlay               An overlay view is selected or direct inclusion.
     * @onChangeCheckedSites    function implementation when checked items are ticked or unticked. The function includes the updated items after the event.
     * @filter                  include filter field at the top.
     * @maxHeight               maximum height of the check listbox
     * @btnWidth                if Overlay is selected.
    */

    const lstOfSites = useSelector(s => s.report.lstOfMines);
    const dispatch = useDispatch();

    const onChangeCheckedSites = (updatedItems) => {
        dispatch({type: actionTypeReport.SET_CHK_STATE_SITES, payload: {response: updatedItems}});
        props.onChangeCheckedSites(updatedItems);
    }

    let sites = lstOfSites.map(r => {
        return {...r}
    });

    let siteFilter = null;
    if (sites !== null && sites.length > 0) {
        siteFilter = props.isOverlay? <OverlayCheckListBox btnLabel="Sites" btnWidth={props.btnWidth} filter allItem items={sites} maxHeight={props.maxHeight} IdField="SiteID" labelField="Site" setItems={onChangeCheckedSites} /> 
        :
        <ChkListBox btnLabel="Sites" filter={props.filter} allItem items={sites} maxHeight={props.maxHeight} IdField="SiteID" labelField="Site" setItems={onChangeCheckedSites} />
    }

    return siteFilter;

}

export default SiteFilter;