import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as ApiEmit from "../../../../api/energy-model/emissionServices";
import EmitChart from "./EmitChart";
import Loading from "../../../../components/Loading";
import * as utils from "../../../../utils/comm";
import * as tbUtils from "../../../../utils/tables";

import { PointSpreadLoading } from "react-loadingg";

import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import { InputText } from "primereact/inputtext";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import { extractYearArray } from "../../../../utils/comm";

import SideFilter from "../../../../components/Filters";

const convertToChartDataSource = (emissionData, lstSelectedScenarios, years, compareLabel = "tCO2-e") => {
    let dataSource = [];
    if (emissionData && lstSelectedScenarios && lstSelectedScenarios.length > 0) {
        lstSelectedScenarios.forEach((s) => {
            let emitByScen = emissionData.filter((e) => e.PathwayScenarioID === s.PathwayScenarioID);
            var dataCur = [];
            years.forEach((yr) => {
                var emitByScenByYear = emitByScen.filter((r) => parseInt(r.Year) === yr);

                if (emitByScenByYear.length > 0) {
                    let sum = 0;
                    emitByScenByYear.forEach((d) => {
                        sum += parseFloat(d[compareLabel]);
                    });
                    dataCur.push(sum);
                } else {
                    dataCur.push(null);
                }
            });
            dataSource.push({
                name: s.PathwayScenarioName,
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const CompareEmissions = (props) => {
    const lstOfScenarios = useSelector((state) => state.report.lstOfScenarios);
    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);
    const [years, setYears] = useState([]);
    const [targetEmissions, setTargetEmissions] = useState(null);

    const [baseFactor, setBaseFactor] = useState(1.0);

    const lstOfSites = useSelector((s) => s.report.lstOfMines);
    const selectedBaselineEmitVer = useSelector((s) => s.report.selectedBaselineEmitVer);
    const selectedUserAccess = useSelector((s) => s.user.selectedUserAccess);
    const selectedTargetEmitVersion = useSelector((s) => s.report.selectedTargetEmitVersion);

    const [dataSource, setDataSource] = useState(null);
    const [chartDataSource, setChartDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const queryCol = React.useMemo(() => {
        return selectedBaselineEmitVer.Mode === "Operation" ? "tCO2-e" : "tCO2-eRTequity";
    }, [selectedBaselineEmitVer]);

    useEffect(() => {
        setIsLoading(true);
        setAccessToken(instance, accounts).then(
            (resolve) => {
                let yrs = null;
                const eyear = selectedUserAccess.ProjectID === 1 ? 2050 : 2030;
                ApiEnergy.getEnergyEmissionCalculations(-1, -1, 2021, eyear, "PathwayScenarioID,Year,Site,SiteID", queryCol).then((res) => {
                    if (!res.hasError) {
                        yrs = extractYearArray(res.data, "Year");
                        setYears(yrs);
                    } else {
                        throw new Error(res.message); // check if res.status === 401.
                    }
                    setHasToken(true);
                    setDataSource(res.data);
                    console.log(res.data);
                    let selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
                    updateDataBySiteFilter(res.data, yrs, selectedSites);

                    ApiEmit.getAllTargetEmissions(selectedUserAccess.ProjectID).then(
                        (res2) => {
                            if (!res2.hasError) {
                                setTargetEmissions(res2.data);
                                setIsLoading(false);
                            }
                        },
                        (err2) => {
                            setIsLoading(false);
                        }
                    );
                });
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBaselineEmitVer]);

    const updateDataBySiteFilter = (data, yrs, selectedSites) => {
        let flt_data = data.filter((r) => selectedSites.includes(r.SiteID));

        const rdc_data_by_scenario = tbUtils.groupByTotal(flt_data, ["Year", "PathwayScenarioID"], [queryCol]);
        setChartDataSource(convertToChartDataSource(rdc_data_by_scenario, lstOfScenarios, yrs, queryCol));
    };

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    const dummyFunc = () => {
        console.log("to send dummy function.");
    };

    const handleSiteFilter = (updatedItems) => {
        let selectedSites = updatedItems.filter((f) => f.checked).map((r) => r.SiteID);
        updateDataBySiteFilter(dataSource, years, selectedSites);
    };

    const sideFilters = (
        <SideFilter
            hasScenarioFilter={false}
            hasBaselineFilter={true}
            hasTargetVerFilter={true}
            hasSiteFilter={true}
            hasTMMSOPFilter={false}
            hasSiteSearchBar={true}
            handleScenarioChange={dummyFunc}
            handleTargetVerChange={dummyFunc}
            handleBaselineVerChange={dummyFunc}
            onChangeCheckedSites={handleSiteFilter}
            handleTMMSOPVerChange={dummyFunc}
            isOverlay={false}
            maxHeightSiteFilter="400px"
            hasBaselineFactorFilter={true}
            factor={baseFactor}
            setFactor={setBaseFactor}
        />
    );

    let view = <Loading comp={<PointSpreadLoading />} />;
    if (chartDataSource) {
        if (isLoading) {
            view = <Loading comp={<PointSpreadLoading />} />;
        } else {
            view = (
                <EmitChart
                    cardTitle="Compare Emissions"
                    dataSource={chartDataSource}
                    loadingComp={<Loading />}
                    chartType="line"
                    filter={sideFilters}
                    toFixed={3}
                    lineStroke={4}
                    xaxis={years}
                    yaxisTitle="M tCO2-e"
                    stacked={false}
                    ymin={0}
                    selectedBaselineEmission={selectedBaselineEmitVer}
                    selectedTargetEmission={targetEmissions ? targetEmissions.filter((r) => r.VersionID === selectedTargetEmitVersion.VersionID) : []}
                    targetPos={null}
                    baseFactor={baseFactor}
                    // colors={colors}
                />
            );
        }
    }

    return view;
};

export default CompareEmissions;
