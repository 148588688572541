import React from "react";
import BtnDeleteRenewableRow from "./BtnDeleteRenewableRow";
import InputData from "./InputData";

const RenewableRowItem = ({ renewable, renewableTable, onUpdate, rowIdx }) => {
    const style = { textAlign: "left", padding: "1px" };
    const backColor = rowIdx % 2 === 0 ? "#F8F9F9" : null;

    if (!renewable.show) {
        return null;
    }

    return (
        <>
            <tr key={`Renewable_Row_${rowIdx}`} style={{ textAlign: "top", backgroundColor: backColor }}>
                <td style={style}>{renewable.Substation}</td>
                <InputData renewable={renewable} renewableTable={renewableTable} rowIdx={rowIdx} onUpdate={onUpdate} />
                <BtnDeleteRenewableRow renewable={renewable} renewableTable={renewableTable} rowIdx={rowIdx} onUpdate={onUpdate} />
            </tr>
        </>
    );
};

export default RenewableRowItem;
