import React from 'react';
import ShowProfile from '../../../../components/showProfile';

const ViewProfiles = () => {
    return (
        <ShowProfile 
        plotHeight={70}
        plotColWidth="p-col-7" 
        showPickList={true} 
        selectedProfile={null} 
        originalProfile={null}/>
    )
}

export default ViewProfiles;