import React, {useState} from 'react'
import CustomDataTable from '../../../../components/CustomTable/CustomDataTable'
import { ToggleButton } from 'primereact/togglebutton'


const ChkUpdate = (props) => {

    const [showOrgVals, setShowOrgVals] = useState(false)

    let view = <h4>There is no updates available!</h4>

    if (props.lstUpdate.length > 0) {
        view = <CustomDataTable 
        data={props.dataSource}
        lstUpdate={props.lstUpdate}
        columns={props.columns}
        showOrgVals={showOrgVals}
        showUpdates={true}
        tableType="normal"
        IdCol={props.IdCol}
        setLstUpdate={props.setLstUpdate}
        setDataSource={props.setDataSource}/>;
    }

    const toggleShow = () => {
        setShowOrgVals(!showOrgVals);
    }

    return (
        <div>
            <ToggleButton
                style={{ margin: "10px" }}
                onLabel="Turn off"
                offLabel="Show Org. Vals"
                checked={showOrgVals}
                onChange={toggleShow}
            />
            {view}
        </div>
    )
}

export default ChkUpdate;
