import React, { useState, useEffect, useRef } from "react";
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as ApiMisc from "../../../../api/energy-model/miscServices";
import * as tableUtils from "../../../../utils/tables";
import { showCompletionMessage } from "../common/helpers";
import { Toast } from "primereact/toast";
import MainTabView from "../common/main";

const StorageEdit = (props) => {
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [substationMap, setSubstationMap] = useState(null);
    const [storageTypeMap, setStorageTypeMap] = useState(null);
    const [hasErrDB, setHasErrDB] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        updateView();
    }, []);

    const showWarnError = (level, header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    const updateView = () => {
        setIsLoading(true);
        ApiEnergy.getListOfStorage().then((r) => {
            if (!r.hasError) {
                setDataSource(r.data);
            } else {
                setHasError(true);
            }
            setIsLoading(false);
        });

        ApiEnergy.getStorageTypesMapName2Id().then((r) => {
            if (!r.hasError) {
                setStorageTypeMap(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiMisc.getSubstationMapName2Id().then((r) => {
            if (!r.hasError) {
                setSubstationMap(r.data);
            } else {
                setHasError(true);
            }
        });

        if (dataSource && substationMap && storageTypeMap) {
            setIsLoading(false);
        }
    };

    if (hasError) {
        return <h3>An error occured when loading the data. Please refresh the page.</h3>;
    }

    const onSubmit = (lstUpdate, lstNewItems, setLstUpdate, setLstNewItems, setMessage) => {
        setHasErrDB(false);
        if (lstNewItems.length > 0) {
            ApiEnergy.createStorage(lstNewItems, substationMap, storageTypeMap).then((r) => {
                if (!r.hasError) {
                    setLstNewItems([]);
                    updateView();
                } else {
                    showWarnError("error","New items",r.message)
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }

        if (lstUpdate.length > 0) {
            ApiEnergy.updateStorage(lstUpdate, substationMap, storageTypeMap).then((r) => {
                if (!r.hasError) {
                    setLstUpdate([]);
                    updateView();
                } else {
                    showWarnError("warn","Update",r.message)
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            });
        }
    };

    let IdCol = "StorageID";

    let columns = [
        { name: "Tag", label: "Tag", sortable: false, filter: true, type: "dropdown", newRow: { type: "text", isUnique: true } },
        { name: "Substation", label: "Substation", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "StorageType", label: "Storage Type", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "StorageTypeAbbreviation", label: "Storage Type Abbr.", sortable: false, filter: true, type: "dropdown", editable: { type: "dropdown" }, newRow: { type: "dropdown" } },
        { name: "RatingMW", label: "Rating (MW)", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 1 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "CapacityMWh", label: "Capacity (MWh)", sortable: false, filter: true, type: "normal", dataFormat: { type: "float", format: 1 }, editable: { type: "text" }, newRow: { type: "text" } },
        { name: "Available", label: "Available", sortable: false, filter: true, type: "normal", editable: { type: "text" }, newRow: { type: "text" } },
    ];

    let mainView = null;
    if (dataSource) {
        columns = tableUtils.extractUnqVals(dataSource, columns);
        mainView = <MainTabView dataSource={dataSource} setDataSource={setDataSource} title="Storage" IdCol={IdCol} columns={columns} isLoading={isLoading} onSubmit={onSubmit} />;
    }

    return (
        <>
        {mainView}
        <Toast ref={toast} />
        </>
    )
};

export default StorageEdit;
