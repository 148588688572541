import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button} from 'primereact/button';
import { ROUTES } from '../../../../../../settings/settings';
import {confirmDialog} from 'primereact/confirmdialog';


const GoBackButton = (props) => {

    /**
     * @hasConfirmDiag        If there are any items for updates or deleters, then this should be true.
     * 
     */

    const history = useHistory();

    const cancelChanges = () => {
        if (props.hasConfirmDiag){
            const accept = () => {
                history.push(ROUTES.SCENARIO_MANAGE.PATH)
            };
            const reject = () => {
                // toast.currents.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            };
            confirmDialog({
                message: "There are items in queue for update/delete.\nAre you sure you want to cancel them and go back to List of Scenarios menu?",
                header: "Cancel Confirmation",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept,
                reject,
            });
        } else {
            history.push(ROUTES.SCENARIO_MANAGE.PATH)
        }
        
    }

    return (
        <Button label="Cancel & Go Back" style={{ margin: "10px" }} onClick={() => cancelChanges()} className="p-button-danger" />
    )
}


export default GoBackButton;