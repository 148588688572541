import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import * as actionTypeReport from "../../../store/report/actionReport";

const BaselineEmitVerDropdown = (props) => {
    const lstOfBaselineEmitVer = useSelector((state) => state.report.lstOfBaselineEmitVer);
    const selectedBaselineEmission = useSelector((state) => state.report.selectedBaselineEmitVer);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let selecTar = lstOfBaselineEmitVer.filter((s) => s.VersionName === e.target.value)[0];
        dispatch({ type: actionTypeReport.SELEC_BASELINE_EMIT_VER, payload: { response: selecTar } });

        props.onChange(selecTar); // Extra implementation of onChange event in parent component.
    };

    return <Dropdown style={{ marginLeft: "0px" }} optionLabel="VersionName" optionValue="VersionName" value={selectedBaselineEmission.VersionName} options={lstOfBaselineEmitVer} onChange={handleChange} placeholder="Select a Baseline Emission" />;
};

export default BaselineEmitVerDropdown;
