import React from "react";
import AddRowRenewable from "./AddRowRenewable";
import RenewableRowItem from "./RenewableRowItem";

function RenewableTable({ renewableTable, onUpdate, startOverallYr, endOverallYr, selectedSub }) {
    React.useEffect(() => {}, [renewableTable]);
    return (
        <table>
            <tbody style={{ width: "100%" }}>
                <tr style={{ textAlign: "left" }}>
                    <th>Substation</th>
                    <th>Year</th>
                    <th>Solar</th>
                    <th>Wind</th>
                    <th>Storage</th>

                    <th>
                        <AddRowRenewable renewableTable={renewableTable} onUpdate={onUpdate} selectedSub={selectedSub} />
                    </th>
                </tr>
                {renewableTable.map((renewable, idx) => (
                    <RenewableRowItem key={idx} rowIdx={idx} renewable={renewable} startOverallYr={startOverallYr} endOverallYr={endOverallYr} renewableTable={renewableTable} onUpdate={onUpdate}></RenewableRowItem>
                ))}
            </tbody>
        </table>
    );
}

export default RenewableTable;
