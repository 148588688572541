import React, {useState, useEffect,useRef} from 'react'
import * as ApiEnergy from "../../../../api/energy-model/energyServices";
import * as tableUtils from '../../../../utils/tables';
import { showCompletionMessage } from '../common/helpers';
import { Toast } from "primereact/toast";
import MainTabView from '../common/main';

const RenewableSchEdit = (props) => {
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasErrDB, setHasErrDB] = useState(false);
    const [renewableMap, setRenewableMap] = useState(null);
    const toast = useRef(null);
    const showWarnError = (level,header, message) => {
        toast.current.show({ severity: level, summary: header, detail: message, life: 6000 });
    };

    useEffect(() => {
        updateView();
    }, []);


    const updateView = () => {
        setIsLoading(true);

        ApiEnergy.getListOfRenewableSchedule().then((r) => {
            if (!r.hasError) {
                setDataSource(r.data);
            } else {
                setHasError(true);
            }
        });

        ApiEnergy.getRenewablesMapName2Id().then(r => {
            if (!r.hasError) {
                setRenewableMap(r.data);
            } 
            else {
                setHasError(true);
            }
        });

        if ((dataSource) && (renewableMap)) {
            setIsLoading(false);
        }
    };


    if (hasError) {
        return (<h3>An error occured when loading the data. Please refresh the page.</h3>)
    }


    const onSubmit = (lstUpdate, lstNewItems, setLstUpdate, setLstNewItems, setMessage) => {
        setHasErrDB(false);
        if (lstNewItems.length > 0) {
            ApiEnergy.createRenewableSchedule(lstNewItems, renewableMap).then(r => {
                if (!r.hasError) {
                    setLstNewItems([]);
                    updateView();
                }
                else {
                    showWarnError("error","New items",r.message)
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            })
        }
        
        if (lstUpdate.length > 0) {
            ApiEnergy.updateRenewableSchedule(lstUpdate, renewableMap).then(r => {
                if (!r.hasError) {
                    setLstUpdate([]);
                    updateView();
                } else {
                    showWarnError("error","Update",r.message)
                    setHasErrDB(true);
                }
                showCompletionMessage(setMessage, hasErrDB);
            })
        }
    }

    let IdCol = 'RenewableScheduleID' 

    let columns = [
        {name: 'Scenario', label: 'Scenario ID', sortable: false, filter: true, type: 'dropdown', newRow: {type: 'dropdown'}},
        {name: 'RenewableTag', label: 'Renewable Tag', sortable: false, filter: true, type: 'dropdown', editable: {type: 'dropdown'}, newRow: {type: 'dropdown'}},
        {name: 'RenewableType', label: 'Renewable Type', sortable: false, filter: true, type: 'dropdown'},
        {name: 'ratedMW', label: 'Rated (MW)', sortable: false, filter: true, type: 'normal', dataFormat: {type: 'float', format: 2}},
        {name: 'CapacityFactor', label: 'Capacity Factor', sortable: false, filter: true, type: 'normal', dataFormat: {type: 'float', format: 3}},
        {name: 'Year', label: 'Year', sortable: false, filter: true, type: 'normal', editable: {type: 'text'}, newRow: {type: 'text'}}
    ]


    let mainView = null
    if (dataSource) {
        columns = tableUtils.extractUnqVals(dataSource, columns);
        mainView = <MainTabView dataSource={dataSource} setDataSource={setDataSource} title="Renewable-Schedule"
        IdCol={IdCol} columns={columns} isLoading={isLoading} onSubmit={onSubmit}/>
    }

    return (
        <>
        {mainView}
        <Toast ref={toast} />
        </>
    );
}

export default RenewableSchEdit;
