import React, { useState, useEffect } from "react";
import ToolbarRenewable from "./ToolbarRenewable";
import { Input, Tooltip } from "antd";
import renewable_schedule from "../../../../../api/energy-model/data/renewable_schedule.json";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import RenewableCostInput from "./RenewableCostInput";
import RenewableSchedule from "./renewable-schedule";
import RenewableLoading from "./renewable-loading";
import RenewableResults from "./renewable-results";
import HelpCostInputs from "./help/HelpCostInputs";
import renewable_inputs from "../../../../../api/energy-model/data/renewable_inputs.json";
const substations = [
    { SubstationID: 23, Substation: "Dampier", GridCO2Emit: 0.0001 },
    { SubstationID: 24, Substation: "Port Hedland" },
    { SubstationID: 25, Substation: "Lake MacLeod" },
];

const cardStyle = { marginTop: "25px", borderRadius: 0 };

function Renewables({ results, setResults, sharedData, renewableInputs, setRenewableInputs, transition, setTransition, funcsOnMonthly, setFuncsOnMonthly, renewableOutputs, setRenewableOutputs,importDropdown,setImportDropdown  }) {
    const renewDeepCopy = global.structuredClone(renewable_schedule);
    const [dailyRand, setDailyRand] = useState(renewableInputs ? renewableInputs["input"]["max_rand_change"] : 0.1);
    const [renewableTable, setRenewableTable] = useState([]);
    const [selectedResult, setSelectedResult] = useState(results ? results[0] : null);
    const [startOverallYr, setStartOverallYr] = useState(results ? results[0].years[0] : null);
    const [endOverallYr, setEndOverallYr] = useState(results ? results[0].years[results[0].years.length - 1] : null);
    const [apiResult, setApiResult] = useState([]);
    const [apiRows, setApiRows] = useState([]);
    const [apiSummaryRows, setApiSummaryRows] = useState([]);
    const [apiEmissionResults, setApiEmissionResults] = useState([]);
    const [costInput, setCostInput] = useState(
        renewableInputs
            ? renewableInputs["input"]["costInput"]
            : [
                  { name: "windInstUnitCost", dispName: "Wind Install Cost", unit: "M$/MW", value: 3.1, tooltipData: "Installation cost (in million $ AUD) per MW of installed capacity" },
                  { name: "solarInstUnitCost", dispName: "Solar Installation Cost", unit: "M$/MW", value: 2.5, tooltipData: "Installation cost (in million $ AUD) per MW of installed capacity" },
                  { name: "storageInstUnitCost", dispName: "Storage Installation Cost", unit: "M$/MW", value: 0.5, tooltipData: "Installation cost (in million $ AUD) per MW of installed capacity" },
                  { name: "storageInstUnitCostRate", dispName: "Storage Invertor Cost", unit: "M$/MWh", value: 0.5, tooltipData: "Installation cost (in million $ AUD) per MWh of installed storage capacity " },
                  { name: "wOMUnitCost", dispName: "Wind O&M Cost", unit: "M$/MW", value: 0.064285714285, tooltipData: "Installation cost (in million $ AUD) per MW of installed storage inverter capacity" },
                  { name: "sOMUnitCost", dispName: "Solar O&M Cost", unit: "M$/MW", value: 0.017, tooltipData: "Operations and Maintenance cost (in million $ AUD) per MW of installed capacity" },
                  { name: "storageOMUnitCost", dispName: "Storage O&M Cost", unit: "M$/MW", value: 0.03571428571428, tooltipData: "Operations and Maintenance cost (in million $ AUD) per MW of installed capacity" },
                  { name: "gridCost", dispName: "Grid Cost", unit: "$/MW", value: 300, tooltipData: "Cost per MWh from network provider ($ AUD)" },
                  { name: "dieselCost", dispName: "Diesel Cost", unit: "$/L", value: 0.87, tooltipData: "Cost per L ($ AUD)" },
                  { name: "bioDieselCost", dispName: "Bio Diesel Cost", unit: "$/L", value: 1.39, tooltipData: "Cost per L ($ AUD)" },
                  { name: "runLife", dispName: "Run Life", unit: "Years", value: 20, tooltipData: "Asset lifetime" },
              ]
    );
    const [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        setRenewableTable(renewableInputs ? renewableInputs["input"]["tbl_renew_schl"] : renewDeepCopy);
    }, []);

    useEffect(() => {
        if (renewableOutputs) {
            let apiRenewResult = renewableOutputs;
            setApiResult(apiRenewResult);
            if (apiRenewResult.data) {
                setApiRows(apiRenewResult.data.summary_result);
                setApiSummaryRows(apiRenewResult.data.summary_total_result);
                setApiEmissionResults(apiRenewResult.data.emission_calcs);
            }
        }
    }, [renewableOutputs]);

    const onUpdate = React.useCallback(
        (updatedrenewTable) => {
            setRenewableTable(updatedrenewTable);
        },
        [renewableTable]
    );

    useEffect(() => {
        setSelectedResult(results[importDropdown]);
    }, [results, importDropdown]);



    useEffect(() => {
        setDailyRand(renewableInputs["input"]["max_rand_change"]);
        setCostInput(renewableInputs["input"]["costInput"]);
        setRenewableTable(renewableInputs["input"]["tbl_renew_schl"]);
    }, [renewableInputs]);

    const handleResultChange = (e) => {
        setSelectedResult(e.target.value);
        setStartOverallYr(e.target.value.years[0]);
        setEndOverallYr(e.target.value.years[e.target.value.years.length - 1]);
        if (e.target.value["Renewables"]) {
            setRenewableInputs(e.target.value["Renewables"][0]);
            if (e.target.value["Renewables"][0]) {
                if (e.target.value["Renewables"][0]["output"]) {
                    let apiRenewResult = e.target.value["Renewables"][0]["output"];
                    setApiResult(apiRenewResult);
                    setApiRows(apiRenewResult.data.summary_result);
                    setApiSummaryRows(apiRenewResult.data.summary_total_result);
                    setApiEmissionResults(apiRenewResult.data.emission_calcs);
                }
            }
        } else {
            const tempRenewableInputs = global.structuredClone(renewable_inputs);
            setRenewableInputs(tempRenewableInputs);
            setApiResult([]);
            setApiRows([]);
            setApiSummaryRows([]);
            setApiEmissionResults([]);
        }
        setDailyRand(renewableInputs["input"]["max_rand_change"]);
        setCostInput(renewableInputs["input"]["costInput"]);
        setRenewableTable(renewableInputs["input"]["tbl_renew_schl"]);
    };

    const onApiCall = (apiRenewResult) => {
        setApiResult(apiRenewResult);
        setApiRows(apiRenewResult.data.summary_result);
        setApiSummaryRows(apiRenewResult.data.summary_total_result);
        setApiEmissionResults(apiRenewResult.data.emission_calcs);
    };

    const apiCallLoad = React.useCallback(
        (isRunning) => {
            setApiLoading(isRunning);
        },
        [apiLoading]
    );

    const mainInputs = React.useMemo(() => {
        return (
            <Card title="Main Inputs" style={cardStyle}>
                <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                    <span style={{ marginRight: "10px" }}>Select Load Result</span>
                    <Dropdown options={results} value={selectedResult} optionLabel="nameDateTime" onChange={handleResultChange} />
                </div>
                <div className="p-grid p-align-center" style={{ margin: "10px" }}>
                    <span style={{ marginRight: "10px" }}>Daily Load Randomness</span>
                    <Tooltip placement="topRight" title="A random variation applied to the hourly load profile. Value between 0 (no variation) and 0.5 (up to 50% + or – the average load.">
                        <Input style={{ width: "100px" }} value={dailyRand} onChange={(e) => setDailyRand(e.target.value)} tooltip="Enter your username" />
                    </Tooltip>
                </div>
            </Card>
        );
    }, [selectedResult, results, dailyRand]);

    const aiGuide = React.useMemo(() => {
        return (
            <Card title="Renewable/Storage Schedule" style={cardStyle}>
                <RenewableSchedule subs={substations} renewableTable={renewableTable} onUpdate={onUpdate} startOverallYr={startOverallYr} endOverallYr={endOverallYr} selectedResult={selectedResult} />
            </Card>
        );
    }, [renewableTable, startOverallYr, endOverallYr, selectedResult, substations]);

    const costInputUI = React.useMemo(() => {
        return (
            <Card style={cardStyle}>
                <div className="p-grid p-align-center">
                    <h2>Cost Inputs</h2>

                    <HelpCostInputs />
                </div>
                <RenewableCostInput costInput={costInput} setCostInput={setCostInput} />
            </Card>
        );
    }, [costInput]);

    return (
        <>
            <ToolbarRenewable
                selectedResult={selectedResult}
                sharedData={sharedData}
                costInput={costInput}
                renewableTable={renewableTable}
                startOverallYr={startOverallYr}
                endOverallYr={endOverallYr}
                onUpdate={onUpdate}
                apiResult={apiResult}
                setApiResult={setApiResult}
                dailyRand={dailyRand}
                onApiCall={onApiCall}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
                apiCallLoad={apiCallLoad}
                substations={substations}
                results={results}
                setResults={setResults}
                transition={transition}
                funcsOnMonthly={funcsOnMonthly}
                setFuncsOnMonthly={setFuncsOnMonthly}
                setTransition={setTransition}
                setRenewableInputs={setRenewableInputs}
                renewableOutputs={renewableOutputs}
                setRenewableOutputs={setRenewableOutputs}
                setImportDropdown = {setImportDropdown}
            />
            <Accordion multiple activeIndex={0}>
                <AccordionTab header="Inputs">
                    {mainInputs}
                    {costInputUI}
                    {aiGuide}
                </AccordionTab>
            </Accordion>
            <RenewableResults apiRows={apiRows} apiResult={apiResult} apiSummaryRows={apiSummaryRows} substations={substations} apiEmissionResults={apiEmissionResults} startOverallYr={startOverallYr} endOverallYr={endOverallYr} />
            <RenewableLoading apiLoading={apiLoading} />
        </>
    );
}

export default Renewables;
