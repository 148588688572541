import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PowerChart from "./powerChart";

import { error } from "../../../../store/error/actionError";
import ErrorPage from "../../../../components/error/ErrorPage";

import SideFilter from "../../../../components/Filters";

import * as actionTypeReport from "../../../../store/report/actionReport";
import * as APIEnergy from "../../../../api/energy-model/energyServices";
import * as tbUtils from "../../../../utils/tables";

import { extractUnqVals } from "../../../../utils/comm";
//IMPORT STYLES
import "primeflex/primeflex.css";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import { extractYearArray } from "../../../../utils/comm";

const convertToDataSource = (scenarioDetail, years, valueKeyName, yValueName, labels) => {
    let dataSource = [];
    if (scenarioDetail) {
        labels.forEach((l) => {
            let dataCat = scenarioDetail.filter((r) => r[valueKeyName] === l);

            var dataCur = [];
            years.forEach((yr) => {
                var d = dataCat.filter((r) => parseInt(r.Year) === yr);

                if (d.length > 0) {
                    dataCur.push(parseFloat(d[0][yValueName]));
                } else {
                    dataCur.push(null);
                }
            });

            dataSource.push({
                name: l,
                data: dataCur,
            });
        });
    }
    return dataSource;
};

const PowerFigures = () => {
    const [yLabel, setYLabel] = useState("GWh-equivalent");
    const lstOfFunctions = useSelector((state) => state.report.lstOfFunctions);
    let lstOfSites = useSelector((state) => state.report.lstOfMines);
    const lstOfAllSiteDataRecords = useSelector((state) => state.report.lstOfAllMineDataRecords);

    const errorMsg = useSelector((s) => s?.error.msg);

    const selectedScenario = useSelector((state) => state.report.selectedScenario);
    const selectedSiteDataRecord = useSelector((state) => state.report.selectedMineDataRecord);
    const funcLabels = lstOfFunctions.map((r) => r.Function);

    const lstOfEnergies = useSelector((state) => state.report.lstOfEnergies);

    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);
    const [years, setYears] = useState([]);

    const dispatch = useDispatch();

    const [chartPowerDataSource, setChartPowerDataSource] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const updateData = (scenario, selectedRecord) => {
        if (scenario && selectedRecord) {
            dispatch({ type: actionTypeReport.SELECT_MINE_DATA_RECORD, payload: { response: selectedRecord } });

            const syear = 2021;
            const eyear = selectedUserAccess.ProjectID === 1 ? 2050 : 2030;

            setIsLoading(true);

            APIEnergy.getEnergyEmissionCalculations(scenario.PathwayScenarioID, -1, syear, eyear, "PathwayScenarioID,Year,Site,SiteID,Function,FunctionID,EnergyTag", "EnergyAmount,MWh-e").then((res) => {
                if (!res.hasError) {
                    let yrs = extractYearArray(res.data, "Year");
                    setYears(yrs);
                    setDataSource(res.data);
                    let selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
                    let selectedEnergies = lstOfEnergies.filter((f) => f.checked).map((r) => r.EnergyTag);
                    updateDataByFilters(res.data, yrs, selectedSites, selectedEnergies);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    dispatch(error(res.message));
                    throw new Error(res.message);
                }
            });
        }
    };

    const updateDataByFilters = (data, yrs, selectedSites, selectedEnergies) => {
        const flt_data = data.filter((r) => selectedSites.includes(r.SiteID) && selectedEnergies.includes(r.EnergyTag));

        const unqEnergies = extractUnqVals(flt_data, "EnergyTag");

        const y = unqEnergies.includes("GridPower") ? "MWh-e" : "EnergyAmount";
        setYLabel(unqEnergies.includes("GridPower") ? "GWh-equivalent" : "kL");
        const rdc_data = tbUtils.groupByTotal(flt_data, ["Year", "Function", "FunctionID"], [y]);

        setChartPowerDataSource(convertToDataSource(rdc_data, yrs, "Function", y, funcLabels));
    };

    const handleScenarioChange = (scenario) => {
        updateData(scenario, selectedSiteDataRecord);
    };

    const handleRecordChange = (e) => {
        let selecRecord = lstOfAllSiteDataRecords.filter((s) => s.DataVersion === e.target.value)[0];
        updateData(selectedScenario, selecRecord);
    };

    const handleSiteFilter = (updatedItems) => {
        let selectedSites = updatedItems.filter((f) => f.checked).map((r) => r.SiteID);
        let selectedEnergies = lstOfEnergies.filter((f) => f.checked).map((r) => r.EnergyTag);
        updateDataByFilters(dataSource, years, selectedSites, selectedEnergies);
    };

    const handleEnergyChange = (updatedItems) => {
        let selectedEnergies = updatedItems.filter((f) => f.checked).map((r) => r.EnergyTag);
        let selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
        updateDataByFilters(dataSource, years, selectedSites, selectedEnergies);
    };

    useEffect(() => {
        setAccessToken(instance, accounts).then(
            (resolve) => {
                updateData(selectedScenario, selectedSiteDataRecord);
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    if (errorMsg) {
        return <ErrorPage msg={errorMsg} />;
    }

    // let ylabel = "GWh-equivalent";

    const dummyFunc = () => {
        console.log("this is a dummy function.");
    };

    const sideFilters = (
        <SideFilter
            hasScenarioFilter={true}
            hasBaselineFilter={false}
            hasTargetVerFilter={false}
            hasSiteFilter={true}
            hasTMMSOPFilter={false}
            hasSiteSearchBar={true}
            hasFuncFilter={false}
            hasEnergyFilter={true}
            handleScenarioChange={handleScenarioChange}
            handleTargetVerChange={dummyFunc}
            handleBaselineVerChange={dummyFunc}
            onChangeCheckedSites={handleSiteFilter}
            onChangeCheckedEnergies={handleEnergyChange}
            onChangeCheckedFuncs={dummyFunc}
            handleTMMSOPVerChange={handleRecordChange}
            isOverlay={false}
            maxHeightSiteFilter="400px"
            customFilt={null}
        />
    );

    return <PowerChart filter={sideFilters} dataSource={chartPowerDataSource} legends={funcLabels} xaxis={years} ylabel={yLabel} isLoading={isLoading} />;
};

export default PowerFigures;
