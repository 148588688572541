import React from "react";
import Help from "../../../../../../components/Buttons/Help";
const HelpCostInputs = () => {
    return (
        <Help width="400px">
            <div>Note: The AI guideline is built on the default cost values. The guideline will no longer predict the cost optimal solution if these cost values are modified.</div>
        </Help>
    );
};

export default HelpCostInputs;
