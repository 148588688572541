import React from "react";
import PropTypes from "prop-types";
import { ListBox } from "primereact/listbox";

const ListMonthlyProfiles = ({ monthlyProfiles, setSelectedProfile, selectedProfile }) => {
    return (
        <div>
            <h5>Monthly Profiles</h5>
            <ListBox value={selectedProfile} options={monthlyProfiles} onChange={(e) => setSelectedProfile(e.value)} optionLabel="MonthlyProfileName" style={{ width: "200px" }} />
        </div>
    );
};

ListMonthlyProfiles.propTypes = {};

export default ListMonthlyProfiles;
