import React from "react";
import { Button,Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const BtnRemovePath = ({ onUpdate, trans, pathIdx }) => {
    const handleDeletePath = () => {
        const tempData = global.structuredClone(trans);
        tempData.ReplacePaths.splice(pathIdx, 1);
        onUpdate(tempData);
    };

    return (
        <>
            <td>
                <Tooltip placement="left" title="Remove transition pathway">
                    <Button icon={<DeleteOutlined />} danger type="text" onClick={handleDeletePath} />
                </Tooltip>
            </td>
        </>
    );
};

BtnRemovePath.propTypes = {};

export default BtnRemovePath;
