import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "primereact/card";
import * as ST from "../../settings/settings";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import * as userApi from "../../api/auth/userServices";
import * as actionUser from "../../store/user/actionUser";
import RefreshPage from "../../components/StartUp/RefreshPage";

import SignInButton from "./SignInButton";

export const AuthPage = () => {
    /**
     * Authentication Page
     *
     * This page use SSO with MSAL for Sign In purposes.
     * After signing in with the organizational credentials,
     * the function checks if the users exists in the database and
     * if the uses is an approved used.
     * If so then user gets a valid access toke, to interact with
     * the backend API.
     */
    const [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState("Please Sign In");
    const { instance, inProgress, accounts } = useMsal();

    const isAuthAD = useIsAuthenticated();
    const dispatch = useDispatch();

    let spanStyle = { display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" };
    let cardStyle = { position: "absolute", top: "25%", left: "40%", width: "400px", height: "500px" };

    // if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    //     return (
    //         <div style={{ width: "100%", height: "100vh" }} className="p-grid p-justify-center p-align-center">
    //             <h4>Azure Authentication in progress...</h4>
    //         </div>
    //     );
    // }

    if (hasError) {
        return (
            <div style={{ width: "100%", height: "100vh", color: "red" }} className="p-grid p-justify-center p-align-center">
                {/* <h4>{message}</h4> */}
                <RefreshPage message="Something went wrong, please refresh. If you continue to get this error, contact admins." />
            </div>
        );
    }

    if (isAuthAD) {
        let username = accounts[0].username;
        userApi.getAccessToken(instance, accounts).then(
            (token) => {
                userApi.attachAccessTokenToHeader(token);
                userApi.getUserByName(username).then(
                    (res) => {
                        if (res.hasError) {
                            setHasError(true);
                            setMessage(res.message);
                        } else {
                            if (res.data[0].AdmitStat.toLowerCase() === "approved") {
                                // USER IS REGISTERED AND APPROVED
                                dispatch({
                                    type: actionUser.SET_USER,
                                    payload: { userDB: res.data },
                                });
                            } else {
                                // USER IS REGISTERED BUT NOT APPROVED
                                setHasError(true);
                                setMessage("Registration request is pending!");
                            }
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }

    if (!isAuthAD) {
        return (
            <>
                <Card style={cardStyle}>
                    <span style={spanStyle}>
                        <img src={ST.LOGO_WORLEY} alt="logo" style={{ height: "40px", marginTop: "40px" }} />
                    </span>
                    <span style={spanStyle}>
                        <h4>Welcome to Net Zero</h4>
                    </span>
                    <span style={spanStyle}>
                        <p style={hasError ? { color: "red" } : { color: "blue" }}>{message}</p>
                    </span>
                    <span style={spanStyle}>
                        <SignInButton />
                    </span>
                </Card>
            </>
        );
    } else {
        return <h1>Authorizing user</h1>;
    }
};
