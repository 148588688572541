import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TechCountChartOverYears from "./techCountChart";

import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import SideFilter from "../../../../components/Filters";

import * as APIEnergy from "../../../../api/energy-model/energyServices";
import * as tbUtils from "../../../../utils/tables";
//IMPORT STYLES
import "primeflex/primeflex.css";

import { useMsal } from "@azure/msal-react";
import { setAccessToken } from "../../../../api/auth/userServices";
import RefreshSignIn from "../../../../components/StartUp/RefreshSignIn";
import { extractYearArray } from "../../../../utils/comm";

const convertToBarChartDataSource = (res, yrs) => {
    let techs = [...new Set(res.map((item) => item.Technology))];
    let data = [];

    if (res) {
        techs.forEach((item) => {
            let currData = res.filter((r) => r.Technology === item);
            data.push({
                name: item,
                type: "column",
                data: currData.length === yrs.length ? currData.map((r) => parseInt(r.TechnologyCount)) : yrs.map((yr) => 0.0),
            });
        });
    }
    return data;
};

const TechCountChart = (props) => {
    const lstOfScenarios = useSelector((state) => state.report.lstOfScenarios);
    const lstOfFunctions = useSelector((state) => state.report.lstOfFunctions);
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    let lstOfSites = useSelector((state) => state.report.lstOfMines);

    const selectedScenario = useSelector((state) => state.report.selectedScenario);

    const { instance, accounts } = useMsal();
    const [hasToken, setHasToken] = useState(true);
    const [years, setYears] = useState([]);

    const excludesFuncs = ["Processing", "Rail", "Port"];

    const funcLabels = lstOfFunctions.filter((r) => !excludesFuncs.includes(r.Function)).map((r) => r.Function);
    const [selectedFunction, setSelectedFunction] = useState(funcLabels[0]);

    const [chartDataSource, setChartDataSource] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const updateData = (scenario) => {
        if (scenario) {
            setIsLoading(true);
            const eyear = selectedUserAccess.ProjectID === 1 ? 2050 : 2030;

            APIEnergy.getEnergyEmissionCalculations(scenario.PathwayScenarioID, -1, 2021, eyear, "PathwayScenarioID,Year,Site,SiteID,Function,FunctionID,Technology", "TechnologyCount").then((res) => {
                if (!res.hasError) {
                    let yrs = extractYearArray(res.data, "Year");
                    setYears(yrs);
                    setDataSource(res.data);
                    let selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
                    updateDataByFilters(res.data, yrs, selectedSites, selectedFunction);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    throw new Error(res.message);
                }
            });
        }
    };

    const updateDataByFilters = (data, yrs, selectedSites, selecFunc) => {
        let flt_data = data.filter((r) => selectedSites.includes(r.SiteID) && r.Function === selecFunc);
        let rdc_data = tbUtils.groupByTotal(flt_data, ["Year", "Function", "FunctionID", "Technology"], ["TechnologyCount"]);

        setChartDataSource(convertToBarChartDataSource(rdc_data, yrs));
    };

    const handleScenarioChange = (scenario) => {
        let selectedScenario = lstOfScenarios.filter((s) => s.PathwayScenarioID === scenario.PathwayScenarioID)[0];
        updateData(selectedScenario);
    };

    const handleSiteFilter = (updatedItems) => {
        let selectedSites = updatedItems.filter((f) => f.checked).map((r) => r.SiteID);
        updateDataByFilters(dataSource, years, selectedSites, selectedFunction);
    };

    const handleFunctionChange = (e) => {
        setSelectedFunction(e.target.value);
        let selectedSites = lstOfSites.filter((f) => f.checked).map((r) => r.SiteID);
        updateDataByFilters(dataSource, years, selectedSites, e.target.value);
    };

    useEffect(() => {
        setAccessToken(instance, accounts).then(
            (resolve) => {
                updateData(selectedScenario);
                setHasToken(true);
            },
            (err) => {
                setHasToken(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hasToken) {
        return <RefreshSignIn />;
    }

    let funcFilt = (
        <React.Fragment>
            <Divider style={{ marginTop: "20px" }} align="left" type="solid">
                <b>Functions</b>
            </Divider>
            <Dropdown value={selectedFunction} options={funcLabels} onChange={handleFunctionChange} placeholder="Select a Function" />
        </React.Fragment>
    );

    const dummyFunc = () => {};

    const sideFilters = (
        <SideFilter
            hasScenarioFilter={true}
            hasBaselineFilter={false}
            hasTargetVerFilter={false}
            hasSiteFilter={true}
            hasTMMSOPFilter={false}
            hasSiteSearchBar={true}
            handleScenarioChange={handleScenarioChange}
            handleTargetVerChange={dummyFunc}
            handleBaselineVerChange={dummyFunc}
            onChangeCheckedSites={handleSiteFilter}
            handleTMMSOPVerChange={dummyFunc}
            isOverlay={false}
            maxHeightSiteFilter="400px"
            customFilt={funcFilt}
        />
    );

    return <React.Fragment>{<TechCountChartOverYears filter={sideFilters} dataSource={chartDataSource} labels={years} ylabel="Technology Count" isLoading={isLoading} />}</React.Fragment>;
};

export default TechCountChart;
