import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Route, Redirect, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// HANDLE AUTHENTICATION
import { AuthPage } from "./views/auth/AuthPage";
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

//API
import * as API from "./api/energy-model/miscServices";
import * as userApi from "./api/auth/userServices";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import ErrorBoundary from "./components/error/ErrorBoundary";
import ErrorPage from "./components/error/ErrorPage";

import * as ST from "./settings/settings";

import Loading from "./components/Loading";
import RefreshPage from "./components/StartUp/RefreshPage";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";

const layoutMode = "static";
const layoutColorMode = "light";
const inputStyle = "outlined";
const ripple = true;

const WrapperLayout = ({ menuItemRoutes, role }) => {
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        document.documentElement.style.fontSize = "13px";
    }, [mobileMenuActive]);

    PrimeReact.ripple = true;

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            setStaticMenuInactive((prevState) => !prevState);
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={ST.MENU_ITEMS} role={role} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <ErrorBoundary>
                        <Switch>
                            {menuItemRoutes}
                            <Redirect from="/" to="/" />
                        </Switch>
                    </ErrorBoundary>
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>
            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

const App = () => {
    const selectedUserAccess = useSelector((state) => state.user.selectedUserAccess);
    const accessToken = useSelector((s) => s.user.accessToken);

    const [isLoading, setLoading] = useState(true);
    const [refreshRequired, setRefreshRequired] = useState(false);

    const isProjectOnChange = useSelector((s) => s.report.isProjectOnChange);

    const menuItemKeys = Object.keys(ST.ROUTES).filter((key) => ST.ROUTES[key].TYPE === "menu");
    let menuItemRoutes = null;

    const isAuthAD = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();

    const dispatch = useDispatch();

    if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
        return <Loading />;
    }

    if (isProjectOnChange) {
        return (
            <>
                <Loading />
                <div style={{ textAlign: "center" }}>
                    <h3>Loading {selectedUserAccess.ProjectName} ...</h3>
                </div>
            </>
        );
    }

    if (isAuthAD) {
        userApi.setAccessToken(instance, accounts, dispatch);
    }

    if (selectedUserAccess !== null && isAuthAD && accessToken !== null) {
        API.getAllComms(dispatch, selectedUserAccess.ProjectID).then((res) => {
            if (res) {
                console.log("Successful!");
            } else {
                setRefreshRequired(true);
            }
            setLoading(false);
        });

        if (isLoading) {
            return <Loading />;
        }

        if (refreshRequired) {
            return <RefreshPage message="The backend API was in sleep mode and Data was not loaded properly. Please refresh the page." />;
        }

        let role = selectedUserAccess.UserRole;

        menuItemRoutes = menuItemKeys.map((key) => {
            if (ST.ROUTES[key].ROLES.includes(role) || ST.ROUTES[key].ROLES.includes("all")) {
                return ST.ROUTES[key].COMPONENT ? (
                    <Route
                        key={key}
                        path={ST.ROUTES[key].PATH}
                        exact={ST.ROUTES[key].EXACT}
                        name={ST.ROUTES[key].NAME}
                        // isLoggedIn = {this.props.isLoggedIn}
                        component={ST.ROUTES[key].COMPONENT}
                    />
                ) : null;
            } else {
                return null;
            }
        });

        return (
            <React.Fragment>
                <AuthenticatedTemplate>
                    <WrapperLayout role={role} menuItemRoutes={menuItemRoutes} />
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <h5>There was a problem with Authentication.</h5>
                </UnauthenticatedTemplate>
            </React.Fragment>
        );
    } else {
        return (
            <>
                <Switch>
                    <Route key="SignIn" path="/login" exact={true} name="signin" component={AuthPage} />
                    <Redirect from="/" to="/login" />
                </Switch>
            </>
        );
    }
};

export default App;
