import React, {useState} from 'react'
import { Card } from 'primereact/card';
import NetItemDiagContent from './component/newItemDiagContent'
import { Button } from 'primereact/button'
import NewItemDiag from './component/newItemDiag';


const getRowColInfo = (newItem, columns) => {
    return columns.map(c => { return {...c, value: newItem[c.name]}})
}

const ChkNewItems = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [addRowVals, setAddRowVals] = useState([])
    const [selectedIdx, setSelectedIdx] = useState(null)

    const handleEdit = (e,s,idx) => {
        setSelectedIdx(idx)
        setAddRowVals(getRowColInfo(s, props.columns));
        setShowDialog(true);
    }

    const handleCancel = (e,s,idx) => {
        let items = [...props.lstNewItems];
        items.splice(idx, 1)
        props.setLstNewItems(items);
    }

    const footer = (s,idx) =>{
        return (
            <div className='p-grid p-justify-between'>
                <Button className='p-button-rounded p-button-danger' onClick={(e) => handleCancel(e,s,idx)}>Remove</Button>
                <Button className='p-button-rounded p-button-danger' onClick={(e) => handleEdit(e,s,idx)}>Edit</Button>
            </div>
        )
    } 

    let view = <h4>There is no new additions</h4>
    if (props.lstNewItems.length > 0) {
        view = props.lstNewItems.map((item,idx) => {
            let rowVals = getRowColInfo(item, props.columns);
            return (
                <Card 
                key={`new_Item_view_${item.name}_${idx}`}
                footer={footer(item, idx)} 
                style={{ width: '25em', margin:"20px", background: '#14249F', color:'white'}} >
                    <NetItemDiagContent addRowVals={rowVals} mode='read'/> 
                </Card>
            )
        })
    }

    return (
        <React.Fragment>
            <NewItemDiag showDialog={showDialog} setShowDialog={setShowDialog} 
            diagMode='edit'
            idx={selectedIdx}
            lstNewItems={props.lstNewItems} setLstNewItems={props.setLstNewItems} 
            dataSource={props.dataSource} setDataSource={props.setDataSource}
            addRowVals={addRowVals} setAddRowVals={setAddRowVals}/>
            <div className='p-grid'>
                {view}
            </div>
        </React.Fragment>
    );    
}

export default ChkNewItems;
