import React, { useMemo } from "react";
//IMPORT LIBS
//IMPORT COMPONENTS
import HtmlBubbleLayer from "./HtmlBubbleLayer"
//IMPORT STYLES
import "primeflex/primeflex.css";
import * as ST_MAP from '../mapSettings'

import { AzureMap, AzureMapDataSourceProvider, AzureMapsProvider } from "react-azure-maps";
import { AuthenticationType } from "azure-maps-control";

const colorLevels = ST_MAP.COLORS_HEAT_MAP;

const returnColorLevel = (value, valueMax) => {
    let idx = parseInt((value / valueMax) * colorLevels.length - 0.00000001);
    idx = idx < 0 ? 0 : idx;
    return colorLevels[idx];
};

const returnRadius = (value, valueMax) => {
    let scale = 100;
    return parseInt((value / valueMax) * scale) + parseInt(scale * 0.1);
};



const renderPoints = (dataSource, maxValue, field) => {
    return dataSource.map((r) => {
        let size = returnRadius(r[field], maxValue)
        let color = returnColorLevel(r[field], maxValue)
        // return markerHtmlCircle({
        //     key: r.SubstationID,
        //     radius: `${size}px`,
        //     color: `${color}`,
        //     opacity: 0.8,
        //     lat: r.Longitude,
        //     lng: r.Latitude,
        //     text: r[field],
        //     data: r
        // });

        return <HtmlBubbleLayer key = {r.SubstationID} radius = {`${size * 2}px`} color={`${color}`} 
        opacity={0.8} lat={r.Longitude} lng={r.Latitude} text={r[field]} data={r}/>
    });
};

const MapDemandAzure = (props) => {

    let isThereData = ((props.dataSource !== null) && (props.dataSource.length > 0))

    let view = <h1>There is no map data to display for this Scenario</h1>;
    let posCenter = isThereData ? [props.dataSource[0].Longitude, props.dataSource[0].Latitude] : [120.9, -26.67];


    const option = useMemo(() => {

        return {
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: process.env.REACT_APP_AZURE_MAP,
            },
            center: posCenter,
            zoom: 6,
            view: "Auto",
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isThereData) {
        view = (
            <AzureMapsProvider>
                <div style={{ height: "60vh" }}>
                    <AzureMap options={option}>
                        <AzureMapDataSourceProvider id={"MultiplePoint AzureMapDataSourceProvider"}>
                            {renderPoints(props.dataSource, props.maxValue, props.field)}
                        </AzureMapDataSourceProvider>
                    </AzureMap>
                </div>
            </AzureMapsProvider>
        );
    }

    return <>{view}</>;
};

export default MapDemandAzure;
