import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Badge } from "primereact/badge";
import "./CustomDataTable.css";
import * as commUtils from "../../utils/comm";

const CustomDataTable = (props) => {
    const [first1, setFirst1] = useState(0);
    const [rows1, setRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // ****************************************************************************
    // PAGINATION
    // ****************************************************************************
    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page + 1);
    };

    const onPageInputKeyDown = (event, options) => {
        if (event.key === "Enter") {
            const page = parseInt(currentPage);
            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
            } else {
                const first = currentPage ? options.rows * (page - 1) : 0;

                setFirst1(first);
                setPageInputTooltip("Press 'Enter' key to go to this page.");
            }
        }
    };

    const onPageInputChange = (event) => {
        setCurrentPage(event.target.value);
    };

    const template1 = {
        layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
        PrevPageLink: (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Previous</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Next</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { "p-disabled": true });

                return (
                    <span className={className} style={{ userSelect: "none" }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                // { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Go to <InputText size="2" className="p-ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange} />
                </span>
            );
        },
    };
    // ****************************************************************************

    // ****************************************************************************
    // MAIN BODY TEMPLATE
    // ****************************************************************************
    const mainBodyTemplate = (rowData, options) => {
        let col = props.columns.filter((c) => c.name === options.field)[0];
        let hasChanged = rowData[`${options.field}_hasChanged`];
        let isNewItem = rowData["editStatus"] === "new";

        let color = null;
        if (isNewItem) {
            color = "green";
        } else {
            if (hasChanged) {
                color = "red";
            } else {
                color = "black";
            }
        }
        let oldVal = hasChanged ? rowData[`${options.field}_oldVal`] : null;
        let badge = props.showOrgVals && hasChanged ? <Badge value={oldVal} severity="info" style={{ margin: "2px" }} /> : null;

        let val = rowData[options.field];
        if (col.dataFormat) {
            val = commUtils.formatValueData(val, col.dataFormat);
        }
        return (
            <React.Fragment>
                <span style={{ color: color }}>
                    {val}
                    {badge}
                </span>
            </React.Fragment>
        );
    };

    // ****************************************************************************
    // HANDLE CELL EDITS
    // ****************************************************************************
    const cellEditor = (options) => {
        const colInfo = props.columns.filter((c) => c.name === options.field)[0];
        if (colInfo.editable.type === "text") {
            return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
        } else {
            if (colInfo.editable.items) {
                let item = colInfo.editable.items.filter((r) => r[colInfo.editable.field] === options.value)[0];
                return <Dropdown optionLabel={colInfo.editable.field} optionValue={colInfo.editable.field} value={item[colInfo.editable.field]} options={colInfo.editable.items} onChange={(e) => onDropdownChange(e, options)} />;
            } else {
                return <Dropdown value={options.value} options={colInfo.unqVals} onChange={(e) => onDropdownChange(e, options)} />;
            }
        }
    };

    const onDropdownChange = (e, options) => {
        let { rowData, field } = options;
        updateDataSource(rowData, e.value, field);
    };

    const updateDataSource = (rowData, newValue, field) => {
        let newData = props.data.map((r) => {
            if (r[props.IdCol] === rowData[props.IdCol] && r[field] !== newValue) {
                r[`${field}_oldVal`] = r[`${field}_hasChanged`] ? r[`${field}_oldVal`] : r[field];
                r[field] = newValue;
                r[`${field}_hasChanged`] = true;
                props.setLstUpdate(commUtils.updateLstOfUpdate(props.lstUpdate, r, props.IdCol));
            }
            return r;
        });
        props.setDataSource(newData);
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field } = e;
        updateDataSource(rowData, newValue, field);
    };

    // ****************************************************************************
    // HANDLE COLUMN FILTERS
    // ****************************************************************************
    const dropdownRowFilterTemplate = (options) => {
        const colInfo = props.columns.filter((c) => c.name === options.field)[0];
        let unqVals = [];
        if (colInfo) {
            unqVals = colInfo.unqVals;
        }
        return <Dropdown value={options.value} options={unqVals} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={dropdownItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const dropdownItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    };

    // ****************************************************************************

    let view = null;
    if (props.tableType === "normal") {
        let dataView = props.showUpdates ? props.lstUpdate : props.data;
        view = (
            <DataTable
                size={props.size}
                value={dataView}
                responsiveLayout="scroll"
                // selectionMode="single"
                // selection={selectedProduct1} onSelectionChange={e => setSelectedProduct1(e.value)}
                // showGridlines
                paginator
                paginatorTemplate={template1}
                first={first1}
                rows={rows1}
                onPage={onCustomPage1}
            >
                {props.columns.map((col) => {
                    let editor = col.editable ? (options) => cellEditor(options) : null;
                    let editComplete = col.editable && col.editable.type === "text" ? onCellEditComplete : null;
                    let filterElement = col.type === "dropdown" ? dropdownRowFilterTemplate : null;
                    let color = col.editable ? "green" : "black";

                    if (col.customCol) {
                        return col.customCol;
                    } else {
                        return (
                            <Column style={{ color: color }} key={col.name} field={col.name} header={col.label} sortable={col.sortable} editor={editor} onCellEditComplete={editComplete} body={mainBodyTemplate} filter={col.filter} filterMenuStyle={{ width: "14rem" }} filterElement={filterElement} />
                        );
                    }
                })}
            </DataTable>
        );
    }

    return <React.Fragment>{view}</React.Fragment>;
};

export default CustomDataTable;
