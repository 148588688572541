import React, { useState } from "react";
import PropTypes from "prop-types";
import { Toolbar } from "primereact/toolbar";
import ExportAllJson from "./ExportAllJson";
import FilterResults from "./FilterResults";
import { Space } from "antd";

const ToolbarResults = ({ results, setFltResults }) => {
    const right = null;
    const left = (
        <Space>
            <ExportAllJson results={results} />
            <FilterResults results={results} setFltResults={setFltResults} />
        </Space>
    );

    return <Toolbar style={{ marginBottom: "15px" }} left={left} right={right} />;
};

ToolbarResults.propTypes = {};

export default ToolbarResults;
